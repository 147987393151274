import React, { Fragment,Component } from 'react'
import Headernav from '../Component/Headernav'
import QG from '../Component/QG.js'

class Qiguan extends Component {
    constructor(props) {
        super(props)

        this.state = {
            
        }
    }

    /**
     *
     *
     * @return {*} 其观
     * @memberof Qiguan zxy
     */
    render() {
        return (
            <Fragment>
                <Headernav />
                <QG />
            </Fragment>
        )
    }
}

export default Qiguan