import React, { Component, Fragment } from 'react'
import { Pagination,message } from 'antd'
import '../Component-css/ZlList.css'
import { withRouter } from 'react-router-dom'
import api from '../api/api'
import { Spin,Space} from 'antd'


class ZlList extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selectList:[],
            page:1,
            ps:10,
            search:'',
            total:0,
            zlwyid:'',
            id:'',
            loadingCompent:false
        }
        this.onPageChange = this.onPageChange.bind(this)
    }

    shouldComponentUpdate(nextprops,nextstate){
        if(nextprops !== this.props){
            var search = this.props.match.params.search
            var zlwyid = this.props.match.params.zlwyid
            this.setState({
                search : search,
                zlwyid:zlwyid
            })
            api.post({
                str:'/retrieve/patentRetrievalSimple'
            },{
                page:this.state.page,
                ps:this.state.ps,
                type:1,
                search:search
           })
           .then(res => res.json())
           .then(res => {
               if(res.code === 200){
                   this.setState({
                       total:res.data.total,
                       selectList:res.data.dataList
                   })
               }else if(res.code === 4000){
                message.error(res.msg)
            }else if(res.code === 4001){
                message.error(res.msg)
            }
           })
        }
        return true
    }

    componentDidMount() {
        var _search = this.props.match.params.search
        var zlwyid = this.props.match.params.zlwyid
        this.setState({
            search : _search,
            zlwyid:zlwyid
        })
        var search = _search.replace('_','/')
        api.post({
            str:'/retrieve/patentRetrievalSimple'
        },{
            page:this.state.page,
            ps:this.state.ps,
            type:1,
            search:search
       })
       .then(res => res.json())
       .then(res => {
           if(res.code === 200){
               this.setState({
                   total:res.data.total,
                   selectList:res.data.dataList,
                   loadingCompent:true
               })
           }else if(res.code === 4000){
            message.error(res.msg)
        }else if(res.code === 4001){
            message.error(res.msg)
        }
       })
    }

    onPageChange(pageNumber) {
        this.setState({
            page: pageNumber + 1,
            loadingCompent:false
        })
        var search = this.state.search
        var _search = search.replace('_','/')
        api.post({
            str: '/retrieve/patentRetrievalSimple'
        }, {
            ps:this.state.ps,
            page: pageNumber,
            search: _search,
            type: 1
        })
            .then(res => res.json())
            .then(res => {
                if (res.code === 200) {
                    if (res.data.dataList.length > 0) {
                        this.setState({
                            selectList: res.data.dataList,
                            loadingCompent:true
                        })
                    }else{
                        this.setState({
                            loadingCompent:true
                        })
                    }
                }else if(res.code === 4000){
                    message.error(res.msg)
                }else if(res.code === 4001){
                    message.error(res.msg)
                }
            })

    }

    listChange(index,zlwyid){
        var _search = this.props.match.params.search
        var search = _search.replace('_','/')
       this.props.history.push(`/Zlxq/${search}/${zlwyid}`)
       this.setState({
        currentIndex:index
       })
    }

    render() {
        return (
            <Fragment>
                <div className={this.state.loadingCompent === false ? 'displayFlex' : 'displayNone'} style={{ width: '292px', height: '500px', alignItems: 'center', justifyContent: 'center' }}>
                    <Space size="middle" >
                        <Spin size="large" />
                    </Space>
                </div>
                <div className={this.state.loadingCompent === true ? 'displayFlex' : 'displayNone'} style={{ width: '286px', height:'800px'}}></div>
                <div className={this.state.loadingCompent === true ? 'displayFlex' : 'displayNone'} style={{ width: '286px', height:'500px', boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.16)',backgroundColor:'#fff',alignItems:'center',flexDirection:'column',overflowY:'auto' ,overflowX:'hidden',position:'fixed'}}>
                    <div style={{ width: '100%', height: '36px', lineHeight: '36px', marginLeft: '8px', fontSize: '12px', color: '#8A8A8A' }}>PS：共检测到<div style={{ display: 'inline', width: '100%', height: '100%', color: '#00C8DF' }}>&lt; {this.state.total} &gt;</div>条信息</div>
                    {
                        this.state.selectList.map((item,index) => {
                            return <div key={index} onClick={this.listChange.bind(this,index,item.zlwyid)} style={{width:'100%',borderBottom:'1px solid #F2F2F2',display:'flex',height:'72px!important',flexDirection:'row',alignItems:'center',lineHeight:'36px'}} className={(index === this.state.currentIndex?'currentItem':'') + 'cousor'} >
                                <div className={this.state.currentIndex === index?'contentList':''} style={{width:'36px',height:'48px',color:"#646464",textAlign:'center',lineHeight:'48px',fontSize:'16px',backgroundColor:'#F1F1F1',borderRadius:' 0px 4px 4px 0px',fontWeight:'700'}}>{index+1}</div>
                                <div style={{width:'219px',display:'flex',flexDirection:'column',marginLeft:'7px',fontSize:'12px'}}>
                                    <div style={{width:'219px',overflow:'hidden',whiteSpace:'nowrap',textOverflow:'ellipsis',WebkitLineClamp:'2',WebkitBoxOrient:'2'}}>{item.zlmc}</div>
                                    <div>{item.zlbh}</div>
                                </div>
                            </div>
                        })
                    }
                    <Pagination value={this.state.page} simple pageSize={10} total={this.state.total} showSizeChanger={false} style={{marginTop:'36px',marginBottom:'36PX'}} onChange={this.onPageChange} />
                </div>
            </Fragment>
        )
    }
}

export default withRouter(ZlList)