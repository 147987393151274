import React, { useState, useEffect } from 'react'
import '../Component-css/PayConfirmContent.css'
import Fixednav from './Fixednav'
import Headernav from './Headernav'
import Icon from '@ant-design/icons'
import api from '../api/api'
import { message, Select } from 'antd'
import { useHistory } from 'react-router-dom'

const invoice = () => (
    <svg fill='currentColor' width='1em' height='1em' viewBox='0 0 1024 1024'>
        <path d="M109.714286 0h146.285714v1024H109.714286V0zM438.857143 475.428571h146.285714v548.571429H438.857143V475.428571zM768 768h146.285714v256h-146.285714v-256z" fill="#00C8DF"></path>
    </svg>
)

const addInvoice = () => (
    <svg fill='currentColor' width='1em' height='1em' viewBox='0 0 1024 1024'>
        <path d="M928.007758 320H95.992242A64.186182 64.186182 0 0 1 32.007758 256V95.992242a64.186182 64.186182 0 0 1 64.015515-64h831.984485a64.186182 64.186182 0 0 1 64.015515 64.015516V256a64.186182 64.186182 0 0 1-64.015515 64z" fill="#006773" ></path>
        <path d="M864.007758 256H159.992242a32.100848 32.100848 0 0 1-31.992242-32.007758v-63.984484c0-17.609697 14.398061-32.007758 32.007758-32.007758h703.984484c17.609697 0 32.007758 14.398061 32.007758 32.007758v63.984484a32.100848 32.100848 0 0 1-32.007758 32.007758z" fill="#FFFFFF" ></path>
        <path d="M813.118061 969.852121l-118.396122-88.762182a42.247758 42.247758 0 0 0-47.072969-2.466909l-115.106909 85.085091a40.029091 40.029091 0 0 1-41.053091 0l-115.106909-85.116121a42.309818 42.309818 0 0 0-47.135031 2.482424l-117.496242 88.110546a32.023273 32.023273 0 0 1-51.339636-25.382788l-0.41891-47.802182V184.537212c0-13.498182 11.046788-24.54497 24.51394-24.54497h654.987636c13.498182 0 24.513939 11.046788 24.51394 24.54497V896l-0.387879 48.872727a31.588848 31.588848 0 0 1-50.501818 24.948364" fill="#00C8DF" ></path>
        <path d="M672.007758 416.007758H351.976727a32.100848 32.100848 0 0 1-31.992242-32.007758c0-17.594182 14.398061-32.007758 32.007757-32.007758h320c17.594182 0 31.992242 14.413576 31.992243 32.007758a32.100848 32.100848 0 0 1-32.007758 32.007758z m0 256H351.976727a32.100848 32.100848 0 0 1-31.992242-32.007758c0-17.594182 14.398061-32.007758 32.007757-32.007758h320c17.594182 0 31.992242 14.413576 31.992243 32.007758a32.100848 32.100848 0 0 1-32.007758 32.007758z" fill="#FFFFFF" ></path>
    </svg>
)

const seleted = () => (
    <svg fill='currentColor' width='1em' height='1em' viewBox='0 0 1024 1024'>
        <path d="M512 512m-512 0a512 512 0 1 0 1024 0 512 512 0 1 0-1024 0Z" fill="#FFC817" ></path><path d="M1106.8416 136.533333H991.914667c-24.9856 0-48.776533 11.0592-63.488 29.969067L543.232 657.954133l-174.4896-222.72a80.6912 80.6912 0 0 0-63.488-30.0032H190.327467c-15.906133 0-25.258667 16.6912-15.906134 28.672l305.3568 389.495467c31.095467 39.936 95.573333 39.936 126.6688 0L1122.5088 165.205333c9.557333-11.946667 0.273067-28.637867-15.6672-28.637866z" fill="#FFFFFF" ></path>
    </svg>
)

const bottommark = () => (
    <svg fill='currentColor' width='2.2em' height='1em' viewBox='0 0 1024 1024'>
        <path d="M1409.19333 51.330668A165.880075 165.880075 0 0 1 1531.61237 0.019341c47.784384 0 88.514882 17.065851 122.41904 51.311327 35.041881 34.245475 52.562822 75.31729 52.562822 122.874129 0 47.784384-17.520941 88.742427-52.562822 122.987902L975.720707 979.712978c-14.56286 17.065851-32.652662 29.011947-54.383179 35.724516-21.84429 6.826341-44.71253 9.443104-68.94604 8.305381a228.34109 228.34109 0 0 1-71.676575-15.700584 171.796236 171.796236 0 0 1-60.754431-39.36523L52.571924 297.192699A165.766302 165.766302 0 0 1 0.009102 174.318569c0-47.784384 17.520941-88.742427 52.562822-122.874129C69.637776 34.151044 88.523984 21.294769 109.68564 12.648071 130.847296 4.456463 152.691585 0.019341 174.990964 0.019341c22.413151 0 44.371213 4.323349 66.215503 12.856275 21.730517 8.532926 41.071815 21.3892 58.023895 38.455052l554.981484 554.071305 554.981484-554.071305z" fill="#FFFFFF" ></path>
    </svg>
)

const Invoice = props => <Icon component={invoice} {...props} />;
const Add = props => <Icon component={addInvoice} {...props} />;
const Seleted = props => <Icon component={seleted} {...props} />;
const Bottommark = props => <Icon component={bottommark} {...props} />;

const {Option} = Select

function PayConfirmContent() {
    const [list, setList] = useState([])
    const [paylist, setPaylist] = useState([])
    const [paymethod, setPaymethod] = useState(0)
    const [sum,setSum] = useState(0)
    const [sumall,setSumall] = useState(0)
    const [invoice,setInvoice] = useState({})
    const [serviceCharge,setServiceCharge] = useState(0)
    const [remark,setRemark] = useState('')
    const hist = useHistory()

    useEffect(() => {
        handleInvoice(1, 10)
        let list = JSON.parse(localStorage.getItem('payList'))
        var s = 0
        var _s = 0
        list.forEach((element)=>{
            s = Number(element.money) + s
        })
        _s = s + 30*list.length
        list.forEach((element) => {
            element.date = element.pay_able[0].date
        })
        setPaylist(list)
        setSum(s)
        setSumall(_s)
        setServiceCharge(30*list.length)
    }, [])

    /**
     *  获取发票信息
     * @author zxy
     * @param {int} page
     * @param {int} ps
     */
    const handleInvoice = (page, ps) => {
        api.get({
            str: '/user/invoiceList'
        }, {
            page,
            ps
        })
            .then(res => res.json())
            .then(res => {
                if (res.code == 200) {
                    var list = res.data.list
                    list.forEach(element => {
                        element.selected = false
                    });
                    setList(list)
                } else {
                    message.error(res.msg)
                }
            })
    }

    /**
     * 添加发票
     * @author zxy
     */
    function handleAdd() {
        const invoice = 'invoice'
        hist.push(`/Grzx/${invoice}`)
    }

    /**
     * 选择器
     *
     * @param {*} value
     */
    function handleSelect(value){
        var data = [...paylist]
        var list = value.split(',')
        data.forEach((element)=>{
            if(element.zlid == list[3]){
                element.date = list[2]
            }
        })
        setPaylist(data)
    }


    /**
     * 发票选择
     * @author zxy
     * @param {index} index
     */
    function handleSelected(index,name,type,ein,email,id) {
        let ls = [...list]
        ls.forEach((element, i) => {
            if (i == index) {
            element.selected = !element.selectd
            } else {
                element.selected = false 
            }
        })
        let data = {
            invoice_name:name,
            invoice_type:type,
            invoice_ein:ein,
            email:email,
            invoice_id:id
        }
        setList(ls)
        setInvoice(data)
    }

    /**
     * 跳转支付页面
     * @author zxy
     */
    function handlePay() {
        let list = localStorage.getItem('payList')
        let invoice_id = invoice.invoice_id
        let invoice_name = invoice.invoice_name
        let invoice_ein = invoice.invoice_ein
        let email = invoice.email
        let invoice_type = invoice.invoice_type
        let arr = Object.keys(invoice)
        if(arr.length == 0){
            message.error('请选择发票信息')
        }else if(paymethod == 0){
            message.error('请选择支付方式')
        }else{
            api.post({
                str:'/order/createOrder'
            },{
                order_info:list,
                invoice_id,
                invoice_name,
                invoice_ein,
                email,
                invoice_type,
                remark:remark
            })
            .then(res => res.json())
            .then(res => {
                if(res.code == 200){
                    localStorage.setItem('order_sn',res.data.order_sn)
                    localStorage.setItem('money',res.data.total_fee + serviceCharge)
                    hist.push(`/Paying/${paymethod}`)
                }else{
                    message.error(res.msg)
                }
            })
        }
    }

    return (
        <div>
            <Headernav />
            <Fixednav />
            <div className='payconfirm-wrap'>
                <div className='payconfirm-invoice'>
                    <div className='payconfirm-invoice-header'>
                        <Invoice />
                        <h2>发票选择</h2>
                        <span>Invoice selection</span>
                    </div>
                    <div className='payconfirm-invoice-ul'>
                        <ul>
                            {
                                list.map((item, index) => {
                                    return <li key={index}>
                                        <div className='payconfirm-invoice-content'>
                                            <div>
                                                <h3>类型：</h3>
                                                <span>{item.get_name}</span>
                                            </div>
                                            <div>
                                                <h3>税号：</h3>
                                                <span>{item.ein}</span>
                                            </div>
                                            <div>
                                                <h3>抬头：</h3>
                                                <span>{item.name}</span>
                                            </div>
                                        </div>
                                        <div onClick={() => handleSelected(index,item.name,item.type,item.ein,item.email,item.id)} className='payconfirm-invoice-select'>
                                            <Seleted className={item.selected == true ? '' : 'displaynone'} style={{ fontSize: '3rem' }} />
                                            <p className={item.selected == false ? 'payconfirm-invoice-select-circle' : 'displaynone'}></p>
                                            <span>{item.type == 1 ? '企业发票' : '个人发票'}</span>
                                        </div>
                                    </li>
                                })
                            }
                            <div onClick={() => handleAdd()} className={(list.length <= 0 ? 'marginleft0' : '') + ' payconfirm-invoice-add'}>
                                <Add style={{ fontSize: '66px' }} />
                                <span>添加发票</span>
                            </div> 
                        </ul>
                    </div>
                </div>
                <div className='payconfirm-payment'>
                    <div className='payconfirm-invoice-header'>
                        <Invoice />
                        <h2>支付方式</h2>
                        <span>payment method</span>
                    </div>
                    <ul>
                        <li onClick={() => setPaymethod(1)}>
                            <img src='http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20220104/07bdf76a39d91339db0b3bc85003475ff84b968a.png' />
                            <Seleted className={paymethod == 1 ? '' : 'displaynone'} style={{ fontSize: '3rem', position: 'absolute', top: '-25px', right: '-15px' }} />
                            <span>微信支付</span>
                        </li>
                        <li onClick={() => setPaymethod(2)}>
                            <img src='http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20220104/4486dbce45ed523c2447eb3cf63368aa3a0ee8ff.png' />
                            <Seleted className={paymethod == 2 ? '' : 'displaynone'} style={{ fontSize: '3rem', position: 'absolute', top: '-25px', right: '-15px' }} />
                            <span>支付宝支付</span>
                        </li>
                    </ul>
                </div>
                <div className='payconfirm-order'>
                    <div className='payconfirm-invoice-header'>
                        <Invoice />
                        <h2>订单信息</h2>
                        <span>order information</span>
                    </div>
                    <ul>
                        {
                            paylist.map((item, index) => {
                                return <li key={index}>
                                    <div className='payconfirm-order-top'>
                                        <span>专利名称：{item.zlmc}</span>
                                        <span>待缴金额</span>
                                    </div>
                                    <div className='payconfirm-order-bottom'>
                                        <span className='payconfirm-order-bottom-span'>专利号：{item.zlid}</span>
                                        <span className='payconfirm-order-bottom-span'>专利类型：{item.zllx}</span>
                                        <span className='payconfirm-order-bottom-span'>缴费日期：{item.date}</span>
                                        <span className='payconfirm-order-bottom-span'>缴费项目：</span>
                                        <Select value={item.pay_able[0].category + ' ' + item.pay_able[0].money} className='payconfirm-order-bottom-select' onChange={handleSelect}>
                                            {
                                                item.pay_able.map((o,i) => {
                                                    return <Option className='payconfirm-order-bottom-option' key={i} value={o.category + ',' + o.money + ',' +o.date + ',' + item.zlid} >{o.category}  {o.money}</Option>
                                                })
                                            }
                                        </Select>
                                        <span className='payconfirm-order-bottom-span payconfirm-order-bottom-span2'>￥{item.money}</span>
                                    </div>
                                </li>
                            })
                        }
                    </ul>
                </div>
                <div className='payconfirm-information'>
                    <div className='payconfirm-information-remarks'>
                        <p>订单备注</p>
                        <input value={remark} placeholder='选填：对本次交易的说明' onChange={(e) => {setRemark(e.target.value)}} />
                    </div>
                    <div className='payconfirm-information-order'>
                        <p>共四件订单，总金额：</p>
                        <span>￥{sum}</span>
                    </div>
                    <div className='payconfirm-information-order'>
                        <p>手续费：</p>
                        <span>￥{serviceCharge}</span>
                    </div>  
                    <div className='payconfirm-information-order payconfirm-information-total'>
                        <p>总费用：</p>
                        <span>￥{sumall}</span>
                    </div>
                    <div onClick={() => handlePay()} className='payconfirm-information-button'>前往支付</div>
                </div>
            </div>
        </div>
        
    )
}

export default PayConfirmContent