import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import api from '../api/api'
import '../Component-css/ReleaseCollect.css'
import Headernav from './Headernav'
import { message,Pagination } from 'antd'

class ReleaseCollect extends Component {
    constructor(props) {
        super(props)

        this.state = {
            collectlist:[],
            page:1,
            total:0
        }
    }

    qs(){
        this.props.history.push('/Qishuo')
    }

    componentDidMount(){
        this.collectlist()
    }

    /**
     * 收藏列表
     * @author zxy
     * @return {*} 
     * @memberof ReleaseCollect
     */
    collectlist(){
        var page = this.state.page
        api.post({
            str:'/talk/getUserCollectArticle'
        },{
            page,
            ps:10
        })
        .then(res => res.json())
        .then(res => {
            if(res.code == 200){
                this.setState({
                    total:res.data.total,
                    collectlist:res.data.dataList,
                    page:page
                })
            }else{
                message.error(res.msg)
            }
        })
    }

    /**
     * 收藏列表分页处理
     * @author zxy
     * @return {*} 
     * @memberof ReleaseCollect
     */
    pagechange(pageNumber,pageSize){
       setTimeout(() => {
        this.setState({
            page:pageNumber
        })
        this.collectlist()
       }, 100);
       
    }

    /**
     * 取消收藏
     * @author zxy
     * @param {*} id
     * @memberof ReleaseCollect
     */
    handlecollectquite(id){
        api.post({
            str:'/talk/reviseCollect'
        },{
            article_id:id,
            is_collect:1
        })
        .then(res =>res.json())
        .then(res => {
            if(res.code == 200){
                this.collectlist()
            }else{
                message.error(res.msg)
            }
        })
    }

    /**
     * 其说详情
     * @author zxy
     * @param {*} id
     * @memberof ReleaseCollect
     */
    handledetail(id){
        this.props.history.push('/QishuoXq/' + id)
    }

    render() {
        return (
            <Fragment>
                <Headernav />
                <div className='qs-release-collect'>
                    <p>其说&gt;收藏</p>
                    <ul className={this.state.collectlist.length == 0 ? 'displaynone' : 'qs-release-collect-content'}>
                        {
                            this.state.collectlist.map((item,index) => {
                                return <li key={index} >
                                    <div>
                                        <img src={item.head} />
                                        <span>{item.name}</span>
                                        <p>发布时间：{item.add_time}</p>
                                    </div>
                                    <div>
                                        <p onClick={this.handledetail.bind(this,item.id)}>{item.title}</p>
                                        <div onClick={this.handlecollectquite.bind(this,item.id)}>已收藏</div>
                                    </div>
                                </li>
                            })
                        }
                    </ul>
                    <Pagination style={{marginTop:'30px'}} className={this.state.collectlist.length > 0 ? '' : 'displaynone'} onChange={this.pagechange.bind(this)} pageSize={10} current={this.state.page} defaultCurrent={1} total={this.state.total} />
                    <div className={this.state.collectlist.length == 0 ? 'qs-release-collect-content-null' : 'displaynone'}>
                        <img src='http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210720/5bcb89f9feae4f6f60c7ba5bad74b24c35139d7c.png' />
                        <span>您还没有收藏</span>
                        <div onClick={this.qs.bind(this)}>其说首页</div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default withRouter(ReleaseCollect)