import React, { Component, Fragment } from 'react'
import '../Component-css/PersionCenter.css'
import { withRouter } from 'react-router'
import { Cascader, message, Radio, Upload, Table, Pagination } from 'antd'
import options from '../Component/perisonoptions.js';
import 'moment/locale/zh-cn';
import Icon from '@ant-design/icons'
import locale from 'antd/es/date-picker/locale/zh_CN';
import api from '../api/api'
import ImgCrop from 'antd-img-crop';

const delect = () => (
    <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 1024 1024">
        <path d="M796.7744 128.238933H222.685867A95.675733 95.675733 0 0 0 127.010133 223.914667v31.914666h765.44V223.914667a95.675733 95.675733 0 0 0-95.675733-95.675734z m-167.1168-63.7952l14.062933 100.693334H375.808l14.062933-100.693334h239.8208zM637.269333 0.682667H382.1568c-26.282667 0-50.7904 21.2992-54.4768 47.4112l-18.6368 133.461333c-3.652267 26.043733 14.916267 47.342933 41.233067 47.342933h318.941866c26.282667 0 44.8512-21.2992 41.198934-47.377066l-18.670934-133.461334C688.128 21.981867 663.620267 0.682667 637.303467 0.682667z m175.445334 318.907733H206.711467a57.344 57.344 0 0 0-58.026667 63.556267l52.258133 574.532266c3.1744 34.952533 34.474667 63.556267 69.563734 63.556267h478.4128c35.054933 0 66.389333-28.603733 69.5296-63.556267l52.258133-574.532266a57.344 57.344 0 0 0-58.026667-63.556267zM382.122667 893.6448H286.481067l-31.880534-446.464h127.556267v446.464z m191.3856 0h-127.5904v-446.464h127.5904v446.464z m159.4368 0h-95.675734v-446.464h127.5904l-31.914666 446.464z" fill="#F92424" />
    </svg>
);

const addressadd = () => (
    <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 1024 1024">
        <path d="M339.072 18.005333c9.685333 0 18.901333 3.712 25.813333 10.453334l2.517334 2.602666 135.253333 159.829334h429.312c40.917333 0 74.026667 33.152 74.026667 74.112v666.88c0 40.96-33.109333 74.112-74.069334 74.112H92.16c-40.96 0-74.112-33.109333-74.112-74.069334V54.997333c0-19.029333 14.506667-35.029333 33.493333-36.864L55.04 18.005333h284.074667zM512 400.810667a37.12 37.12 0 0 0-37.12 37.12v111.146666H363.776l-3.498667 0.170667a37.077333 37.077333 0 0 0-33.493333 36.864l0.128 3.541333a37.12 37.12 0 0 0 36.949333 33.536h111.189334v111.146667a37.077333 37.077333 0 0 0 74.069333 0v-111.146667h111.189333l3.498667-0.213333a37.12 37.12 0 0 0 33.493333-36.906667l-0.128-3.498666a37.12 37.12 0 0 0-36.949333-33.493334h-111.189333v-111.189333A37.12 37.12 0 0 0 512 400.810667zM321.834667 92.16H92.16v98.730667h313.429333L321.877333 92.16h-0.042666z" fill="#00C8DF" />
    </svg>
);

const addressdelete = () => (
    <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 1024 1024">
        <path d="M550.4 908.8a22.784 22.784 0 0 0-3.2 6.4l-22.4 89.6s-1.621333 9.6 3.2 12.8c4.778667 4.778667 14.378667 4.778667 14.378667 4.778667l94.421333-20.778667 9.6-3.2c3.2-1.578667 6.4-4.778667 6.4-4.778667l326.4-313.6s6.4-6.4 8.021333-12.8c1.578667-6.4-6.4-11.221333-6.4-11.221333l-70.4-67.2s-4.821333-4.821333-12.8-4.821333c-8.021333 0-14.421333 6.4-14.421333 6.4l-328.021333 315.221333c0-1.621333-3.2 1.578667-4.778667 3.2zM427.178667 998.4l33.621333-131.2s1.578667-6.4 4.778667-11.178667c3.2-4.821333 8.021333-9.642667 8.021333-9.642666l420.778667-403.2s8.021333-6.4 11.221333-14.378667c3.2-8.021333 3.2-19.2 3.2-19.2V76.8c0-41.6-35.2-76.8-80-76.8H116.778667C73.6 0 36.778667 33.578667 36.778667 76.8v860.8c0 41.6 35.2 76.8 80 76.8H403.2s7.978667 0 14.378667-6.4c8.021333-3.2 9.6-9.6 9.6-9.6zM243.2 204.8h464c20.778667 0 38.4 16 38.4 36.778667 0 20.821333-17.621333 36.821333-38.4 36.821333H243.2c-20.821333 0-38.4-16-38.4-36.821333 0-20.778667 17.578667-36.778667 38.4-36.778667z m0 220.8h278.4c20.778667 0 38.4 16 38.4 36.821333 0 20.778667-16 36.778667-38.4 36.778667H243.2c-20.821333 0-38.4-16-38.4-36.778667 0-20.821333 17.578667-36.821333 38.4-36.821333z m-38.4 262.4c0-19.2 17.578667-36.821333 38.4-36.821333h113.578667c20.821333 0 38.4 16 38.4 36.821333s-17.578667 36.821333-38.4 36.821333H243.2c-20.821333 0-38.4-17.621333-38.4-36.821333z" fill="#00C8DF" />
    </svg>
);

const Delect = props => <Icon component={delect} {...props} />;
const Addressadd = props => <Icon component={addressadd} {...props} />;
const Addressdelete = props => <Icon component={addressdelete} {...props} />;

const onPreview = async file => {
    let src = file.url;
    if (!src) {
        src = await new Promise(resolve => {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = () => resolve(reader.result);
        });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
}

class PersionCenter extends Component {
    constructor(props) {
        super(props)

        this.state = {
            tag: '',
            sex: 0,
            username: '',
            mobile: '',
            pc_address: '',
            head: '',
            email: '',
            optionsaddress: [],
            address: '',
            ordertab: 0,
            iseditaddress: false,
            orderlist: [],
            nav: [
                {
                    text: '个人信息',
                    tag: 'information'
                }, {
                    text: '我的收藏',
                    tag: 'collect'
                }, {
                    text: '订单管理',
                    tag: 'order'
                }, {
                    text: '地址管理',
                    tag: 'address'
                }, {
                    text: '发票信息',
                    tag: 'invoice'
                }, {
                    text: '我的消息',
                    tag: 'news'
                }, {
                    text: '退出登录',
                    tag: 'exit'
                }
            ],
            columns: [
                {
                    title: '序号',
                    width: '5rem',
                    align: 'center',
                    key: 'index',
                    render: (text, record, index) => `${index + 1}`
                }, {
                    title: '专利号',
                    key: 'zlbh',
                    align: 'center',
                    width: '17.1rem',
                    dataIndex: 'zlbh',
                }, {
                    title: '专利名称',
                    key: 'zlmc',
                    width: '26rem',
                    align: 'center',
                    dataIndex: 'zlmc'
                }, {
                    title: '发明人',
                    key: 'fmr',
                    width: '14rem',
                    align: 'center',
                    dataIndex: 'fmr',
                    render: (text, record) => (
                        <div>
                            {
                                record.fmr.map((item, index) => {
                                    return <span key={index}>{item}</span>
                                })
                            }
                        </div>
                    )
                }, {
                    title: '专利类型',
                    key: 'zllx',
                    align: 'center',
                    width: '11.4rem',
                    dataIndex: 'zllx'
                }, {
                    title: '法律状态',
                    key: 'flzt',
                    align: 'center',
                    width: '9rem',
                    dataIndex: 'flzt'
                }, {
                    title: '管理',
                    key: 'program',
                    width: '7.5rem',
                    fixed:'right',
                    align: 'center',
                    render: (text, record) => (
                        <div onClick={this.collectexit.bind(this, record.zlwyid)} style={{ color: '#00C8DF', cursor: 'pointer' }}>取消</div>
                    )
                }
            ],
            collectList: [],
            total: 0,
            page: 1,
            imgchange: '',
            qq: '',
            orderTotal: 0,
            orderlist: [],
            pageorder: 1,
            order_sn: '',
            total_fee: '',
            add_time: '',
            pay_time: '',
            invoice_name: '',
            invoice_ein: '',
            order_email: '',
            invoice_type: 0,
            pc_mobile: '',
            order_username: '',
            pay_able: [],
            type_name: '',
            status_name: '',
            invoice_type_name: '',
            addresstag: 0,
            pageaddress: 1,
            totaladdress: 0,
            listaddress: [],
            isyzm: false,
            time: 60,
            province_name: '',
            city_name: '',
            area_name: '',
            isinvoiceAdd: 0,
            iseditinvoice: false,
            invoiceList: [],
            invoicetotal: 0,
            pageinvoice: 1,
            invoicetag: 0,
            invoicetype: 0,
            newsList:[],
            seeNum:0,
            newstag:1
        }
    }

    componentDidMount() {
        var value = this.props.match.params.tag
        setTimeout(() => {
            this.setState({
                tag: value
            })
        }, 100);
        this.user()
        if (value == 'information') {
            this.useinformation()
        } else if (value == 'collect') {
            this.mycollection()
        } else if (value == 'order') {
            this.orderlist()
        } else if (value == 'address') {
            this.useraddress()
        } else if (value == 'invoice') {
            this.invoiceList()
        } else if (value == 'news') {
            this.newslist()
        } else if (value == 'exit') {
            api.post({
                str:'/login/layout'
            })
            .then(res => res.json())
            .then(res => {
                if(res.code == 200){
                    this.props.history.push('/')
                    localStorage.removeItem('token')
                }else{
                    message.error(res.msg)
                }
            })
        }
    }

    /**
    * nav change by clicked
    * @description 'tag' as a param of router
    * @param {*} value
    * @memberof PersionCenterAside
    */
    handlenav(value) {
        if (value == 'information') {
            this.user()
            this.useinformation()
        } else if (value == 'collect') {
            this.mycollection()
        } else if (value == 'order') {
            this.orderlist()
        } else if (value == 'address') {
            this.useraddress()
        } else if (value == 'invoice') {
            this.invoiceList()
        } else if (value == 'news') {
            this.newslist()
        } else if (value == 'exit') {
            api.post({
                str:'/login/layout'
            })
            .then(res => res.json())
            .then(res => {
                if(res.code == 200){
                    this.props.history.push('/')
                    localStorage.removeItem('token')
                }else{
                    message.error(res.msg)
                }
            })
        }
        this.props.history.push(`/Grzx/${value}`)
        this.setState({
            tag: value,
            ordertab: 0
        })
    }

    /**
     * 订单列表
     * @author zxy
     * @memberof PersionCenter
     */
    orderlist() {
        api.post({
            str: '/order/getUserOrderList'
        }, {
            page: this.state.pageorder,
            ps: 10,
            type: 1,
            time_sort: 1
        })
            .then(res => res.json())
            .then(res => {
                if (res.code == 200) {
                    this.setState({
                        orderTotal: res.data.total,
                        orderlist: res.data.dataList,
                        pageorder: this.state.pageorder
                    })
                } else {
                    message.error(res.msg)
                }
            })
    }

    /**
     * 收藏列表
     * @author zxy
     * @memberof PersionCenter
     */
    mycollection() {
        api.post({
            str: '/user/favoriteList'
        }, {
            ps: 10,
            page: this.state.page
        })
            .then(res => res.json())
            .then(res => {
                if (res.code == 200) {
                    this.setState({
                        collectList: res.data.list,
                        total: res.data.total,
                        page: this.state.page
                    })
                } else {
                    message.error(res.msg)
                }
            })
    }

    /**
     * 用户信息
     *
     * @memberof PersionCenter
     */
    user() {
        api.get({
            str: '/user/getUserInfo'
        })
            .then(res => res.json())
            .then(res => {
                if (res.code == 200) {
                    this.setState({
                        username: res.data.username,
                        mobile: res.data.mobile,
                        pc_address: res.data.pc_address,
                        head: res.data.head,
                        email: res.data.email,
                        sex: res.data.sex
                    })
                } else {
                    message.error(res.msg)
                }
            })
    }

    /**
     * 取消收集
     *
     * @param {*} id
     * @memberof PersionCenter
     */
    collectexit(id) {
        api.post({
            str: '/user/delFavoriteOne'
        }, {
            id
        })
            .then(res => res.json())
            .then(res => {
                if (res.code == 200) {
                    this.mycollection()
                } else {
                    message.error(res.msg)
                }
            })
    }

    /**
     * 用户信息-address处理
     *
     * @memberof PersionCenter
     */
    useinformation() {
        options.forEach(element => {
            if (element.children.length > 0) {
                element.children.forEach(item => {
                    item.children = []
                })
            }
        });
        this.setState({
            optionsaddress: options
        })
    }

    /**
     * 地址修改
     *
     * @param {*} value
     * @memberof PersionCenter
     */
    handleaddress(value) {
        var address = value.join('/')
        this.setState({
            address: address
        })
    }

    /**
     * 头像上传
     *
     * @param {*} info
     * @memberof PersionCenter
     */
    handleimg(info) {
        if (info.file.status == 'done') {
            this.setState({
                imgchange: info.file.response.data.url
            })
        }
    }

    /**
     * 上传前类型处理
     *
     * @param {*} file
     * @param {*} fileList
     * @memberof PersionCenter
     */
    beforeUpload(file, fileList) {
        var limit = file.size / 1024 / 1024
        if (file.type == 'image/png' || 'image/jpeg') {
            if (limit < 2) {
                return true
            } else {
                message.error('文件过大，超过2M')
            }
        } else {
            message.error('请上传图片文件(jpg,png)')
        }
    }

    /**
     * 修改姓名
     *
     * @param {*} e
     * @memberof PersionCenter
     */
    handleuser(e) {
        this.setState({
            username: e.target.value
        })
    }

    /**
     * 修改性别
     *
     * @param {*} e
     * @memberof PersionCenter
     */
    handlesex(e) {
        this.setState({
            sex: e.target.value
        })
    }

    /**
     * 修改QQ
     * @description QQ需做正则验证
     * @description 验证规则
     * @description 1.开头不能为0
     * @description 2.5-11位数
     ******************************************************************************
     * @memberof PersionCenter
     * @param {*} e
     */
    handleqq(e) {
        var qq = e.target.value
        this.setState({
            qq: qq
        })
    }

    /**
     * 修改邮箱
     * @description 邮箱需做正则验证
     * @description 验证规则:
     *                  1. 开头必须是字母，数字，下划线和中划线
     *                  2. 开头字母等符号多次出现
     *                  3. 域名多次出现，@ . 必须有
     *************************************************************************
     * @memberof PersionCenter
     * @param {*} e
     */
    handleemail(e) {
        this.setState({
            email: e.target.value
        })
    }

    /**
     * 确认修改
     *
     * @memberof PersionCenter
     */
    saveEditor() {
        var qq = this.state.qq,
            email = this.state.email
        var number = qq.replace(/\s/g, '')
        var regs = /[1-9][0-9]{4,10}/
        var email_sure = email.replace(/\s/g, '')
        var reg = /^([a-z0-9_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/
        var sex = this.state.sex
        var name = this.state.username
        var img = this.state.imgchange
        var address = this.state.address
        if (!regs.test(number)) {
            message.error('请输入正确的QQ')
        } else if (!reg.test(email_sure)) {
            message.error('请输入正确的邮箱')
        } else {
            api.post({
                str: '/user/editUserInfo'
            }, {
                head: img,
                username: name,
                address: address,
                email: email_sure,
                qq: number,
                sex: sex
            })
                .then(res => res.json())
                .then(res => {
                    if (res.code == 200) {
                        this.user()
                    } else {
                        message.error(res.msg)
                    }
                })
        }
    }

    /**
     * 收藏分页
     * @author zxy
     * @param {*} pageNumber
     * @param {*} pageSize
     * @memberof PersionCenter
     */
    handlechange(pageNumber, pageSize) {
        this.setState({
            page: pageNumber
        })
        setTimeout(() => {
            this.mycollection()
        }, 100);
    }

    /**
     * 订单详情
     *
     * @param {*} order_sn
     * @memberof PersionCenter
     */
    orderdetail(order_sn) {
        api.post({
            str: '/order/getOrderInfo'
        }, {
            order_sn
        })
            .then(res => res.json())
            .then(res => {
                if (res.code == 200) {
                    this.setState({
                        order_sn: res.data.order_sn,
                        total_fee: res.data.total_fee,
                        add_time: res.data.add_time,
                        pay_time: res.data.pay_time,
                        invoice_name: res.data.invoice_name,
                        invoice_ein: res.data.invoice_ein,
                        order_email: res.data.email,
                        invoice_type: res.data.invoice_type,
                        pc_mobile: res.data.pc_mobile,
                        order_username: res.data.username,
                        pay_able: res.data.pay_able,
                        type_name: res.data.type_name,
                        status_name: res.data.status_name,
                        invoice_type_name: res.data.invoice_type_name,
                        ordertab: 1
                    })
                }
            })
    }

    /**
     * 打开地址编辑面板
     *
     * @memberof PersionCenter
     */
    handleeditaddress() {
        this.setState({
            iseditaddress: !(this.state.iseditaddress)
        })
    }

    /**
     * 获取地址列表
     *
     * @memberof PersionCenter
     */
    useraddress() {
        api.get({
            str: '/user/addressList'
        }, {
            page: this.state.pageaddress,
            ps: 10
        })
            .then(res => res.json())
            .then(res => {
                if (res.code == 200) {
                    this.setState({
                        pageaddress: res.data.page,
                        totaladdress: res.data.total,
                        listaddress: res.data.list
                    })
                } else {
                    message.error(res.msg)
                }
            })
    }

    /**
     * 删除地址信息
     *
     * @param {*} id
     * @memberof PersionCenter
     */
    delectaddress(id) {
        api.post({
            str: '/user/delAddressOne'
        }, {
            id
        })
            .then(res => res.json())
            .then(res => {
                if (res.code == 200) {
                    this.useraddress()
                    this.setState({
                        iseditaddress: false
                    })
                } else {
                    message.error(res.msg)
                }
            })
    }

    /**
     * 打开新添地址
     *
     * @memberof PersionCenter
     */
    handleaddressAdd() {
        this.setState({
            addresstag: 1
        })
    }

    /**
     * 地址管理验证码倒计时
     * @description 弃用
     * @memberof PersionCenter
     */
    lastTime() {
        var time = 60
        var interval = setInterval(() => {
            if (time > 0) {
                time--
                this.setState({
                    time: time,
                    isyzm: true
                })
            } else {
                this.setState({
                    isyzm: false
                })
                clearInterval(interval)
            }
        }, 1000)
    }

    /**
     * 验证码地址管理
     * @description 弃用
     * @memberof PersionCenter
     */
    handleyzm() {

    }

    /**
     * 退出添加地址
     *
     * @memberof PersionCenter
     */
    addressaddexit() {
        this.setState({
            addresstag: 0
        })
    }

    /**
     * 省市区地址选择
     *
     * @memberof PersionCenter
     */
    handleaddressoptions(value) {
        this.setState({
            province_name: value[0],
            city_name: value[value.length - 1],
            area_name: value[value.length - 2]
        })
    }

    /**
     * 地址管理新增保存
     *
     * @memberof PersionCenter
     */
    saveaddress() {
        const regs = /^((13[0-9])|(14[57])|(15[^4,\\D])|(16[0-9])|(17[0-1,6-8])|(18[0-9])|(19[0-9]))\d{8}$/
        var name = this.refs.addressname.value
        var mobile = this.refs.addressmobile.value
        var detail = this.refs.addressdetail.value
        var province_name = this.state.province_name
        var city_name = this.state.city_name
        var area_name = this.state.area_name
        if (name == ' ' || !name) {
            message.error('请输入收票人姓名')
        } else if (mobile == ' ' || !mobile) {
            message.error('请输入手机号')
        } else if (!regs.test(mobile)) {
            message.error('请正确输入手机号')
        } else if (province_name == '' || city_name == '' || area_name == '') {
            message.error('请选择省市区')
        } else if (detail == '' || !detail) {
            message.error('请填写详细地址')
        } else {
            api.post({
                str: '/user/addAddress'
            }, {
                name,
                address: detail,
                mobile,
                province_name,
                city_name,
                area_name
            })
                .then(res => res.json())
                .then(res => {
                    if (res.code == 200) {
                        this.setState({
                            addresstag: 0
                        })
                        setTimeout(() => {
                            this.useraddress()
                        }, 100)
                    } else {
                        message.error(res.msg)
                    }
                })
        }
    }

    /**
     * 打开发票编辑
     *
     * @memberof PersionCenter
     */
    handleInvoice() {
        this.setState({
            iseditinvoice: !(this.state.iseditinvoice),
            isinvoiceAdd: 0
        })
    }


    /**
     * 删除发票信息
     *
     * @param {*} id
     * @memberof PersionCenter
     */
    delectinvoice(id) {
        api.post({
            str: '/user/delInvoiceOne'
        }, {
            id
        })
            .then(res => res.json())
            .then(res => {
                if (res.code == 200) {
                    this.invoiceList()
                } else {
                    message.error(res.msg)
                }
            })
    }

    /**
     * 发票列表
     * 
     * @memberof PersionCenter
     */
    invoiceList() {
        api.get({
            str: '/user/invoiceList'
        }, {
            page: this.state.pageinvoice,
            ps: 10
        })
            .then(res => res.json())
            .then(res => {
                if (res.code == 200) {
                    this.setState({
                        invoicetotal: res.data.total,
                        pageinvoice: res.data.page,
                        invoiceList: res.data.list
                    })
                } else {
                    message.error(res.msg)
                }
            })
    }

    /**
     * 打开发票新增
     *
     * @memberof PersionCenter
     */
    handleinvoiceadd() {
        this.setState({
            isinvoiceAdd: 1,
            iseditinvoice: false
        })
    }

    /**
     * 发票类型选择
     *
     * @memberof PersionCenter
     */
    handleinvoicetype(e) {
        this.setState({
            invoicetype: e.target.value
        })
    }

    /**
     * 新增发票保存
     *
     * @memberof PersionCenter
     */
    invoiceSave() {
        var reg = /^([a-z0-9_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/
        var email = (this.refs.invoiceemail.value).replace(/\s/g, '')
        var ein = this.refs.ein.value
        var invoicename = this.refs.invoicename.value
        var type = this.state.invoicetype
        if (invoicename == '' || !invoicename) {
            message.error('请填写抬头名称')
        } else if (email == ' ' || !email) {
            message.error('请输入邮箱')
        } else if (!reg.test(email)) {
            message.error('请正确输入邮箱')
        } else if (type == 0) {
            message.error('请选择发票对象')
        } else if (type == 1 && ein == '') {
            message.error('企业请输入税号')
        } else {
            api.post({
                str: '/user/addInvoice'
            }, {
                name: invoicename,
                type,
                ein,
                email
            })
                .then(res => res.json())
                .then(res => {
                    if (res.code == 200) {
                        this.invoiceList()
                        this.setState({
                            isinvoiceAdd: 0,
                            iseditinvoice: false
                        })
                    } else {
                        message.error(res.msg)
                    }
                })
        }
    }

    /**
     * 取消新增
     *
     * @memberof PersionCenter
     */
    invoiceExit() {
        this.setState({
            isinvoiceAdd: 0,
            iseditinvoice: false
        })
    }

    /**
     * 个人消息列表
     *
     * @memberof PersionCenter
     */
    newslist(){
        api.post({
            str:'/notice/getNotice'
        },{
            page:1,
            ps:50,
            type:1
        })
        .then(res => res.json())
        .then(res => {
            if(res.code == 200 ){
                this.setState({
                    newsList:res.data.dataList,
                    seeNum:res.data.seeNum
                })
            }else{
                message.error(res.msg)
            }
        })
    }

    /**
     * 个人消息tag切换
     *
     * @memberof PersionCenter
     */
    handlenewstag(tag){
        api.post({
            str:'/notice/getNotice'
        },{
            page:1,
            ps:50,
            type:tag
        })
        .then(res => res.json())
        .then(res => {
            if(res.code == 200 ){
                this.setState({
                    newsList:res.data.dataList,
                    seeNum:res.data.seeNum,
                    newstag:tag
                })
            }else{
                message.error(res.msg)
            }
        })
    }

    /**
     * 查阅
     *
     * @memberof PersionCenter
     */
    handleSaw(id){
        api.post({
            str:'/notice/getNoticeInfo'
        },{
            id
        })
        .then(res => res.json())
        .then(res => {
            if(res.code == 200){
                this.newslist()
            }
        })
    }

    render() {
        const props = {
            name: 'file',
            action: 'https://zlbb.qzhip.com/index.php/index/base/uploadFile',
            listType: 'text',
            maxCount: 1,
            onChange: this.handleimg.bind(this)
        }
        return (
            <Fragment>
                <div className='persion-center-wrap'>
                    <div className='persion-center'>
                        <div className='persion-center-aside-wrap'>
                            <div className='persion-center-aside-title'>
                                <img src={this.state.head} />
                                <p>{this.state.username}</p>
                                <span>{this.state.pc_address}</span>
                            </div>
                            <div className='persion-center-aside-nav'>
                                {
                                    this.state.nav.map((item, index) => {
                                        return <li onClick={this.handlenav.bind(this, item.tag)} className={(this.state.tag == item.tag ? 'active-persion' : '') + ' persion-center-aside-nav-li'} key={index}>{item.text}</li>
                                    })
                                }
                            </div>
                        </div>
                        <div className='persion-center-content'>
                            <div className={this.state.tag == 'information' ? ' persion-center-content-information' : 'displaynone'}>
                                <p>个人信息</p>
                                <div className='persion-center-content-information-div'>
                                    <span className='persion-center-content-information-title'>我的头像</span>
                                    <img src={this.state.imgchange != '' ? this.state.imgchange : null} className='persion-center-content-information-second persion-center-content-information-headimg' />
                                    <ImgCrop rotate>
                                        <Upload beforeUpload={this.beforeUpload.bind(this)} onPreview={onPreview} {...props} className='persion-center-content-information-button'>点击更换</Upload>
                                    </ImgCrop>
                                </div>
                                <div className='persion-center-content-information-div'>
                                    <span className='persion-center-content-information-title'>我的昵称</span>
                                    <input onChange={this.handleuser.bind(this)} className='persion-center-content-information-second  persion-center-content-information-input' value={this.state.username} />
                                </div>
                                <div className='persion-center-content-information-div'>
                                    <span className='persion-center-content-information-title'>我的性别</span>
                                    <Radio.Group onChange={this.handlesex.bind(this)} className='persion-center-content-information-second' value={this.state.sex}>
                                        <Radio value={1}>男</Radio>
                                        <Radio value={2}>女</Radio>
                                    </Radio.Group>
                                </div>
                                <div className='persion-center-content-information-div'>
                                    <span className='persion-center-content-information-title'>我的qq</span>
                                    <input type='text' title='请输入qq' onChange={this.handleqq.bind(this)} className='persion-center-content-information-second persion-center-content-information-input' value={this.state.qq} />
                                </div>
                                <div className='persion-center-content-information-div'>
                                    <span className='persion-center-content-information-title'>我的电话</span>
                                    <span className='persion-center-content-information-second persion-center-content-information-input'>{this.state.mobile}</span>
                                </div>
                                <div className='persion-center-content-information-div'>
                                    <span className='persion-center-content-information-title'>我的邮箱</span>
                                    <input onChange={this.handleemail.bind(this)} className='persion-center-content-information-second persion-center-content-information-input' value={this.state.email} />
                                </div>
                                <div className='persion-center-content-information-div'>
                                    <span className='persion-center-content-information-title'>所在城市</span>
                                    <Cascader className='persion-center-content-information-second persion-center-content-information-cascader' options={this.state.optionsaddress} changeOnSelect={true} onChange={this.handleaddress.bind(this)} />
                                </div>
                                <div className='persion-center-content-information-save'>
                                    <div onClick={this.saveEditor.bind(this)}>保存</div>
                                </div>
                            </div>

                            <div className={this.state.tag == 'collect' ? ' persion-center-content-collect' : 'displaynone'}>
                                <div className='persion-center-content-collect-title'>
                                    <p>我的收藏</p>
                                </div>
                                <Table rowKey='id' bordered={false} pagination={false} className='persion-center-content-collect-table' scroll={{x:1024}} columns={this.state.columns} dataSource={this.state.collectList} ></Table>
                                <Pagination defaultCurrent={1} current={this.state.page} onChange={this.handlechange.bind(this)} total={this.state.total} style={{ marginTop: 30 }} />
                            </div>

                            <div className={this.state.tag == 'order' ? ' persion-center-content-order' : 'displaynone'}>
                                <div className={this.state.ordertab == 0 ? 'persion-center-content-order-program' : 'displaynone'}>
                                    <div className='persion-center-content-order-program-title'>
                                        <p>订单管理</p>
                                        {/* <div className='persion-center-content-order-program-title-div'>
                                            <icon></icon>
                                            <span>编辑</span>
                                        </div> */}
                                    </div>
                                    <ul>
                                        {
                                            this.state.orderlist.length > 0 ? this.state.orderlist.map((item, index) => {
                                                return <li onClick={this.orderdetail.bind(this, item.order_sn)} key={index}>
                                                    <div className='persion-center-content-order-program-li-div1'>
                                                        <p>订单号：</p>
                                                        <span>{item.order_sn}</span>
                                                    </div>
                                                    <div className='persion-center-content-order-program-li-div2'>
                                                        <p>专利号：</p>
                                                        <span>{item.order_sn}</span>
                                                    </div>
                                                    <div className='persion-center-content-order-program-li-div3'>
                                                        <p>支付状态：</p>
                                                        <span className={item.status == 1 ? 'colorred' : 'colorgreen'}>{item.status_name}</span>
                                                    </div>
                                                    <div className='persion-center-content-order-program-li-div1'>
                                                        <p>订单时间：</p>
                                                        <span>{item.add_time}</span>
                                                    </div>
                                                    <div className='persion-center-content-order-program-li-div2'>
                                                        <p>订单类型：</p>
                                                        <span>{item.type_name}</span>
                                                    </div>
                                                    <div className='persion-center-content-order-program-li-div3'>
                                                        <p>订单金额：</p>
                                                        <span>￥{item.total_fee}</span>
                                                    </div>
                                                </li>
                                            }) : <div className='persion-center-content-order-program-emplt'>
                                                <img src='http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20211202/b096cbabe743ab458a7ee6a727cd2c3ac16d89ee.png' />
                                            </div>
                                        }
                                    </ul>
                                </div>
                                <div className={this.state.ordertab == 1 ? 'persion-center-content-order-detail' : 'displaynone'}>
                                    <div className='persion-center-content-order-detail-title'>
                                        <p>订单管理-详情</p>
                                    </div>
                                    <div className='persion-center-content-order-detail-content1'>
                                        <div style={{ borderBottom: '0.5px solid #E0E0E0' }}>
                                            <h3>用户昵称：</h3>
                                            <span>{this.state.username}</span>
                                        </div>
                                        <div style={{ borderBottom: '0.5px solid #E0E0E0', borderLeft: '0.5px solid #E0E0E0' }}>
                                            <h3>邮箱地址：</h3>
                                            <span>{this.state.order_email}</span>
                                        </div>
                                        <div>
                                            <h3>联系电话：</h3>
                                            <span>{this.state.pc_mobile}</span>
                                        </div>
                                        <div style={{ borderLeft: '0.5px solid #E0E0E0' }}>
                                            <h3>用户类型：</h3>
                                            <span>超级会员</span>
                                        </div>
                                    </div>
                                    <div className='persion-center-content-order-detail-content2'>
                                        <ul>
                                            <li>
                                                <h3>订单号：</h3>
                                                <span>{this.state.order_sn}</span>
                                            </li>
                                            <li>
                                                <h3>订单类型：</h3>
                                                <span>{this.state.type_name}</span>
                                            </li>
                                            <li>
                                                <h3>下单时间：</h3>
                                                <span>{this.state.add_time}</span>
                                            </li>
                                        </ul>
                                        <ul>
                                            <li>
                                                <h3>订单状态：</h3>
                                                <span>{this.state.pay_time}</span>
                                            </li>
                                            <li>
                                                <h3>订单金额：</h3>
                                                <span>￥{this.state.total_fee}</span>
                                            </li>
                                            <li>
                                                <h3>支付时间：</h3>
                                                <span>{this.state.pay_time}</span>
                                            </li>
                                        </ul>
                                        <div>
                                            <h3>订单内容：</h3>
                                            {
                                                this.state.pay_able.map((item, index) => {
                                                    return <span key={index}>{item}</span>
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div className='persion-center-content-order-detail-content3'>
                                        <ul>
                                            <li>
                                                <h3>发票类型：</h3>
                                                <span>{this.state.invoice_type_name}</span>
                                            </li>
                                            <li>
                                                <h3>税号：</h3>
                                                <span>{this.state.invoice_ein}</span>
                                            </li>
                                            <li>
                                                <h3>发票抬头：</h3>
                                                <span>{this.state.invoice_name}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className={this.state.tag == 'address' ? ' persion-center-content-address' : 'displaynone'}>
                                <div className={this.state.addresstag == 0 ? ' persion-center-content-address-list' : 'displaynone'}>
                                    <div className='persion-center-content-address-list-title'>
                                        <p>地址管理</p>
                                        <div onClick={this.handleaddressAdd.bind(this)} className={(this.state.addresstag == 1 ? 'activeaddress' : '') + ' persion-center-content-address-list-button'} >
                                            <Addressadd style={{ fontSize: '2.4rem' }} />
                                            <span>新增</span>
                                        </div>
                                        <div onClick={this.handleeditaddress.bind(this)} className={(this.state.iseditaddress == true ? 'activeaddress' : '') + ' persion-center-content-address-list-button'}>
                                            <Addressdelete style={{ fontSize: '2.4rem' }} />
                                            <span>编辑</span>
                                        </div>
                                    </div>
                                    <ul>
                                        {
                                            this.state.listaddress.length > 0 ? this.state.listaddress.map((item, index) => {
                                                return <li key={index}>
                                                    <b className='persion-center-content-address-list-li-b'>{index + 1}</b>
                                                    <img className='persion-center-content-address-list-li-img1' src={require('../images/agentbg.png')} />
                                                    <img className='persion-center-content-address-list-li-img2' src={require('../images/agentspan.png')} />
                                                    <Delect onClick={this.delectaddress.bind(this, item.id)} className={this.state.iseditaddress == true ? ' ' : 'displaynone'} style={{ marginTop: 'auto', marginBottom: 'auto', fontSize: '3rem', marginLeft: '2.3rem', cursor: 'pointer' }} />
                                                    <div className='persion-center-content-address-list-li-wrap'>
                                                        <div>
                                                            <p>姓名：</p>
                                                            <span>{item.name}</span>
                                                        </div>
                                                        <div>
                                                            <p>电话号码：</p>
                                                            <span>{item.mobile}</span>
                                                        </div>
                                                        <div>
                                                            <p>省市区县：</p>
                                                            <span>{item.province_name}{item.city_name}{item.area_name}</span>
                                                        </div>
                                                        <div>
                                                            <p>详细地址：</p>
                                                            <span className='persion-center-content-address-list-li-wrap-detail-address'>{item.address}</span>
                                                        </div>
                                                    </div>
                                                </li>
                                            }) : null
                                        }
                                    </ul>
                                </div>
                                <div className={this.state.addresstag == 1 ? ' persion-center-content-address-add' : 'displaynone'}>
                                    <div className='persion-center-content-address-list-title'>
                                        <p>地址管理-新建</p>
                                        <div className={(this.state.addresstag == 1 ? 'activeaddress' : '') + ' persion-center-content-address-list-button'}>
                                            <Addressadd style={{ fontSize: '2.4rem' }} />
                                            <span >新增</span>
                                        </div>
                                    </div>
                                    <div className='persion-center-content-address-list-input'>
                                        <span className='persion-center-content-address-list-span'>姓名：</span>
                                        <input ref='addressname' />
                                    </div>
                                    <div className='persion-center-content-address-list-input'>
                                        <span className='persion-center-content-address-list-span'>电话号码：</span>
                                        <input ref='addressmobile' />
                                    </div>
                                    {/* <div className='persion-center-content-address-list-input2'>
                                        <span className='persion-center-content-address-list-span'>验证码：</span>
                                        <input />
                                        <div onClick={this.state.isyzm == false ? this.handleyzm.bind(this): ''}>{this.state.isyzm == false ? '获取验证码':`${this.state.time}S`}</div>
                                    </div> */}
                                    <div className='persion-center-content-address-list-input'>
                                        <span className='persion-center-content-address-list-span'>省市区县：</span>
                                        <Cascader className='persion-center-content-address-list-cascader' onChange={this.handleaddressoptions.bind(this)} options={options} />
                                    </div>
                                    <div className='persion-center-content-address-list-input'>
                                        <span className='persion-center-content-address-list-span'>详细地址：</span>
                                        <input ref='addressdetail' />
                                    </div>
                                    <div className='persion-center-content-address-list-input marginTop'>
                                        <p>注：地址信息用来邮寄发票，请务必填写真实可用的信息</p>
                                        <div onClick={this.addressaddexit.bind(this)}>取消</div>
                                        <div onClick={this.saveaddress.bind(this)}>保存</div>
                                    </div>
                                </div>
                            </div>

                            <div className={this.state.tag == 'invoice' ? 'persion-center-content-address-list-fpxx' : 'displaynone'}>
                                <div className='persion-center-content-address-list-fpxx-title'>
                                    <p>{this.state.isinvoiceAdd == 0 ? '发票信息' : '发票信息-新建'}</p>
                                    <div onClick={this.handleinvoiceadd.bind(this)} className={(this.state.isinvoiceAdd == 1 ? 'activeaddress' : '') + ' persion-center-content-address-list-button'}>
                                        <Addressadd style={{ fontSize: '2.4rem' }} />
                                        <span>新增</span>
                                    </div>
                                    <div onClick={this.handleInvoice.bind(this)} className={(this.state.iseditinvoice == true ? 'activeaddress' : '') + ' persion-center-content-address-list-button'}>
                                        <Addressdelete style={{ fontSize: '2.4rem' }} />
                                        <span>编辑</span>
                                    </div>
                                </div>

                                <div className={this.state.isinvoiceAdd == 0 ? 'persion-center-content-invoice-list' : 'displaynone'}>
                                    <ul>
                                        {
                                            this.state.invoiceList.map((item, index) => {
                                                return <li key={index} >
                                                    <Delect onClick={this.delectinvoice.bind(this, item.id)} className={this.state.iseditinvoice == true ? ' ' : 'displaynone'} style={{ marginTop: 'auto', marginBottom: 'auto', fontSize: '3rem', marginLeft: '2.3rem', cursor: 'pointer' }} />
                                                    <div className='persion-center-content-invoice-list-content'>
                                                        <div className='persion-center-content-invoice-list-content-div'>
                                                            <span>类型：</span>
                                                            <p>{item.get_name}</p>
                                                        </div>
                                                        <div className='persion-center-content-invoice-list-content-div'>
                                                            <span>税号：</span>
                                                            <p>{item.ein}</p>
                                                        </div>
                                                        <div className='persion-center-content-invoice-list-content-div'>
                                                            <span>抬头：</span>
                                                            <p>{item.name}</p>
                                                        </div>
                                                    </div>
                                                    <div className={(item.type == 1 ? 'bg1' : 'bg2') + ' persion-center-content-invoice-list-content-name'}>
                                                        <span>{item.type == 1 ? '单位' : '个人'}</span>
                                                        <span>发票</span>
                                                    </div>
                                                </li>
                                            })
                                        }
                                    </ul>
                                </div>

                                <div className={this.state.isinvoiceAdd == 1 ? 'persion-center-content-invoice-list-add' : 'displaynone'}>
                                    <div className='persion-center-content-invoice-list-add-content'>
                                        <span>类型：</span>
                                        <p>电子发票</p>
                                    </div>
                                    <div className='persion-center-content-invoice-list-add-type'>
                                        <span className='persion-center-content-invoice-list-add-type-span'>对象：</span>
                                        <Radio.Group onChange={this.handleinvoicetype.bind(this)}>
                                            <Radio value={2}>个人</Radio>
                                            <Radio value={1}>企业</Radio>
                                        </Radio.Group>
                                    </div>
                                    <div className='persion-center-content-invoice-list-add-content'>
                                        <span>邮箱：</span>
                                        <input ref="invoiceemail" />
                                    </div>
                                    <div className={this.state.invoicetype == 1 ? 'persion-center-content-invoice-list-add-content' : 'displaynone'}>
                                        <span>税号：</span>
                                        <input ref="ein" />
                                    </div>
                                    <div className='persion-center-content-invoice-list-add-content'>
                                        <span>抬头：</span>
                                        <input ref='invoicename' />
                                    </div>
                                    <div style={{ marginTop: '72px' }} className='persion-center-content-invoice-list-add-content'>
                                        <span className='persion-center-content-invoice-list-add-content-span'>注：国家知识产权局现仅提供电子发票，无法开具纸质发票</span>
                                        <div onClick={this.invoiceExit.bind(this)} className='persion-center-content-invoice-list-add-content-div'>取消</div>
                                        <div onClick={this.invoiceSave.bind(this)} className='persion-center-content-invoice-list-add-content-div'>保存</div>
                                    </div>
                                </div>
                            </div>

                            <div className={this.state.tag == 'news' ? 'persion-center-content-news-list' : 'displaynone'}>
                                <div className='persion-center-content-news-list-title'>
                                    <p>我的消息</p>
                                </div>
                                <div className='persion-center-content-news-list-tab'>
                                    <div onClick={this.handlenewstag.bind(this,1)} className={this.state.newstag == 1 ? 'activenews' : ''}>系统</div>
                                    <div onClick={this.handlenewstag.bind(this,2)} className={this.state.newstag == 2 ? 'activenews' : ''}>个人</div>
                                </div>
                                <div className='persion-center-content-news-list-content'>
                                    <ul>
                                        {
                                            this.state.newsList.map((item,index) => {
                                                return <li onClick={this.handleSaw.bind(this,item.id)} key={index}>
                                                    <div className={(item.is_see == 1 ? ' seeyes' : ' ') + ' persion-center-content-news-list-content-title'}>
                                                        <p>{item.title}</p>
                                                        <h3>{item.add_time}</h3>
                                                    </div>
                                                    <span className={(item.is_see == 1 ? ' seeyes' :'')}>{item.content}</span>
                                                </li>
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default withRouter(PersionCenter)