import React, { Component } from 'react'
import { Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import  OrderDetail  from '../Component/OrderDetail.js'

/**
 *
 *
 * @class Order 获取订单详情
 * @extends {Component} zxy
 */
class Order extends Component {
    constructor(props) {
        super(props)

        this.state = {
            
        }
    }

    render() {
        return (
            <Fragment>
                <OrderDetail/>
            </Fragment>
        )
    }
}

export default withRouter(Order)