import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { message, Pagination } from 'antd'
import api from '../api/api'
import '../Component-css/QSXQ.css'
import Icon from '@ant-design/icons'
import 'quill/dist/quill.snow.css'
import Fixednav from './Fixednav'
import SearchFixed from './SearchFixed'
import { Picker } from 'emoji-mart';
import "emoji-mart/css/emoji-mart.css"

const release = () => (
    <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 1024 1024">
        <path d="M583.8848 0c74.274133 0 138.308267 47.786667 158.037333 117.077333a38.0928 38.0928 0 0 1 2.4576 7.441067 460.526933 460.526933 0 0 1 0.546134 203.025067l179.063466 0.136533c3.6864 0 7.168 0.273067 10.581334 0.682667a167.458133 167.458133 0 0 1 131.6864 75.093333l0.955733 1.501867c18.090667 27.648 26.760533 60.347733 24.7808 94.208 0.341333 8.260267-0.682667 16.452267-2.798933 24.3712l-90.385067 389.12c-2.6624 9.762133-6.144 17.6128-10.786133 24.576a162.474667 162.474667 0 0 1-58.1632 61.6448 168.96 168.96 0 0 1-92.910934 24.917333l-752.981333-0.136533c-22.459733 0-43.554133-8.533333-59.392-24.029867a80.349867 80.349867 0 0 1-24.576-57.9584l0.341333-454.587733c0-41.096533 31.607467-75.9808 73.386667-81.237334l7.099733-0.887466c116.053333-15.018667 207.735467-40.482133 240.366934-66.7648 52.0192-41.847467 97.621333-123.904 98.577066-176.5376v-1.570134C419.84 70.314667 491.861333 0 583.8848 0zM263.236267 526.813867a50.722133 50.722133 0 0 0-51.336534 50.039466v233.608534c0 27.648 23.005867 50.039467 51.336534 50.039466a50.722133 50.722133 0 0 0 51.336533-50.039466V576.853333a50.722133 50.722133 0 0 0-51.336533-50.039466z" ></path>
    </svg>
);

const Release = props => <Icon component={release} {...props} />;

class QSXQ extends Component {
    constructor(props) {
        super(props)

        this.state = {
            title: '',
            author: '',
            add_time: '',
            content: '',
            userlist: [1, 2, 3, 4],
            cover_img: '',
            newrelease: [],
            article_num: 0,
            is_like: 1,
            is_collect: 1,
            id: 0,
            reviewcontent: '',
            usertotal: 0,
            page: 1,
            fontsize: 2,
            isEmoji: false
        }
    }

    componentDidMount() {
        var id = this.props.match.params.id
        this.setState({
            id
        })
        setTimeout(() => {
            this.qsdetail()
        }, 100);
    }

    /**
     * 其说详情 && 评价列表
     * @author zxy
     * @memberof QSXQ
     */
    qsdetail() {
        var id = this.state.id
        api.post({
            str: '/talk/getTalkArticleInfo'
        }, {
            article_id: id
        })
            .then(res => res.json())
            .then(res => {
                if (res.code === 200) {
                    api.post({
                        str: '/talk/getTalkArticleReview'
                    }, {
                        page: 1,
                        ps: 10,
                        article_id: id
                    })
                        .then(rev => rev.json())
                        .then(rev => {
                            if (rev.code == 200) {
                                this.setState({
                                    usertotal: rev.data.total,
                                    userlist: rev.data.dataList,
                                    content: res.data.content,
                                    title: res.data.title,
                                    author: res.data.author_name,
                                    add_time: res.data.add_time,
                                    cover_img: res.data.author_head,
                                    newrelease: res.data.recentList,
                                    article_num: res.data.article_num,
                                    is_like: res.data.is_like,
                                    is_collect: res.data.is_collect,
                                    id: res.data.id,
                                    page: 1
                                })
                            } else {
                                message.error(rev.msg)
                            }
                        })
                } else if (res.code === 4000) {
                    message.error(res.msg)
                } else if (res.code === 4001) {
                    message.error(res.msg)
                } else {
                    message.error(res.msg)
                }
            })
    }


    /**
     * 最新发布详情
     * @author zxy
     * @memberof QSXQ
     */
    detail(id) {
        this.props.history.push('/QishuoXq/' + id)
    }

    /**
     * 点赞
     * @author zxy
     * @param {*} id
     * @memberof QSXQ
     */
    handlelike(id) {
        api.post({
            str: '/talk/likeArticle'
        }, {
            article_id: id,
            is_like: 2
        })
            .then(res => res.json())
            .then(res => {
                if (res.code == 200) {
                    this.qsdetail()
                } else {
                    message.error(res.msg)
                }
            })
    }

    /**
     * 取消点赞
     * @author zxy
     * @param {*} id
     * @memberof QSXQ
     */
    handlelikenone(id) {
        api.post({
            str: '/talk/likeArticle'
        }, {
            article_id: id,
            is_like: 1
        })
            .then(res => res.json())
            .then(res => {
                if (res.code == 200) {
                    this.qsdetail()
                } else {
                    message.error(res.msg)
                }
            })
    }

    /**
     * 收藏
     * @author zxy
     * @param {*} id
     * @memberof QSXQ
     */
    handlecollect(id) {
        api.post({
            str: '/talk/reviseCollect'
        }, {
            article_id: id,
            is_collect: 2
        })
            .then(res => res.json())
            .then(res => {
                if (res.code == 200) {
                    this.qsdetail()
                } else {
                    message.error(res.msg)
                }
            })
    }

    /**
     * 取消收藏
     * @author zxy
     * @param {*} id
     * @memberof QSXQ
     */
    handlecollectnone(id) {
        api.post({
            str: '/talk/reviseCollect'
        }, {
            article_id: id,
            is_collect: 1
        })
            .then(res => res.json())
            .then(res => {
                if (res.code == 200) {
                    this.qsdetail()
                } else {
                    message.error(res.msg)
                }
            })
    }

    /**
     * 发表评论内容
     * @author zxy
     * @memberof QSXQ
     */
    handlereview(e) {
        this.setState({
            reviewcontent: e.target.value
        })
    }

    /**
     * 发表
     * @author zxy
     * @param {*} id
     * @memberof QSXQ
     */
    handlereviewpost(id) {
        var content = this.state.reviewcontent
        api.post({
            str: '/talk/releaseArticleReview'
        }, {
            article_id: id,
            content: content
        })
            .then(res => res.json())
            .then(res => {
                if (res.code == 200) {
                    message.success(res.msg)
                    this.qsdetail()
                } else {
                    message.error(res.msg)
                }
            })
    }

    /**
     * 评论点赞
     * @author zxy
     * @memberof QSXQ
     */
    handlerelease(id, is_like) {
        var _islike = 1
        if (is_like == 1) {
            _islike = 2
        } else {
            _islike = 1
        }
        api.post({
            str: '/talk/likeReview'
        }, {
            review_id: id,
            is_like: _islike,
        })
            .then(res => res.json())
            .then(res => {
                if (res.code == 200) {
                    this.qsdetail()
                } else {
                    message.error(res.msg)
                }
            })
    }

    /**
     * 评论列表分页处理
     * @author zxy
     * @param {*} pageNumber
     * @param {*} pageSize
     * @memberof QSXQ
     */
    pagechange(pageNumber, pageSize) {
        var id = this.state.id
        api.post({
            str: '/talk/getTalkArticleReview'
        }, {
            page: pageNumber,
            ps: 10,
            article_id: id
        })
            .then(res => res.json())
            .then(res => {
                if (res.code == 200) {
                    this.setState({
                        content: res.data.content,
                        title: res.data.title,
                        author: res.data.author_name,
                        add_time: res.data.add_time,
                        cover_img: res.data.author_head,
                        newrelease: res.data.recentList,
                        article_num: res.data.article_num,
                        is_like: res.data.is_like,
                        is_collect: res.data.is_collect,
                        id: res.data.id,
                        page: pageNumber
                    })
                } else {
                    message.error(res.msg)
                }
            })
    }

    /**
     * 设置字号
     * @author zxy
     * @param {*} value
     * @memberof QSXQ
     */
    handlefontsize(value) {
        this.setState({
            fontsize: value
        })
    }

    /**
     * 插入表情
     * @author zxy
     * @param {*} emoji
     * @param {*} e
     * @memberof QSXQ
     */
    handleemoji(emoji, e) {
        var reviewcontent = this.state.reviewcontent
        reviewcontent = reviewcontent + emoji.native
        this.setState({
            reviewcontent
        })
    }

    render() {
        const { newrelease } = this.state
        return (
            <Fragment>
                {/* <div className="QsQg" style={{  width: '88%',marginBottom:'50px' }} dangerouslySetInnerHTML={{ __html: this.state.content }}></div> */}
                <div className='qsdetail-content-all'>
                    <div className='qsdetail-content-wrap'>
                        <div className="qsdetail-content ql-snow">
                            <div className='qsdetail-content-title'>
                                <p className='qsdetail-content-p'>{this.state.title}</p>
                                <ul>字号
                                    <li onClick={this.handlefontsize.bind(this, 1)}>小</li>
                                    <li onClick={this.handlefontsize.bind(this, 2)}>中</li>
                                    <li onClick={this.handlefontsize.bind(this, 3)}>大</li>
                                </ul>
                            </div>
                            <span className='qsdetail-content-span'>发布时间：{this.state.add_time}</span>
                            <div className={(this.state.fontsize == 1 ? 'fontsizelittle' : this.state.fontsize == 2 ? 'fontsizemiddle' : 'fontsizebig') + ' ql-editor'} style={{ fontSize: '250%' }} dangerouslySetInnerHTML={{ __html: this.state.content }}></div>
                            <div className='qsdetail-content-autor'>
                                <img src={this.state.cover_img} />
                                <div className='qsdetail-content-autor-line'></div>
                                <span>{this.state.author}</span>
                                <div className='qsdetail-content-button'>
                                    <div onClick={this.state.is_like == 1 ? this.handlelike.bind(this, this.state.id) : this.handlelikenone.bind(this, this.state.id)}>{this.state.is_like == 1 ? '点赞' : '取消点赞'}</div>
                                    <div onClick={this.state.is_collect == 1 ? this.handlecollect.bind(this, this.state.id) : this.handlecollectnone.bind(this, this.state.id)}>{this.state.is_collect == 1 ? '收藏' : '取消收藏'}</div>
                                </div>
                            </div>
                            <p className='qsdetail-review-title' >发布评论</p>
                            <textarea className='qsdetail-review-textarea' value={this.state.reviewcontent} onChange={this.handlereview.bind(this)} placeholder="这里填写您的评论"></textarea>
                            <div className='qsdetail-review'>
                                {/* <span onClick={() => this.setState({ isEmoji:!this.state.isEmoji})} style={{ cursor: 'pointer' }}>添加表情</span> */}
                                <div className={this.state.isEmoji == true ? 'qsdetail-review-emoji' : 'displaynone'}>
                                    <Picker set='apple'
                                        autoFocus={true}
                                        onClick={(emoji, e) => this.handleemoji(emoji, e)}
                                        showSkinTones={false}
                                        showPreview={false}
                                    />
                                </div>
                                <div className='qsdetail-review-button' onClick={this.handlereviewpost.bind(this, this.state.id)} >发表</div>
                            </div>
                            <ul className='qsdetail-content-ul'>
                                {
                                    this.state.userlist.map((item, index) => {
                                        return <li key={index}>
                                            <div>
                                                <div className='qsdetail-content-ul-username'><img src={item.head} /><p>{item.name}</p></div>
                                                <span className='qsdetail-content-ul-content' >{item.content}</span>
                                            </div>
                                            <Release onClick={this.handlerelease.bind(this, item.id, item.is_like)} className={item.is_like == 1 ? '' : 'displaynone'} style={{ fontSize: '2rem', color: '#999', marginLeft: 'auto', marginTop: '60px', cursor: 'pointer' }} />
                                            <Release onClick={this.handlerelease.bind(this, item.id, item.is_like)} className={item.is_like == 2 ? '' : 'displaynone'} style={{ fontSize: '2rem', color: '#F2994A', marginLeft: 'auto', marginTop: '60px', cursor: 'pointer' }} />
                                            <span style={{ marginRight: '0', color: '#999', marginLeft: '15px', marginTop: '60px' }}>{item.like_num}</span>
                                        </li>
                                    })
                                }
                            </ul>
                            <Pagination style={{marginBottom:'30px',marginTop:20}} onChange={this.pagechange.bind(this)} pageSize={10} current={this.state.page} defaultCurrent={1} total={this.state.usertotal} />
                        </div>
                        <div className='qsdetail-content-aside'>
                            <img src={this.state.cover_img} />
                            <p>{this.state.author}</p>
                            <span>{this.state.article_num}篇投稿</span>
                            <h1>最新发布</h1>
                            {
                                newrelease.length > 0 ? newrelease.map((item, index) => {
                                    return <li onClick={this.detail.bind(this, item.id)} key={index}>
                                        <p>{item.title}</p>
                                        <span>发布时间：{item.add_time}</span>
                                    </li>
                                }) : null
                            }
                        </div>
                    </div>
                    <Fixednav />
                    <SearchFixed />
                </div>
            </Fragment>
        )
    }
}

export default withRouter(QSXQ)