import React, { Component } from 'react'
import { Fragment } from 'react'
import MonitorImportContent from '../Component/MonitorImportContent.js'
import {withRouter} from 'react-router-dom'

class MonitorImport extends Component {
    constructor(props) {
        super(props)

        this.state = {
            
        }
    }

    /**
     *
     *
     * @return {*}  监控导入
     * @memberof MonitorImport zxy
     */
    render() {
        return (
            <Fragment>
                <MonitorImportContent />
            </Fragment>
        )
    }
}

export default withRouter(MonitorImport)