import React, { Component, Fragment } from 'react';
import '../Component-css/Easysearch.css';
import '../util/Apiconfig.js'
import { getCurrentDate } from '../api/currentDate.js'
import { withRouter } from 'react-router-dom'
import { message } from 'antd';
import Icon from '@ant-design/icons'
import SearchFixed from './SearchFixed';
import Fixednav from './Fixednav';


const search = () => (
    <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 1024 1024">
        <path d="M911.872 860.672L757.248 706.56c50.176-62.464 83.456-145.92 83.456-233.472 0-208.896-166.912-375.296-375.296-375.296S89.6 264.192 89.6 472.576s166.912 375.296 375.296 375.296c87.552 0 171.008-29.184 233.472-83.456l154.624 154.624c8.192 8.192 20.992 12.288 29.184 12.288 8.192 0 20.992-4.096 29.184-12.288 16.896-16.384 16.896-41.472 0.512-58.368z m-446.976-95.744c-162.816 0-291.84-129.536-291.84-291.84s129.536-291.84 291.84-291.84 292.352 129.536 292.352 291.84-129.536 291.84-292.352 291.84z" />
    </svg>
);
const Search = props => <Icon component={search} {...props} />;

const search_arr = []
const search_data = {}

class Easysearch extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Redirect: false,
            value: '',
            inputValue: ''
        }
        this.inputSearch = this.inputSearch.bind(this)
        this.onKeyUp = this.onKeyUp.bind(this)
    }

    onKeyUp = (e) => {
        var type = 1;
        var id = this.refs.input.value.trim()
        var time = getCurrentDate()
        if (e.keyCode === 13) {
            if (localStorage.search) {
                var sa = localStorage.getItem('search')
                var ss = JSON.parse(sa)
                search_data.search = id
                search_data.time = time
                if (ss.length > 9) {
                    ss.splice(0, 1, search_data)
                    var search = JSON.stringify(ss)
                    localStorage.setItem('search', search)
                } else {
                    ss.push(search_data)
                    var searcht = JSON.stringify(ss)
                    localStorage.setItem('search', searcht)
                }
            } else {
                search_data.search = id
                search_data.time = time
                search_arr.push(search_data)
                var searchs = JSON.stringify(search_arr)
                localStorage.setItem('search', searchs)
            }
            this.props.history.push('/Zllb/' + id + '/' + type)
        }
    }

    record() {
        const w = window.open('about:blank');
        w.location.href = `https://beian.miit.gov.cn`
    }

    inputSearch = (e) => {
        var type = 1;
        var inputValues = this.refs.input.value.trim()
        var time = getCurrentDate()
        if(inputValues === ''){
            message.error('搜索框不能为空')
        }else if (localStorage.search) {
            var sa = localStorage.getItem('search')
            var ss = JSON.parse(sa)
            search_data.search = inputValues
            search_data.time = time
            if (ss.length > 9) {
                ss.splice(9,1)
                ss.splice(0,0,search_data)
                var search = JSON.stringify(ss)
                localStorage.setItem('search', search)
            } else {
                ss.push(search_data)
                var searcht = JSON.stringify(ss)
                localStorage.setItem('search', searcht)
            }
            this.props.history.push(`/Zllb/${inputValues}/${type}`)
        } else {
            search_data.search = inputValues
            search_data.time = time
            search_arr.push(search_data)
            var searchs = JSON.stringify(search_arr)
            localStorage.setItem('search', searchs)
            this.props.history.push(`/Zllb/${inputValues}/${type}`)
        }
    }

    render() {
        return (
            <Fragment>
                <div className="indexcontent">
                    <div className='indexcontent-background'></div>
                    <img src='http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210830/6336ba49b1320d9ec4d38e5074ffae82818aef60.png' className="logo-center" alt='logo' />
                    <div className="content-search">
                        <input ref='input' onKeyUp={this.onKeyUp} placeholder='在搜索框中输入想要查询的信息' />
                        <button className="cousor" onClick={this.inputSearch} ><Search style={{color:'#fff'}} /></button>
                    </div>
                    <img className="content-search-img" alt='' src={require('../images/gzheqm.jpg')}></img>
                    <div className="content-search-nextdiv" >一键专利查询 丨  实时智能监控</div>
                    <div className='cousor content-search-footer' onClick={this.record.bind(this)} >Copyright2018四川中索汇智信息技术有限公司，AllRightReserved蜀ICP备18026701号-3</div>
                    <Fixednav />
                   <SearchFixed />
                </div>
            </Fragment>
        )
    }
}
export default withRouter(Easysearch);