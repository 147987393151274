import React, { Component,Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import Headernav from '../Component/Headernav.js'
import SuccessPayContent from '../Component/SuccessPayContent.js'

/**
 *
 *
 * @class SuccessPay 成功缴费
 * @extends {Component} zxy
 */
class SuccessPay extends Component {
    constructor(props) {
        super(props)

        this.state = {
            
        }
    }

    render() {
        return (
            <Fragment>
                <Headernav/>
                <SuccessPayContent/>
            </Fragment>
        )
    }
}

export default withRouter(SuccessPay)