import { Carousel, message, Radio } from 'antd'
import React, { Component } from 'react'
import { Fragment } from 'react'
import api from '../api/api'
import "../Component-css/SearchFixed.css"

class SearchFixed extends Component {
    constructor(props) {
        super(props)

        this.state = {
            value: '1',
            isask: true,
            isbind:true,
            bannerlist:['用户189****5325已咨询','用户191****4647已咨询','用户135****8953已咨询','用户151****7458已咨询','用户189****1248已咨询','用户170****4125已咨询','用户153****2869已咨询','用户151****6538已咨询','用户181****7748已咨询','用户151****4688已咨询']
        }
    }

    radioChange(e) {
        this.setState({
            value: e.target.value
        })
    }

    handleAsk() {
        var problem = this.refs.myproblem.value
        var name = this.refs.name.value
        var mobile = this.refs.mobile.value
        const regs = /^((13[0-9])|(14[57])|(15[^4,\\D])|(16[0-9])|(17[0-1,6-8])|(18[0-9])|(19[0-9]))\d{8}$/
        var number = mobile.replace(/\s/g, '')
        if(problem == '' || !problem){
            message.error('请输入您想咨询的问题')
        }else if(name == '' || !name){
            message.error('请输入您的称谓')
        } else if (!regs.test(number) || number.length !== 11) {
            message.error('请输入正确的电话号码!!!')
        }else {
            api.overtherpost({
                str: 'task/addClue'
            }, {
                name,
                mobile,
                problem,
                source:'其知网'
            })
                .then(res => res.json())
                .then(res => {
                    if (res.code == 200) {
                        message.success(res.msg)
                        this.setState({
                            isbind:false
                        })
                    setTimeout(() => {
                        this.setState({
                            isbind: true
                        })
                    }, 10000);
                    } else {
                        message.error(res.msg)
                    }
                })
        }
    }

    handleIsask() {
        this.setState({
            isask: false
        })
    }

    handleIsasknone() {
        this.setState({
            isask: true
        })
    }

    render() {
        return (
            <Fragment>
                <div className={this.state.isask == true ? 'searchfixed-wrap' : 'opacitynone animateopacity zindex'} >
                    <span className='hot-call'>全国咨询热线：4000-666-289</span>
                    <p style={{ height: '30px', fontSize: '2.4rem', fontWeight: '700', color: '#333', marginBottom: '0' }}>快速咨询</p>
                    <Carousel autoplay style={{width:'330px',height:'20px',textAlign:'center',marginTop:'20px' }} dotPosition="left" dots={false}>
                        {
                            this.state.bannerlist.map((item,index) => {
                                return <span key={index} style={{ marginTop: '23px', fontSize: '1.2rem', fontWeight: '400', color: '#999',}}>{item}</span>
                            })
                        }
                    </Carousel>
                    <textarea ref="myproblem" placeholder="我的问题" style={{ width: '294px', height: '60px', paddingLeft: '10px', paddingTop: '10px', resize: 'none', border: 'none', background: '#F8F8F8', marginTop: '40px', outline: 'none', color: '#999' }} ></textarea>
                    <div style={{ width: '294px', height: '40px', lineHeight: '40px', marginTop: '10px', display: 'flex', flexDirection: 'row' }}>
                        <input ref='name' placeholder='请输入称谓' style={{ width: '294px', height: '40px', lineHeight: '40px', paddingLeft: '10px', borderRadius: '4px', outline: 'none', border: 'none', background: '#F8F8F8', color: '#999' }} />
                    </div>
                    <input ref='mobile' maxLength={11} minLength={11} placeholder='请输入手机号' style={{ width: '294px', height: '40px', marginTop: '10px', borderRadius: '4px', outline: 'none', paddingLeft: '10px', border: 'none', background: '#F8F8F8', color: '#999' }} />
                    <div className={this.state.isbind == true ? '' : 'displaynone'} onClick={this.handleAsk.bind(this)} style={{ marginTop: '40px', width: '294px', height: '40px', textAlign: 'center', lineHeight: '40px', color: '#FFF', background: '#00C8DF', fontWeight: "700", fontSize: '1.4rem', cursor: 'pointer' }}>立即咨询</div>
                    <div className={this.state.isbind == false ? '' : 'displaynone'} style={{ marginTop: '40px', width: '294px', height: '40px', textAlign: 'center', lineHeight: '40px', color: '#FFF', background: '#00C8DF', fontWeight: "700", fontSize: '1.4rem', cursor: 'pointer' }}>立即咨询</div>
                    <img onClick={this.handleIsask.bind(this)} className='isask' alt='' src='http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210830/ba27d4a4a901664a39306a547e4ffb850f73c112.png' />
                </div>
                <div onClick={this.handleIsasknone.bind(this)} className={this.state.isask == true ? 'opacitynone' : ''} style={{ zIndex: 99, cursor: 'pointer', position: 'fixed', right: '0px', bottom: '0px', width: '50px', height: '150px',background:'rgba(0, 0, 0, 0.8)',color:"#fff",fontWeight:'700',fontSize:'1.4rem' ,textAlign:'center',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}><img alt='' src={require('../images/lxwm-ft.png')} style={{width:28,height:24,marginBottom:'10px'}} />免<br />费<br />咨<br />询</div>
            </Fragment>
        )
    }
}

export default SearchFixed