import React, { Fragment,Component } from 'react'
import AgentDetails from '../Component/AgentDetails'

/**
 *
 *
 * @class ZldlXq 专利代理详情
 * @extends {Component} zxy
 */
class ZldlXq extends Component {
    constructor(props) {
        super(props)

        this.state = {
            
        }
    }

    render() {
        return (
            <Fragment>
                <AgentDetails />
            </Fragment>
        )
    }
}

export default ZldlXq