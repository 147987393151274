import React, { Fragment, Component } from 'react'
import { withRouter } from 'react-router-dom'
import '../Component-css/MarketContent.css'

class Markettalk extends Component {
    constructor(props) {
        super(props)

        this.state = {

        }
    }

  

    render() {
        return (
            <Fragment>
                <div style={{ width: '163px',height:'140px', position: 'fixed', top: '130px', right: '0px',overflow:'hidden' }}>
                    
                </div>
            </Fragment>
        )
    }
}

export default withRouter(Markettalk)