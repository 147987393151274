
import React, { Fragment, Component } from 'react'
import { withRouter } from 'react-router-dom'

class Marketsearch extends Component {
    constructor(props) {
        super(props)

        this.state = {

        }
    }

    requireInformation(){
        this.props.history.push('/RequireMarket')
    }

    componentDidMount(){
      
    }

    Marketsearch(){
        const { search } = this.props
        var inputValue = this.refs.marketSearch.value.trim()
        search(inputValue)
    }

    render() {
        return (
            <Fragment>
                <div className="marketContent-nav">
                    <input placeholder='一种机械' className="marketContent-input" ref="marketSearch" />
                    <button onClick={this.Marketsearch.bind(this)} className="search-button">搜索</button>
                </div>
            </Fragment>
        )
    }
}

export default withRouter(Marketsearch)