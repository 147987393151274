/**
 * 请求方法处理
 */
import qs from 'querystring'

/**
 * get
 */
export function httpXz(url,projectId){
    var token = localStorage.getItem('token')
    fetch(url + `?ids=` + projectId.ids+'&fileName='+projectId.fileName,{
        method:'GET',
        headers:{
            'content-type':'application/x-www-form-urlencoded',
            'accept':'application/json,text/plain,*/*',
            'Token':token
        }
    })
    window.location.href = url + `?ids=` + projectId.ids+'&fileName='+projectId.fileName
}

export function httpDxz(url,projectId){
    var token = localStorage.getItem('token')
    fetch(url + `?id=` + projectId.id,{
        method:'GET',
        headers:{
            'content-type':'application/x-www-form-urlencoded',
            'accept':'application/json,text/plain,*/*',
            'Token':token
        }
    })
      window.location.href = url + `?id=` + projectId.id
}

export function httpGet(url,params){
    var token = localStorage.getItem('token')
    const result = fetch(url + `?=` + qs.stringify(params),{
        method:'GET',
        headers:{
            'content-type':'application/x-www-form-urlencoded',
            'accept':'application/json,text/plain,*/*',
            'Token':token
        }
    })
    return result
}

/**
 * post
 */
export function httpPost(url,params) {
    var token = localStorage.getItem('token')
    const result = fetch(url, {
        method:'POST',
        headers:{
            'content-type':'application/x-www-form-urlencoded',
            'accept':'application/json,text/plain,*/*',
            'Token':token
        },
        body:qs.stringify(params)
    })
    return result
}

/**
 * post
 */
 export function zxPost(url,params) {
    const result = fetch(url, {
        method:'POST',
        headers:{
            'content-type':'application/x-www-form-urlencoded',
            'accept':'application/json,text/plain,*/*',
        },
        body:qs.stringify(params)
    })
    return result
}

const base = {
    Url:'https://zlbb.qzhip.com/index.php/index',
    Url2:'https://crmapi.qzhip.com/index.php/api/'
//     Url:'http://192.168.1.158/index.php/index'
}

const api = {
    xz(Urldata,projectId) {
        const url = base.Url + Urldata.str
        return httpXz(url,projectId)
    },
    onexz(Urldata,projectId) {
        const url = base.Url + Urldata.str
        return httpDxz(url,projectId)
    },
   
    get(Urldata,projectId) {
        const url = base.Url + Urldata.str
        return httpGet(url,projectId)
    },
    post(Urldata,params) {
        const url = base.Url+Urldata.str
        return httpPost(url,params)
    },
    overtherpost(Urldata,params){
        const url = base.Url2 + Urldata.str
        return zxPost(url,params)
    }
}
export default api