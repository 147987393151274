import React, { Component,Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import Agent from '../Component/Agent.js'

/**
 *
 *
 * @class Zldl 专利代理
 * @extends {Component} zxy
 */
class Zldl extends Component {
    constructor(props) {
        super(props)

        this.state = {
            
        }
    }

    render() {
        return (
            <Fragment>
                <Agent />
            </Fragment>
        )
    }
}

export default withRouter(Zldl)