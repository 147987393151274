import React, { Component, Fragment } from 'react'
import Headernav from './Headernav'
import { withRouter } from 'react-router-dom'
import { Table, Space, DatePicker,Pagination, message } from 'antd'
import '../Component-css/RequireMarketContent.css'
import api from '../api/api'

const { RangePicker } = DatePicker

class RequireMarketContent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isXq:false,
            data:[],
            column:[{
                title: '序号',
                width:'3.84%',
                render: (text, record, index) => `${index + 1}`
            }, {
                title: '需求描述',
                align:'left',
                width: '70.24%',
                render: (text,record) => 
                <div onClick={() => this.handeldescription(record.description)}>{record.need_desc}</div>
            }, {
                title: '发布时间',
                key: 'add_time',
                dataIndex: 'add_time',
                width: '9.83%'
            }, {
                title: '操作',
                width:'15.51%',
                render: (text, record) =>
                    <div className='requireMarket-table-a'>
                        <a style={{ width: '100%', height: '100%',fontSize:'0.75rem',color:'#00C8DF' }} rel="noopener noreferrer" target="_blank" href="http://wpa.qq.com/msgrd?v=3&uin=407650541&site=qq&menu=yes">联系匹配</a>
                    </div>
            }],
            total:0,
            page:1,
            ps:20
        }
    }

    changeDate(date, dateString){
        var start_time = dateString[0]
        var end_time = dateString[1]
        api.post({
            str:'/market/getMarketBuyList'
        },{
            page:this.state.page,
            ps:this.state.ps,
            search_type:'market',
            start_time:start_time,
            end_time:end_time
        })
        .then(res => res.json())
        .then(res => {
            if(res.code === 200){
                this.setState({
                    total:res.data.total,
                    data:res.data.dataList
                })
            }else{
                message.error(res.msg)
            }
        })
    }

    handeldescription(key){
        this.setState({
            isXq:true
        })
    }

    handleCancel(){
        this.setState({
            isXq:false
        })
    }

    handleOk(){
        this.setState({
            isXq:false
        })
    }

    pageChangeBuy(pageNumber,pageSize){
        api.post({
            str:'/market/getMarketBuyList'
        },{
            page:pageNumber,
            ps:this.state.ps,
            search_type:'market'
        })
        .then(res => res.json())
        .then(res => {
            if(res.code === 200){
                this.setState({
                    total:res.data.total,
                    data:res.data.dataList
                })
            }else{
                message.error(res.msg)
            }
        })
        this.setState({
            page:pageNumber
        })
    }

    marketBuylist(){
        api.post({
            str:'/market/getMarketBuyList'
        },{
            page:1,
            ps:this.state.ps,
            search_type:'market'
        })
        .then(res => res.json())
        .then(res => {
            if(res.code === 200) {
                this.setState({
                    total:res.data.total,
                    data:res.data.dataList
                })
            }else{
                message.error(res.msg)
            }
        })
    }

    search(){
        var search_value = this.refs.requireMarket.value.trim()
        if(search_value === ''){
            this.marketBuylist()
        }else{
            api.post({
                str:'/market/getMarketBuyList'
            },{
                page:1,
                ps:this.state.ps,
                search_type:'market',
                search_value:search_value
            })
            .then(res => res.json())
            .then(res => {
                if(res.code === 200) {
                    this.setState({
                        total:res.data.total,
                        data:res.data.dataList
                    })
                }else{
                    message.error(res.msg)
                }
            })
        }
    }

    componentDidMount(){
        this.marketBuylist()
    }

    render() {
        return (
            <Fragment>
                <Headernav />
                <div className='requireMarket'>
                    <div className='requireMarket-search'>
                        <input ref='requireMarket' />
                        <div onClick={this.search.bind(this)}>搜索</div>
                    </div>
                    <div className='requireMarket-time'>
                        <div>时间筛选</div>
                        <Space style={{marginLeft:'10px',fontSize:'0.75rem'}}>
                            <RangePicker style={{fontSize:'0.75rem'}} onCalendarChange={this.changeDate.bind(this)} />
                        </Space>
                    </div>
                    <div className='requireMarket-tab'>
                        <Table bordered className='requireMarket-table' columns={this.state.column} dataSource={this.state.data} rowKey='id' pagination={false} />
                        <Pagination href="#top" style={{ width: '100%', height: '60px', marginTop: '24px', paddingLeft: '24px', borderTop: '1px solid #D9D9D9', display: "flex", alignItems: 'center' }} showQuickJumper defaultCurrent={1} total={this.state.total} pageSize={20} onChange={this.pageChangeBuy.bind(this)} />
                    </div>
                    
                    {/* <Modal
                        className='xuqiu'
                        visible={this.state.isXq}
                        bodyStyle={{width:450}}
                        width={450}
                        height={329}
                        style={{ top: '30%' }}
                        onCancel={this.handleCancel.bind(this)}
                        footer={[
                            <a rel="noopener noreferrer" target="_blank" href="http://wpa.qq.com/msgrd?v=3&uin=1599330459&site=qq&menu=yes" key="submit" onClick={this.handleOk.bind(this)} style={{width:'391px',height:'32px',color:'#fff',fontSize:'0.75rem',backgroundColor:'#00C8DF'}} type="primary">咨询客服</a>
                        ]}
                    >
                        <div style={{width:'100%',height:'100%',textAlign:'center',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                            <div style={{height:'82px',fontSize:'1rem',fontWeight:'600',width:'100%',lineHeight:'82px'}}>详细需求</div>
                            <div style={{width:'86.89%',border:'0.5px solid #BDBDBD',padding:'20px',fontSize:'0.75rem',height:'150px',textAlign:'left'}}>机械护具，用于日常造作防护的，发明新型皆可，下证未下证皆可，只需要一个，5年内授权。</div>
                        </div>
                    </Modal> */}
                </div>
            </Fragment>
        )
    }
}

export default withRouter(RequireMarketContent)