import React, { Component } from 'react'
import { Fragment } from 'react'
import { withRouter } from 'react-router'
import AdvancedSearchContent from '../Component/AdvancedSearchContent'
import Fixednav from '../Component/Fixednav'
import Headernav from '../Component/Headernav'

class AdvancedSearch extends Component {
    constructor(props) {
        super(props)

        this.state = {
            
        }
    }

    /**
     * 新版高级检索
     * 暂未使用***********
     * @return {*}
     * @memberof AdvancedSearch zxy
     */
    render() {
        return (
            <Fragment>
                <Headernav/>
                <Fixednav />
                <AdvancedSearchContent />
            </Fragment>
        )
    }
}

export default withRouter(AdvancedSearch)