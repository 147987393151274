import React, { Fragment, Component } from 'react'
import { withRouter } from 'react-router-dom'
import Qzxy from '../Component/Qzxy.js'

class QZXY extends Component {
    constructor(props) {
        super(props)

        this.state = {
            
        }
    }

    /**
     *
     *
     * @return {*} 其知学院
     * @memberof QZXY   zxy
     */
    render() {
        return (
            <Fragment>
                <Qzxy />
            </Fragment>
        )
    }
}

export default withRouter(QZXY)