import React, { Component,Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import Headernav from '../Component/Headernav'
import FailPayContent from '../Component/FailPayContent'

/**
 *
 *
 * @class FailPay 缴费下载
 * @extends {Component} zxy
 */
class FailPay extends Component {
    constructor(props) {
        super(props)

        this.state = {
            
        }
    }

    render() {
        return (
            <Fragment>
                <Headernav />
                <FailPayContent />
            </Fragment>
        )
    }
}

export default withRouter(FailPay)