import React from 'react';
import ReactDOM from 'react-dom';
import Home from './Home';
// import Zllb from './page/Zllb.js';
// import * as serviceWorker from './serviceWorker';
import './util/tool.jsx'


// ReactDOM.render(
//   <React.StrictMode>
//     <Home />
// </React.StrictMode>,
//   document.getElementById('root')
// );
ReactDOM.render(<Home />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
