import React, { Component } from 'react'
import { Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import Headernav from '../Component/Headernav'
import PayingContent from '../Component/PayingContent'

class Paying extends Component {
    constructor(props) {
        super(props)

        this.state = {
            
        }
    }

    /**
     *
     *
     * @return {*} 正在缴费
     * @memberof Paying zxy
     */
    render() {
        return (
            <Fragment>
                <Headernav/>
                <PayingContent/>
            </Fragment>
        )
    }
}

export default withRouter(Paying)