import React, { Component } from 'react'
import { Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import Headernav from './Headernav';
import Fixednav from './Fixednav';
import '../Component-css/RemindSetContent.css'
import api from '../api/api';
import { message } from 'antd';

class RemindSetContent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            mobile:'',
            email:'',
            isTruetel:true,
            isYzm:true,
            isYzm2:true,
            time:0,
            isTrueEmail:true
        }
    }

     //校验手机号，号段主要有：130~139、150~153，155~159，180~189、170~171、176~178、160~169、145、147、190~199
     handleTel(e) {
        const regs = /^((13[0-9])|(14[57])|(15[^4,\\D])|(16[0-9])|(17[0-1,6-8])|(18[0-9])|(19[0-9]))\d{8}$/
        var number = e.target.value.replace(/\s/g, '')
        // eslint-disable-next-line no-mixed-operators
        if (!regs.test(number) || number.length !== 11) {
            this.setState({
                isTruetel: false,
                mobile:number
            })
        } else {
            this.setState({
                mobile: number,
                isTruetel: true
            })
        }
    }

    //验证码倒计时
    lastTime() {
        let time = 60
        this.interval = setInterval(() => {
            if (time > 0) {
                time--
                this.setState({
                    isYzm: false,
                    time: time
                })
            } else if (time === 0) {
                this.setState({
                    isYzm: true
                })
                clearInterval(this.interval)
            }
        }, 1000)
    }

    emaillastTime() {
        let time2 = 60
        this.interval = setInterval(() => {
            if (time2 > 0) {
                time2--
                this.setState({
                    isYzm2: false,
                    time: time2
                })
            } else if (time2 === 0) {
                this.setState({
                    isYzm2: true
                })
                clearInterval(this.interval)
            }
        }, 1000)
    }

    remindpersionOption(){
        api.post({
            str:'/monitor/getRemindConfig'
        })
        .then(res => res.json())
        .then(res => {
            if(res.code === 200){
                if(res.data.mobile !== '' || res.data.email !== ''){
                    this.setState({
                        mobile:res.data.mobile,
                        email:res.data.email
                    })
                }
            }else{
                message.error(api.msg)
            }
        })
    }

    emailinput(e){
        const regs =/^([a-zA-Z]|[0-9])(\w|-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/
        var email = e.target.value
        // eslint-disable-next-line no-mixed-operators
        if (!regs.test(email)) {
            this.setState({
                isTrueEmail: false,
                email:email
            })
        } else {
            this.setState({
                email: email,
                isTrueEmail: true
            })
        }
    }

    mobilePostCode(){
        if(this.state.mobile === ''){
            message.error('联系电话不能为空')
        }else if(this.state.isTruetel === false){
            message.error('您输入的电话不正确，请重新输入')
        }else{
            this.lastTime()
            api.post({
                str:'/monitor/getRemindCode'
            },{
                mobile:this.state.mobile
            })
            .then(res => res.json())
            .then(res => {
                if(res.code === 200){
                    message.success(res.msg)
                }else{
                    message.error(res.msg)
                }
            })
        }
    }

    emailPostCode(){
        if(this.state.email === ''){
            message.error('请输入邮箱')
        }else if(this.state.isTrueEmail === false){
            message.error('请输入正确的邮箱')
        }else{
            this.emaillastTime()
            api.post({
                str:'/monitor/getEmailCode'
            },{
                email:this.state.email
            })
            .then(res => res.json())
            .then(res =>{
                if(res.code === 200){
                    message.success(res.msg)
                }else{
                    message.error(res.msg)
                }
            })
        }
    }

    mobileagree(){
        var mobile = this.state.mobile
        var code = this.refs.code.value.trim()
        if(mobile === '' && code === ''){
            message.error('请填写电话和验证码')
        }else{
            api.post({
                str:'/monitor/setRemindMobile'
            },{
                mobile:mobile,
                code:code
            })
            .then(res => res.json())
            .then(res =>{
                if(res.code === 200){
                    message.success(res.msg)
                }else{
                    message.error(res.msg)
                }
            })
        }
    }

    emailagree(){
        var code = this.refs.emailcode.value.trim()
        var email = this.state.email
        if(email === '' || code === ''){
            message.error('请输入邮箱和验证码')
        }else{
            api.post({
                str:'/monitor/setRemindEmail'
            },{
                email:this.state.email,
                code:this.refs.emailcode.value.trim()
            })
            .then(res => res.json())
            .then(res => {
                if(res.code === 200){
                    message.success(res.msg)
                }else{
                    message.error(res.msg)
                }
            })
        }
    }

    componentDidMount(){
        this.remindpersionOption()
    }

    render() {
        return (
            <Fragment>
                <div style={{width:'100%',height:'100%',backgroundColor:'#f8f8f8'}}>
                <Headernav />
                <Fixednav />
                <div className='remind'>
                    <div className='remind-title'>
                        <div><img alt='' src={require('../images/options.png')} /><span>提醒设置</span></div>
                    </div>
                    <div className='remind-method'>
                        <div>
                            <h3>短信提醒</h3>
                            <span className='remind-method-item '>
                                <span className='remind-method-item-name'>联系人电话：</span>
                                <input placeholder='请输入电话' value={this.state.mobile} onChange={this.handleTel.bind(this)} maxLength='11' minLength='11' />
                            </span>
                            <span style={{fontSize:'0.75rem',color:'#f00',width:'92.19%',textAlign:'left',marginTop:'10px',paddingLeft:'24px'}} className={this.state.isTruetel === false ? '':'displayNone'}>请输入正确的电话号码</span>
                            <span className='remind-method-item'>
                                <span className='remind-method-item-name'>验证码：</span>
                                <input className='remind-method-item-input' placeholder='请输入验证码' ref='code' />
                                <div className={(this.state.isYzm === true ? '' : 'displayNone') + ' cousor'} onClick={this.mobilePostCode.bind(this)}>发送验证码</div>
                                <div className={this.state.isYzm === false ? '' : 'displayNone'}>{this.state.time}s后重新发送</div>
                            </span>
                        </div>
                        <div>
                            <h3>邮箱提醒</h3>
                            <span className='remind-method-item '>
                                <span className='remind-method-item-name'>联系人邮箱：</span>
                                <input type='email' placeholder='请输入邮箱' value={this.state.email} onChange={this.emailinput.bind(this)} />
                            </span>
                            <span style={{fontSize:'0.75rem',color:'#f00',width:'92.19%',textAlign:'left',marginTop:'10px',paddingLeft:'24px'}} className={this.state.isTrueEmail === false ? '':'displayNone'}>请输入正确的邮箱</span>
                            <span className='remind-method-item'>
                                <span className='remind-method-item-name'>验证码：</span>
                                <input className='remind-method-item-input' placeholder='请输入验证码' ref='emailcode' />
                                <div className={(this.state.isYzm2 === true ? '' : 'displayNone') + ' cousor'} onClick={this.emailPostCode.bind(this)}>发送验证码</div>
                                <div className={this.state.isYzm2 === false ? '' : 'displayNone'}>{this.state.time}s后重新发送</div>
                            </span>
                        </div>
                    </div>
                    <div className='remind-Model'>
                        <div>
                            <h4>短信发送模板</h4>
                            <div style={{ width: '100%',display:'flex',alignItems:'center',height:'82px' }}>
                                <span className='remind-Model-content'>
                                    <div>「其知网」年费提醒：xxx您好，您有xx件专利离本年度年费缴纳截止日还有xxx天
                                请您登录其知网查看并尽快缴费。www.qzhip.com</div>
                            </span>
                            </div>
                            <div onClick={this.mobileagree.bind(this)} className='remind-Model-button cousor'>同意</div>
                        </div>
                        <div>
                            <h4>邮件发送列表模板</h4>
                            <div style={{ width: '100%',display:'flex',alignItems:'center' ,height:'82px'}}>
                            <span className='remind-Model-content1'>
                                <div style={{marginLeft:'15px',paddingLeft:'0'}}>序号&emsp;专利号&emsp;专利名称&emsp;申请人&emsp;申请日期&emsp;截止日期&emsp;缴费年份&emsp;年费金额&emsp;服务费</div>
                                <span style={{marginLeft:'15px'}}>总计：</span>
                            </span>
                            </div>
                            <div onClick={this.emailagree.bind(this)} className='remind-Model-button cousor'>同意</div>
                        </div>
                    </div>
                </div>
                </div>
            </Fragment>
        )
    }
}

export default withRouter(RemindSetContent)