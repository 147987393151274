import React, { Fragment, useEffect, useState } from 'react'
import Fixednav from './Fixednav'
import '../Component-css/PayingContent.css'
import api from '../api/api'
import { message } from 'antd'
import {  useHistory, useParams } from 'react-router-dom'

function PayingContent() {
    const [data,setData] = useState({})
    const {paymethod} = useParams()
    const history = useHistory()

    useEffect(() => {
        init()
        const timer = setInterval(() => {
            status()
        },2000)
        return () => {
            clearInterval(timer)
        }
    },[])

    function init(){
        let order_sn = localStorage.getItem('order_sn')
        let money = Number(localStorage.getItem('money'))
        api.post({
            str:'/order/orderPay'
        },{
            order_sn,
            total_fee:money
        })
        .then(res => res.json())
        .then(res => {
            if(res.code == 200){
                if(res.data.wxPayCode == '' && paymethod == 1 ){
                    message.error('二维码生成失败，请稍后再试')
                }else if(paymethod == 2 && res.data.aliPayCode == ''){
                    message.error('二维码生成失败，请稍后再试')
                }else{
                   let data = {
                    'money' : money,
                    'wxPayCode':res.data.wxPayCode,
                    'aliPayCode':res.data.aliPayCode,
                    'type':paymethod,
                    'order_sn':order_sn
                   }
                    setData(data)
                    
                }
            }else{
                message.error(res.msg)
            }
        })
    }

    function status() {
        let order_sn = localStorage.getItem('order_sn')
        api.post({
            str:'/order/getOrderStatus'
        },{
            order_sn : order_sn
        })
        .then(res => res.json())
        .then(res => {
            if(res.code == 200){
                if(res.data.status == 2){
                    history.push('/SuccessPay')
                    clearInterval()
                }
            }
        })
    }

    return (
        <Fragment>
            <Fixednav />
            <div className='payingcontent-wrap'>
                <div className='payingcontent-header'>
                    <img src='http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20220105/01403a79a93ed0aa50c55b7946ac0bd0c4825f31.png' />
                    <span>支付中心</span>
                </div>
                <p className='payingcontent-number'>应付金额：￥{data.money}</p>
                <img className='payingcontent-content' src={data.type == 1 ? data.wxPayCode : data.aliPayCode} />
                <p className='payingcontent-title'>年费缴纳及付款说明</p>
                <ul className='payingcontent-ul'>
                    <li>1.年费缴纳后除特殊原因，不可退还；</li>
                    <li>2.待缴专利在支付费用3工作日内失效，其知网将不承担任何责任；</li>
                    <li>3.本服务为代缴服务，收款方为中国知识产权局，其知网只收取手续费。</li>
                </ul>
            </div>
        </Fragment>
    )
}

export default PayingContent