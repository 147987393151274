import React, { Component } from 'react'
import { Fragment } from 'react'
import '../Component-css/AdvancedSearchContent.css'
import Icon from '@ant-design/icons'
import { Space,DatePicker,Cascader } from 'antd'
import 'moment/locale/zh-cn';
import options from '../Component/perisonoptions.js';
import locale from 'antd/es/date-picker/locale/zh_CN';
import SearchFixed from './SearchFixed'


const { RangePicker } = DatePicker;

const close = () => (
    <svg t="1615426581433" className="icon" viewBox="0 0 1024 1024" version="1.0" xmlns="http://www.w3.org/2000/svg" p-id="3728" width="0.75rem" height="0.75rem">
        <path d="M810.666667 273.493333L750.506667 213.333333 512 451.84 273.493333 213.333333 213.333333 273.493333 451.84 512 213.333333 750.506667 273.493333 810.666667 512 572.16 750.506667 810.666667 810.666667 750.506667 572.16 512z" p-id="3729"></path>
    </svg>
)

const Close = props => <Icon component={close} {...props} />;


class AdvancedSearchContent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            titlechange: 1,
            zlh: '',
            sqr: '',
            sqh: '',
            sqid: '',
            gkh: '',
            zlmc: '',
            fmr: '',
            zllx: '',
            zlyxx: '',
            zldl: '',
            zlrq:'',
            zldq:'',
            list:[]
        }
        this.handleDateOpen = this.handleDateOpen.bind(this)
        this.onChangeaddress = this.onChangeaddress.bind(this)
    }

    /**
     *
     *  地区选择
     * @param {*} value
     * @memberof AdvancedSearchContent zxy
     */
    onChangeaddress = value => {
        var dateOpen = '专利地区：' + value;
        var list = this.state.list
        if (value !== "") {
            if (list.length !== 0) {
                // eslint-disable-next-line array-callback-return
                list.map((item, index) => {
                    var array = item.split('：')
                    if (array[0] === '专利地区') {
                        list.splice(index, 1, dateOpen)
                    } else {
                        list.push(dateOpen)
                        for(var i = 0 ; i < list.length ; i++){
                            for(var j = list.length - 1; j > 0 ; j--){
                                if(list[i] === list[j] && i !== j){
                                    list.splice(j,1)
                                }
                            }
                        }
                    }
                })
            } else {
                list.push(dateOpen)
            }
        } else {
            return false
        }
        this.setState({
            zldq: value,
            list:list
        })
    }

    /**
     *
     * 申请日选择
     * @param {*} date
     * @param {*} dateString
     * @return {*} 
     * @memberof AdvancedSearchContent zxy
     */
    handleDateOpen(date, dateString) {
        var dateOpen = '申请日：' + dateString;
        var list = this.state.list
        if (dateString !== "") {
            if (list.length !== 0) {
                // eslint-disable-next-line array-callback-return
                list.map((item, index) => {
                    var array = item.split('：')
                    console.log(array)
                    if (array[0] === '申请日') {
                        list.splice(index, 1, dateOpen)
                    } else {
                        list.push(dateOpen)
                        for(var i = 0 ; i < list.length ; i++){
                            for(var j = list.length - 1; j > 0 ; j--){
                                if(list[i] === list[j] && i !== j){
                                    list.splice(j,1)
                                }
                            }
                        }
                    }
                })
            } else {
                list.push(dateOpen)
            }
        } else {
            return false
        }
        this.setState({
            sqrq: dateOpen,
            list:list
        })
    }

    /**
     *  子页面切换
     * @author zxy
     * @param {*} value
     * @memberof AdvancedSearchContent
     */
    titlechange(value) {
        this.setState({
            titlechange: value
        })
    }

    /**
     * 专利号选择
     * @author zxy
     * @return {*} 
     * @memberof AdvancedSearchContent
     */
    zlhBlur() {
        var zlh = '专利号：' + this.refs.zlh.value
        var list = this.state.list
        if (this.refs.zlh.value !== "") {
            if (list.length !== 0) {
                // eslint-disable-next-line array-callback-return
                list.map((item, index) => {
                    var array = item.split('：')
                    if (array[0] === '专利号') {
                        list.splice(index, 1, zlh)
                    } else {
                        list.push(zlh)
                        for(var i = 0 ; i < list.length ; i++){
                            for(var j = list.length - 1; j > 0 ; j--){
                                if(list[i] === list[j] && i !== j){
                                    list.splice(j,1)
                                }
                            }
                        }
                    }
                })
            } else {
                list.push(zlh)
            }
        } else {
            return false
        }
        this.setState({
            zlh: zlh,
            list:list
        })
    }

    /**
     * 申请人选择
     * @author zxy
     * @return {*} 
     * @memberof AdvancedSearchContent
     */
    sqrBlur() {
        var sqr = '申请人：' + this.refs.sqr.value
        var list = this.state.list
        if (this.refs.sqr.value !== "") {
            if (list.length !== 0) {
                // eslint-disable-next-line array-callback-return
                list.map((item, index) => {
                    var array = item.split('：')
                    if (array[0] === '申请人') {
                        list.splice(index, 1, sqr)
                    } else {
                        list.push(sqr)
                        for(var i = 0 ; i < list.length ; i++){
                            for(var j = list.length - 1; j > 0 ; j--){
                                if(list[i] === list[j] && i !== j){
                                    list.splice(j,1)
                                }
                            }
                        }
                    }
                })
            } else {
                list.push(sqr)
            }
        } else {
            return false
        }
        this.setState({
            sqr: sqr,
            list:list
        })
    }

    /**
     * 申请号选择
     * @author zxy
     * @return {*} 
     * @memberof AdvancedSearchContent
     */
    sqhBlur() {
        var sqh = '申请号：' + this.refs.sqh.value
        var list = this.state.list
        if (this.refs.sqh.value !== "") {
            if (list.length !== 0) {
                // eslint-disable-next-line array-callback-return
                list.map((item, index) => {
                    var array = item.split('：')
                    if (array[0] === '申请号') {
                        list.splice(index, 1, sqh)
                    } else {
                        list.push(sqh)
                        for(var i = 0 ; i < list.length ; i++){
                            for(var j = list.length - 1; j > 0 ; j--){
                                if(list[i] === list[j] && i !== j){
                                    list.splice(j,1)
                                }
                            }
                        }
                    }
                })
            } else {
                list.push(sqh)
            }
        } else {
            return false
        }
        this.setState({
            sqh: sqh,
            list:list
        })
    }

    /**
     * 授权号选择
     * @author zxy
     * @return {*} 
     * @memberof AdvancedSearchContent
     */
    sqidBlur() {
        var sqid = '授权号：' + this.refs.sqid.value
        var list = this.state.list
        if (this.refs.sqid.value !== "") {
            if (list.length !== 0) {
                // eslint-disable-next-line array-callback-return
                list.map((item, index) => {
                    var array = item.split('：')
                    if (array[0] === '授权号') {
                        list.splice(index, 1, sqid)
                    } else {
                        list.push(sqid)
                        for(var i = 0 ; i < list.length ; i++){
                            for(var j = list.length - 1; j > 0 ; j--){
                                if(list[i] === list[j] && i !== j){
                                    list.splice(j,1)
                                }
                            }
                        }
                    }
                })
            } else {
                list.push(sqid)
            }
        } else {
            return false
        }
        this.setState({
            sqid: sqid,
            list:list
        })
    }

    /**
     * 公开号选择
     * @author zxy
     * @return {*} 
     * @memberof AdvancedSearchContent
     */
    gkhBlur() {
        var gkh = '公开号：' + this.refs.gkh.value
        var list = this.state.list
        if (this.refs.gkh.value !== "") {
            if (list.length !== 0) {
                // eslint-disable-next-line array-callback-return
                list.map((item, index) => {
                    var array = item.split('：')
                    if (array[0] === '公开号') {
                        list.splice(index, 1, gkh)
                    } else {
                        list.push(gkh)
                        for(var i = 0 ; i < list.length ; i++){
                            for(var j = list.length - 1; j > 0 ; j--){
                                if(list[i] === list[j] && i !== j){
                                    list.splice(j,1)
                                }
                            }
                        }
                    }
                })
            } else {
                list.push(gkh)
            }
        } else {
            return false
        }
        this.setState({
            gkh: gkh,
            list:list
        })
    }

    /**
     * 专利名称选择
     * @author zxy
     * @return {*} 
     * @memberof AdvancedSearchContent
     */
    zlmcBlur() {
        var zlmc = '专利名称：' + this.refs.zlmc.value
        var list = this.state.list
        if (this.refs.zlmc.value !== "") {
            if (list.length !== 0) {
                // eslint-disable-next-line array-callback-return
                list.map((item, index) => {
                    var array = item.split('：')
                    if (array[0] === '专利名称') {
                        list.splice(index, 1, zlmc)
                    } else {
                        list.push(zlmc)
                        for(var i = 0 ; i < list.length ; i++){
                            for(var j = list.length - 1; j > 0 ; j--){
                                if(list[i] === list[j] && i !== j){
                                    list.splice(j,1)
                                }
                            }
                        }
                    }
                })
            } else {
                list.push(zlmc)
            }
        } else {
            return false
        }
        this.setState({
            zlmc: zlmc,
            list:list
        })
    }

    /**
     * 发明人选择
     * @author zxy
     * @return {*} 
     * @memberof AdvancedSearchContent
     */
    fmrBlur() {
        var fmr = '发明人：' + this.refs.fmr.value
        var list = this.state.list
        if (this.refs.fmr.value !== "") {
            if (list.length !== 0) {
                // eslint-disable-next-line array-callback-return
                list.map((item, index) => {
                    var array = item.split('：')
                    if (array[0] === '发明人') {
                        list.splice(index, 1, fmr)
                    } else {
                        list.push(fmr)
                        for(var i = 0 ; i < list.length ; i++){
                            for(var j = list.length - 1; j > 0 ; j--){
                                if(list[i] === list[j] && i !== j){
                                    list.splice(j,1)
                                }
                            }
                        }
                    }
                })
            } else {
                list.push(fmr)
            }
        } else {
            return false
        }
        this.setState({
            fmr: fmr,
            list:list
        })
    }

    /**
     * 专利类型
     * @author zxy
     * @return {*} 
     * @memberof AdvancedSearchContent
     */
    zllxBlur() {
        var zllx = '专利类型：' + this.refs.zllx.value
        var list = this.state.list
        if (this.refs.zllx.value !== "") {
            if (list.length !== 0) {
                // eslint-disable-next-line array-callback-return
                list.map((item, index) => {
                    var array = item.split('：')
                    if (array[0] === '专利类型') {
                        list.splice(index, 1, zllx)
                    } else {
                        list.push(zllx)
                        for(var i = 0 ; i < list.length ; i++){
                            for(var j = list.length - 1; j > 0 ; j--){
                                if(list[i] === list[j] && i !== j){
                                    list.splice(j,1)
                                }
                            }
                        }
                    }
                })
            } else {
                list.push(zllx)
            }
        } else {
            return false
        }
        this.setState({
            zllx: zllx,
            list:list
        })
    }

    /**
     * 专利有效性选择
     * @author zxy
     * @return {*} 
     * @memberof AdvancedSearchContent
     */
    zlyxxBlur() {
        var zlyxx = '专利有效性：' + this.refs.zlyxx.value
        var list = this.state.list
        if (this.refs.zlyxx.value !== "") {
            if (list.length !== 0) {
                // eslint-disable-next-line array-callback-return
                list.map((item, index) => {
                    var array = item.split('：')
                    if (array[0] === '专利有效性') {
                        list.splice(index, 1, zlyxx)
                    } else {
                        list.push(zlyxx)
                        for(var i = 0 ; i < list.length ; i++){
                            for(var j = list.length - 1; j > 0 ; j--){
                                if(list[i] === list[j] && i !== j){
                                    list.splice(j,1)
                                }
                            }
                        }
                    }
                })
            } else {
                list.push(zlyxx)
            }
        } else {
            return false
        }
        this.setState({
            zlyxx: zlyxx,
            list:list
        })
    }

    /**
     * 代理人选择
     * @author zxy
     * @return {*} 
     * @memberof AdvancedSearchContent
     */
    zldlBlur() {
        var zldl = '专利代理：' + this.refs.zldl.value
        var list = this.state.list
        if (this.refs.zldl.value !== "") {
            if (list.length !== 0) {
                // eslint-disable-next-line array-callback-return
                list.map((item, index) => {
                    var array = item.split('：')
                    if (array[0] === '专利代理') {
                        list.splice(index, 1, zldl)
                    } else {
                        list.push(zldl)
                        for(var i = 0 ; i < list.length ; i++){
                            for(var j = list.length - 1; j > 0 ; j--){
                                if(list[i] === list[j] && i !== j){
                                    list.splice(j,1)
                                }
                            }
                        }
                    }
                })
            } else {
                list.push(zldl)
            }
        } else {
            return false
        }
        this.setState({
            zldl: zldl,
            list:list
        })
    }

    /**
     * 清除选择条件
     * @author zxy
     * @param {*} item
     * @param {*} index
     * @memberof AdvancedSearchContent
     */
    delectlist(item,index){
        var list = this.state.list
        list.splice(index,1)
        item.split('：')
        if(item[0] === '专利号'){
            this.setState({
                zlh:''
            })
        }else if(item[0] === '申请人'){
            this.setState({
                sqr:''
            })
        }else if(item[0] === '申请日'){
            this.setState({
                sqrq:''
            })
        }else if(item[0] === '申请号'){
            this.setState({
                sqh:''
            })
        }else if(item[0] === '授权号'){
            this.setState({
                sqid:''
            })
        }else if(item[0] === '公开号'){
            this.setState({
                gkh:''
            })
        }else if(item[0] === '专利名称'){
            this.setState({
                zlmc:''
            })
        }else if(item[0] === '发明人'){
            this.setState({
                fmr:''
            })
        }else if(item[0] === '专利类型'){
            this.setState({
                zllx:''
            })
        }else if(item[0] === '专利有效性'){
            this.setState({
                zlyxx:''
            })
        }else if(item[0] === '专利地区'){
            this.setState({
                zldq:''
            })
        }else if(item[0] === '代理机构'){
            this.setState({
                zldl:''
            })
        }
        this.setState({
            list:list
        })
    }

    handleOk(){
        // var sqh = this.state.sqh.trim()
        // var sqr = this.state.sqr.trim()
        // var zlwyid = this.state.zlh.trim()
        // var dljg = this.state.dljg.trim()
        // var zlmc = this.state.zlmc.trim()
        // var fmr = this.state.fmr.trim()
        // var zlyxx = this.state.zlyxx
        // var sqrq = this.state.sqrq
        // var zllx = this.state.zllx
        // var address = this.state.address
        // var _sqrq = 'sqrq:' + sqrq.join(',') + '&gkrq:' + gkrq.join(',') + '&address:' + address.join('')
        // var item = 'sqr:' + sqr + '&sqh:' + sqh + '&zlwyid:' + zlwyid + '&dljg:' + dljg + '&zlmc:' + zlmc + '&fmr:' + fmr + '&zlyxx:' + zlyxx + '&zllx:' + zllx + '&' + _sqrq
        // if (sqh === '' && sqr === '' && zlwyid === '' && dljg === '' && zlmc === '' && fmr === '' && zlyxx === '' && sqrq.length === 0 && gkrq.length === 0 && zllx === '' && address.length === 0) {
        //     message.error('请输入至少一项')
        // } else {
        //     this.props.history.push(`/Zllb/${search}/${item}`)
        // }
    }

    render() {
        return (
            <Fragment>
                <div className='AdvancedSearch-content'>
                    <div className='AdvancedSearch-title'>
                        <div onClick={this.titlechange.bind(this, 1)} className={(this.state.titlechange === 1 ? 'background00A3FF' : ' ') + ' cousor'}>高级查询</div>
                        <div onClick={this.titlechange.bind(this, 2)} className={(this.state.titlechange === 2 ? 'background00A3FF' : ' ') + ' cousor'}>缴费查询</div>
                        <div onClick={this.titlechange.bind(this, 3)} className={(this.state.titlechange === 3 ? 'background00A3FF' : ' ') + ' cousor'}>审查查询</div>
                    </div>
                    <div className={(this.state.titlechange === 1 ? '' : 'displayNone') + ' AdvancedSearch-advanced'}>
                        <div className='AdvancedSearch-advanced-tag'>
                            <div className='AdvancedSearch-advanced-tag-content'>
                                {
                                    this.state.list.map((item, index) => {
                                        return <div className='AdvancedSearch-advanced-tag-item' key={index}>{item}<Close onClick={this.delectlist.bind(this,item,index)} className='cousor' style={{marginLeft:'5px'}} /></div>
                                    })
                                }
                            </div>
                            <div className='AdvancedSearch-advanced-tag-content-footer'>
                                <span>*请在下方选择您需要筛选的条件</span>
                                <div className="cousor" onClick={this.handleOk.bind(this)}>立即查询</div>
                            </div>
                        </div>
                    </div>
                    <div className='AdvancedSearch-advanced-input'>
                        <div className='AdvancedSearch-advanced-input-wrap'>
                            <div>
                                <span>专利号：</span><input placeholder='请输入专利号' ref='zlh' onBlur={this.zlhBlur.bind(this)} />
                            </div>
                            <div>
                                <span>申请人：</span><input placeholder='请输入申请人姓名' ref='sqr' onBlur={this.sqrBlur.bind(this)} />
                            </div>
                            <div>
                                <span>申请日：</span>
                                <Space className='date-input' style={{ fontSize:'0.75rem'}} direction="vertical"  >
                                    <RangePicker onCalendarChange={this.handleDateOpen} locale={locale} bordered={false} />
                                </Space>
                            </div>
                            <div>
                                <span>申请号：</span><input placeholder='请输入申请号' ref='sqh' onBlur={this.sqhBlur.bind(this)} />
                            </div>
                            <div>
                                <span>授权号：</span><input placeholder='请输入授权号' ref='sqid' onBlur={this.sqidBlur.bind(this)} />
                            </div>
                            <div>
                                <span>公开号：</span><input placeholder='请输入公开号' ref='gkh' onBlur={this.gkhBlur.bind(this)} />
                            </div>
                        </div>
                        <div className='AdvancedSearch-advanced-input-wrap2'>
                            <div>
                                <span>专利名称：</span><input placeholder='请输入专利名称' ref='zlmc' onBlur={this.zlmcBlur.bind(this)} />
                            </div>
                            <div>
                                <span>发明人：</span><input placeholder='请输入发明人姓名' ref='fmr' onBlur={this.fmrBlur.bind(this)} />
                            </div>
                            <div>
                                <span>专利类型：</span><input placeholder='请选择专利类型' ref='zllx' onBlur={this.zllxBlur.bind(this)} />
                            </div>
                            <div>
                                <span>专利有效性：</span><input placeholder='请选择专利有效性' ref='zlyxx' onBlur={this.zlyxxBlur.bind(this)} />
                            </div>
                            <div>
                                <span>专利地区：</span><Cascader style={{ fontSize:'0.75rem',width:'380px',padding:'0'}} className="zldq_select" expandTrigger="hover" displayRender={this.displayRender} options={options} onChange={this.onChangeaddress} placeholder="请选择地址" ></Cascader>

                            </div>
                            <div>
                                <span>代理机构：</span><input placeholder='请输入代理机构' ref='zldl' onBlur={this.zldlBlur.bind(this)} />
                            </div>
                        </div>
                    </div>
                </div>
                <SearchFixed />
            </Fragment>
        )
    }
}

export default AdvancedSearchContent