import React, { PureComponent } from 'react'
import { Image } from 'antd'
import { withRouter } from 'react-router-dom'

class Futu extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
        }
    }

    render() {
        
        return (
            <div style={{ width: '360px', height:'240px',display: 'flex', flexDirection: 'column',justifyContent:'center', alignItems: "center",borderBottom:'1px solid #E7E7E7',borderLeft:'1px solid #E7E7E7',borderRight:'1px solid #E7E7E7',borderTop:'4px solid #00C8DF',marginLeft:'30px',backgroundColor:'#F7F7F7',marginTop:'35px'}}>
            {
                this.props.zltp.map((item,index)=>{
                    return <Image key={index} width={340} height={220} src={item} style={{ borderRadius: '4px'}}></Image>
                })
            }
        </div>
        )
    }
}

export default withRouter(Futu)