import React, { Component, Fragment } from 'react'
import Headernav from '../Component/Headernav'
import QS from '../Component/QS.js'

class Qishuo extends Component {
    constructor(props) {
        super(props)

        this.state = {
            
        }
    }

    /**
     *
     *
     * @return {*} 其说
     * @memberof Qishuo zxy
     */
    render() {
        return (
            <Fragment>
                <Headernav />
                <QS />
            </Fragment>
        )
    }
}

export default Qishuo