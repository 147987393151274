import React, { Component } from 'react'
import { Fragment } from 'react'
import PayConfirmContent from '../Component/PayConfirmContent'

class PayConfirm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            
        }
    }

    /**
     *
     *
     * @return {*} 缴纳年费
     * @memberof PayConfirm zxy
     */
    render() {
        return (
            <Fragment>
                <PayConfirmContent/>
            </Fragment>
        )
    }
}

export default PayConfirm