import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import Headernav from './Headernav'
import Icon from '@ant-design/icons'
import { Radio, Cascader, message } from 'antd'
import options from '../Component/perisonoptions.js';
import api from '../api/api.js'
import 'moment/locale/zh-cn';
import Modal from 'antd/lib/modal/Modal'
import { Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    // getBase64(file)
    return isJpgOrPng && isLt2M;
}

class EditorUser extends Component {
    constructor(props) {
        super(props)

        this.state = {
            head: '',
            name: '',
            sign: '',
            qq: '',
            tel: '',
            email: '',
            sex: '',
            options: [],
            isTruetel: false,
            isTrueEmail: false,
            address: [],
            isImagechange: false
        }
        this.onChangeaddress = this.onChangeaddress.bind(this)
        this.imageCancel = this.imageCancel.bind(this)
        this.imageOk = this.imageOk.bind(this)
    }

    componentDidMount() {
        for (var i = 0; i < options.length; i++) {
            for (var j = 0; j < options[i].children.length; j++) {
                options[i].children[j].children = []
            }
        }
        this.setState({
            options: options
        })
        this._init()
    }

    _init() {
        api.get({
            str: '/user/getUserInfo'
        })
            .then(res => res.json())
            .then(res => {
                if (res.code === 200) {
                    this.setState({
                        head: res.data.head,
                        name: res.data.username,
                        sign: res.data.sign,
                        qq: res.data.qq,
                        tel: res.data.mobile,
                        email: res.data.email,
                        sex: res.data.sex,
                    })
                } else {
                    message.error(res.msg)
                }
            })

    }

    myname(e) {
        this.setState({
            name: e.target.value
        })
    }

    mysign(e) {
        this.setState({
            sign: e.target.value
        })
    }

    myQQ(e) {
        this.setState({
            qq: e.target.value
        })
    }

    mytelchange(e) {
        const regs = /^((13[0-9])|(14[57])|(15[^4,\\D])|(16[0-9])|(17[0-1,6-8])|(18[0-9])|(19[0-9]))\d{8}$/
        var number = e.target.value.replace(/\s/g, '')
        this.setState({
            tel: number
        })
        if (!regs.test(number) || number.length !== 11) {
            this.setState({
                isTruetel: false
            })
        } else {
            this.setState({
                tel: number,
                isTruetel: true
            })
        }
    }

    myemailchange(e) {
        const regs = /^[A-Za-z0-9]+([_.][A-Za-z0-9]+)*@([A-Za-z0-9-]+\.)+[A-Za-z]{2,6}$/
        const email = e.target.value
        this.setState({
            email: email
        })
        if (!regs.test(email)) {
            this.setState({
                isTrueEmail: false
            })
        } else {
            this.setState({
                email: email,
                isTrueEmail: true
            })
        }
    }

    sexchange(e) {
        this.setState({
            sex: e.target.value
        })
    }

    changeImageUrl = info => {
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return true;
        }
        if (info.file.status === 'done') {
            this.setState({
                imageUrl: info.file.response.data.url
            })
        }
    }

    imgchange() {
        this.setState({
            isImagechange: true
        })
    }

    imageOk() {
        var head = this.state.imageUrl
        api.post({
            str: '/user/editUserInfo'
        }, {
            head: head
        })
            .then(res => res.json())
            .then(res => {
                if (res.code === 200) {
                    message.success(res.msg)
                    window.location.reload()
                } else {
                    message.error(res.msg)
                }
            })
    }

    imageCancel() {
        this.setState({
            isImagechange: false
        })
    }

    onChangeaddress(value) {
        this.setState({
            address: value
        })
    }

    cancel() {
        this.props.history.push('/Grzx/1')
    }

    save() {
        var username = this.state.name,
            address = this.state.address,
            mobile = this.state.tel,
            email = this.state.email,
            sex = this.state.sex,
            sign = this.state.sign,
            QQ = this.state.qq,
            isTruetel = this.state.isTruetel,
            isTrueEmail = this.state.isTrueEmail
        api.post({
            str: '/user/editUserInfo'
        }, {
            username,
            address,
            mobile,
            sex,
            sign,
            QQ,
            email
        })
            .then(res => res.json())
            .then(res => {
                if (res.code === 200) {
                    message.success(res.msg)
                    this.props.history.push('/Grzx/1')
                } else {
                    message.error(res.msg)
                }
            })
    }

    render() {
        const { loading, imageUrl } = this.state;
        const uploadButton = (
            <div>
                {loading ? <LoadingOutlined /> : <PlusOutlined />}
                <div style={{ marginTop: 8 }}>Upload</div>
            </div>
        );
        const props = {
            name: 'file',
            action: 'https://zlbb.qzhip.com/index.php/index/base/uploadFile',
            accept: 'image/.png,image/.jepg',
            showUploadList: false,
            listType: 'picture-card'
        }
        const pagination = {
            defaultCurrent: 1,
            total: this.orderlisttotal
        }
        return (
            <Fragment>
                <Headernav />
                <div className='persioncenter-header'>
                    <img alt='' src='http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210705/0bff6edbae56035d21f8c82b64237c54d0a57f24.png' />
                    <div className='persioncenter-information'>
                        <img src={this.state.head} />
                        <div className='editor-head'>
                            <div onClick={this.imgchange.bind(this)} className='editor-head-button'>点击更换</div>
                        </div>
                        <Modal
                            visible={this.state.isImagechange}
                            width={414}
                            height={611}
                            onOk={this.imageOk}
                            onCancel={this.imageCancel}
                            footer={[
                                <div style={{ height: '60px', width: '100%', display: 'flex', flexDirection: 'row', justifyContent: "center", alignItems: 'center' }} key="ss">
                                    <button key="cancel" onClick={this.imageCancel} className="cancle-btn cousor" >取消</button>
                                    <button key="submit" onClick={this.imageOk} className="ok-btn cousor" type="primary" >应用</button>
                                </div>
                            ]}
                        >
                            <div style={{ width: '100%', height: '79px', color: 'rgba(0,0,0,0.8)', fontWeight: 'bold', fontSize: '16px', textAlign: 'center', lineHeight: '79px', padding: "0" }} >编辑头像</div>
                            <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                <ImgCrop>
                                    <Upload
                                        {...props}
                                        className="avatar-uploader"
                                        beforeUpload={beforeUpload}
                                        onChange={this.changeImageUrl.bind(this)}
                                    >
                                        {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                                    </Upload>
                                </ImgCrop>
                            </div>

                        </Modal>
                    </div>
                    <div className='editor-head-content'>
                        <div className='editor-head-content-information'>
                            <p>我的昵称</p><input maxLength={8} value={this.state.name} onChange={this.myname.bind(this)} />
                        </div>
                        <div className='editor-head-content-information'>
                            <p>我的性别</p>
                            <Radio.Group style={{ width: '980px', marginLeft: '37px' }} onChange={this.sexchange.bind(this)} value={this.state.sex}>
                                <Radio value={1}>男</Radio>
                                <Radio value={2}>女</Radio>
                            </Radio.Group>
                        </div>
                        <div className='editor-head-content-information'>
                            <p style={{ width: '65px' }}>个性签名</p><input value={this.state.sign} onChange={this.mysign.bind(this)} />
                        </div>
                        <div className='editor-head-content-information'>
                            <p style={{ width: '65px' }}>我的qq</p><input value={this.state.qq} onChange={this.myQQ.bind(this)} />
                        </div>
                        <div className='editor-head-content-information'>
                            <p style={{ width: '65px' }}>我的电话</p><input value={this.state.tel} onChange={this.mytelchange.bind(this)} />
                        </div>
                        <div className='editor-head-content-information'>
                            <p style={{ width: '65px' }}>我的邮箱</p><input value={this.state.email} onChange={this.myemailchange.bind(this)} />
                        </div>
                        <div className='editor-head-content-information'>
                            <p style={{ width: '65px' }}>所在城市</p>
                            <Cascader style={{ fontSize: '1.4rem', width: '300px', padding: '0', borderRadius: 'none', marginLeft: '37px', marginRight: 'auto', display: 'flex' }} className="editor_address_select" expandTrigger="hover" displayRender={this.displayRender} options={this.state.options} onChange={this.onChangeaddress} placeholder="请选择地址" ></Cascader>
                        </div>
                        <div className='editor-head-content-footer'>
                            <div onClick={this.cancel.bind(this)} style={{ cursor: 'pointer' }}>取消</div>
                            <div onClick={this.save.bind(this)} style={{ cursor: 'pointer' }}>保存</div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default withRouter(EditorUser)