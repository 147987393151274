/*
 * @Author: zxy 
 * @Date: 2021-11-22 11:53:06 
 * @Last Modified by: zxy
 * @Last Modified time: 2021-11-25 10:08:36
 */
import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { Image, message } from 'antd'
import api from '../api/api'
import { Pagination } from 'antd'
import '../Component-css/QS.css'
import Icon from '@ant-design/icons'
import Fixednav from './Fixednav'
import SearchFixed from './SearchFixed'

const release = () => (
    <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 1024 1024">
        <path d="M511.829333 22.016C229.546667 22.016 0 222.378667 0 467.626667a433.493333 433.493333 0 0 0 220.16 366.592v140.458666a26.538667 26.538667 0 0 0 14.250667 23.808c4.266667 1.024 8.704 1.024 12.970666 0 5.376 0 10.581333-1.706667 15.018667-4.778666l68.096-42.922667 73.642667-49.066667a595.626667 595.626667 0 0 0 108.373333 10.24c282.112 0 511.744-199.68 511.744-445.696S793.856 22.016 511.829333 22.016zM288.256 558.933333a59.306667 59.306667 0 1 1 54.186667-36.181333 58.624 58.624 0 0 1-54.186667 36.181333z m233.813333-17.749333a59.306667 59.306667 0 1 1-1.450666-118.613333 59.306667 59.306667 0 0 1 1.365333 118.613333z m230.314667 0a59.306667 59.306667 0 1 1 0-118.613333 59.306667 59.306667 0 0 1 0 118.613333z" fill="#999999"  ></path>
    </svg>
);
const follow = () => (
    <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 1024 1024">
        <path d="M957.016763 5.74891L27.186813 468.257519c-34.047515 17.970944-34.892303 54.168828-9.292668 73.982946l223.17762 138.775623a44.031373 44.031373 0 0 0 55.807204-9.241469l492.792978-444.025672 18.559736 9.241468-446.32964 471.750077a33.893917 33.893917 0 0 0-9.292668 27.724405v203.5171a50.584879 50.584879 0 0 0 27.954802 46.207342 41.010616 41.010616 0 0 0 46.181742-9.241469l111.870406-111.204815 222.844824 148.221888c35.711491 19.993315 65.355869 9.753461 74.39254-27.775604l186.262946-924.91482c9.779061-46.104943-19.839717-73.931746-65.099872-55.525609z" ></path>
    </svg>
);
const collection = () => (
    <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 1024 1024">
        <path d="M580.076032 18.6624c8.9344 6.5792 16.6912 14.6944 22.9376 23.9616L737.362432 241.7152c6.5792 9.7536 16.384 16.768 27.5712 19.7376l212.4288 56.4736a95.7696 95.7696 0 0 1 40.96 22.784c11.6224 10.9312 20.48 24.6272 25.7536 39.8592a101.632 101.632 0 0 1 4.608 47.616 100.608 100.608 0 0 1-17.664 44.288l-142.3872 199.3472c-6.1952 8.6784-9.472 19.1488-9.344 29.9008l2.6624 220.3392a101.376 101.376 0 0 1-9.984 45.184 98.5088 98.5088 0 0 1-28.928 35.5584 95.1296 95.1296 0 0 1-41.472 18.176c-15.0272 2.6368-30.464 1.536-44.9792-3.1488l-217.1136-70.0416a47.0272 47.0272 0 0 0-28.5696-0.1024l-229.2736 71.9872a94.0288 94.0288 0 0 1-44.544 2.8928 95.1808 95.1808 0 0 1-41.0624-17.9968 98.4576 98.4576 0 0 1-28.7744-35.072 101.2992 101.2992 0 0 1-10.3168-44.5952v-224.6912c0-9.8048-2.816-19.3792-8.1152-27.5456L16.364032 469.3504a101.5808 101.5808 0 0 1-10.1376-89.9072c5.376-14.72 14.1056-27.9296 25.4464-38.528a95.7696 95.7696 0 0 1 39.7568-22.3232l206.3872-57.1648a48.2304 48.2304 0 0 0 26.5728-18.8928l141.056-201.3184A96.1792 96.1792 0 0 1 508.165632 1.28a94.5152 94.5152 0 0 1 71.8848 17.3824z m103.2448 544.768c-37.5808 43.6736-88.3712 65.3824-156.0576 65.3824-68.352 0-122.4448-22.2464-165.2992-67.2512a47.616 47.616 0 0 0-33.792-14.4384 47.5904 47.5904 0 0 0-33.9712 14.1056 50.176 50.176 0 0 0-14.5152 34.56c-0.2048 13.0048 4.5568 25.6 13.312 35.0464 61.44 64.5376 140.544 97.1008 234.2912 97.1008 94.3872 0 171.7248-33.1008 228.3264-98.9696 8.192-9.8816 12.288-22.6816 11.392-35.6608a50.048 50.048 0 0 0-16.1792-33.6384 47.4624 47.4624 0 0 0-34.5344-12.3136 47.744 47.744 0 0 0-33.024 16.0768h0.0512z" ></path>
    </svg>
);

const information = () => (
    <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 1024 1024">
        <path d="M512 0C228.082526 0 0 230.4 0 512c0 274.593684 218.758737 500.358737 491.061895 512h300.193684c23.282526 0 46.565053-23.282526 46.565053-46.538105v-69.820632C951.861895 814.538105 1024 672.579368 1024 512 1024 230.4 795.917474 0 512 0zM244.358737 581.793684c-32.579368 0-58.179368-25.6-58.179369-58.152421 0-32.606316 25.6-58.206316 58.206316-58.206316 32.552421 0 58.152421 25.6 58.152421 58.206316 0 32.579368-25.6 58.179368-58.179368 58.179369z m267.641263 0c-32.606316 0-58.206316-25.6-58.206316-58.152421 0-32.606316 25.6-58.206316 58.206316-58.206316 32.606316 0 58.206316 25.6 58.206316 58.206316 0 32.579368-25.6 58.179368-58.206316 58.179369z m267.641263 0c-32.606316 0-58.206316-25.6-58.206316-58.152421 0-32.606316 25.6-58.206316 58.206316-58.206316 32.579368 0 58.179368 25.6 58.179369 58.206316 0 32.579368-25.6 58.179368-58.206316 58.179369z"  ></path>
    </svg>
);
const mine = () => (
    <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 1024 1024">
        <path d="M512.107789 0C229.322105 0 0 229.268211 0 512.053895 0 794.866526 229.295158 1024 512 1024s512-229.268211 512-511.946105C1024 229.376 794.947368 0 512.107789 0z m0 791.309474c-145.650526 0-265.216-111.427368-278.123789-253.628632a23.444211 23.444211 0 0 1 23.336421-25.6h509.574737c13.743158 0 24.602947 11.856842 23.363368 25.6-12.934737 142.201263-132.608 253.628632-278.150737 253.628632z" ></path>
    </svg>
);
const Release = props => <Icon component={release} {...props} />;
const Follow = props => <Icon component={follow} {...props} />;
const Collection = props => <Icon component={collection} {...props} />;
const Information = props => <Icon component={information} {...props} />;
const Mine = props => <Icon component={mine} {...props} />;

class QS extends Component {
    constructor(props) {
        super(props)

        this.state = {
            dataList: [],
            total: 0,
            page: 1,
            hg: 10,
            value: '',
            // forumlist:[1,2,3,4,5],
            currentindex: -1,
            navlist: [],
            ps: 10,
            cate_id: 0
        }
    }

    onChange = page => {
        this.setState({
            page: page,
        });
        api.post({
            str: '/news/getNewsList'
        }, {
            category: 2,
            page: page,
            ps: 10
        })
            .then(res => res.json())
            .then(res => {
                if (res.code === 200) {
                    this.setState({
                        dataList: res.data.dataList,
                        total: res.data.total
                    })
                } else if (res.code === 4000) {
                    message.error(res.msg)
                } else if (res.code === 4001) {
                    message.error(res.msg)
                }
            })
    };

    componentDidMount() {
        this.navlist()
        this.getTalkArticlePageList()
    }

    /**
     * 文章分页列表
     * @author zxy 
     * @memberof QS
     */
    getTalkArticlePageList() {
        var cate_id = this.state.cate_id
        var search = this.state.value
        api.post({
            str: '/talk/getTalkArticlePageList'
        }, {
            page: 1,
            ps: 10,
            cate_id,
            search
        })
            .then(res => res.json())
            .then(res => {
                if (res.code == 200) {
                    this.setState({
                        total: res.data.total,
                        dataList: res.data.dataList,
                        page: 1
                    })
                } else {
                    message.error(res.msg)
                }
            })
    }

    /**
     * 文章分页处理
     * @author zxy 
     * @date 2021.11.22
     * @param {*} pageNumber
     * @param {*} pageSize
     * @memberof QS
     */
    onPageChange(pageNumber, pageSize) {
        var cate_id = this.state.date_id
        var search = this.state.search
        api.post({
            str: '/talk/getTalkArticlePageList'
        }, {
            page: pageNumber,
            ps: 10,
            cate_id,
            search
        })
            .then(res => res.json())
            .then(res => {
                if (res.code == 200) {
                    this.setState({
                        total: res.data.total,
                        dataList: res.data.dataList,
                        page: pageNumber
                    })
                } else {
                    message.error(res.msg)
                }
            })
    }

    /**
     * 新版其说顶部分类
     * @author zxy
     * @memberof QS
     */
    navlist() {
        api.post({
            str: '/talk/getTalkCategory'
        })
            .then(res => res.json())
            .then(res => {
                if (res.code == 200) {
                    this.setState({
                        navlist: res.data
                    })
                } else {
                    this.navlist()
                }
            })
    }

    /**
     *
     * 页面切换
     * @param {*} hg
     * @memberof QS
     * @description 弃用
     */
    // changehg(hg){
    //     console.log(hg)
    //     this.setState({
    //         hg
    //     })
    // }

    /**
     * 清除搜索
     * @author zxy
     * @memberof QS
     */
    clearinput() {
        this.setState({
            value: ''
        })
    }

    /**
     * search搜索输入功能
     * @author zxy
     * @date 2021.11.22
     * @param {*} e
     * @memberof QS
     */
    talkingsearch(e) {
        this.setState({
            value: e.target.value
        })
    }

    /**
     * 搜索功能
     * @author zxy
     * @memberof QS
     */
    search() {
        this.getTalkArticlePageList()
    }

    /**
     * 其说详情
     * @author zxy
     * @param {*} id
     * @memberof QS
     */
    qsxq(id) {
        this.props.history.push('/QishuoXq/' + id)
    }

    /**
     * 文章分类转换
     * @author zxy
     * @param {*} id
     * @param {*} index
     * @memberof QS
     * @description 延时器解决state异步问题
     */
    navchange(id, index) {
        setTimeout(() => {
            this.setState({
                currentindex: index,
                cate_id: id
            })
            this.getTalkArticlePageList()
        }, 100);
    }

    /**
     * 投稿
     * @author zxy
     * @memberof QS
     */
    release() {
        this.props.history.push('/ReleaseContent')
    }

    /**
     * 收藏
     * @author zxy
     * @memberof QS
     */
    releasecollect() {
        this.props.history.push('/ReleaseCollect')
    }

    /**
     * 我的
     * @author zxy
     * @memberof QS
     */
    releasemine() {
        this.props.history.push('/ReleaseMine')
    }

    render() {
        return (
            <Fragment>
                <div style={{background:'#FAFCFF',width:'100vw',height:'100%',marginTop:0}}>
                    <div className='talking-patent'>
                        {/* <div className='talking-patent-list'>
                        <ul>
                            <p>管理</p>
                            <li onClick={this.changehg.bind(this,10)} className={this.state.hg == 10 ? 'active-talking' : ''}>论坛首页</li>
                            <li onClick={this.changehg.bind(this,0)} className={this.state.hg == 0 ? 'active-talking' : ''}>发布</li>
                            <li onClick={this.changehg.bind(this,1)} className={this.state.hg == 1 ? 'active-talking' : ''}>项目</li>
                            <li onClick={this.changehg.bind(this,2)} className={this.state.hg == 2 ? 'active-talking' : ''}>消息</li>
                            <li onClick={this.changehg.bind(this,3)} className={this.state.hg == 3 ? 'active-talking' : ''}>收藏</li>
                            <li onClick={this.changehg.bind(this,4)} className={this.state.hg == 4 ? 'active-talking' : ''}>关注</li>
                        </ul>
                    </div> */}
                        <div className={this.state.hg == 10 ? 'talking-patent-content' : 'displaynone'}>
                            <div className='talking-patent-title'>
                                <div className='talking-patent-title-header'><p>热门</p><span>Popular</span></div>
                                <span style={{ position: 'relative', marginLeft: 'auto', marginRight: '10px' }}>
                                    <input onChange={this.talkingsearch.bind(this)} value={this.state.value} placeholder='人工智能' />
                                    <img onClick={this.clearinput.bind(this)} alt='' src="http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210716/6710f79b150d3c0b0f4c30a0146410342d2e086d.png" />
                                </span>
                                <div style={{ cursor: 'pointer' }} onClick={this.search.bind(this)}>搜索</div>
                            </div>
                            <div className='talking-patent-content-ul'>
                                <ul>
                                    {
                                        this.state.navlist.map((item, index) => {
                                            return <li className={index == this.state.currentindex ? 'talking-patent-content-ul-active' : ''} onClick={this.navchange.bind(this, item.id, index)} key={index}>
                                                {item.name}
                                            </li>
                                        })
                                    }
                                </ul>
                                <span style={{ color: '#999', fontSize: '1.2rem', fontWeight: '400' }}>{this.state.total}  条博客</span>
                            </div>
                            <ul className='talking-patent-content-list'>
                                {
                                    this.state.dataList.map((item, index) => {
                                        return <li onClick={this.qsxq.bind(this, item.id)} key={index}>
                                            <img src={item.cover_img} />
                                            <div className='talking-patent-content-list-li'>
                                                <div className='talking-patent-content-list-li-title'>
                                                    <p>{item.title}</p>
                                                </div>
                                                <div className='talking-patent-content-list-li-title-persion'>
                                                    <img src={item.head} />
                                                    <div >
                                                        <p>{item.name}</p>
                                                        <span>发布时间：{item.add_time}</span>
                                                    </div>
                                                </div>
                                                <p className='abstract'>{item.content}</p>
                                                <div className='talking-patent-content-list-li-footer'>
                                                    <Release />
                                                    <span>{item.review_num}条评论</span>
                                                </div>
                                            </div>
                                        </li>
                                    })
                                }
                            </ul>
                            <Pagination total={this.state.total} defaultCurrent={1} current={this.state.page} onChange={this.onPageChange.bind(this)} />
                        </div>
                        <div className='talking-patent-list-nav'>
                            <ul>
                                <li onClick={this.release.bind(this)}>
                                    <Follow style={{ fontSize: '3rem' }} />
                                    <span>投稿</span>
                                </li>
                                <li onClick={this.releasecollect.bind(this)}>
                                    <Collection style={{ fontSize: '3rem' }} />
                                    <span>收藏</span>
                                </li>
                                <li >
                                    <Information style={{ fontSize: '3rem' }} />
                                    <span>消息</span>
                                </li>
                                <li onClick={this.releasemine.bind(this)}>
                                    <Mine style={{ fontSize: '3rem' }} />
                                    <span>我的</span>
                                </li>
                            </ul>
                            <div>广告位招商</div>
                        </div>
                        <div className={this.state.hg == 0 ? 'talking-patent-release' : 'displaynone'}>
                            <div>
                                <h1>发布论题</h1><span>发帖请遵守《发帖协议》</span>
                            </div>
                            <div>
                                <input placeholder='请填写标题' />
                                <span>0/25</span>
                            </div>
                            <div>
                                <textarea placeholder="请填写内容"></textarea>
                            </div>
                            <div>发布</div>
                        </div>
                    </div>
                    <Fixednav />
                    <SearchFixed />
                </div>
            </Fragment>
        )
    }
}

export default withRouter(QS)