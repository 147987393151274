import React, { Fragment, Component } from 'react'
import '../Component-css/agent.css'
import { Cascader, Checkbox, message, Pagination } from 'antd'
import Headernav from './Headernav'
import api from '../api/api.js'
import options from '../Component/perisonoptions.js'
import Fixednav from './Fixednav'
import { withRouter } from 'react-router-dom'
import SearchFixed from './SearchFixed'

class Agent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            currentIndex: 0,
            currentmajorIndex: 0,
            agentList: [],
            ismchoice: false,
            banner: [],
            industry: [],
            majorlist: [],
            page: 1,
            ps: 10,
            category: '',
            region: '',
            total: 0,
            country:'中国',
            country_type:1,
            optionslist: [],
            currentprovinceIndex:'',
            provincelist: [
                { name: '北京', value: '北京' },
                { name: '上海', value: '上海' },
                { name: '重庆', value: '重庆' },
                { name: '四川', value: '四川' },
                { name: '江苏', value: '江苏' },
                { name: '广东', value: '广东' },
                { name: '浙江', value: '浙江' },
                { name: '陕西', value: '陕西' },
                { name: '湖北', value: '湖北' },
                { name: '更多', value: '更多' },
            ]
        }
    }

    //轮播
    /**
     * 轮播
     * @author zxy
     * @memberof Agent
     */
    lunbo() {
        api.post({
            str: '/banner/getBannerList'
        }, {
            module_id: 1
        })
            .then(res => res.json())
            .then(res => {
                if (res.code === 200) {
                    this.setState({
                        banner: res.data
                    })
                } else {
                    message.error(res.msg)
                }
            })
    }

    /**
     * 专利行业列表
     * @author zxy
     * @memberof Agent
     */
    typelist() {
        api.post({
            str: '/agent/getCategoryList'
        }, {
            type: 1
        })
            .then(res => res.json())
            .then(res => {
                if (res.code === 200) {
                    this.setState({
                        industry: res.data
                    })
                } else {
                    message.error(res.msg)
                }
            })
    }

    /**
     * 专利专业列表
     * @author zxy
     * @memberof Agent
     */
    majorlist() {
        api.post({
            str: '/agent/getCategoryList'
        }, {
            type: 4
        })
            .then(res => res.json())
            .then(res => {
                if (res.code === 200) {
                    this.setState({
                        majorlist: res.data
                    })
                } else {
                    message.error(res.msg)
                }
            })
    }

    /**
     * 代理人列表
     * @author zxy
     * @memberof Agent
     */
    industryList() {
        api.post({
            str: '/agent/getAgentList'
        }, {
            page: this.state.page,
            ps: this.state.ps,
            category: this.state.category,
            country_type:1
        })
            .then(res => res.json())
            .then(res => {
                if (res.code === 200) {
                    this.setState({
                        agentList: res.data.dataList,
                        total: res.data.total
                    })
                } else {
                    message.error(res.msg)
                }
            })
    }

    /**
     * 页面初始化
     * @author zxy
     * @description 获取3个选择列表，并做选择初始化处理
     * @memberof Agent
     */
    componentDidMount() {
        const arr = options
        arr.forEach(element => {
            element.children.forEach(item => {
                item.children = []
                this.setState({
                    optionslist: arr
                })
            })
        })
        // this.lunbo()
        this.typelist()
        this.industryList()
        this.majorlist()
    }

    /**
     * 选择专业分类
     * @author zxy
     * @description 分不限和其他具体专业
     * @param {*} item
     * @param {*} index
     * @memberof Agent
     */
    handelmajor(item, index) {
        this.setState({
            major: item,
            currentmajorIndex: index
        })
        if (item === '不限') {
            api.post({
                str: '/agent/getAgentList'
            }, {
                page: 1,
                ps: this.state.ps,
                category: this.state.category,
                region: this.state.region
            })
                .then(res => res.json())
                .then(res => {
                    if (res.code === 200) {
                        this.setState({
                            agentList: res.data.dataList,
                            total: res.data.total
                        })
                    } else {
                        message.error(res.msg)
                    }
                })
        } else {
            api.post({
                str: '/agent/getAgentList'
            }, {
                page: 1,
                ps: this.state.ps,
                category: this.state.category,
                region: this.state.region,
                major: item
            })
                .then(res => res.json())
                .then(res => {
                    if (res.code === 200) {
                        this.setState({
                            agentList: res.data.dataList,
                            total: res.data.total
                        })
                    } else {
                        message.error(res.msg)
                    }
                })
        }
    }

    /**
     * 行业分类选择
     * @author zxy
     * @description 分不限和其他选择
     * @param {*} item
     * @param {*} index
     * @memberof Agent
     */
    handelIndustry(item, index) {
        this.setState({
            currentIndex: index,
            category: item
        })
        if (item === '不限') {
            this.setState({
                category: ''
            })
            api.post({
                str: '/agent/getAgentList'
            }, {
                page:1,
                ps: this.state.ps
            })
                .then(res => res.json())
                .then(res => {
                    if (res.code === 200) {
                        this.setState({
                            agentList: res.data.dataList,
                            total: res.data.total
                        })
                    } else {
                        message.error(res.msg)
                    }
                })
        } else {
            api.post({
                str: '/agent/getAgentList'
            }, {
                page: 1,
                ps: this.state.ps,
                category: item
            })
                .then(res => res.json())
                .then(res => {
                    if (res.code === 200) {
                        this.setState({
                            agentList: res.data.dataList,
                            total: res.data.total
                        })
                    } else {
                        message.error(res.msg)
                    }
                })
        }



    }

    // IndustryCheck(e) {
    //     var categorylist = e.toString()
    //     var isAll = categorylist.indexOf('不限')
    //     this.setState({
    //         category: categorylist
    //     })
    //     if (isAll === 0) {
    //         api.post({
    //             str: '/agent/getAgentList'
    //         }, {
    //             page: this.state.page,
    //             ps: this.state.ps,
    //             category: ''
    //         })
    //             .then(res => res.json())
    //             .then(res => {
    //                 if (res.code === 200) {
    //                     this.setState({
    //                         agentList: res.data.dataList,
    //                         total: res.data.total
    //                     })
    //                 } else {
    //                     message.error(res.msg)
    //                 }
    //             })
    //         message.warn('因为勾选不限，默认搜索不限')
    //     } else {
    //         api.post({
    //             str: '/agent/getAgentList'
    //         }, {
    //             page: this.state.page,
    //             ps: this.state.ps,
    //             category: categorylist
    //         })
    //             .then(res => res.json())
    //             .then(res => {
    //                 if (res.code === 200) {
    //                     this.setState({
    //                         agentList: res.data.dataList,
    //                         total: res.data.total
    //                     })
    //                 } else {
    //                     message.error(res.msg)
    //                 }
    //             })
    //     }
    // }

    mchoice() {
        if (this.state.ismchoice === false) {
            this.setState({
                ismchoice: true
            })
        } else {
            this.setState({
                ismchoice: false
            })
        }
    }

    /**
     * 代理人详情
     * @author zxy
     * @description 需传递代理人id
     * @param {*} id
     * @memberof Agent
     */
    AgentDetails(id) {
        this.props.history.push(`/ZldlXq/${id}`)
    }

    // handelOptions(value) {
    //     var region = value.join()
    //     var regionlist = region.replace(',', '')
    //     this.setState({
    //         region: regionlist
    //     })
    //     if (this.state.category === '不限') {
    //         api.post({
    //             str: '/agent/getAgentList'
    //         }, {
    //             page: this.state.page,
    //             ps: this.state.ps,
    //             region: regionlist
    //         })
    //             .then(res => res.json())
    //             .then(res => {
    //                 if (res.code === 200) {
    //                     this.setState({
    //                         agentList: res.data.dataList,
    //                         total: res.data.total
    //                     })
    //                 } else {
    //                     message.error(res.msg)
    //                 }
    //             })
    //     } else {
    //         api.post({
    //             str: '/agent/getAgentList'
    //         }, {
    //             page: this.state.page,
    //             ps: this.state.ps,
    //             category: this.state.category,
    //             major: this.state.major,
    //             region: regionlist
    //         })
    //             .then(res => res.json())
    //             .then(res => {
    //                 if (res.code === 200) {
    //                     this.setState({
    //                         agentList: res.data.dataList,
    //                         total: res.data.total
    //                     })
    //                 } else {
    //                     message.error(res.msg)
    //                 }
    //             })
    //     }
    // }

    /**
     * 代理人分页处理
     * @author zxy
     * @param {*} pageNumber
     * @param {*} pageSize
     * @memberof Agent
     */
    onPageChange(pageNumber, pageSize) {
        this.setState({
            page: pageNumber,
            ps: pageSize
        })
        api.post({
            str: '/agent/getAgentList'
        }, {
            page: pageNumber,
            ps: pageSize,
            category:this.state.category,
            region:this.state.region,
            country:this.state.country,
            country_type:this.state.country_type
        })
            .then(res => res.json())
            .then(res => {
                if (res.code === 200) {
                    this.setState({
                        agentList: res.data.dataList,
                        total: res.data.total
                    })
                } else {
                    message.error(res.msg)
                }
            })
    }

    /**
     * 图片点击
     * @author zxy
     * @description 废用
     * @param {*} id
     * @param {*} is_jump
     * @memberof Agent
     */
    imageClick(id, is_jump) {
        if (is_jump === 1) {
            this.props.history.push(`/ZldlXq/${id}`)
        }
    }

    /**
     * 省份选择
     * @author zxy
     * @description 选择国外时出现国外的国家选择，并对country_type和country进行处理
     * @description 国内选择中选择更多时，对省份列表进行替换，替换为全部省份
     * @param {*} value
     * @param {*} index
     * @memberof Agent
     */
    handelprovince(value,index) {
        var list = this.state.provincelist
        if(value == '美国' || value == '欧洲' || value == '俄罗斯' || value == '南美洲' || value == '非洲' || value == '加拿大' || value == '日本' || value == '印度' || value == '澳大利亚' || value == '韩国'){
            api.post({
                str:'/agent/getAgentList'
            },{
                page:1,
                ps:this.state.ps,
                category:this.state.category,
                major:this.state.major,
                country_type:2,
                country:value
            })
            .then(res => res.json())
            .then(res => {
                if(res.code === 200){
                    this.setState({
                        agentList:res.data.dataList,
                        total:res.data.total,
                        currentprovinceIndex:index,
                        country:value
                    })
                }else{
                    message.error(res.msg)
                }
            })
        }else if(value == '更多'){
            var list = [
                { name: '北京', value: '北京' },
                { name: '上海', value: '上海' },
                { name: '重庆', value: '重庆' },
                { name: '四川', value: '四川' },
                { name: '江苏', value: '江苏' },
                { name: '广东', value: '广东' },
                { name: '浙江', value: '浙江' },
                { name: '陕西', value: '陕西' },
                { name: '湖北', value: '湖北' },
                { name: '天津', value: '天津' },
                { name: '山东', value: '山东' },
                { name: '河北', value: '河北' },
                { name: '山西', value: '山西' },
                { name: '内蒙古', value: '内蒙古' },
                { name: '辽宁', value: '辽宁' },
                { name: '吉林', value: '吉林' },
                { name: '黑龙江', value: '黑龙江' },
                { name: '安徽', value: '安徽' },
                { name: '福建', value: '福建' },
                { name: '江西', value: '江西' },
                { name: '河南', value: '河南' },
                { name: '湖南', value: '湖南' },
                { name: '广西', value: '广西' },
                { name: '海南', value: '海南' },
                { name: '贵州', value: '贵州' },
                { name: '云南', value: '云南' },
                { name: '西藏', value: '西藏' },
                { name: '甘肃', value: '甘肃' },
                { name: '青海', value: '青海' },
                { name: '宁夏', value: '宁夏' },
                { name: '新疆', value: '新疆' },
                { name: '台湾', value: '台湾' },
                { name: '香港', value: '香港' },
                { name: '澳门', value: '澳门' },
            ]
            this.setState({
                provincelist :list,
                country_type:1,
                page:1,
                country:'中国'
            })
        }else {
            api.post({
                str:'/agent/getAgentList'
            },{
                page:1,
                ps:this.state.ps,
                category:this.state.category,
                major:this.state.major,
                country_type:1,
                region:value,
                country:'中国'
            })
            .then(res => res.json())
            .then(res => {
                if(res.code === 200){
                    this.setState({
                        agentList:res.data.dataList,
                        total:res.data.total,
                        currentprovinceIndex:index,
                        country:'中国'
                    })
                }else{
                    message.error(res.msg)
                }
            })
        }
    }

    /**
     * 国家选择后对省份选择项或国外国家选择项进行处理
     * @author zxy
     * @description 对省份进行处理，对国外国家进行处理
     * @param {*} country
     * @memberof Agent
     */
    handlecountry(country){
        var country_all = ''
        if(country == 1){
            country_all = '中国'
            var list = [
                { name: '北京', value: '北京' },
                { name: '上海', value: '上海' },
                { name: '重庆', value: '重庆' },
                { name: '四川', value: '四川' },
                { name: '江苏', value: '江苏' },
                { name: '广东', value: '广东' },
                { name: '浙江', value: '浙江' },
                { name: '陕西', value: '陕西' },
                { name: '湖北', value: '湖北' },
                { name: '更多', value: '更多' },
            ]
            this.setState({
                provincelist :list,
                country_type:1,
                page:1,
                country:'中国'
            })
        }else{
            country_all = ''
            var list2 = [
                { name: '美国', value: '美国' },
                { name: '欧洲', value: '欧洲' },
                { name: '俄罗斯', value: '俄罗斯' },
                { name: '南美洲', value: '南美洲' },
                { name: '非洲', value: '非洲' },
                { name: '加拿大', value: '加拿大' },
                { name: '日本', value: '日本' },
                { name: '印度', value: '印度' },
                { name: '澳大利亚', value: '澳大利亚' },
                { name: '韩国', value: '韩国' },
            ]
            this.setState({
                provincelist:list2,
                country_type:2,
                page:1,
                country:''
            })
        }
        api.post({
            str:'/agent/getAgentList'
        },{
            page:1,
            ps:this.state.ps,
            category:this.state.category,
            major:this.state.major,
            country_type:country,
            country:country_all
        })
        .then(res => res.json())
        .then(res => {
            if(res.code === 200){
                this.setState({
                    agentList:res.data.dataList,
                    total:res.data.total
                })
            }else{
                message.error(res.msg)
            }
        })
        this.setState({
            country_type:country
        })
    }

    render() {
        return (
            <Fragment>
                <Headernav />
                <div className='agent'>
                    <div className='agent-nav'>
                        {/* 轮播图 */}
                        {/* <div className="agent-nav-content">
                            <Carousel effect="fade" autoplay >
                                {
                                    this.state.banner.map((item, index) => {
                                        return <img onClick={this.imageClick.bind(this,item.jump_id,item.is_jump)} key={index} className='banner' src={item.image} title={item.title} alt="图片" />
                                    })
                                }
                            </Carousel>
                        </div> */}
                        <div className="industry">
                            <div style={{ fontSize: '1.2rem', width: '6%', height: '57px' }}>
                                <p style={{ fontSize: '1.2rem', width: '100%', height: '26px', lineHeight: '26px', textAlign: 'right' }}>行业选择:</p>
                                {/* <div onClick={this.mchoice.bind(this)} className="cousor" style={{ fontSize: '0.75em', width: '100%', height: '26px', lineHeight: '26px', marginBottom: '0', textAlign: 'right', color: '#00C8DF' }}>多选</div> */}
                            </div>
                            <ul className={(this.state.ismchoice === false ? '' : 'displayNone') + " industry-ul"}>
                                {
                                    this.state.industry.map((item, index) => {
                                        return <li key={index} onClick={this.handelIndustry.bind(this, item.name, index)} className={(this.state.currentIndex === index ? 'currentCss' : '') + " industry-li hoverCss"} >{item.name}</li>
                                    })
                                }
                            </ul>

                            {/* <ul className={(this.state.ismchoice === true ? '' : 'displayNone') + " industry-ul"}>
                                <Checkbox.Group onChange={this.IndustryCheck.bind(this)} className="industry-checkbox hoverCss">
                                    {
                                        this.state.industry.map((item, index) => {
                                            return <Checkbox key={index} value={item.name} >{item.name}</Checkbox>
                                        })
                                    }

                                </Checkbox.Group>
                            </ul> */}
                        </div>
                        <div className='majorlist'>
                            <div style={{ fontSize: '1.2rem', width: '6%', height: '26px' }}>
                                <p style={{ fontSize: '1.2rem', width: '100%', height: '26px', lineHeight: '26px', textAlign: 'right' }}>专业领域:</p>
                                {/* <div onClick={this.mchoice.bind(this)} className="cousor" style={{ fontSize: '0.75em', width: '100%', height: '26px', lineHeight: '26px', marginBottom: '0', textAlign: 'right', color: '#00C8DF' }}>多选</div> */}
                            </div>
                            <ul className={(this.state.ismchoice === false ? '' : 'displayNone') + " majorlist-ul"}>
                                {
                                    this.state.majorlist.map((item, index) => {
                                        return <li key={index} onClick={this.handelmajor.bind(this, item.name, index)} className={(this.state.currentmajorIndex === index ? 'currentCss' : '') + " industry-li hoverCss"} >{item.name}</li>
                                    })
                                }
                            </ul>
                        </div>
                        <div className="industry-address">
                            <p style={{ fontSize: '1.2rem', width: '68px', height: '26px', lineHeight: '26px', textAlign: 'right' }}>地区选择:</p>
                            <div className='industry-address-country'>
                                <div className='industry-address-country-tab'>
                                    <span onClick={this.handlecountry.bind(this,1,'中国')} className={this.state.country_type === 1 ? 'currentCss' : ''}>国内</span>
                                    <span onClick={this.handlecountry.bind(this,2)} className={this.state.country_type === 2 ? 'currentCss' : ''}>国外</span>
                                </div>
                                <ul>
                                    {
                                        this.state.provincelist.map((item, index) => {
                                            return <li className={this.state.currentprovinceIndex === index ? 'currentCss':'' } onClick={this.handelprovince.bind(this, item.value,index)} key={index}>{item.name}</li>
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='agent-content-adv'>其知网诚邀各领域执业专利代理师入驻平台！ 联系方式：13020022305（微信同号）</div>
                    <div className='agent-content'>
                        {
                            this.state.agentList.map((item,index) => {
                                return <li key={index}>
                                    <img onClick={this.AgentDetails.bind(this,item.id)} style={{cursor:'pointer'}} src={item.head} />
                                    <div className='agent-content-name-title'>
                                        <p onClick={this.AgentDetails.bind(this,item.id)}>{item.name}</p>
                                        <span>{item.major}</span>
                                    </div>
                                    <span className='agent-content-experience'>代理经验：{item.agent_detail}{item.agent_detail == '统计中' ? '' : '件'}</span>
                                    <div className='agent-content-footer'>
                                        <a rel="noopener noreferrer" target="_blank" href="http://wpa.qq.com/msgrd?v=3&uin=407650541&site=qq&menu=yes">立即咨询</a>
                                        <span>{item.direction}</span>
                                    </div>
                                </li>
                            })
                        }
                    </div>

                    
                    <Pagination href="#top" style={{ width: '90vw', height: '60px', marginTop: '24px', marginLeft: '16px', paddingLeft: '24px', borderTop: '1px solid #D9D9D9', display: "flex", alignItems: 'center' }} showQuickJumper defaultCurrent={1} total={this.state.total} pageSize={10} onChange={this.onPageChange.bind(this)} />
                </div>
                <Fixednav />
                <SearchFixed />
            </Fragment>
        )
    }
}

export default withRouter(Agent)