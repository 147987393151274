import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { message } from 'antd'
import api from '../api/api'
import '../Component-css/QSXQ.css'
import SearchFixed from './SearchFixed'
import 'quill/dist/quill.snow.css'
import Fixednav from './Fixednav'

class QGXQ extends Component {
    constructor(props) {
        super(props)

        this.state = {
            title: '',
            author: '',
            add_time: '',
            content: ''
        }
    }

    componentDidMount() {
        var id = this.props.match.params.id
        api.post({
            str: '/news/getNewsInfo'
        }, {
            id: id
        })
            .then(res => res.json())
            .then(res => {
                if (res.code === 200) {
                    this.setState({
                        content: res.data.content,
                        title: res.data.title,
                        author: res.data.author,
                        add_time: res.data.add_time
                    })
                }else if(res.code === 4000){
                    message.error(res.msg)
                }else if(res.code === 4001){
                    message.error(res.msg)
                }
            })
    }

    render() {
        return (
            <Fragment>
                <div className='QsQg-content' style={{width: '100vw',height:'100%',position:'fixed',top:'0',bottom:'0'}}></div>
                <div style={{ width: '90vw', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                    <div style={{width: '62.5vw',backgroundColor:'#fff', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column',marginTop:'100px',marginBottom:'50px',opacity:'0.9'}}>
                    <div style={{ color: '#000000', fontSize: '24px', fontWeight: '400', marginTop: '43px' }}>{this.state.title}</div>
                    <div style={{ display: 'flex', flexDirection: 'row', fontSize: '12px', fontWeight: '300px', color: '#737373' }}>
                        <div>作者：{this.state.author}</div>
                        <div style={{ marginLeft: '10px' }}>{this.state.add_time}</div>
                    </div>
                    <div  className="QsQg ql-editor" style={{  width: '88%',marginBottom:'50px',fontSize:'250%'}} dangerouslySetInnerHTML={{ __html: this.state.content }}></div>
                    </div>
                </div>
                <SearchFixed />
                <Fixednav />
            </Fragment>
        )
    }
}

export default withRouter(QGXQ)