import React, { Component } from 'react'
import { Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import MarketProgramContent from '../Component/MarketProgramContent'

class MarketProgram extends Component {
    constructor(props) {
        super(props)

        this.state = {
            
        }
    }

    /**
     *
     *
     * @return {*} 市场管理
     * @memberof MarketProgram zxy
     */
    render() {
        return (
            <Fragment>
                <MarketProgramContent />
            </Fragment>
        )
    }
}

export default withRouter(MarketProgram)