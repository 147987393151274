import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import Headernav from './Headernav'
import { Image, message } from 'antd'
import '../Component-css/agentDetails.css'
import Fixednav from './Fixednav'
import api from '../api/api'
import SearchFixed from './SearchFixed'

class AgentDetails extends Component {
    constructor(props) {
        super(props)

        this.state = {
            name: '',
            card_id: '',
            work_years: '',
            rate: '',
            region: '',
            head: '',
            category_name: '',
            email: '',
            case: [],
            business: '',
            school: '',
            practice_id: '',
            organization: '',
            organization_code: '',
            rate_proportion: '',
            agent_detail: '',
            desc: '',
            evaluate: '',
            major:'',
            serving_customer: [],
            mark: [
                { value: '下证快', label: '下证快' },
                { value: '交流愉快', label: '交流愉快' },
                { value: '专业', label: '专业' },
            ],
        }
    }

    /**
     * 代理人详情
     * @author zxy
     * @memberof AgentDetails
     */
    AgentDetails() {
        var id = this.props.match.params.id
        api.post({
            str: '/agent/getAgentInfo'
        }, {
            id: id
        })
            .then(res => res.json())
            .then(res => {
                if (res.code === 200) {
                    // var serving_customer = res.data.serving_customer.split(',')
                    this.setState({
                        name: res.data.name,
                        card_id: res.data.card_id,
                        work_years: res.data.work_years,
                        rate: res.data.rate,
                        region: res.data.region,
                        head: res.data.head,
                        category_name: res.data.category_name,
                        email: res.data.email,
                        case: res.data.case,
                        business: res.data.business,
                        school: res.data.school,
                        practice_id: res.data.practice_id,
                        organization: res.data.organization,
                        organization_code: res.data.organization_code,
                        agent_detail: res.data.agent_detail,
                        rate_proportion: res.data.rate_proportion,
                        desc: res.data.desc,
                        serving_customer: res.data.serving_customer,
                        direction: res.data.direction,
                        tel: res.data.tel,
                        work_address: res.data.work_address,
                        major:res.data.major
                    })
                } else {
                    message.error(res.msg)
                }
            })
    }

    componentDidMount() {
        this.AgentDetails()
    }

    render() {
        return (
            <Fragment>
                <Headernav />
                <div className='agentdetails-header'>
                    <div className='agentdetails-title'>
                        <div className='agentdetails-infomation'>
                            <div>
                                <p>{this.state.name}</p>
                                <span>高级专利代理人</span>
                            </div>
                            <span className='agentdetails-infomation-address'>{this.state.organization}</span>
                            <p className='agentdetails-infomation-desc'>{this.state.desc}</p>
                            <div className='agentdetails-infomation-mark'>
                                {
                                    this.state.mark.map((item, index) => {
                                        return <li key={index}>
                                            {item.value}
                                        </li>
                                    })
                                }
                            </div>
                        </div>
                        <img alt='' src={this.state.head} />
                    </div>
                </div>
                <div className='agentdetails-content'>
                    <div className="agentdetails-content-left">
                        <span>从业资格</span>
                        <div>
                            <p>执业证号：</p>
                            <span>{this.state.practice_id}</span>
                        </div>
                        <div>
                            <p>执业机构：</p>
                            <span>{this.state.organization}</span>
                        </div>
                        <div>
                            <p>机构代码：</p>
                            <span>{this.state.organization_code}</span>
                        </div>
                        <div>
                            <p>办公地址：</p>
                            <span>{this.state.region}</span>
                        </div>
                        <div>
                            <p>毕业院校：</p>
                            <span>{this.state.school}</span>
                        </div>
                    </div>
                    <div className="agentdetails-content-right">
                        <span>代理资质</span>
                        <div>
                            <p>擅长方向：</p>
                            <span>{this.state.direction}</span>
                        </div>
                        <div>
                            <p>擅长领域：</p>
                            <span>{this.state.major}</span>
                        </div>
                        <div>
                            <p>执业时间：</p>
                            <span>{this.state.work_years}</span>
                        </div>
                        <div>
                            <p>服务客户：</p>
                            <span>{this.state.serving_customer}</span>
                        </div>
                        <div>
                            <p>代理经验：</p>
                            <span>{this.state.rate_proportion}</span>
                        </div>
                    </div>
                </div>
                <div className='agentdetails-footer'>
                    <div>
                        <p>全国服务热线：028-83033035</p>
                        <a>在线咨询</a>
                    </div>
                </div>
                <div className='agentdetails-footer-ft'></div>
                <Fixednav />
                <SearchFixed />
            </Fragment>
        )
    } 
}

export default withRouter(AgentDetails)