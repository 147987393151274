// eslint-disable-next-line no-unused-vars
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

/**
 *
 *
 * @class ScrollToTop 返回向上
 * @extends {Component} zxy
 */
class ScrollToTop extends Component {
    constructor(props) {
        super(props)

        this.state = {
            
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
          window.scrollTo(0, 0)
        }
    }

    render() {
        return this.props.children
    }
}

export default withRouter(ScrollToTop)