import React, { Component } from 'react'
import { Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import RemindSetContent from '../Component/RemindSetContent.js'

class RemindSet extends Component {
    constructor(props) {
        super(props)

        this.state = {
            
        }
    }

    /**
     *
     *
     * @return {*} 提醒设置
     * @memberof RemindSet  zxy
     */
    render() {
        return (
            <Fragment>
                <RemindSetContent/>
            </Fragment>
        )
    }
}

export default withRouter(RemindSet)