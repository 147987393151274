import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import MarketXqContent from '../Component/MarketXqContent.js'

class MarketXq extends Component {
    constructor(props) {
        super(props)

        this.state = {
            
        }
    }

    /**
     *
     *
     * @return {*} 市场详情
     * @memberof MarketXq zxy
     */
    render() {
        return (
            <Fragment>
                <MarketXqContent />
            </Fragment>
        )
    }
}

export default withRouter(MarketXq)