import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router'
import BookDownload from '../Component/Bookdownload'

/**
 *
 *
 * @class Bookdownload 书式下载
 * @extends {Component} zxy
 */
class Bookdownload extends Component {
    constructor(props) {
        super(props)

        this.state = {
            
        }
    }

    render() {
        return (
            <Fragment>
                <BookDownload />
            </Fragment>
        )
    }
}

export default withRouter(Bookdownload)