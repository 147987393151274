import React, { Component } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import Zllb from './page/Zllb'
import App from './page/App'
import Zlxq from './page/Zlxq'
import ZhuCe from './page/ZhuCe'
import zhCN from 'antd/es/locale/zh_CN'
import { ConfigProvider } from 'antd';
import Grzx from './page/Grzx'
import Qiguan from './page/Qiguan'
import Qishuo from './page/Qishuo'
import QishuoXq from './page/QishuoXq'
import QiguanXq from './page/QiguanXq'
import Zldl from './page/Zldl'
import ZldlXq from './page/ZldlXq'
import QZXY from './page/QZXY'
import QZXYxq from './page/QZXYxq'
import Market from './page/Market'
import MarketContent from './page/MarketXq'
import RequireMarket from './page/RequireMarket'
import MarketProgram from './page/MarketProgram'
import About from './page/About'
import Apply from './page/Apply'
import Annualfee from './page/Annualfee'
import RemindSet from './page/RemindSet'
import Addpay from './page/Addpay'
import PayConfirm from './page/PayConfirm'
import MonitorImport from './page/MonitorImport'
import Paying from './page/Paying'
import SuccessPay from './page/SuccessPay'
import FailPay from './page/FailPay'
import Order from './page/Order'
import ScrollToTop from './ScrollToTop'
import EditorUser from './Component/EditorUser'
import AdvancedSearch from './page/AdvancedSearch'
import MoreVideo from './Component/MoreVideo'
import ReleaseContent from './Component/ReleaseContent'
import ReleaseCollect from './Component/ReleaseCollect'
import ReleaseMine from './Component/ReleaseMine'
import BookDownload from './page/Bookdownload'
import Signin from './page/Signin'

/**
 *
 *
 * @class Router 路由
 * @extends {Component} zxy
 */
class Router extends Component {
    constructor(props) {
        super(props)

        this.state = {

        }
    }

    render() {

        return (
            <ConfigProvider locale={zhCN}>
                
                <HashRouter >
                <ScrollToTop>
                    <Switch forceRefresh={true}>
                        <Route path='/' component={App} exact ></Route>
                        <Route path='/App' component={App}></Route>
                        <Route path='/Zllb/:search/:type' component={Zllb}></Route>
                        <Route path='/ZhuCe' component={ZhuCe}></Route>
                        <Route path='/Grzx/:tag' component={Grzx} ></Route>
                        <Route path='/Qiguan' component={Qiguan} ></Route>
                        <Route path='/Qishuo' component={Qishuo} ></Route>
                        <Route path='/QiguanXq/:id' component={QiguanXq} ></Route>
                        <Route path='/QishuoXq/:id' component={QishuoXq} ></Route>
                        <Route path='/Zldl' component={Zldl} ></Route>
                        <Route path='/ZldlXq/:id' component={ZldlXq} ></Route>
                        <Route path='/QZXY' component={QZXY} ></Route>
                        <Route path='/QZXYxq/:id' component={QZXYxq} ></Route>
                        <Route path='/Market' component={Market} ></Route>
                        <Route path='/MarketContent/:id' component={MarketContent} ></Route>
                        <Route path='/RequireMarket' component={RequireMarket} ></Route>
                        <Route path='/About' component={About} ></Route>
                        <Route path='/Apply' component={Apply} ></Route>
                        <Route path='/Annualfee' component={Annualfee} ></Route>
                        <Route path='/RemindSet' component={RemindSet} ></Route>
                        <Route path='/Addpay' component={Addpay} ></Route>
                        <Route path='/PayConfirm' component={PayConfirm} ></Route>
                        <Route path='/MonitorImport' component={MonitorImport} ></Route>
                        <Route path='/Paying/:paymethod' component={Paying} ></Route>
                        <Route path='/SuccessPay' component={SuccessPay} ></Route>
                        <Route path='/FailPay' component={FailPay} ></Route>
                        <Route path='/Order/:order_sn' component={Order} ></Route>
                        <Route path='/AdvancedSearch' component={AdvancedSearch} ></Route>
                        <Route path='/EditorUser' component={EditorUser} ></Route>
                        <Route path='/MoreVideo/:hg/:id' component={MoreVideo} ></Route>
                        <Route path='/ReleaseContent' component={ReleaseContent} ></Route>
                        <Route path='/ReleaseCollect' component={ReleaseCollect} ></Route>
                        <Route path='/ReleaseMine' component={ReleaseMine} ></Route>
                        <Route path='/BookDownload' component={BookDownload} ></Route>
                        <Route path='/Signin' component={Signin} ></Route>
                    </Switch>
                    <Switch forceRefresh={false}>
                        <Route path='/MarketProgram' component={MarketProgram} ></Route>
                        <Route path='/Zlxq/:search/:id' component={Zlxq}></Route>
                    </Switch>
                    
                </ScrollToTop>
                </HashRouter>
            </ConfigProvider>
        )
    }
}

export default Router