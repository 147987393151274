/*
 * @Author: zxy 
 * @descrption 专利申请
 * @Date: 2021-11-10 14:13:54 
 * @Last Modified by: zxy
 * @Last Modified time: 2021-11-22 11:22:47
 */
import React, { Component } from 'react'
import { Fragment } from 'react'
import Headernav from './Headernav'
import '../Component-css/ApplyContent.css'
import TweenOne from 'rc-tween-one'
import QueueAnim from 'rc-queue-anim'
import SearchFixed from './SearchFixed'

class ApplyContent extends Component {
    constructor(props) {
        super(props)

        this.state = {

        }
    }

    render() {
        return (
            <Fragment>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                    <Headernav />
                    <header>
                        <div style={{ fontSize: '4.2rem', marginTop: '110px', marginLeft: '382px', fontWeight: '700', color: '#00C8DF' }}>专利</div>
                        <div style={{ marginLeft: '16px', display: 'flex', flexDirection: 'column', marginTop: '110px' }}>
                            <TweenOne animation={{ rotate: 0, duration: 1000 }} className='Applytitle' style={{ width: '299px', height: '116px', lineHeight: '116px', fontSize: '2.625rem', color: '#ffffff', paddingLeft: '30px', background: '#00C8DF', fontWeight: '700', display: 'flex' }}>申请流程</TweenOne>
                            <TweenOne className='Applytitle_English' style={{ display: 'flex', flexDirection: 'column' }} animation={{ y: 0, delay: 1000, duration: 500, opacity: 1 }}>
                                <span>Patent</span>
                                <span>application process</span>
                            </TweenOne>
                        </div>
                        <div style={{ backgroundColor: '#00C8DF', opacity: '0.3', width: '150px', height: '113px', position: 'absolute', top: '180px', left: '350px' }}></div>
                    </header>
                    <div className="content">
                        <QueueAnim delay={100} duration={2000} className='queue-simple'>
                            <div className='queue-simple-div' key='div1'>
                                <div className='queue-simple-div-div1'>01</div>
                                <div className='queue-simple-div-div2'>
                                    <div>Online consultation</div>
                                    <div>在线咨询</div>
                                    <div className='queue-simple-div-div3'>
                                        <div>专业顾问指导</div>
                                        <div>解决面临问题</div>
                                    </div>
                                </div>
                                <div style={{ marginTop: '-35px', marginRight: '40px', marginLeft: 'auto' }}><img alt='' src={require('../images/rightjt.png')} /></div>
                            </div>
                            <div className='queue-simple-div' key='div2'>
                                <div className='queue-simple-div-div1'>02</div>
                                <div className='queue-simple-div-div2'>
                                    <div>Confirm Order</div>
                                    <div>确认订单</div>
                                    <div className='queue-simple-div-div3'>
                                        <div>沟通保护客体</div>
                                        <div>确认申请方案</div>
                                    </div>
                                </div>
                                <div style={{ marginTop: '-35px', marginRight: '40px', marginLeft: 'auto' }}><img alt='' src={require('../images/rightjt.png')} /></div>
                            </div>
                            <div className='queue-simple-div' key='div3'>
                                <div className='queue-simple-div-div1'>03</div>
                                <div className='queue-simple-div-div2'>
                                    <div>Technical clarification</div>
                                    <div>技术交底</div>
                                    <div className='queue-simple-div-div3'>
                                        <div>交流技术问题</div>
                                        <div>挖掘创新特征</div>
                                    </div>
                                </div>
                                <div style={{ marginTop: '-35px', marginRight: '40px', marginLeft: 'auto' }}><img alt='' src={require('../images/bottomjt.png')} /></div>
                            </div>
                            <div className='queue-simple-div' key='div4'>
                                <div className='queue-simple-div-div1'>06</div>
                                <div className='queue-simple-div-div2'>
                                    <div>Patent examination</div>
                                    <div>专利审查</div>
                                    <div className='queue-simple-div-div3'>
                                        <div>申请文件审查</div>
                                        <div>授权条件审查</div>
                                    </div>
                                </div>
                                <div style={{ marginTop: '-35px', marginRight: '40px', marginLeft: 'auto' }}><img alt='' src={require('../images/bottomjt.png')} /></div>
                            </div>
                            <div className='queue-simple-div' key='div5'>
                                <div className='queue-simple-div-div1'>05</div>
                                <div className='queue-simple-div-div2'>
                                    <div>Patent acceptance</div>
                                    <div>专利受理</div>
                                    <div className='queue-simple-div-div3'>
                                        <div>递交专利申请</div>
                                        <div>下发受理通知</div>
                                    </div>
                                </div>
                                <div style={{ marginTop: '-35px', marginRight: '40px', marginLeft: 'auto' }}><img alt='' src={require('../images/leftjt.png')} /></div>
                            </div>
                            <div className='queue-simple-div' key='div6'>
                                <div className='queue-simple-div-div1'>04</div>
                                <div className='queue-simple-div-div2'>
                                    <div>Patent finalization</div>
                                    <div>专利定稿</div>
                                    <div className='queue-simple-div-div3'>
                                        <div>专家撰写专利</div>
                                        <div>形成申请文件</div>
                                    </div>
                                </div>
                                <div style={{ marginTop: '-35px', marginRight: '40px', marginLeft: 'auto' }}><img alt='' src={require('../images/leftjt.png')} /></div>
                            </div>
                            <div className='queue-simple-div' key='div7'>
                                <div className='queue-simple-div-div1'>07</div>
                                <div className='queue-simple-div-div2'>
                                    <div>Patent examination</div>
                                    <div>获得授权</div>
                                    <div className='queue-simple-div-div3'>
                                        <div>下发授权通知</div>
                                        <div>办理登记手续</div>
                                    </div>
                                </div>
                                <div style={{ marginTop: '-35px', marginRight: '40px', marginLeft: 'auto' }}><img alt='' src={require('../images/rightjt.png')} /></div>
                            </div>
                            <div className='queue-simple-div' key='div8'>
                                <div className='queue-simple-div-div1'>08</div>
                                <div className='queue-simple-div-div2'>
                                    <div>Status monitoring</div>
                                    <div>状态监控</div>
                                    <div className='queue-simple-div-div3'>
                                        <div>监控权利状态</div>
                                        <div>及时缴纳年费</div>
                                    </div>
                                </div>
                                <div style={{ marginTop: '0px', marginRight: '40px', marginLeft: 'auto' }}><img alt='' src={require('../images/rightjt.png')} /></div>
                            </div>
                            <div className='queue-simple-div4 queue-simple-div' key='div9'>
                                <div style={{marginBottom:0,width:'200px',height:'48px',lineHeight:'48px',textAlign:'center',background:'#00C8DF',display:'flex',marginBottom:'20px'}}>
                                    <a style={{color:'#FFF',height:'48px',lineHeight:'48px',fontSize:'1.4rem'}} rel="noopener noreferrer" target="_blank" href="http://wpa.qq.com/msgrd?v=3&uin=407650541&site=qq&menu=yes">马上联系</a>
                                </div>
                            </div>
                        </QueueAnim>
                    </div>
                </div>
                <SearchFixed />
            </Fragment>
        )
    }
}

export default ApplyContent