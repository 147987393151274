import React,{ Component,Fragment } from 'react';
import Headernav from '../Component/Headernav.js';
import Easysearch from '../Component/Easysearch.js';
import '../page-css/App.css';


/**
 *
 *
 * @class App 主页
 * @extends {Component} zxy
 */
class App extends Component {
   
    render() {
        return (
            <Fragment>
                <Headernav/>
                <Easysearch/>
            </Fragment>
        )
    };
}
export default App;
