import React, { Component } from 'react'
import { Fragment } from 'react'
import AboutContent from '../Component/AboutContent.js'

class About extends Component {
    constructor(props) {
        super(props)

        this.state = {
            
        }
    }

    /**
     *
     *
     * @return {*} 关于我们
     * @memberof About zxy
     */
    render() {
        return (
            <Fragment>
                <AboutContent/>
            </Fragment>
        )
    }
}

export default About