import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import "../Component-css/Content.css"
import { message } from 'antd'
import api from '../api/api'
import md5 from 'js-md5'
import Headernav from '../Component/Headernav'
import '../page-css/register.css'

/**
 *
 *
 * @class ZhuCe 注册
 * @extends {Component} zxy
 */
class ZhuCe extends Component {
    constructor(props) {
        super(props)

        this.state = {
            passwordShow: false,
            tel: 0,
            isTruetel: true,
            isTrueuser: true,
            username: '',
            isYzm: true,
            time: 0,
            err: '',
            iserr: false
        }
        this.passwordShow = this.passwordShow.bind(this)
        this.handleDl = this.handleDl.bind(this)
    }

    handleDl() {
        this.props.history.push(`/Signin`)
    }

    //校验手机号，号段主要有：130~139、150~153，155~159，180~189、170~171、176~178、160~169、145、147、190~199
    handleTel(e) {
        const regs = /^((13[0-9])|(14[57])|(15[^4,\\D])|(16[0-9])|(17[0-1,6-8])|(18[0-9])|(19[0-9]))\d{8}$/
        var number = e.target.value.replace(/\s/g, '')
        if (!regs.test(number) || number.length !== 11) {
            this.setState({
                isTruetel: false
            })
        } else {
            this.setState({
                tel: number,
                isTruetel: true
            })
        }
    }

    Yzm() {
        var mobile = this.refs.tel.value
        var scene = 'register'
        if (mobile === '') {
            var err = '请输入手机号'
            this.setState({
                err: err,
                iserr: true
            })
        } else if (this.state.isTruetel === false) {
            var err1 = '您输入的电话不正确，请重新输入'
            this.setState({
                err: err1
            })
        } else {
            this.lastTime()
            api.post({
                str: '/login/getCode'
            }, {
                mobile: mobile,
                scene: scene
            })
                .then(res => res.json())
                .then(res => {
                    if (res.code === 200) {
                        this.setState({
                            err: res.msg,
                            iserr: false
                        })
                    } else {
                        this.setState({
                            err: res.msg,
                            iserr: true
                        })
                    }
                })
        }
    }

    lastTime() {
        let time = 60
        this.interval = setInterval(() => {
            if (time > 0) {
                time--
                this.setState({
                    isYzm: false,
                    time: time
                })
            } else if (time === 0) {
                this.setState({
                    isYzm: true
                })
                clearInterval(this.interval)
            }
        }, 1000)
    }

    handleUsername(e) {
        const regs = /^[\u4E00-\u9FA5A-Za-z0-9]{4,11}$/
        var name = e.target.value
        if (!regs.test(name) || name.length === 0) {
            this.setState({
                isTrueuser: false
            })
        } else {
            this.setState({
                isTrueuser: true,
                username: name
            })
        }
    }

    passwordShow() {
        if (this.state.passwordShow === false) {
            this.setState({
                passwordShow: true
            })
        } else {
            this.setState({
                passwordShow: false
            })
        }
    }

    zhuce() {
        var username = this.refs.username.value
        var mobile = this.refs.tel.value
        var password = md5(this.refs.password.value)
        var yzm = parseInt(this.refs.yzm.value)
        if (username === '') {
            this.setState({
                err: '请输入用户名'
            })
        } else if (mobile === '') {
            this.setState({
                err: '请输入手机号'
            })
        } else if (password === '') {
            this.setState({
                err: '请输入密码'
            })
        } else if (yzm === '') {
            this.setState({
                err: '请输入验证码'
            })
        } else {
            api.post({
                str: '/login/register'
            }, {
                username: username,
                mobile: mobile,
                password: password,
                code: yzm
            })
                .then(res => res.json())
                .then(res => {
                    if (res.code === 200) {
                        api.post({
                            str: '/login/login'
                        }, {
                            mobile: mobile,
                            password: password,
                            type: 1
                        })
                            .then(res => res.json())
                            .then(res => {
                                if (res.code === 200) {
                                    localStorage.setItem('token', res.data.token)
                                    this.props.history.push('/App')
                                } else {
                                    message.error(res.msg)
                                    this.props.history.push('/App')
                                }
                            })
                    } else {
                        this.setState({
                            err: res.msg
                        })
                    }
                })
        }
    }

    render() {
        return (
            <Fragment>
                <Headernav />
                <div className='register-wrap' >
                    <div className='register-wrap-bg' ></div>
                    <div className='register-content-wrap'>
                        <div className='register-content'>
                            <div className='register-content-title' >欢迎注册</div>
                            <div className='register-content-change' >已有账号？<span onClick={this.handleDl}  >登陆</span></div>
                            <div className='register-content-username' >
                                <span>用户名：</span>
                                <input maxLength="8" ref='username' onBlur={this.handleUsername.bind(this)} placeholder="请输入用户名(字符长度为4-11)，默认为手机号" />
                                <span className={this.state.isTrueuser === false ? '' : 'displayNone'} >您输入的字符中包含非法字符或者字符长度为4-11，请重新输入</span>
                            </div>
                            <div className='register-content-phone'>
                                <span>手机号：</span>
                                <input maxLength="11" ref='tel' minLength='11' onBlur={this.handleTel.bind(this)} placeholder="请输入手机号" type="tel" />
                                <span className={this.state.isTruetel === false ? '' : 'displayNone'} >请输入正确的电话号码</span>
                            </div>
                            <div className='register-content-password' >
                                <span>密码：</span>
                                <input ref='password' placeholder="请输入密码" type={this.state.passwordShow ? 'text' : 'password'} maxLength="20" minLength="6" />
                                <span onClick={this.passwordShow} ><img src={require(this.state.passwordShow ? '../images/openeyes.png' : '../images/closeeyes.png')} alt="是否显示" /></span>
                            </div>
                            <div className='register-content-verification' >
                                <div>
                                    <span>验证码：</span>
                                    <input ref="yzm" placeholder="请输入验证码" />
                                </div>
                                <button onClick={this.Yzm.bind(this)} className={this.state.isYzm === true ? 'register-content-verification-button' : 'displayNone'} >获取验证码</button>
                                <button className={this.state.isYzm === true ? 'displayNone' : 'register-content-verification-button2'}>{this.state.time}s后重新发送</button>
                            </div>
                            <span className='register-content-error'>{this.state.err}</span>
                            <div className='register-content-sure'>
                                <button onClick={this.zhuce.bind(this)} key="submit" className="ok-btndl" type="primary" >注册</button>
                            </div>
                            <div className='register-content-sure-ft' ></div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default withRouter(ZhuCe)