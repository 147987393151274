import React, { Component } from 'react'
import { Fragment } from 'react'
import ApplyContent from '../Component/ApplyContent.js'

/**
 *
 *
 * @class Apply 专利申请
 * @extends {Component} zxy
 */
class Apply extends Component {
    constructor(props) {
        super(props)

        this.state = {
            
        }
    }

    render() {
        return (
            <Fragment>
                <ApplyContent/>
            </Fragment>
        )
    }
}

export default Apply