import React, { Component, Fragment } from 'react'
import "../Component-css/Sider.css"
import { Radio } from 'antd';
import { DatePicker, Space } from 'antd';
import { Cascader, message } from 'antd';
import { withRouter } from 'react-router-dom'
import options from '../Component/perisonoptions.js';
import locale from 'antd/es/date-picker/locale/zh_CN';
import 'moment/locale/zh-cn';
import api from '../api/api';

class Sider extends Component {
    constructor(props) {
        super(props)

        this.state = {
            zlyxx: '',
            address: [],
            zllx: '',
            sqrq: [],
            gkrq: []
        }
        this.handleDate = this.handleDate.bind(this)
        this.handleDateOpen = this.handleDateOpen.bind(this)
        this.onChange = this.onChange.bind(this)
        this.onChangeaddress = this.onChangeaddress.bind(this)
        this.onChange2 = this.onChange2.bind(this)
    }

    onChange2 = e => {
        this.setState({
            zlyxx: e.target.value
        })
    }

    onChangeaddress = value => {
        this.setState({
            address: value
        })
    }

    onChange = e => {
        this.setState({
            zllx: e.target.value
        })
    }

    handleDate(date, dateString) {
        const dates = dateString;
        this.setState({
            sqrq: dates
        })
    }

    handleDateOpen(date, dateString) {
        const dateOpen = dateString;
        this.setState({
            gkrq: dateOpen
        })
    }

    handleShaiOk = e => {
        var sqh = this.refs.sqh.value.trim()
        var search = this.props.match.params.search
        var sqr = this.refs.sqr.value.trim()
        var zlwyid = this.refs.zlwyid.value.trim()
        var dljg = this.refs.dljg.value.trim()
        var zlmc = this.refs.zlmc.value.trim()
        var fmr = this.refs.fmr.value.trim()
        var zlyxx = this.state.zlyxx
        var sqrq = this.state.sqrq
        var gkrq = this.state.gkrq
        var zllx = this.state.zllx
        var address = this.state.address
        var data = {
            sqh: sqh,
            search: search,
            sqr: sqr,
            zlwyid: zlwyid,
            dljg: dljg,
            zlmc: zlmc,
            fmr: fmr,
            zlyxx: zlyxx,
            sqrq: sqrq,
            gkrq: gkrq,
            zllx: zllx,
            address: address,
        }
        this.props.secondsearch(data)
    }

    render() {
        const { RangePicker } = DatePicker;
        return (
            <Fragment>
                <div className="sider-select"></div>
                <div className="sider-select" style={{ position: 'fixed', overflowX: 'hidden', overflowY: 'auto' }}>
                    <div style={{ width: '264px', marginTop: '31px', height: '45px', borderBottom: '0.5px solid rgba(0, 0, 0, 0.1)', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <div style={{ width: '12px', height: '12px', backgroundColor: '#00C8DF', }}></div>
                        <div style={{ fontSize: '12px', color: 'rgba(0,0,0,0.8)', marginLeft: '10px' }}>申请号:</div>
                        <input style={{ fontSize: '0.75rem', border: '0', height: '44px', width: '180px', marginLeft: '10px' }} placeholder="例如 CN201310111568.6" ref="sqh" />
                    </div>
                    <div style={{ width: '264px', height: '45px', borderBottom: '0.5px solid rgba(0, 0, 0, 0.1)', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <div style={{ width: '12px', height: '12px', backgroundColor: '#00C8DF', }}></div>
                        <div style={{ fontSize: '12px', color: 'rgba(0,0,0,0.8)', marginLeft: '10px' }}>申请人:</div>
                        <input style={{ fontSize: '0.75rem', border: '0', height: '44px', width: '180px', marginLeft: '10px' }} placeholder="例如 张三" ref="sqr" />
                    </div>
                    <div style={{ width: '264px', lineHeight: '45px', borderBottom: '0.5px solid rgba(0, 0, 0, 0.1)', display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '0' }}>
                            <div style={{ width: '12px', height: '12px', backgroundColor: '#00C8DF', }}></div>
                            <div style={{ fontSize: '12px', color: 'rgba(0,0,0,0.8)', marginLeft: '10px' }}>申请日:</div>
                        </div>
                        <Space direction="vertical" size={12} style={{ fontSize: '0.75rem', marginLeft: '0px' }} >
                            <RangePicker onCalendarChange={this.handleDate} locale={locale} bordered={false} />
                        </Space>
                    </div>
                    <div style={{ width: '264px', height: '45px', borderBottom: '0.5px solid rgba(0, 0, 0, 0.1)', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <div style={{ width: '12px', height: '12px', backgroundColor: '#00C8DF', }}></div>
                        <div style={{ fontSize: '12px', color: 'rgba(0,0,0,0.8)', marginLeft: '10px' }}>公开/公告号:</div>
                        <input style={{ fontSize: '0.75rem', border: '0', height: '44px', width: '120px', marginLeft: '10px' }} placeholder="例如 CN103208406B" ref="zlwyid" />
                    </div>
                    <div style={{ width: '264px', lineHeight: '45px', borderBottom: '0.5px solid rgba(0, 0, 0, 0.1)', display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '0' }}>
                            <div style={{ width: '12px', height: '12px', backgroundColor: '#00C8DF', }}></div>
                            <div style={{ fontSize: '12px', color: 'rgba(0,0,0,0.8)', marginLeft: '10px' }}>公开/公告日:</div>
                        </div>
                        <Space direction="vertical" size={12} style={{ fontSize: '0.75rem', marginLeft: '0px' }} >
                            <RangePicker onCalendarChange={this.handleDateOpen} locale={locale} bordered={false} />
                        </Space>
                    </div>
                    <div style={{ width: '264px', height: '45px', borderBottom: '0.5px solid rgba(0, 0, 0, 0.1)', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <div style={{ width: '12px', height: '12px', backgroundColor: '#00C8DF', }}></div>
                        <div style={{ fontSize: '12px', color: 'rgba(0,0,0,0.8)', marginLeft: '10px' }}>代理机构:</div>
                        <input style={{ fontSize: '0.75rem', border: '0', height: '44px', width: '160px', marginLeft: '10px' }} placeholder="例如 中索知识产权有限公司" ref="dljg" />
                    </div>
                    <div style={{ width: '264px', height: '45px', borderBottom: '0.5px solid rgba(0, 0, 0, 0.1)', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <div style={{ width: '12px', height: '12px', backgroundColor: '#00C8DF', }}></div>
                        <div style={{ fontSize: '12px', color: 'rgba(0,0,0,0.8)', marginLeft: '10px' }}>专利名称:</div>
                        <input style={{ fontSize: '0.75rem', border: '0', height: '44px', width: '160px', marginLeft: '10px' }} placeholder="例如 一种一二三四五的计数器" ref="zlmc" />
                    </div>
                    <div style={{ width: '264px', height: '45px', borderBottom: '0.5px solid rgba(0, 0, 0, 0.1)', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <div style={{ width: '12px', height: '12px', backgroundColor: '#00C8DF', }}></div>
                        <div style={{ fontSize: '12px', color: 'rgba(0,0,0,0.8)', marginLeft: '10px' }}>发明人:</div>
                        <input style={{ fontSize: '0.75rem', border: '0', height: '44px', width: '180px', marginLeft: '10px' }} placeholder="例如 张三" ref="fmr" />
                    </div>
                    <div style={{ width: '264px', lineHeight: '45px', borderBottom: '0.5px solid rgba(0, 0, 0, 0.1)', display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '0' }}>
                            <div style={{ width: '12px', height: '12px', backgroundColor: '#00C8DF', }}></div>
                            <div style={{ fontSize: '12px', color: 'rgba(0,0,0,0.8)', marginLeft: '10px' }}>专利类型:</div>
                        </div>
                        <Radio.Group defaultChecked="false" buttonStyle="solid" onChange={this.onChange} style={{ display: 'flex', alignItems: 'center', height: "60px", width: '270px', flexDirection: "row" }}>
                            <Radio.Button value="发明专利" style={{ width: '82px', height: '32px', backgroundColor: '#fff', border: '0.5px solid rgba(0,0,0,0.1)', textAlign: 'center', borderRadius: '16px', color: 'rgba(0,0,0,0.4)', fontSize: '12px', lineHeight: '32px', marginLeft: '10px' }}>发明专利</Radio.Button>
                            <Radio.Button value="实用新型" style={{ width: '82px', height: '32px', backgroundColor: '#fff', border: '0.5px solid rgba(0,0,0,0.1)', textAlign: 'center', borderRadius: '16px', color: 'rgba(0,0,0,0.4)', fontSize: '12px', lineHeight: '32px', marginLeft: 'auto' }}>实用新型</Radio.Button>
                            <Radio.Button value="外观设计" style={{ width: '82px', height: '32px', backgroundColor: '#fff', border: '0.5px solid rgba(0,0,0,0.1)', textAlign: 'center', borderRadius: '16px', color: 'rgba(0,0,0,0.4)', fontSize: '12px', lineHeight: '32px', marginLeft: 'auto', marginRight: '10px' }}>外观专利</Radio.Button>
                        </Radio.Group>
                    </div>
                    <div style={{ width: '264px', lineHeight: '45px', borderBottom: '0.5px solid rgba(0, 0, 0, 0.1)', display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '0' }}>
                            <div style={{ width: '12px', height: '12px', backgroundColor: '#00C8DF', }}></div>
                            <div style={{ fontSize: '12px', color: 'rgba(0,0,0,0.8)', marginLeft: '10px' }}>专利有效性:</div>
                        </div>
                        <Radio.Group defaultChecked="false" buttonStyle="solid" onChange={this.onChange2} style={{ display: 'flex', alignItems: 'center', height: "60px", width: '270px', flexDirection: "row" }}>
                            <Radio.Button value="有效专利" style={{ width: '82px', height: '32px', backgroundColor: '#fff', border: '0.5px solid rgba(0,0,0,0.1)', textAlign: 'center', borderRadius: '16px', color: 'rgba(0,0,0,0.4)', fontSize: '12px', lineHeight: '32px', marginLeft: '10px' }}>有效专利</Radio.Button>
                            <Radio.Button value="无效专利" style={{ width: '82px', height: '32px', backgroundColor: '#fff', border: '0.5px solid rgba(0,0,0,0.1)', textAlign: 'center', borderRadius: '16px', color: 'rgba(0,0,0,0.4)', fontSize: '12px', lineHeight: '32px', marginLeft: 'auto' }}>失效专利</Radio.Button>
                            <Radio.Button value="实质审查" style={{ width: '82px', height: '32px', backgroundColor: '#fff', border: '0.5px solid rgba(0,0,0,0.1)', textAlign: 'center', borderRadius: '16px', color: 'rgba(0,0,0,0.4)', fontSize: '12px', lineHeight: '32px', marginLeft: 'auto', marginRight: '10px' }}>审中</Radio.Button>
                        </Radio.Group>
                    </div>

                    <div style={{ width: '264px', borderBottom: '0.5px solid rgba(0, 0, 0, 0.1)', lineHeight: '45px', display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '0' }}>
                            <div style={{ width: '12px', height: '12px', backgroundColor: '#00C8DF', }}></div>
                            <div style={{ fontSize: '12px', color: 'rgba(0,0,0,0.8)', marginLeft: '10px' }}>专利地区:</div>
                        </div>
                        <Cascader className="address_select_content" style={{ fontSize: '0.75rem', border: 'none' }} expandTrigger="hover" displayRender={this.displayRender} options={options} onChange={this.onChangeaddress} placeholder="请选择地址" ></Cascader>
                    </div>
                    <div style={{ width: '100%', height: '36px', marginTop: '31px', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                        <button key="submit" onClick={this.handleShaiOk.bind(this)} className="ok-btn-content cousor" style={{ width: '200px' }} type="primary" >筛选</button>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default withRouter(Sider)