import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import Fixednav from './Fixednav'
import Headernav from './Headernav'
import Markettalk from './Markettalk'
import Icon from '@ant-design/icons'
import QueueAnim from 'rc-queue-anim'
import '../Component-css/MarketXqContent.css'
import { Table, message } from 'antd'
import api from '../api/api'
import SearchFixed from './SearchFixed'


const star = () => (
    <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 1024 1024">
        <path d="M550.4 909.312a74.666667 74.666667 0 0 1-57.6-23.978667l-325.888-389.888A83.2 83.2 0 0 1 155.733333 405.333333l116.778667-243.754666A84.821333 84.821333 0 0 1 348.8 113.066667h402.688a85.888 85.888 0 0 1 76.8 48.512L945.066667 405.333333a83.2 83.2 0 0 1-11.733334 89.6l-325.333333 390.4a74.666667 74.666667 0 0 1-57.6 23.978667zM348.8 181.333333a16.554667 16.554667 0 0 0-14.933333 10.666667l-116.821334 243.2a15.488 15.488 0 0 0 0 16l325.888 387.2c3.754667 3.712 6.912 3.712 10.154667 0l325.845333-387.2a14.421333 14.421333 0 0 0 0-16L768 192a16.554667 16.554667 0 0 0-14.933333-9.6L348.8 181.333333z" fill="#EA4335" />
        <path d="M550.4 629.845333a36.266667 36.266667 0 0 1-24.533333-11.178666l-167.978667-167.466667a34.645333 34.645333 0 0 1 10.965333-55.978667 33.621333 33.621333 0 0 1 37.034667 7.466667l144.512 144.512L694.4 405.333333a34.346667 34.346667 0 0 1 48.554667 48.512l-168.533334 164.821334a34.645333 34.645333 0 0 1-24.021333 11.178666z" fill="#EA4335" />
    </svg>
);

const Star = props => <Icon component={star} {...props} />;

// const { TabPane } = Tabs;
// const { Step } = Steps
// const customDot = (dot, { status, index }) => (
//     <Popover
//         content={
//             <span>
//                 step {index} status: {status}
//             </span>
//         }
//     >
//         {dot}
//     </Popover>
// );
const data = [{
    key: '1',
    name: '买方',
    enterprise: '《企业营业执照》《企业组织机构代码证》',
    persion: '“身份证”'
}, {
    key: '2',
    name: '卖方',
    enterprise: '《企业营业执照》“专利证书原件”',
    persion: '“身份证” “专利证书原件”'
}]
const column = [{
    title: '',
    dataIndex: 'name',
    key: 'name',
    width: '33.2%'
}, {
    title: '企业',
    dataIndex: 'enterprise',
    key: 'enterprise',
    width: '33.2%'
}, {
    title: '个人',
    dataIndex: 'persion',
    key: 'persion',
    width: '33.2%'
}]

// const tab = () => (
//     <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 1024 1024">
//         <path d="M1004.608 461.824a72.832 72.832 0 0 1 0 100.352l-306.368 317.696a66.56 66.56 0 0 1-96.704 0l-142.208-147.456S579.2 608.64 623.808 561.024c44.672-47.552 22.528-76.928 14.464-88.384-8-11.456-177.088-183.04-177.088-183.04l140.352-145.408a66.688 66.688 0 0 1 96.704 0l306.368 317.632z" />
//         <path d="M515.84 495.488a48.384 48.384 0 0 1 0 68.352l-216.64 216.64a48.448 48.448 0 0 1-68.416 0L14.144 563.84a48.384 48.384 0 0 1 0-68.352l216.64-216.704a48.384 48.384 0 0 1 68.416 0L515.776 495.36v0.128z" />
//     </svg>
// );

// const step = () => (
//     <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 1024 1024">
//         <path d="M716.8 819.2c0 113.11104-91.68896 204.8-204.8 204.8S307.2 932.31104 307.2 819.2s91.68896-204.8 204.8-204.8 204.8 91.68896 204.8 204.8z"  ></path>
//         <path d="M512 0h10.24v675.84H512V0z" />
//     </svg>
// );

// const safe = () => (
//     <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 1024 1024">
//         <path d="M172.662154 106.916103L465.158564 7.719385a145.801846 145.801846 0 0 1 93.682872 0l292.49641 99.196718a145.854359 145.854359 0 0 1 97.93641 155.93682L895.606154 699.549538a145.801846 145.801846 0 0 1-52.696616 95.31077l-238.828307 194.402461a145.880615 145.880615 0 0 1-184.162462 0l-238.828307-194.376205a145.801846 145.801846 0 0 1-52.696616-95.337026L74.725744 262.826667a145.854359 145.854359 0 0 1 97.93641-155.936821z m498.03159 321.982359l-172.242052 178.149743-111.510974-77.193846a36.417641 36.417641 0 0 0-50.779897 9.163487 36.548923 36.548923 0 0 0 9.189743 50.779898l137.032205 94.86441a36.49641 36.49641 0 0 0 46.972718-4.647385l193.719795-200.33641a36.417641 36.417641 0 0 0-12.839385-59.286974 36.49641 36.49641 0 0 0-39.594666 8.559589l0.052513-0.052512z"  ></path>
//     </svg>
// );

// const Tab = props => <Icon component={tab} {...props} />;
// const StepIcon = props => <Icon component={step} {...props} />;
// const Safe = props => <Icon component={safe} {...props} />;

class MarketXqContent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: '',
            application_number: '',
            category_name: '',
            price: '',
            title: '',
            type: '',
            sale_type_name: '',
            auth_status_name: '',
            is_favorite: 2,
            zlsqrq: '',
            zlwyid: '',
            zlgkr: '',
            zlfmr: [],
            zlsqr: [],
            sqrdz: '',
            zlqr: [],
            zldqr: [],
            dljg: '',
            dlre: '',
            zlzflh: '',
            zlipcfl: '',
            zy: '',
            zlflxx: [],
            sms: [],
            zlyxx: '',
            is_negotiate: 0,
            effective_date: '',
            add_time: '',
            pay_process: [{
                'key': 1,
                'content_title': '完成选购',
                'content': '客户确认专利'
            }, {
                'key': 2,
                'content_title': '材料准备',
                'content': '双方准备转让材料'
            }, {
                'key': 3,
                'content_title': '签订协议',
                'content': '双方签订协议'
            }, {
                'key': 4,
                'content_title': '程序办理',
                'content': '提交专利局审核'
            }, {
                'key': 5,
                'content_title': '证书交付',
                'content': '办理完成支付使用'
            }],
            same_list: []
        }
        this.searchhandel = this.searchhandel.bind(this)
    }

    MarketXqContent() {
        var id = this.props.match.params.id
        var list = JSON.parse(id)
        if (list[0].price === '0.00') {
            this.setState({
                is_negotiate: 1
            })
        } else {
            this.setState({
                is_negotiate: 0
            })
        }
        this.setState({
            application_number: list[0].application_number,
            auth_status_name: list[0].auth_status_name,
            is_favorite: list[0].is_favorite,
            price: list[0].price,
            sale_type_name: list[0].sale_type_name,
            title: list[0].title,
            type: list[0].type,
            type_value: list[0].type_value,
            category_name: list[0].category_name,
            id: Number(list[0].id),
            add_time: list[0].add_time
        })
    }

    componentDidMount() {
        this.MarketXqContent()
        this.same_list()
    }

    same_list() {
        var id = this.props.match.params.id
        var list = JSON.parse(id)
        api.post({
            str: '/market/getMarketSimilarity'
        }, {
            title: list[0].title
        })
            .then(res => res.json())
            .then(res => {
                if (res.code == 200) {
                    this.setState({
                        same_list: res.data
                    })
                } else {
                    message.error(res.msg)
                }
            })
    }

    handelSms(id) {
        api.post({
            str: '/retrieve/getInstructions'
        }, {
            id: id
        })
            .then(res => res.json())
            .then(res => {
                if (res.code === 200) {
                    this.setState({
                        sms: res.data
                    })
                }
            })
    }

    searchhandel() {

    }

    handelShoucang(id) {
        api.post({
            str: '/market/addFavorite'
        }, {
            id: id
        })
            .then(res => res.json())
            .then(res => {
                if (res.code === 200) {
                    message.success(res.msg)
                    this.setState({
                        is_favorite: 1
                    })
                } else {
                    message.error(res.msg)
                }
            })
    }

    handelQuiteShoucang(id) {
        api.post({
            str: '/market/delFavorite'
        }, {
            id: id
        })
            .then(res => res.json())
            .then(res => {
                if (res.code === 200) {
                    message.success(res.msg)
                    this.setState({
                        is_favorite: 2
                    })
                } else {
                    message.error(res.msg)
                }
            })
    }

    tabsselect(key) {
    }

    render() {
        return (
            <Fragment>
                <Headernav />
                <div className='market-details'>
                    <div className='market-details-header-wrap'>
                        <div className='market-details-header'>
                            <div className='market-details-basic-information'>
                                <img className='market-details-basic-information-image' alt='list' src={this.state.type_value === 1 ? 'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210316/cbf813004ec4104fbde834f6ec46de5fdf688f13.png' : 'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210316/b324f2065a0b03ecec293019146b428333cbdcec.png'} style={{ width: '100%', height: '100%', objectFit: "cover" }} />
                                <div className='market-details-basic-information-content'>
                                    <div className='market-details-basic-information-content-title'>{this.state.title}</div>
                                    <div className='market-details-basic-information-content-public'>
                                        <div>专利号：{this.state.application_number}</div>
                                        <div>专利状态：{this.state.auth_status_name}</div>
                                    </div>
                                    <div className='market-details-basic-information-content-public'>
                                        <div>专利类型：{this.state.type}</div>
                                        <div>专利领域：{this.state.category_name}</div>
                                    </div>
                                    <div className='market-details-basic-information-content-public'>
                                        <div>更新日期：{this.state.add_time}</div>
                                    </div>
                                    <div className='market-details-basic-information-content-public'>
                                        <div className={this.state.is_negotiate === 0 ? '' : 'displayNone'} style={{ width: '36.08%' }}><span style={{ fontSize: '0.75rem' }}>￥</span>{this.state.price}</div>
                                        <div className={this.state.is_negotiate === 1 ? '' : 'displayNone'} style={{ width: '36.08%', color: '#EA4335', fontSize: '1.25rem', marginLeft: '0', textAlign: 'left', fontWeight: '700' }}>议价</div>
                                        {/* <div onClick={this.handelShoucang.bind(this, this.state.id)} className='cousor' style={{ borderRadius: '4px', backgroundColor: '#0FC25B', marginLeft: '30px',width:'100px' }}>专利详情</div> */}
                                        <div onClick={this.handelShoucang.bind(this, this.state.id)} className={(this.state.is_favorite === 2 ? ' ' : 'displayNone') + ' cousor'} style={{ borderRadius: '4px', backgroundColor: '#00A3FF', marginLeft: '30px', width: '100px' }}>我要收藏</div>
                                        <div onClick={this.handelQuiteShoucang.bind(this, this.state.id)} className={(this.state.is_favorite === 1 ? ' ' : 'displayNone') + ' cousor'} style={{ borderRadius: '4px', backgroundColor: '#00A3FF', marginLeft: '30px', width: '100px' }}>已经收藏</div>
                                        <a className="gmzx" rel="noopener noreferrer" target="_blank" href="http://wpa.qq.com/msgrd?v=3&uin=407650541&site=qq&menu=yes" style={{ width: '100px', height: '30px', color: '#fff', borderRadius: '4px', backgroundColor: '#EA4335', lineHeight: '30px' }}>购买咨询</a>
                                    </div>
                                </div>
                            </div>
                            <div className='market-details-basic-information-steps'>
                                <div className='market-details-basic-information-steps-title'>
                                    <div>专利交易流程</div><span>Patent transaction process</span>
                                </div>
                                <QueueAnim key='demo' type={['right', 'left']} className='market-details-basic-information-steps-content'>
                                    {
                                        this.state.pay_process.map((item, index) => {
                                            return <div key={index} className='market-details-basic-information-steps-content-li'>
                                                <div style={{ width: '24px', height: '24px', borderRadius: '12px', background: '#00C8DF', color: '#fff', fontSize: '0.75rem', textAlign: 'center', lineHeight: '24px' }}>{item.key}</div>
                                                <div style={{ fontSize: '0.875rem', color: '#333', marginTop: '10px' }}>{item.content_title}</div>
                                                <div style={{ fontSize: '0.75rem', color: '#828282' }}>{item.content}</div>
                                            </div>
                                        })
                                    }
                                </QueueAnim>
                            </div>
                        </div>
                        <div className='market-details-header-same'>
                            <div style={{ width: '100%', height: '60px', color: '#fff', background: 'rgba(0,0,0,0.8)', fontSize: '1.25rem', fontWeight: '700', fontFamily: '微软雅黑', textAlign: 'center', lineHeight: '60px' }}>相似专利</div>
                            <div style={{ width: '100%', height: '440px', background: '#fff', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                {
                                    this.state.same_list.map((item, index) => {
                                        return <div key={index} className='market-details-header-same-li' style={{ width: '300px', height: '100px', marginTop: '10px', padding: '19px 25px' }}>
                                            <div style={{ fontSize: '0.75rem', fontWeight: '700', color: '#4F4F4F' }}>
                                                <span>{item.title}</span><span style={{ color: '#F2994A' }}>&lt;{item.type}&gt;</span>
                                            </div>
                                            <div style={{ fontSize: '10px', color: '#828282', marginTop: '7px' }}>#{item.category_name}</div>
                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                <span style={{ fontSize: '10px', color: '#828282' }}>{item.sale_type_name}</span>
                                                <span style={{ color: '#EA4335', fontWeight: '700', fontSize: '0.875rem', marginRight: '0', marginLeft: 'auto' }}>￥{item.price}</span>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div className='market-details-content'>
                        {/* <div style={{ height: '300px',background:'#fff',marginTop:'20px' }}>
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', margin: '32px 18px 0 18px' }}>
                                <span style={{ width: '20px', height: '20px', borderRadius: "4px", background: '#00C8DF' }}></span>
                                <div style={{ marginLeft: '9px', fontWeight: '700' ,color:'#333',fontSize:'1rem'}}>专利交易流程</div>
                            </div>
                            <Steps className='market-details-content-steps' current={5} labelPlacement='vertical' >
                                <Step title={<span><StepIcon style={{ fontSize: '48px', color: '#00C8DF', marginTop: '-10px' }} /><div style={{color:"#666",fontSize:'1rem',fontWeight:'700',fontFamily:'Microsoft YaHei UI'}}>完成选购</div></span>} status="process" description='客户确认选择专利' />
                                <Step title={<span><StepIcon style={{ fontSize: '48px', color: '#00C8DF', marginTop: '-10px' }} /><div style={{color:"#666",fontSize:'1rem',fontWeight:'700',fontFamily:'Microsoft YaHei UI'}}>材料准备</div></span>} status="process" description="协助双方准备转让材料" />
                                <Step title={<span><StepIcon style={{ fontSize: '48px', color: '#00C8DF', marginTop: '-10px' }} /><div style={{color:"#666",fontSize:'1rem',fontWeight:'700',fontFamily:'Microsoft YaHei UI'}}>签订协议</div></span>} status="process" description="协助双方协议签订" />
                                <Step title={<span><StepIcon style={{ fontSize: '48px', color: '#00C8DF', marginTop: '-10px' }} /><div style={{color:"#666",fontSize:'1rem',fontWeight:'700',fontFamily:'Microsoft YaHei UI'}}>程序办理</div></span>} status="process" description="提交专利局审核" />
                                <Step title={<span><StepIcon style={{ fontSize: '48px', color: '#00C8DF', marginTop: '-10px' }} /><div style={{color:"#666",fontSize:'1rem',fontWeight:'700',fontFamily:'Microsoft YaHei UI'}}>证书交付</div></span>} status="process" description="办理完成支付使用" />
                            </Steps>
                        </div> */}

                        <div style={{ height: '400px', background: '#fff', marginTop: '20px' }}>
                            <div style={{ width: '90vw', display: 'flex', flexDirection: 'row', margin: '32px 18px 0 18px' }}>
                                <div style={{ marginLeft: '9px', fontWeight: '700', color: '#333', fontSize: '1rem' }}>买卖双方需提供资料</div>
                                <span style={{ fontSize: '0.75rem', color: '#828282', marginLeft: '20px', lineHeight: '30px' }}>Buyers and sellers need to provide information</span>
                            </div>
                            <Table columns={column} dataSource={data} bordered pagination={false} className="ghzl" />
                            <div style={{ width: '90%', display: 'flex', flexDirection: 'row', margin: '32px 18px 0 18px' }}>
                                {/* <span style={{ width: '20px', height: '20px', borderRadius: "4px", border:'2px #00C8DF dashed' }}></span> */}
                                {/* <Star style={{marginTop:'5px'}}/> */}
                                <div style={{ marginLeft: '9px', fontWeight: '700', color: '#666', fontSize: '0.75rem' }}>其知网提供：《专利代理委托书》《专利权转让协议》《办理文件副本请求书》《发明人变更申明》</div>
                            </div>
                            <div style={{ width: '90%', display: 'flex', flexDirection: 'row', margin: '32px 18px 0 18px' }}>
                                {/* <span style={{ width: '20px', height: '20px', borderRadius: "4px", border:'2px #00C8DF dashed' }}></span> */}
                                {/* <Star style={{marginTop:'5px'}}/> */}
                                <div style={{ marginLeft: '9px', fontWeight: '700', color: '#666', fontSize: '0.75rem' }}>过户后您将获得：《专利证书》《手续合格通知书》《专利登记簿副本》</div>
                            </div>
                        </div>

                        <div style={{ height: '158px', background: '#fff', marginTop: '20px' }}>
                            <div style={{ width: '90vw', display: 'flex', flexDirection: 'row', margin: '32px 18px 0 18px' }}>
                                <div style={{ marginLeft: '9px', fontWeight: '700', color: '#333', fontSize: '1rem' }}>交易保障</div>
                                <span style={{ fontSize: '0.75rem', color: '#828282', marginLeft: '20px', lineHeight: '30px' }}>Transaction guarantee</span>
                            </div>
                            <div style={{ marginTop: '40px', marginLeft: '40px', display: 'flex', flexDirection: 'row', width: '90vw' }}>
                                <div style={{ marginLeft: '0' }}>
                                    <Star />
                                    <span style={{ marginLeft: '10px' }}>1. 平台验证专利信息</span>
                                </div>
                                <div style={{ marginLeft: 'auto' }}>
                                    <Star />
                                    <span style={{ marginLeft: '10px' }}>2. 实名审核交易双方</span>
                                </div>
                                <div style={{ marginLeft: 'auto' }}>
                                    <Star />
                                    <span style={{ marginLeft: '10px' }}>3. 签订协议保障权益</span>
                                </div>
                                <div style={{ marginLeft: 'auto' }}>
                                    <Star />
                                    <span style={{ marginLeft: '10px' }}>4. 材料提交全程监控</span>
                                </div>
                                <div style={{ marginRight: '40px', marginLeft: 'auto' }}>
                                    <Star />
                                    <span style={{ marginLeft: '10px' }}>5. 手续确认支付尾款</span>
                                </div>
                            </div>
                            {/* <Steps current={5} labelPlacement='vertical' progressDot={customDot} className='safe-guarantee' >
                                <Step title={<span><Safe style={{ fontSize: '40px', color: '#00C8DF' }} /><div style={{ marginTop: '30px', width: '30px', height: '30px', lineHeight: '28px', textAlign: 'center', border: '1px solid #00C8DF', borderRadius: '50%', marginLeft: '5px', color: '#00C8DF', marginBottom: '10px' }}>1</div></span>} description='平台验证专利信息' ></Step>
                                <Step title={<span><Safe style={{ fontSize: '40px', color: '#00C8DF' }} /><div style={{ marginTop: '30px', width: '30px', height: '30px', lineHeight: '28px', textAlign: 'center', border: '1px solid #00C8DF', borderRadius: '50%', marginLeft: '5px', color: '#00C8DF', marginBottom: '10px' }}>2</div></span>} description='实名审核交易双方' ></Step>
                                <Step title={<span><Safe style={{ fontSize: '40px', color: '#00C8DF' }} /><div style={{ marginTop: '30px', width: '30px', height: '30px', lineHeight: '28px', textAlign: 'center', border: '1px solid #00C8DF', borderRadius: '50%', marginLeft: '5px', color: '#00C8DF', marginBottom: '10px' }}>3</div></span>} description='签订协议保证权力' ></Step>
                                <Step title={<span><Safe style={{ fontSize: '40px', color: '#00C8DF' }} /><div style={{ marginTop: '30px', width: '30px', height: '30px', lineHeight: '28px', textAlign: 'center', border: '1px solid #00C8DF', borderRadius: '50%', marginLeft: '5px', color: '#00C8DF', marginBottom: '10px' }}>4</div></span>} description='材料提交全程监控' ></Step>
                                <Step title={<span><Safe style={{ fontSize: '40px', color: '#00C8DF' }} /><div style={{ marginTop: '30px', width: '30px', height: '30px', lineHeight: '28px', textAlign: 'center', border: '1px solid #00C8DF', borderRadius: '50%', marginLeft: '5px', color: '#00C8DF', marginBottom: '10px' }}>5</div></span>} description='手续确认支付尾款' ></Step>
                            </Steps> */}
                        </div>
                        {/* <Tabs defaultActiveKey="1" animated={false} className='market-details-content-Tabs' onChange={this.tabsselect.bind(this)}>
                            <TabPane tab={<span><Tab />基本信息</span>} key="1">
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', margin: '32px 18px 0 18px' }}>
                                    <span style={{ width: '20px', height: '20px', borderRadius: "4px", background: '#00C8DF' }}></span>
                                    <div style={{ marginLeft: '9px', fontWeight: '700' }}>著录:</div>
                                </div>
                                <div style={{ display: "flex", flexDirection: 'column', width: '100%', lineHeight: '48px', margin: '16px 0 0 47px' }}>
                                    <div>专利标题： {this.state.title}</div>
                                    <div>申请号：{this.state.application_number}</div>
                                    <div>申请日：{this.state.zlsqrq}</div>
                                    <div>公开(公告)号：{this.state.zlwyid}</div>
                                    <div>公开(公告)日：{this.state.zlgkr}</div>
                                    <div>发明人：
                                        {
                                            this.state.zlfmr.map((item, index) => {
                                                return <span key={index} >{item}</span>
                                            })
                                        }
                                    </div>
                                    <div>申请人：
                                        {
                                            this.state.zlsqr.map((item, index) => {
                                                return <span key={index}>{item}</span>
                                            })
                                        }
                                    </div>
                                    <div>申请人地址： {this.state.sqrdz}</div>
                                    <div>专利权人：
                                        {
                                            this.state.zlqr.map((item, index) => {
                                                return <span key={index}>{item}</span>
                                            })
                                        }
                                    </div>
                                    <div>当前专利权人：
                                        {
                                            this.state.zldqr.map((item, index) => {
                                                return <span key={index}>{item}</span>
                                            })
                                        }
                                    </div>
                                    <div>代理机构： {this.state.dljg}</div>
                                    <div>代理人： {this.state.dlre}</div>
                                    <div>主分类号： {this.state.zlzflh}</div>
                                    <div>IPC分类号： {this.state.zlipcfl}</div>
                                </div>
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', margin: '32px 18px 0 18px' }}>
                                    <span style={{ width: '20px', height: '20px', borderRadius: "4px", background: '#00C8DF' }}></span>
                                    <div style={{ marginLeft: '9px', fontWeight: '700' }}>摘要:</div>
                                </div>
                                <div style={{ display: "flex", flexDirection: 'column', width: '100%', lineHeight: '48px', margin: '16px 0 0 47px' }}>
                                    <div>{this.state.zy}</div>
                                </div>
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', margin: '32px 18px 0 18px' }}>
                                    <span style={{ width: '20px', height: '20px', borderRadius: "4px", background: '#00C8DF' }}></span>
                                    <div style={{ marginLeft: '9px', fontWeight: '700' }}>法律状态：{this.state.zlyxx}</div>
                                </div>
                                <div className="flzt" style={{ display: "flex", flexDirection: 'column', width: '100%', lineHeight: '48px', marginBottom: '30px' }}>
                                    {
                                        this.state.zlflxx.map((item, index) => {
                                            return <div key={index} style={{ width: '100%', display: "flex", flexDirection: 'row', borderBottom: '0.5px solid rgba(0, 0, 0, 0.1)', marginLeft: '42px', marginTop: '29px' }}>
                                                <div style={{ width: '8.07%', display: 'flex', flexDirection: 'column', lineHeight: '28px' }}>
                                                    <div>法律状态</div>
                                                    <div>法律状态公开日</div>
                                                    <div>法律状态信息</div>
                                                </div>
                                                <div style={{ width: '90%', display: 'flex', flexDirection: 'column', marginLeft: 'auto', lineHeight: '28px' }}>
                                                    <div style={{ width: '90%', height: '28px' }}>{item.type}</div>
                                                    <div style={{ width: '90%', height: '28px' }}>{item.date}</div>
                                                    <div style={{ width: '90%' }}>{item.content}</div>
                                                </div>
                                            </div>
                                        })
                                    }
                                </div>
                            </TabPane>
                            <TabPane tab={<span onClick={this.handelSms.bind(this, this.state.gkh)}><Tab />专利详情</span>} key="2">
                                <div style={{ display: "flex", flexDirection: 'column', width: '100%', lineHeight: '48px', margin: '16px 0 0 47px' }}>
                                    <div dangerouslySetInnerHTML={{ __html: this.state.sms }} ></div>
                                </div>
                            </TabPane> */}
                        {/* <TabPane tab={<span><Tab />交易流程</span>} key="3">
                                <div style={{height:'300px'}}>
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', margin: '32px 18px 0 18px' }}>
                                    <span style={{ width: '20px', height: '20px', borderRadius: "4px", background: '#00C8DF' }}></span>
                                    <div style={{ marginLeft: '9px', fontWeight: '700' }}>专利交易流程</div>
                                </div>
                                <Steps className='market-details-content-steps' current={5} labelPlacement='vertical' >
                                    <Step title={<span><StepIcon style={{ fontSize: '48px', color: '#00C8DF', marginTop: '-10px' }} /><div>完成选购</div></span>} status="process" description='客户确认选择专利' />
                                    <Step title={<span><StepIcon style={{ fontSize: '48px', color: '#00C8DF', marginTop: '-10px' }} /><div>完成选购</div></span>} status="process" description="协助双方准备转让材料" />
                                    <Step title={<span><StepIcon style={{ fontSize: '48px', color: '#00C8DF', marginTop: '-10px' }} /><div>完成选购</div></span>} status="process" description="协助双方协议签订" />
                                    <Step title={<span><StepIcon style={{ fontSize: '48px', color: '#00C8DF', marginTop: '-10px' }} /><div>完成选购</div></span>} status="process" description="提交专利局审核" />
                                    <Step title={<span><StepIcon style={{ fontSize: '48px', color: '#00C8DF', marginTop: '-10px' }} /><div>完成选购</div></span>} status="process" description="办理完成支付使用" />
                                </Steps>
                                </div>
                            </TabPane> */}
                        {/* <TabPane tab={<span><Tab />过户资料</span>} key="4">
                                <div style={{height:'400px'}}>
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', margin: '32px 18px 0 18px' }}>
                                    <span style={{ width: '20px', height: '20px', borderRadius: "4px", background: '#00C8DF' }}></span>
                                    <div style={{ marginLeft: '9px', fontWeight: '700' }}>买卖双方需提供资料</div>
                                </div>
                                <Table columns={column} dataSource={data} bordered pagination={false} className="ghzl" />
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', margin: '32px 18px 0 18px' }}>
                                    <span style={{ width: '20px', height: '20px', borderRadius: "4px", background: '#00C8DF' }}></span>
                                    <div style={{ marginLeft: '9px', fontWeight: '700' }}>其知网提供：《专利代理委托书》《专利权转让协议》《办理文件副本请求书》《发明人变更申明》</div>
                                </div>
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', margin: '32px 18px 0 18px' }}>
                                    <span style={{ width: '20px', height: '20px', borderRadius: "4px", background: '#00C8DF' }}></span>
                                    <div style={{ marginLeft: '9px', fontWeight: '700' }}>过户后您将获得：《专利证书》《手续合格通知书》《专利登记簿副本》</div>
                                </div>
                                </div>
                            </TabPane> */}
                        {/* <TabPane tab={<span><Tab />安全保障</span>} key="5">
                                <div style={{height:'300px'}}>
                                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row', margin: '32px 18px 0 18px' }}>
                                        <span style={{ width: '20px', height: '20px', borderRadius: "4px", background: '#00C8DF' }}></span>
                                        <div style={{ marginLeft: '9px', fontWeight: '700' }}>买卖双方需提供资料</div>
                                    </div>
                                    <Steps current={5} labelPlacement='vertical' progressDot={customDot} className='safe-guarantee' >
                                        <Step title={<span><Safe style={{ fontSize: '40px', color: '#00C8DF' }} /><div style={{ marginTop: '30px', width: '30px', height: '30px', lineHeight: '28px', textAlign: 'center', border: '1px solid #00C8DF', borderRadius: '50%', marginLeft: '5px', color: '#00C8DF', marginBottom: '10px' }}>1</div></span>} description='平台验证专利信息' ></Step>
                                        <Step title={<span><Safe style={{ fontSize: '40px', color: '#00C8DF' }} /><div style={{ marginTop: '30px', width: '30px', height: '30px', lineHeight: '28px', textAlign: 'center', border: '1px solid #00C8DF', borderRadius: '50%', marginLeft: '5px', color: '#00C8DF', marginBottom: '10px' }}>2</div></span>} description='实名审核交易双方' ></Step>
                                        <Step title={<span><Safe style={{ fontSize: '40px', color: '#00C8DF' }} /><div style={{ marginTop: '30px', width: '30px', height: '30px', lineHeight: '28px', textAlign: 'center', border: '1px solid #00C8DF', borderRadius: '50%', marginLeft: '5px', color: '#00C8DF', marginBottom: '10px' }}>3</div></span>} description='签订协议保证权力' ></Step>
                                        <Step title={<span><Safe style={{ fontSize: '40px', color: '#00C8DF' }} /><div style={{ marginTop: '30px', width: '30px', height: '30px', lineHeight: '28px', textAlign: 'center', border: '1px solid #00C8DF', borderRadius: '50%', marginLeft: '5px', color: '#00C8DF', marginBottom: '10px' }}>4</div></span>} description='材料提交全程监控' ></Step>
                                        <Step title={<span><Safe style={{ fontSize: '40px', color: '#00C8DF' }} /><div style={{ marginTop: '30px', width: '30px', height: '30px', lineHeight: '28px', textAlign: 'center', border: '1px solid #00C8DF', borderRadius: '50%', marginLeft: '5px', color: '#00C8DF', marginBottom: '10px' }}>5</div></span>} description='手续确认支付尾款' ></Step>
                                    </Steps>
                                </div>
                            </TabPane>
                        </Tabs> */}
                    </div>
                </div>
                <Fixednav />
                <SearchFixed />
            </Fragment>
        )
    }
}

export default withRouter(MarketXqContent)