import React, { Component,Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import RequireMarketContent from '../Component/RequireMarketContent.js'

class RequireMarket extends Component {
    constructor(props) {
        super(props)

        this.state = {
            
        }
    }

    /**
     *
     *
     * @return {*} 市场需求
     * @memberof RequireMarket zxy
     */
    render() {
        return (
            <Fragment>
                <RequireMarketContent />
            </Fragment>
        )
    }
}

export default withRouter(RequireMarket)