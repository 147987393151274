import React, { } from 'react'
import { useHistory, withRouter } from 'react-router-dom'
import Icon from '@ant-design/icons'
import '../Component-css/FailPayContent.css'

const fail = () => (
    <svg fill="currentColor" width="1.5em" height="1em" viewBox="0 0 1024 1024">
        <path d="M844.80957 138.402692a472.331963 472.331963 0 0 0-668.021234 0 472.532935 472.532935 0 0 0 668.088225 668.231775 472.389383 472.389383 0 0 0-0.133982-668.164785l0.066991-0.06699zM716.646879 620.50572a40.873869 40.873869 0 0 1-57.755515 57.755514L510.631477 530.278879 362.706542 678.261234a40.873869 40.873869 0 0 1-57.765084-57.755514l148.125906-147.982356-148.125906-148.058915a40.873869 40.873869 0 0 1 57.765084-57.755514l147.915365 148.049345 148.125906-148.116336a40.873869 40.873869 0 0 1 57.755514 57.755514L568.463551 472.523364l148.183328 147.982356z" fill="#F56C6C" />
    </svg>
);

const Fail = props => <Icon component={fail} {...props} />;

function FailPayContent() {
    const his = useHistory()

    const rePay = () => {
        his.push('/PayConfirm')
    }

    return (
        <div className='successpay'>
            <div className='successpaycontent'>
                <Fail className='successIcon' />
                <div className='successtext'>对不起</div>
                <div className='successtext'>本次交易未成功</div>
                <div onClick={() => {rePay()}} className='successdd'>重新支付</div>
                <a rel="noopener noreferrer" target="_blank" href="http://wpa.qq.com/msgrd?v=3&uin=407650541&site=qq&menu=yes" className='successkf'>联系客服</a>
            </div>
        </div>
    )
}


export default withRouter(FailPayContent)