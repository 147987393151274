import { withRouter } from 'react-router-dom'
import React, { Component, Fragment } from 'react'
import  Headernav  from '../Component/Headernav'
import  QSXQ  from '../Component/QSXQ'


class QishuoXq extends Component {
    constructor(props) {
        super(props)

        this.state = {
            
        }
    }

    /**
     *
     *
     * @return {*} 其说详情
     * @memberof QishuoXq   zxy
     */
    render() {
        return (
            <Fragment>
                <Headernav />
                <QSXQ />
            </Fragment>
        )
    }
}

export default withRouter(QishuoXq)