import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { Image, message, Carousel } from 'antd'
import api from '../api/api'
import { Pagination } from 'antd'
import '../Component-css/QG.css'
import SearchFixed from './SearchFixed'
import Fixednav from './Fixednav'
import { array } from 'js-md5'

class QG extends Component {
    constructor(props) {
        super(props)

        this.state = {
            dataList: [],
            total: 0,
            page: 1,
            todayNews:[],
            region:'四川省',
            imglist:[]
        }
    }

    onChange = page => {
        this.setState({
            page: page,
        });
        api.post({
            str: '/news/getNewsList'
        }, {
            category: 1,
            page: page,
            ps: 10
        })
            .then(res => res.json())
            .then(res => {
                if (res.code === 200) {
                    this.setState({
                        dataList: res.data.dataList,
                        total: res.data.total
                    })
                } else if (res.code === 4000) {
                    message.error(res.msg)
                } else if (res.code === 4001) {
                    message.error(res.msg)
                }
            })
    };

    qgxq(id) {
        this.props.history.push('/QiguanXq/' + id)
    }

    componentDidMount() {
        this._init()
        this.todayNews()
        this.banner()
    }

    _init() {
        api.post({
            str: '/news/getNewsList'
        }, {
            category: 1,
            page: this.state.page,
            ps: 10,
            region:this.state.region
        })
            .then(res => res.json())
            .then(res => {
                if (res.code === 200) {
                    this.setState({
                        dataList: res.data.dataList,
                        total: res.data.total
                    })
                } else if (res.code === 4000) {
                    message.error(res.msg)
                } else if (res.code === 4001) {
                    message.error(res.msg)
                }
            })
    }

    banner(){
        api.post({
            str:'/news/getQiguanBanner'
        })
        .then(res => res.json())
        .then(res => {
            if(res.code == 200){
                this.setState({
                    imglist:res.data
                })
            }else{
                message.error('res.msg')
            }
        })
    }

    todayNews(){
        api.post({
            str:'/news/getNewsList'
        },{
            page:this.state.page,
            ps:10,
            category:1,
        })
        .then(res => res.json())
        .then(res => {
            if(res.code === 200){
                var str = new Array();
                res.data.dataList.forEach(element => {
                    str = element.add_time.split(' ')
                    element.add_time = str
                });
                this.setState({
                    todayNews:res.data.dataList,
                    isHas:res.data.isHas
                })
            }else{
                message.error(res.msg)
            }
        })
    }

    render() {
        return (
            <Fragment>
                <div className="qiguanlist">
                    <div className='qiguantitle'>
                       <div className='qiguantitle-carousel'>
                        <Carousel autoplay>
                                {
                                    this.state.imglist.map((item,index) => {
                                        return <div key={index}>
                                        <img src={item.cover_img} className='qiguantitle-carousel-img' />
                                    </div>
                                    })
                                }
                            </Carousel>
                       </div>
                        <ul className='qiguantitle-ul'>
                            <b>今日要闻地区政策<p>NEWS</p></b>
                            {
                                this.state.todayNews.map((item,index) => {
                                    return <li onClick={this.qgxq.bind(this,item.id)} key={index}><p>{item.title}</p><span>（{item.add_time[0]}）</span></li>
                                })
                            }
                        </ul>
                    </div>
                    <div className='qiguancontent'>
                        <span style={{}} >地区政策<p>Regional policy</p></span>
                       {
                           this.state.dataList.map((item,index) => {
                               return <li key={index}>
                                   <img src={item.cover_img} />
                                   <div className='qiguanlicontent'>
                                       <p onClick={this.qgxq.bind(this,item.id)}>{item.title}<b className='qiguanlicontent-b'>{item.add_time}</b></p>
                                       <b className='qiguanlicontent-b1'>{item.abstract}&nbsp; &nbsp;&nbsp;&nbsp;</b>
                                   </div>
                               </li>
                           })
                       }
                    </div>
                </div>
                <SearchFixed />
                <Fixednav />
            </Fragment>
        )
    }
}

export default withRouter(QG)