import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import '../Component-css/MoreVideo.css'
import api from '../api/api'
import Headernav from './Headernav'
import { message } from 'antd'

class MoreVideo extends Component {
    constructor(props) {
        super(props)

        this.state = {
            videolist: [],
            hg: 1,
            category_id: 8,
            page1: 1,
            page2: 1,
            page3: 1,
            page4: 1,
            page5: 1,
            isHas: false,
        }
    }

    componentDidMount() {
        var hg = this.props.match.params.hg
        var id = this.props.match.params.id
        this.setState({
            hg,
            category_id: id
        })
        api.post({
            str: '/college/getCollegeList'
        }, {
            page: 1,
            ps: 16,
            category_id: id
        })
            .then(res => res.json())
            .then(res => {
                if (res.code === 200) {
                    this.setState({
                        videolist: res.data.dataList,
                        isHas: res.data.isHas
                    })
                } else {
                    message.error(res.msg)
                }
            })
    }

    _init() {
        var hg = this.props.match.params.hg
        var id = this.props.match.params.id
        this.setState({
            hg,
            category_id: id
        })
        api.post({
            str: '/college/getCollegeList'
        }, {
            page: 1,
            ps: 16,
            category_id: id
        })
            .then(res => res.json())
            .then(res => {
                if (res.code === 200) {
                    this.setState({
                        videolist: res.data.dataList,
                        isHas: res.data.isHas
                    })
                } else {
                    message.error(res.msg)
                }
            })
      
    }

    handelMoreadd(id) {
        var page1 = this.state.page1 + 1
        var page2 = this.state.page2 + 1
        var page3 = this.state.page3 + 1
        var page4 = this.state.page4 + 1
        var page5 = this.state.page5 + 1
        if (id == 10) {
            var list = this.state.videolist
            api.post({
                str: '/college/getCollegeList'
            }, {
                page: page1,
                ps: 6,
                category_id: id
            })
                .then(res => res.json())
                .then(res => {
                    if (res.code === 200) {
                        this.setState({
                            videolist: list.concat(res.data.dataList),
                            isHas: res.data.isHas,
                            page1: Number(res.data.page)
                        })
                    }
                })
        } else if (id == 8) {
            var list2 = this.state.videolist
            api.post({
                str: '/college/getCollegeList'
            }, {
                page: page2,
                ps: 6,
                category_id: id
            })
                .then(res => res.json())
                .then(res => {
                    if (res.code === 200) {
                        this.setState({
                            videolist: list2.concat(res.data.dataList),
                            isHas: res.data.isHas,
                            page2: Number(res.data.page)
                        })
                    }
                })
        } else if (id == 11) {
            var list3 = this.state.videolist
            api.post({
                str: '/college/getCollegeList'
            }, {
                page: page3,
                ps: 6,
                category_id: id
            })
                .then(res => res.json())
                .then(res => {
                    if (res.code === 200) {
                        this.setState({
                            videolist: list3.concat(res.data.dataList),
                            isHas: res.data.isHas,
                            page3: Number(res.data.page)
                        })
                    }
                })
        } else if (id == 12) {
            var list4 = this.state.videolist
            api.post({
                str: '/college/getCollegeList'
            }, {
                page: page4,
                ps: 6,
                category_id: id
            })
                .then(res => res.json())
                .then(res => {
                    if (res.code === 200) {
                        this.setState({
                            videolist: list4.concat(res.data.dataList),
                            isHas: res.data.isHas,
                            page4: Number(res.data.page)
                        })
                    }
                })
        } else if (id == 13) {
            var list5 = this.state.videolist
            api.post({
                str: '/college/getCollegeList'
            }, {
                page: page5,
                ps: 6,
                category_id: id
            })
                .then(res => res.json())
                .then(res => {
                    if (res.code === 200) {
                        this.setState({
                            videolist: list5.concat(res.data.dataList),
                            isHas5: res.data.isHas,
                            page5: Number(res.data.page)
                        })
                    }
                })
        }
    }

    QzxyXq(id) {
        const w = window.open('about:blank');
        w.location.href = `https://www.qzhip.com/#/QzxyXq/${id}`
    }

    hgchange1() {
        this.setState({
            hg: 1,
            category_id: 8
        })
        api.post({
            str: '/college/getCollegeList'
        }, {
            page: 1,
            ps: 16,
            category_id: 8
        })
            .then(res => res.json())
            .then(res => {
                if (res.code === 200) {
                    this.setState({
                        videolist: res.data.dataList,
                        isHas: res.data.isHas
                    })
                } else {
                    message.error(res.msg)
                }
            })
    }
    hgchange2() {
        this.setState({
            hg: 2,
            category_id: 10
        })
        api.post({
            str: '/college/getCollegeList'
        }, {
            page: 1,
            ps: 16,
            category_id: 10
        })
            .then(res => res.json())
            .then(res => {
                if (res.code === 200) {
                    this.setState({
                        videolist: res.data.dataList,
                        isHas: res.data.isHas
                    })
                } else {
                    message.error(res.msg)
                }
            })
    }
    hgchange3() {
        this.setState({
            hg: 3,
            category_id: 11
        })
        api.post({
            str: '/college/getCollegeList'
        }, {
            page: 1,
            ps: 16,
            category_id: 11
        })
            .then(res => res.json())
            .then(res => {
                if (res.code === 200) {
                    this.setState({
                        videolist: res.data.dataList,
                        isHas: res.data.isHas
                    })
                } else {
                    message.error(res.msg)
                }
            })
    }
    hgchange4() {
        this.setState({
            hg: 4,
            category_id: 12
        })
        api.post({
            str: '/college/getCollegeList'
        }, {
            page: 1,
            ps: 16,
            category_id: 12
        })
            .then(res => res.json())
            .then(res => {
                if (res.code === 200) {
                    this.setState({
                        videolist: res.data.dataList,
                        isHas: res.data.isHas
                    })
                } else {
                    message.error(res.msg)
                }
            })
    }
    hgchange5() {
        this.setState({
            hg: 5,
            category_id: 13
        })
        api.post({
            str: '/college/getCollegeList'
        }, {
            page: 1,
            ps: 16,
            category_id: 13
        })
            .then(res => res.json())
            .then(res => {
                if (res.code === 200) {
                    this.setState({
                        videolist: res.data.dataList,
                        isHas: res.data.isHas
                    })
                } else {
                    message.error(res.msg)
                }
            })
    }

    render() {
        return (
            <Fragment>
                <Headernav />
                <div className='morevideo-tabs'>
                    <div className='morevideo-tabs-details'>
                        <ul>
                            <li onClick={this.hgchange1.bind(this)} className={this.state.hg == 1 ? 'activeMorevideo' : ''}>伟大发明</li>
                            <li onClick={this.hgchange2.bind(this)} className={this.state.hg == 2 ? 'activeMorevideo' : ''}>专利初识</li>
                            <li onClick={this.hgchange3.bind(this)} className={this.state.hg == 3 ? 'activeMorevideo' : ''}>专代考试</li>
                            <li onClick={this.hgchange4.bind(this)} className={this.state.hg == 4 ? 'activeMorevideo' : ''}>职称考试</li>
                            <li onClick={this.hgchange5.bind(this)} className={this.state.hg == 5 ? 'activeMorevideo' : ''}>公开课</li>
                        </ul>
                    </div>
                </div>
                <ul className='morevideo-list'>
                    {
                        this.state.videolist.map((item, index) => {
                            return <li onClick={this.QzxyXq.bind(this, item.id)} key={index}>
                                <img src={item.cover_img} />
                                <p>{item.title}</p>
                                <div>
                                    {/* <img src="" /> */}
                                    <span>{item.author}</span>
                                    <b className='time'>发布时间：{item.duration}</b>
                                    <b className='time-ft'>{item.duration}</b>
                                </div>
                            </li>
                        })
                    }
                </ul>
                <div className='morevideo-footer'>
                    <div className={this.state.isHas === false ? 'displaynone' : ''} onClick={this.handelMoreadd.bind(this, this.state.category_id)}>加载更多</div>
                </div>
            </Fragment>
        )
    }
}

export default withRouter(MoreVideo)