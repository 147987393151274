import React, { Component } from 'react'
import { Fragment } from 'react'
import '../Component-css/OrderDetail.css'
import Headernav from './Headernav'
import { withRouter } from 'react-router-dom'
import api from '../api/api'
import { message } from 'antd'

class OrderDetail extends Component {
    constructor(props) {
        super(props)

        this.state = {
            order_sn:'',
            total_fee:'',
            username:'',
            email:'',
            pc_mobile:'',
            type_name:'',
            add_time:'',
            status_name:'',
            pay_time:'',
            pay_able:[],
            invoice_type_name:'',
            invoice_ein:'',
            invoice_name:''
        }
    }

    componentDidMount(){
        var order_sn = this.props.match.params.order_sn
        console.log(order_sn)
        api.post({
            str:'/order/getOrderInfo'
        },{
            order_sn:order_sn
        })
        .then(res => res.json())
        .then(res => {
            if(res.code === 200){
                this.setState({
                    order_sn:order_sn,
                    total_fee:res.data.total_fee,
                    username:res.data.username,
                    email:res.data.email,
                    pc_mobile:res.data.pc_mobile,
                    type_name:res.data.type_name,
                    add_time:res.data.add_time,
                    status_name:res.data.status_name,
                    pay_time:res.data.pay_time,
                    pay_able:res.data.pay_able,
                    invoice_type_name:res.data.invoice_type_name,
                    invoice_ein:res.data.invoice_ein,
                    invoice_name:res.data.invoice_name
                })
            }else{
                message.error(res.msg)
            }
        })
    }

    render() {
        return (
            <Fragment>
                <Headernav/>
                <div className='orderDetail'>
                    <img alt='' src={require('../images/order.svg')} style={{width:'100%'}} />
                    <span>订单号：{this.state.order_sn}</span>
                </div>
                <div className='orderDetail-content'>
                    <div className='orderDetail-content-header'>
                        <div><span>用户昵称：</span>{this.state.username}</div>
                        <div><span>邮箱地址：</span>{this.state.email}</div>
                        <div><span>联系电话：</span>{this.state.pc_mobile}</div>
                        <div><span>用户类型：</span>普通会员</div>
                    </div>
                    <div className='orderDetail-content-content'>
                        <div className='orderDetail-content-content-header'>
                            <div><span>订单号：</span>{this.state.order_sn}</div>
                            <div><span>订单类型：</span>{this.state.type_name}</div>
                            <div><span>下单时间：</span>{this.state.add_time}</div>
                            <div><span>订单状态：</span>{this.state.status_name}</div>
                            <div><span>订单金额：</span>{this.state.total_fee}</div>
                            <div><span>支付时间：</span>{this.state.pay_time}</div>
                        </div>
                        <div className='orderDetail-content-content-footer'>
                            <div className='orderDetail-content-content-footer-title'>订单内容：</div>
                            <div className='orderDetail-content-content-footer-content'>
                                {
                                    this.state.pay_able.map((item,index) => {
                                        return <div className='orderDetail-content-content-footer-content-text' key={index}>{item}</div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div className='orderDetail-content-footer'>
                        <div><span>发票类型：</span>{this.state.invoice_type_name}</div>
                        <div><span>税号：</span>{this.state.invoice_ein}</div>
                        <div><span>发票抬头：</span>{this.state.invoice_name}</div>
                        <div><span>发票号码：</span>{}</div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default withRouter(OrderDetail)