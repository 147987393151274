import React, { Component } from 'react'
import { Fragment } from 'react'
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/title';
import 'echarts/lib/component/visualMap';
import 'echarts/lib/chart/map';
import 'echarts/lib/chart/bar';
import 'echarts/map/js/china';
import 'echarts/lib/chart/line';
import 'echarts/lib/chart/gauge';
import 'echarts/lib/chart/pie';
import uploadedDataURL from '../api/china.json'
import '../Component-css/AboutContent.css'
import Headernav from '../Component/Headernav'
import img1 from '../images/sector1.png'
import img2 from '../images/sector2.png'
import img3 from '../images/sector3.png'
import img4 from '../images/sector4.png'
import img5 from '../images/sector5.png'
import img6 from '../images/sector6.png'
import { withRouter } from 'react-router';
//json地图数据文件，各省市坐标获取方法见文末

var points = [{
    name: '北京',
    value: [116.321174, 39.697205],
    address: '北京市大兴区天华街9号院19号楼17层'
}, {
    name: '上海',
    value: [121.471629, 31.231236],
    address: '上海市崇明区长兴镇潘园公路1800号3号楼56466室'
}, {
    name: '广州',
    value: [113.273441, 23.136134],
    address: '广州市黄埔区萝岗街香雪达到西351号房喜客文化众创空间'
}, {
    name: '成都',
    value: [104.08277, 30.680647],
    address: '成都市金牛区创新创业服务中心1楼'
}, {
    name: '天津',
    value: [117.198925, 39.15317],
    address: '天津市红桥区张自忠路海河华鼎大厦B座1202室'
}, {
    name: '西安',
    value: [108.893602, 34.192287],
    address: '西安市高新区唐延南路11号i都会3栋1单元1027号'
}, {
    name: '重庆',
    value: [106.540928, 29.576697],
    address: '重庆市江北区嘉陵一村41号1栋29-10'
}, {
    name: '青岛',
    value: [120.418519, 36.181663],
    address: '青岛市李沧区金水路1078号1216室'
}, {
    name: '南京',
    value: [118.77764, 32.03604],
    address: '南京市秦淮区水西门大街2号银都锦创广场5层K040室'
}, {
    name: '大连',
    value: [121.639163, 38.929956],
    address: '大连市甘井子区泉水N4区11号1层1号'
}, {
    name: '福建',
    value: [119.303883, 26.077007],
    address: '中国(福建)自由贸易试验区厦门片区自贸金融中心C栋6层'
}, {
    name: '合肥',
    value: [117.235453, 31.82486],
    address: '合肥市高新区天通路软件园三号楼三楼A04'
}]

var colors = ['#1DE9B6'];

class AboutContent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            sector: [
                { img: img1, name: '专利检索' },
                { img: img2, name: '监控缴费' },
                { img: img3, name: '专利代理' },
                { img: img4, name: '专利市场' },
                { img: img5, name: '其知学院' },
                { img: img6, name: '政策资讯' },
            ],
            partner1: [
                'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210906/ac7e6ca3cff83caa574878f864d28e36ad85a036.png',
                'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210906/ffe7f61feb093dec50364754697acf0c72b8c09d.png',
                'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210906/dec79545ed12328c2b3058b2ad35ec1bf87aa16e.png',
                'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210906/33fcb506cba0b1920af72bce73ddcb22a0ca521b.png',
                'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210906/bcff19a59e7c9e3bf993d5e826229b7e70122753.png',
                'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210906/d2995315e422152bd5def2725136a0aa6089fe93.png',
            ],
            partner2: [
                'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210906/49267437eb4d8c87d5dfa9ceccc2c0464fc2c5fc.png',
                'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210906/891a3e75c1317e4e7af82dcdddbdb87ec9257d7e.png',
                'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210906/a53c3dd9fabe81655b324ed44250d224e9563bd2.png',
                'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210906/4e558f7166497e598adaa97d2cef187ade52e825.png',
                'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210906/6d1521d4429f014e4b7bb3627b425ae81385b063.png',
                'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210906/6c30617a4a4701a5a13629e6266b206f37142a08.png',
            ]
        }
    }

    /**
     *
     * 页面初始化
     * @memberof AboutContent zxy
     */
    componentDidMount() {
        this.chart()
    }

    /**
     *
     *  地图图表
     * @memberof AboutContent zxy
     */
    chart() {
        var mychart = echarts.init(document.getElementById('main'))
        echarts.registerMap('china', uploadedDataURL)
        let option = {
            backgroundColor: '#100c2a',
            color: ['#1DE9B6'],
            left: '0',
            tooltip: {
                trigger: 'item',
                showDelay: 0,
                transitionDuration: 0.75,
                formatter: function (params) {
                    return params.data != undefined ? `<dl className='map-dl'>
                        <dd>${params.data.name}</dd>
                        <dt>${params.data.address}</dt>
                    </dl>`: null
                }
            },
            // legend: {
            //     orient: 'vertical',
            //     x: 'left',
            //     data: ['北京', '伤害'],
            //     textStyle: {
            //         color: '#fff'
            //     }
            // },
            // visualMap: {
            //     type: 'continuous',
            //     seriesIndex: [2, 3],
            //     min: 0,
            //     max: 100,
            //     calculable: true,
            //     color: ['#ff3333', 'orange', 'yellow', 'lime', 'aqua'],
            //     textStyle: {
            //         color: '#fff'
            //     }
            // },
            geo: {
                map: 'china',
                show: false
            },
            series: [{
                type: 'map',
                map: 'china',
                aspectScale: 1,
                itemStyle: {
                    borderColor: 'rgba(100,149,237,1)',
                    borderWidth: 1,
                    areaColor: 'rgba(0,0,0,0)',
                },
                label: {
                    show: false,
                    textStyle: {
                        color: '#fff'
                    },
                    emphasis: {
                        textStyle: {
                            color: '#fff'
                        }
                    }
                },
                emphasis: {
                    label: {
                        show: false
                    },
                    itemStyle: {
                        areaColor: 'rgba(0,0,0,0)',
                    }
                }

            }, {
                type: 'effectScatter',
                coordinateSystem: 'geo',
                showEffectOn: 'render',
                rippleEffect: {
                    period: 5,
                    scale: 5,
                    brushType: 'fill'
                },
                hoverAnimation: true,
                label: {
                    formatter: '{b}',
                    position: 'right',
                    offset: [15, 0],
                    color: param => colors[param.dataIndex % colors.length],
                    show: true
                },
                itemStyle: {
                    color: (param) => {
                        return colors[param.dataIndex % colors.length];
                    },
                    shadowBlur: 10,
                    shadowColor: '#333',
                    opacity: 0.75
                },
                emphasis: {
                    itemStyle: {
                        opacity: 1, //线条宽度
                    }
                },
                data: points
            }]
        };
        mychart.setOption(option);
    }

    /**
     *
     *
     * @param {*} index 中心管理切换
     * @memberof AboutContent zxy
     */
    routerIndex(index) {
        if (index == 0) {
            this.props.history.push('/App')
        } else if (index == 1) {
            this.props.history.push('/Annualfee')
        } else if (index == 2) {
            this.props.history.push('/Zldl')
        } else if (index == 3) {
            this.props.history.push('/Market')
        } else if (index == 4) {
            this.props.history.push('/QZXY')
        } else if (index == 5) {
            this.props.history.push('/Qiguan')
        }
    }

    /**
     *
     *  政府机关
     * @memberof AboutContent zxy
     */
    recordZF() {
        const w = window.open('about:blank');
        w.location.href = `https://beian.miit.gov.cn`
    }

    render() {
        return (
            <Fragment>
                <Headernav />
                <div className='AboutContent-wrap'>
                    <div id='main'></div>
                </div>
                <div className='AboutContent-AboutUs-wrap'>
                    <div className='AboutContent-AboutUs'>
                        <img src='http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210906/78f942783a129bb58e75eb502e6eca2c7ee10d2a.png' alt='' />
                        <div>
                            <h1><span>关</span>于我们</h1>
                            <h5>About us</h5>
                            <p>其知网是专利服务行业供给侧改革的先驱者，是全国率先以“专利服务人才”为核心的数据化、共享化、信息化的专利综合服务生态系统。</p>
                            <p>其知网集专利查询、专利申请、监控缴费、成果转化以及能力提升等为一体，整合各代理机构的优质专利代理师资源，开创性的研发了专利流程标准化，专利监控可视化的全生命周期管理系统，为发明人提供从智能专利检索、精准匹配代理、实时状态查询到专利成果转化的全方位服务，利用互联网创新思维推动知识产权服务行业的发展，助力国家向科技型强国转型。</p>
                            <p className='AboutContent-AboutUs-Title'>联系我们</p>
                            <p className='AboutContent-AboutUs-Title-content'>联系地址：四川省成都市新都区西南石油大学科技园大厦1303b</p>
                            <p className='AboutContent-AboutUs-Title-content'>联系电话：13020022305</p>
                        </div>
                    </div>
                </div>
                <div className='AboutContent-Core-sector-wrap'>
                    <div className='AboutContent-Core-sector'>
                        <h2>核心板块</h2>
                        <span>Core sector</span>
                        <ul>
                            {
                                this.state.sector.map((item, index) => {
                                    return <li onClick={this.routerIndex.bind(this, index)} key={index}>
                                        <img src={item.img} />
                                        <span>{item.name}</span>
                                    </li>
                                })
                            }
                        </ul>
                    </div>
                </div>
                <div className='AboutContent-Our-Mission-wrap'>
                    <div className='AboutContent-Our-Mission'>
                        <h2>企业使命</h2>
                        <span>Our Mission</span>
                        <p>其知坚持践行“助力知识产权保护，驱动科学技术创新”的企业使命,</p>
                        <p>致力于帮助客户利用知识产权工具获得更大的成功,为维护和促进国家知识产权战略的实施敬献微薄之力!</p>
                    </div>
                </div>
                <div className='AboutContent-core-value-wrap'>
                    <div className='AboutContent-core-value'>
                        <h2>核心价值观</h2>
                        <span>core value</span>
                        <p>创新/拼搏/合作/分享/担当/敬业</p>
                        <p>感恩/包容/诚信/团结/友善/自律</p>
                    </div>
                </div>
                <div className='AboutContent-Partner-wrap'>
                    <div className='AboutContent-Partner'>
                            <h2>合作伙伴</h2>
                            <span>Partner</span>
                            <ul>
                                {
                                    this.state.partner1.map((item,index) => {
                                        return <img src={item} key={index} />
                                    })
                                }
                            </ul>
                            <ul>
                            {
                                    this.state.partner2.map((item,index) => {
                                        return <img src={item} key={index} />
                                    })
                                }
                            </ul>
                    </div>
                </div>
                <footer className='AboutContent-footer-wrap'>
                    <div onClick={this.recordZF.bind(this)}>
                        Copyright2018四川中索汇智信息技术有限公司，AllRightReserved蜀ICP备18026701号-3
                    </div>
                </footer>
            </Fragment>
        )
    }
}

export default withRouter(AboutContent)