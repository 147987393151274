import React, { Component, Fragment } from 'react';
import { Menu, Dropdown, Radio, Badge, message, Layout } from 'antd';
import { Modal } from 'antd';
import { DatePicker, Space } from 'antd';
import { Cascader } from 'antd';
import { Link } from 'react-router-dom';
import options from '../Component/perisonoptions.js';
import 'moment/locale/zh-cn';

import locale from 'antd/es/date-picker/locale/zh_CN';
import '../Component-css/Headernav.css';
import '../Component/Easysearch.js'
import { withRouter } from "react-router-dom"
import { getCurrentDate } from '../api/currentDate.js'
import api from '../api/api.js';
import md5 from 'js-md5'
import Icon from '@ant-design/icons'
import fire from '../images/fire.png'

const sczx = () => (
    <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 1024 1024">
        <path d="M513.599 997.3c-28.607 0-57.24-2.613-85.689-7.838-14.788-2.717-24.575-16.906-21.858-31.697 2.717-14.788 16.906-24.574 31.697-21.858 48.519 8.911 97.632 9.243 145.98 0.984 49.845-8.516 97.484-25.963 141.598-51.857 0.068-0.038 0.135-0.077 0.202-0.117 50.854-29.265 94.402-67.576 129.429-113.865 33.811-44.68 58.313-94.882 72.835-149.212 14.468-54.138 18.286-109.619 11.349-164.9-7.179-57.182-25.618-111.65-54.805-161.893-27.577-47.277-60.927-86.414-101.932-119.611-11.686-9.46-13.491-26.602-4.029-38.29 9.46-11.685 26.601-13.492 38.29-4.030 46.217 37.416 83.746 81.425 114.728 134.538 32.904 56.638 53.678 118.026 61.773 182.503 7.819 62.29 3.523 124.782-12.771 185.739-16.347 61.163-43.941 117.69-82.017 168.008-39.432 52.11-88.409 95.221-145.578 128.139-49.785 29.207-103.581 48.893-159.899 58.515-26.293 4.493-52.783 6.74-79.299 6.74zM195.299 869.064c-6.653 0-13.321-2.424-18.57-7.323-35.765-33.381-63.385-68.979-84.423-108.81-32.715-56.461-53.394-117.678-61.466-181.97-7.819-62.29-3.523-124.78 12.769-185.739 16.346-61.164 43.942-117.69 82.017-168.008 39.455-52.145 88.473-95.277 145.69-128.205 80.273-46.472 172.636-68.628 267.012-64.017 15.018 0.731 26.598 13.501 25.867 28.517-0.734 15.018-13.511 26.597-28.517 25.866-83.891-4.091-165.897 15.546-237.141 56.792-50.916 29.301-94.463 67.612-129.49 113.901-33.811 44.68-58.313 94.883-72.835 149.213-14.469 54.137-18.286 109.617-11.348 164.899 7.178 57.182 25.618 111.649 54.804 161.89 0.191 0.329 0.374 0.66 0.551 0.996 18.182 34.548 42.277 65.58 73.659 94.869 10.993 10.26 11.586 27.486 1.326 38.478-5.359 5.743-12.626 8.65-19.909 8.65zM513.599 1002.743c-28.871 0-58.033-2.667-86.673-7.929-8.583-1.576-16.038-6.401-20.993-13.585-4.954-7.183-6.815-15.866-5.239-24.449 1.577-8.583 6.401-16.038 13.585-20.991 7.183-4.955 15.866-6.815 24.449-5.24 47.886 8.797 96.361 9.123 144.078 0.97 49.19-8.403 96.213-25.624 139.758-51.186l0.291-0.166c50.1" fill="#00C8DF" />
    </svg>
);

const Sczx = props => <Icon component={sczx} {...props} />;
const search_arr = []
const search_data = {}

class Headernav extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            dates: '',
            dateOpen: '',
            value: '0',
            link: '/Zllb',
            zlyxx: '',
            address: [],
            zllx: '',
            sqrq: [],
            gkrq: [],
            isDl: false,
            duanxin: false,
            username: '',
            head: '',
            isShowDl: false,
            msgNum: 0,
            iserr: false,
            isYzm: true,
            isYzmsure: true,
            time: 0,
            err: '',
            isFindWord: false,
            tel: 0,
            isTruetel: true,
            errfind: '',
            times: 0,
            scrollIf: false,
            isbdtel: false,
            search: []
        }

        this.handleDate = this.handleDate.bind(this)
        this.handleDateOpen = this.handleDateOpen.bind(this)
        this.onChange = this.onChange.bind(this)
        this.onChange2 = this.onChange2.bind(this)
        this.onChangeaddress = this.onChangeaddress.bind(this)
        this.showDl = this.showDl.bind(this)
        this.handleDl = this.handleDl.bind(this)
        this.handleYzmDl = this.handleYzmDl.bind(this)
        this.handleDlCancel = this.handleDlCancel.bind(this)
        this.nextDl = this.nextDl.bind(this)
        this.passwordFind = this.passwordFind.bind(this)
        this.passwordFindNone = this.passwordFindNone.bind(this)
        this.duanxin = this.duanxin.bind(this)
        this.handleduanxinCancel = this.handleduanxinCancel.bind(this)
        this.showDlFh = this.showDlFh.bind(this)
    }

    zljs() {
        this.props.history.push('/App')
    }

    lastTime() {
        let time = 60
        this.interval = setInterval(() => {
            if (time > 0) {
                time--
                this.setState({
                    isYzm: false,
                    time: time
                })
            } else if (time === 0) {
                this.setState({
                    isYzm: true,
                })
                clearInterval(this.interval)
            }
        }, 1000)
    }

    //校验手机号，号段主要有：130~139、150~153，155~159，180~189、170~171、176~178、160~169、145、147、190~199
    handleTel(e) {
        const regs = /^((13[0-9])|(14[57])|(15[^4,\\D])|(16[0-9])|(17[0-1,6-8])|(18[0-9])|(19[0-9]))\d{8}$/
        var number = e.target.value.replace(/\s/g, '')
        if (!regs.test(number) || number.length !== 11) {
            this.setState({
                isTruetel: false
            })
        } else {
            this.setState({
                tel: number,
                isTruetel: true
            })
        }
    }

    lastTimeFind() {
        let time = 60
        this.interval = setInterval(() => {
            if (time > 0) {
                time--
                this.setState({
                    isYzmsure: false,
                    times: time
                })
            } else if (time === 0) {
                this.setState({
                    isYzmsure: true
                })
                clearInterval(this.interval)
            }
        }, 1000)
    }

    Yzmbdiphone() {
        var mobile = this.refs.telbd.value.trim()
        if (mobile === '') {
            var err = '请输入手机号'
            this.setState({
                err: err,
                iserr: true
            })
        } else {
            this.lastTime()
            api.post({
                str: '/login/getBindCode'
            }, {
                mobile: mobile
            })
                .then(res => res.json())
                .then(res => {
                    if (res.code === 200) {
                        this.setState({
                            err: res.msg,
                            iserr: false
                        })
                    } else {
                        this.setState({
                            err: res.msg,
                            iserr: true
                        })
                    }
                })
        }
    }

    Yzm() {
        var mobile = this.refs.tel.value.trim()
        var scene = 'login'
        if (mobile === '') {
            var err = '请输入手机号'
            this.setState({
                err: err,
                iserr: true
            })
        } else {
            this.lastTime()
            api.post({
                str: '/login/getCode'
            }, {
                mobile: mobile,
                scene: scene
            })
                .then(res => res.json())
                .then(res => {
                    if (res.code === 200) {
                        this.setState({
                            err: res.msg,
                            iserr: false
                        })
                    } else {
                        this.setState({
                            err: res.msg,
                            iserr: true
                        })
                    }
                })
        }
    }

    dlQuit() {
        api.post({
            str: '/login/layout'
        })
            .then(res => res.json())
            .then(res => {
                if (res.code === 200) {
                    message.success(res.msg)
                    localStorage.removeItem('token')
                    window.location.href = 'https://www.qzhip.com/'
                }
            })
    }

    componentDidMount() {
        var link = (window.location.href)
        var reg = RegExp(/token/)
        var isToken = reg.test(link)
        if (isToken === true) {
            var _token = link.split('token=')
            var token = _token[1].slice(0, 32)
            localStorage.setItem('token', token)
            window.location.href = 'https://www.qzhip.com/'
        } else {
            this.getUser()
        }
        window.addEventListener('scroll', this.handlescroll)
    }

    componentWillUnmount(){
        this.setState = (state,callback) => {
            return 
        }
    }

    handlescroll = (e) => {
        var scrollHeight = e.srcElement.scrollingElement.scrollTop
        if (scrollHeight > 0) {
            this.setState({
                scrollIf: true
            })
        } else {
            this.setState({
                scrollIf: false
            })
        }
    }

    getUser() {
        api.get({
            str: '/user/getUserInfo'
        })
            .then(res => res.json())
            .then(res => {
                if (res.code === 200) {
                    this.setState({
                        username: res.data.username,
                        head: res.data.head,
                        isShowDl: true,
                        msgNum: res.data.msgNum
                    })
                } else if (res.code == 4005) {
                  setTimeout(() => {
                    this.setState({
                        isbdtel: true
                    })
                  },100)
                } else {
                    this.setState({
                        isShowDl: false
                    })
                }
            })
    }

    showDlFh() {
        this.setState({
            duanxin: false,
            isDl: true
        })
    }

    handleduanxinCancel() {
        this.setState({
            duanxin: false,
        })
    }

    duanxin() {
        this.setState({
            duanxin: true,
            isDl: false
        })
    }

    nextDl(e) {
        this.setState({
            isDl: false,
            isFindWord: true
        })
    }

    passwordFindNone() {
        this.setState({
            isFindWord: false
        })
    }

    passwordFind() {
        var code = this.refs.yzmfind.value.trim()
        var mobile = this.refs.telqr.value.trim()
        var passwordsure = this.refs.passwordsure.value.trim()
        var password = this.refs.password.value.trim()
        if (passwordsure !== password) {
            this.setState({
                errfind: '密码不一致'
            })
        } else {
            var ps = md5(password)
            api.post({
                str: '/login/forgetPassword'
            }, {
                mobile: mobile,
                code: code,
                password: ps
            })
                .then(res => res.json())
                .then(res => {
                    if (res.code === 200) {
                        message.success(res.msg)
                        this.setState({
                            isFindWord: false,
                            isDl: true
                        })
                    } else {
                        message.error(res.msg)
                    }
                })
        }
    }

    handlesurepassword() {
        var passwordsure = this.refs.passwordsure.value.trim()
        var password = this.refs.password.value.trim()
        if (passwordsure !== password) {
            this.setState({
                errfind: '密码不一致'
            })
        }
    }

    handleDlCancel() {
        this.setState({
            isDl: false
        })
    }

    zhuce() {
        this.props.history.push('/ZhuCe')
    }

    handleDl() {
        var mobile = this.refs.tel.value.trim()
        var password = md5(this.refs.password.value.trim())
        if (mobile === '') {
            this.setState({
                err: '请输入手机号'
            })
        } else if (password === '') {
            this.setState({
                err: '请输入密码'
            })
        } else {
            api.post({
                str: '/login/login'
            }, {
                mobile: mobile,
                password: password,
                type: 1
            })
                .then(res => res.json())
                .then(res => {
                    if (res.code === 200) {
                        localStorage.setItem('token', res.data.token)
                        this.setState({
                            isDl: false
                        })
                        window.location.reload()
                    } else {
                        this.setState({
                            err: res.msg
                        })
                    }
                })
        }
    }

    handleYzmDl() {
        var mobile = this.refs.tel.value.trim()
        var code = this.refs.yzm.value.trim()
        if (mobile === '') {
            this.setState({
                err: '请输入手机号'
            })
        } else if (code === '') {
            this.setState({
                err: '请输入验证码'
            })
        } else {
            api.post({
                str: '/login/login'
            }, {
                mobile: mobile,
                code: code,
                type: 2
            })
                .then(res => res.json())
                .then(res => {
                    if (res.code === 200) {
                        localStorage.setItem('token', res.data.token)
                        this.setState({
                            duanxin: false
                        })
                        window.location.reload()
                    } else {
                        this.setState({
                            err: res.msg
                        })
                    }
                })
        }
    }

    showDl() {
        // this.setState({
        //     isDl: true
        // })
        this.props.history.push('/Signin')
    }

    findYzm() {
        var mobile = this.refs.telqr.value.trim()
        if (mobile === '') {
            var err = '请输入手机号'
            this.setState({
                errfind: err,
                iserrfind: true
            })
        } else if (this.state.isTruetel === false) {
            var err1 = '您输入的电话不正确，请重新输入'
            this.setState({
                errfind: err1
            })
        } else {
            this.lastTimeFind()
            api.post({
                str: '/login/getCode'
            }, {
                mobile: mobile,
                scene: 'forget'
            })
                .then(res => res.json())
                .then(res => {
                    if (res.code === 200) {
                        this.setState({
                            errfind: res.msg,
                            iserrfind: false
                        })
                    } else {
                        this.setState({
                            errfind: res.msg,
                            iserrfind: true
                        })
                    }
                })
        }
    }

    zanweikaifang() {
        message.warning('暂未开放，敬请期待')
    }

    QQdl() {
        api.post({
            str: '/login/login_link'
        }, {
            type: 2,
            return_url: 'https://www.qzhip.com/'
        })
            .then(res => res.json())
            .then(res => {
                if (res.code === 200) {
                    var link = res.data
                    window.location.href = link
                } else {
                    message.error(res.msg)
                }
            })
    }

    Wechat() {
        api.post({
            str: '/login/login_link'
        }, {
            type: 1,
            return_url: 'https://www.qzhip.com/'
        })
            .then(res => res.json())
            .then(res => {
                if (res.code === 200) {
                    var link = res.data
                    window.location.href = link
                } else {
                    message.error(res.msg)
                }
            })
    }

    onChangeaddress = value => {
        this.setState({
            address: value
        })
    }

    onChange = e => {
        this.setState({
            zllx: e.target.value
        })
    }


    onChange2 = e => {
        this.setState({
            zlyxx: e.target.value
        })
    }

    handleDate(date, dateString) {
        const dates = dateString;
        this.setState({
            sqrq: dates
        })
    }

    handleDateOpen(date, dateString) {
        const dateOpen = dateString;
        this.setState({
            gkrq: dateOpen
        })
    }

    jiaoyishichang() {
        this.props.history.push('/Market')
    }

    qiugoushichang() {
        this.props.history.push('/RequireMarket')
    }

    handleOk = (e) => {
        const visible = false
        var sqh = this.refs.sqh.value.trim()
        var search = this.state.search
        var sqr = this.refs.sqr.value.trim()
        var zlwyid = this.refs.zlwyid.value.trim()
        var dljg = this.refs.dljg.value.trim()
        var zlmc = this.refs.zlmc.value.trim()
        var fmr = this.refs.fmr.value.trim()
        var zlyxx = this.state.zlyxx
        var type = 2
        var sqrq = this.state.sqrq
        var gkrq = this.state.gkrq
        var zllx = this.state.zllx
        var address = this.state.address
        var time = getCurrentDate()
        var item = {
            'sqr': sqr,
            'sqh': sqh,
            'gkh': zlwyid,
            'dljg': dljg,
            'zlmc': zlmc,
            'fmr': fmr,
            'zlyxx': zlyxx,
            'zllx': zllx,
            'sqrq': sqrq,
            'gkrq': gkrq,
            'region': address,
            'time': time
        }
        var list = sqh + '&' + sqr + '&' + zlwyid + '&' + dljg + '&' + zlmc + '&' + fmr + '&' + zlyxx + '&' + zllx + '&' + address
        var list_k = [{
            search: list,
            time: time
        }]
        if (sqh === '' && sqr === '' && zlwyid === '' && dljg === '' && zlmc === '' && fmr === '' && zlyxx === '' && sqrq.length === 0 && gkrq.length === 0 && zllx === '' && address.length === 0) {
            message.error('请输入至少一项')
        } else {
            search.push(item)
            var _search = JSON.stringify(search)
            localStorage.setItem('search', JSON.stringify(list_k))
            this.props.history.push(`/Zllb/${_search}/${type}`)
        }
        this.setState({
            visible: visible
        })
    };

    bdtelOk() {
        var mobile = this.refs.telbd.value.trim()
        var code = this.refs.yzmbd.value.trim()
        if (mobile === '') {
            var err = '请输入手机号'
            this.setState({
                err: err,
                iserr: true
            })
        } else if (this.state.isTruetel === false) {
            var err1 = '您输入的电话不正确，请重新输入'
            this.setState({
                err: err1
            })
        } else {
            api.post({
                str: '/login/bindUserMobile'
            }, {
                mobile: mobile,
                code: code
            })
                .then(res => res.json())
                .then(res => {
                    if (res.code === 200) {
                        localStorage.setItem('token', res.data.token)
                        message.success(res.msg)
                        this.setState({
                            isbdtel: false
                        })

                        this.componentDidMount()
                    } else {
                        message.error(res.msg)
                    }
                })
        }
    }

    bdtelCancel() {
        this.setState({
            isbdtel: false
        })
    }

    jdsearch() {
        this.props.history.push('/App')
    }

    handleCancel = e => {
        this.setState({
            visible: false,
        });
    };

    zhucetiaozhuan() {
        this.props.history.push('/ZhuCe')
    }

    handelMarketProgram() {
        this.props.history.push('/MarketProgram')
    }

    showModal() {
        this.setState({
            visible: true,
        });
    }

    zljk() {
        this.props.history.push('/Annualfee')
    }

    zlsq() {
        this.props.history.push('/Apply')
    }

    render() {
        const menu1 = (
            <Menu>
                <Menu.Item className="item">
                    <Link to='/Annualfee' style={{ height: '38px', lineHeight: '38px' }} >监控提醒</Link>
                </Menu.Item>
                <Menu.Item className="item" >
                    <Link to='/Addpay' style={{ height: '38px', lineHeight: '38px' }} >专利缴费</Link>
                    {/* <div style={{height:'38px',lineHeight:'38px'}} onClick={this.zanweikaifang.bind(this)} >专利缴费</div> */}
                </Menu.Item>
            </Menu>
        );
        const menu2 = (
            <Menu>
                <Menu.Item className="item-gr border1">
                    <Link to={`/Grzx/information`} className="item-div">
                        <img src={require('../images/grxx.png')} alt='xx' /><span className="mya">个人信息</span>
                    </Link>
                </Menu.Item>
                <Menu.Item className="item-gr border1">
                    <Link to={`/Grzx/collect`} className="item-div">
                        <img src={require('../images/sc.png')} alt='sc' /><span className="mya">我的收藏</span>
                    </Link>
                </Menu.Item>
                <Menu.Item className="item-gr border1">
                    <Link to={`/Grzx/order`} className="item-div">
                        <img src={require('../images/dd.png')} alt='dd' /><span className="mya">订单管理</span>
                    </Link>
                </Menu.Item>
                <Menu.Item className="item-gr border1">
                    <Link to={`/Grzx/address`} className="item-div">
                        <img src={require('../images/dz.png')} alt='dz' /><span className="mya">地址管理</span>
                    </Link>
                </Menu.Item>
                <Menu.Item className="item-gr border1">
                    <Link to={`/Grzx/invoice`} className="item-div">
                        <img src={require('../images/fp.png')} alt='fp' /><span className="mya">发票信息</span>
                    </Link>
                </Menu.Item>
                <Menu.Item className="item-gr border1">
                    <Badge count={this.state.msgNum}>
                        <Link to={`/Grzx/news`} className="item-div">
                            <img src={require('../images/xiaoxi.png')} alt='xx' /><span className="mya">我的消息</span>
                        </Link>
                    </Badge>
                </Menu.Item>
                {/* <Menu.Item className="item-gr border1">
                    <div className="item-div" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }} onClick={this.handelMarketProgram.bind(this)} alt='管理中心' src={require('../images/glzx.png')}><Sczx style={{fontSize:'22px',marginLeft:'20px'}} /><span className='mya'>市场中心</span></div>
                </Menu.Item> */}
                <Menu.Item className="item-gr">
                    <div onClick={this.dlQuit.bind(this)} className="item-div" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        <img src={require('../images/equit.png')} alt='xx' /><span className="mya">退出登录</span>
                    </div>
                </Menu.Item>
            </Menu>
        )
        const menu3 = (
            <Menu>
                <Menu.Item className='item'>
                    <Link to='/Apply' style={{ height: '38px', lineHeight: '38px' }} >专利申请</Link>
                </Menu.Item>
                <Menu.Item className='item'>
                    <Link to='/Bookdownload' style={{ height: '38px', lineHeight: '38px' }} >书式下载</Link>
                </Menu.Item>
            </Menu>
        )
        const { RangePicker } = DatePicker;
        const menu = (
            <Menu  >
                <Menu.Item className="item">
                    <div style={{ height: '38px', lineHeight: '38px' }} onClick={this.jdsearch.bind(this)}>简单检索</div>
                </Menu.Item>
                <Menu.Item className="item">
                    <div onClick={this.showModal.bind(this)}>高级检索</div>
                    <Modal closable={false}
                        visible={this.state.visible}
                        bodyStyle={{ width: 932 }}
                        width={932}
                        height={400}
                        className="Modal"
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                        footer={[
                            <button key="cancel" onClick={this.handleCancel} className="cancle-btn" style={{ marginLeft: '632px' }}>取消检索</button>,
                            <button key="submit" onClick={this.handleOk} className="ok-btn" type="primary">立即检索</button>
                        ]}
                    >
                        <div style={{ width: '932px', height: '79px', color: 'rgba(0,0,0,0.8)', fontWeight: 'bold', fontSize: '16px', textAlign: 'center', lineHeight: '79px', padding: "0", borderBottom: '1px solid rgba(0, 0, 0, 0.1)' }} >高级检索</div>
                        <div style={{ width: '932px', height: '298px' }} className="seniorsearch">
                            <div style={{ width: '932px', height: '45px', marginTop: '20px' }} className="seniorsearch-item">
                                <div style={{ width: '384px', height: '100%', borderBottom: '0.5px solid rgba(0, 0, 0, 0.1)', display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '40px' }}>
                                    <div style={{ width: '12px', height: '12px', backgroundColor: '#00C8DF', }}></div>
                                    <div style={{ fontSize: '12px', color: 'rgba(0,0,0,0.8)', marginLeft: '10px', letterSpacing: '0.85em', width: '84px' }}>申请号:</div>
                                    <input style={{ fontSize: '0.75rem', border: '0', marginLeft: '21px', height: '100%', width: '258px' }} placeholder="例如 CN201310111568.6" ref="sqh" />
                                </div>
                                <div style={{ width: '384px', height: '100%', borderBottom: '0.5px solid rgba(0, 0, 0, 0.1)', display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '90px' }}>
                                    <div style={{ width: '12px', height: '12px', backgroundColor: '#00C8DF', }}></div>
                                    <div style={{ fontSize: '12px', color: 'rgba(0,0,0,0.8)', marginLeft: '10px', width: '84px', letterSpacing: '0.37em ' }}>专利名称:</div>
                                    <input style={{ fontSize: '0.75rem', border: '0', marginLeft: '21px', height: '100%', width: '258px' }} placeholder="例如 一种一二三四五的计数器" ref="zlmc" />
                                </div>
                            </div>
                            <div style={{ width: '100%', height: '45px' }} className="seniorsearch-item">
                                <div style={{ width: '384px', height: '100%', borderBottom: '0.5px solid rgba(0, 0, 0, 0.1)', display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '40px' }}>
                                    <div style={{ width: '12px', height: '12px', backgroundColor: '#00C8DF', }}></div>
                                    <div style={{ fontSize: '12px', color: 'rgba(0,0,0,0.8)', marginLeft: '10px', letterSpacing: '0.85em', width: '84px' }}>申请人:</div>
                                    <input style={{ fontSize: '0.75rem', border: '0', marginLeft: '21px', height: '100%', width: '258px' }} placeholder="例如 张三" ref="sqr" />
                                </div>
                                <div style={{ width: '384px', height: '100%', borderBottom: '0.5px solid rgba(0, 0, 0, 0.1)', display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '90px' }}>
                                    <div style={{ width: '12px', height: '12px', backgroundColor: '#00C8DF', }}></div>
                                    <div style={{ fontSize: '12px', color: 'rgba(0,0,0,0.8)', marginLeft: '10px', letterSpacing: '0.85em', width: '84px' }}>发明人:</div>
                                    <input style={{ fontSize: '0.75rem', border: '0', marginLeft: '21px', height: '100%', width: '258px' }} placeholder="例如 张三" ref="fmr" />
                                </div>
                            </div>
                            <div style={{ width: '100%', height: '45px' }} className="seniorsearch-item">
                                <div style={{ width: '384px', height: '100%', borderBottom: '0.5px solid rgba(0, 0, 0, 0.1)', display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '40px' }}>
                                    <div style={{ width: '12px', height: '12px', backgroundColor: '#00C8DF', }}></div>
                                    <div style={{ fontSize: '12px', color: 'rgba(0,0,0,0.8)', marginLeft: '10px', letterSpacing: '0.85em', width: '84px' }}>申请日:</div>
                                    <Space direction="vertical" style={{ fontSize: '0.75rem', marginLeft: '10px', width: '258px' }} >
                                        <RangePicker onCalendarChange={this.handleDate} locale={locale} bordered={false} />
                                    </Space>
                                </div>
                                <div style={{ width: '384px', height: '100%', borderBottom: '0.5px solid rgba(0, 0, 0, 0.1)', display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '90px' }}>
                                    <div style={{ width: '12px', height: '12px', backgroundColor: '#00C8DF' }}></div>
                                    <div style={{ fontSize: '12px', color: 'rgba(0,0,0,0.8)', marginLeft: '10px', width: '84px', letterSpacing: '0.37em ' }}>专利类型:</div>
                                    <Radio.Group defaultChecked="false" buttonStyle="solid" onChange={this.onChange} style={{ marginLeft: '21px', display: 'flex', alignItems: 'center', height: "42px", width: '258px', flexDirection: "row" }}>
                                        <Radio.Button value="发明专利" style={{ width: '82px', height: '32px', backgroundColor: '#fff', border: '0.5px solid rgba(0,0,0,0.1)', textAlign: 'center', borderRadius: '16px', color: 'rgba(0,0,0,0.4)', fontSize: '12px', lineHeight: '32px' }}>发明专利</Radio.Button>
                                        <Radio.Button value="实用新型" style={{ width: '82px', height: '32px', backgroundColor: '#fff', border: '0.5px solid rgba(0,0,0,0.1)', textAlign: 'center', borderRadius: '16px', color: 'rgba(0,0,0,0.4)', fontSize: '12px', lineHeight: '32px', marginLeft: '21px' }}>实用新型</Radio.Button>
                                        <Radio.Button value="外观设计" style={{ width: '82px', height: '32px', backgroundColor: '#fff', border: '0.5px solid rgba(0,0,0,0.1)', textAlign: 'center', borderRadius: '16px', color: 'rgba(0,0,0,0.4)', fontSize: '12px', lineHeight: '32px', marginLeft: '21px' }}>外观专利</Radio.Button>
                                    </Radio.Group>
                                </div>
                            </div>
                            <div style={{ width: '100%', height: '45px' }} className="seniorsearch-item">
                                <div style={{ width: '384px', height: '100%', borderBottom: '0.5px solid rgba(0, 0, 0, 0.1)', display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '40px' }}>
                                    <div style={{ width: '12px', height: '12px', backgroundColor: '#00C8DF', }}></div>
                                    <div style={{ fontSize: '12px', color: 'rgba(0,0,0,0.8)', marginLeft: '10px', width: '84px' }}>公开/公告号:</div>
                                    <input style={{ fontSize: '0.75rem', border: '0', marginLeft: '21px', height: '100%', width: '258px' }} placeholder="例如 CN103208406B" ref="zlwyid" />
                                </div>
                                {/* <div style={{ width: '384px', height: '100%', borderBottom: '0.5px solid rgba(0, 0, 0, 0.1)', display: 'flex',  flexDirection: 'row', alignItems: 'center',marginLeft:'40px' }}>
                                    <div style={{ width: '12px', height: '12px', backgroundColor: '#00C8DF', }}></div>
                                    <div style={{ fontSize: '12px', color: 'rgba(0,0,0,0.8)', marginLeft: '10px' }}>公开日:</div>
                                    <Space direction = "vertical" size={12} style={{marginLeft:'0px'}} >
                                        <RangePicker onCalendarChange={this.handleDateOpen} locale={locale} bordered={false} />
                                    </Space>
                                </div> */}
                                <div style={{ width: '384px', height: '100%', borderBottom: '0.5px solid rgba(0, 0, 0, 0.1)', display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '90px' }}>
                                    <div style={{ width: '12px', height: '12px', backgroundColor: '#00C8DF', }}></div>
                                    <div style={{ fontSize: '12px', color: 'rgba(0,0,0,0.8)', marginLeft: '10px', width: '84px' }}>专利有效性:</div>
                                    <Radio.Group defaultChecked="false" buttonStyle="solid" onChange={this.onChange2} style={{ display: 'flex', marginLeft: '21px', alignItems: 'center', height: "42px", width: '258px', flexDirection: "row" }}>
                                        <Radio.Button value="有效专利" style={{ width: '66px', height: '32px', backgroundColor: '#fff', border: '0.5px solid rgba(0,0,0,0.1)', textAlign: 'center', borderRadius: '16px', color: 'rgba(0,0,0,0.4)', fontSize: '12px', lineHeight: '32px' }}>有效专利</Radio.Button>
                                        <Radio.Button value="无效专利" style={{ width: '64px', height: '32px', backgroundColor: '#fff', border: '0.5px solid rgba(0,0,0,0.1)', textAlign: 'center', borderRadius: '16px', color: 'rgba(0,0,0,0.4)', fontSize: '12px', lineHeight: '32px', marginLeft: '16px' }}>失效专利</Radio.Button>
                                        <Radio.Button value="实质审查" style={{ width: '66px', height: '32px', backgroundColor: '#fff', border: '0.5px solid rgba(0,0,0,0.1)', textAlign: 'center', borderRadius: '16px', color: 'rgba(0,0,0,0.4)', fontSize: '12px', lineHeight: '32px', marginLeft: '16px' }}>审中</Radio.Button>
                                    </Radio.Group>
                                </div>
                            </div>
                            <div style={{ width: '100%', height: '45px' }} className="seniorsearch-item">
                                <div style={{ width: '100%', height: '45px' }} className="seniorsearch-item">
                                    <div style={{ width: '384px', height: '100%', borderBottom: '0.5px solid rgba(0, 0, 0, 0.1)', display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '40px' }}>
                                        <div style={{ width: '12px', height: '12px', backgroundColor: '#00C8DF', }}></div>
                                        <div style={{ fontSize: '12px', color: 'rgba(0,0,0,0.8)', marginLeft: '10px', width: '84px' }}>公开/公告日:</div>
                                        <Space style={{ fontSize: '0.75rem', width: '258px', marginLeft: '10px', padding: '0px' }} direction="vertical"  >
                                            <RangePicker onCalendarChange={this.handleDateOpen} locale={locale} bordered={false} />
                                        </Space>
                                    </div>
                                    <div className="zldq" style={{ width: '384px', height: '100%', borderBottom: '0.5px solid rgba(0, 0, 0, 0.1)', display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '90px' }}>
                                        <div style={{ width: '12px', height: '12px', backgroundColor: '#00C8DF', }}></div>
                                        <div style={{ fontSize: '12px', color: 'rgba(0,0,0,0.8)', marginLeft: '10px', width: '84px', letterSpacing: '0.37em' }}>专利地区:</div>
                                        <Cascader style={{ fontSize: '0.75rem', width: '258px', marginLeft: '21px', padding: '0' }} className="address_select" expandTrigger="hover" displayRender={this.displayRender} options={options} onChange={this.onChangeaddress} placeholder="请选择地址" ></Cascader>
                                    </div>
                                </div>
                            </div>

                            <div style={{ width: '100%', height: '45px' }} className="seniorsearch-item">
                                <div style={{ width: '384px', height: '100%', borderBottom: '0.5px solid rgba(0, 0, 0, 0.1)', display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '40px' }}>
                                    <div style={{ width: '12px', height: '12px', backgroundColor: '#00C8DF', }}></div>
                                    <div style={{ fontSize: '12px', color: 'rgba(0,0,0,0.8)', marginLeft: '10px', width: '84px', letterSpacing: '0.37em' }}>代理机构:</div>
                                    <input style={{ fontSize: '0.75rem', border: '0', marginLeft: '21px', height: '100%', width: '258px' }} placeholder="例如 中索知识产权有限公司" ref="dljg" />
                                </div>
                            </div>
                        </div>
                    </Modal>
                </Menu.Item>
            </Menu>
        );
        // const menu3 = (
        //     <Menu  >
        //         <Menu.Item className="item">
        //             <div style={{height:'38px',lineHeight:'38px'}} onClick={this.jiaoyishichang.bind(this)}>交易市场</div>
        //         </Menu.Item>
        //         <Menu.Item className="item">
        //             <div style={{height:'38px',lineHeight:'38px'}} onClick={this.qiugoushichang.bind(this)}>求购市场</div>
        //         </Menu.Item>
        //     </Menu>
        // )

        return (
            <Fragment>
                <div style={{ width: '100%', height:'48px', display: 'flex', flexDirection: 'row', justifyContent: 'center', backgroundColor: 'rgba(0, 0, 0, 0.8)', boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.16)' }}>
                    <div className={(this.state.scrollIf == true ? 'positionfixed' : '') + " header"} style={{ width: '62.5vw', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                        <div className="logo">
                            <Link style={{ width: '100vw', height: '100%' }} to="/App"><img src='http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210830/b1b10ca59c626498968c1630e3185cdc530534a8.png' alt="LOGO" /></Link>
                        </div>
                        <Layout className="nav">
                            <Dropdown trigger={['click', 'hover']} overlay={menu} className="nav-li"><li onClick={this.zljs.bind(this)} className="nav-li-li">专利检索</li></Dropdown>
                            <Dropdown trigger={['click', 'hover']} overlay={menu1} className="nav-li"><li onClick={this.zljk.bind(this)} className="nav-li-li">专利监控</li></Dropdown>
                            {/* <Dropdown overlay={menu3} className="nav-li"><li className="nav-li">专利市场</li></Dropdown> */}
                            <Dropdown trigger={['click', 'hover']} overlay={menu3} className="nav-li"><li onClick={this.zlsq.bind(this)} className="nav-li-li">专利申请</li></Dropdown>
                            <Link to='/Zldl' className="nav-li"><li className="nav-li-li" style={{ position: 'relative' }}>专利代理<img style={{ position: 'absolute', top: '0.6vw', right: '0.4vw', width: '11px', height: '13px', objectFit: 'fill' }} alt='' src={fire} /></li></Link>
                            <Link to='/Market' className="nav-li" ><li className="nav-li-li">专利市场</li></Link>
                            {/* <div onClick={this.zanweikaifang.bind(this)} className="nav-li"><li className="nav-li-li">专利质检</li></div> */}
                            {/* <Link to="/Qiguan" className='nav-li' ><li className="nav-li-li">其观</li></Link> */}
                            <Link to='/Qishuo' className='nav-li' ><li className="nav-li-li">其说</li></Link>
                            <Link to='/QZXY' className="nav-li"><li className="nav-li-li">其知学院</li></Link>
                            <Link to='/About' className="nav-li"><li className="nav-li-li">关于我们</li></Link>
                        </Layout>
                        <div className={(this.state.isShowDl === false ? '' : 'displayNone') + " perison"} style={{ marginRight: '20px' }} >
                            <div className="perison" style={{ display: 'flex', marginLeft: 'auto', marginLeft: '30px' }}>
                                {/* <img alt='' src={require('../images/touxiang.png')} style={{width:'24px',height:'24px'}} /> */}
                                <div className="cousor" style={{ width: '50px', height: '30px', lineHeight: '30px', textAlign: 'center', color: '#fff', fontWeight: '700' }} onClick={this.showDl}  >登录</div>&nbsp;<div onClick={this.zhucetiaozhuan.bind(this)} className="cousor" style={{ width: '50px', height: '30px', lineHeight: '30px', textAlign: 'center', color: '#fff', fontWeight: '700' }}>注册</div>
                                {/* <Modal
                                visible={this.state.isDl}
                                width={408}
                                height={480}
                                onCancel={this.handleDlCancel}
                                footer={false}
                            >
                                <div style={{ width: '100%', height: '79px', color: 'rgba(0,0,0,0.8)', fontWeight: 'bold', fontSize: '16px', textAlign: 'center', lineHeight: '79px', padding: "0", borderBottom: '1px solid rgba(0, 0, 0, 0.1)' }}>登录</div>
                                <div className="cousor" onClick={this.duanxin} style={{ cousor: 'pointer', fontSize: '12px', color: '#00C8DF', marginLeft: 'auto', marginRight: '44px', textAlign:'right',width: '100px', marginTop: '39px' }}>短信验证码登录</div>
                                <div style={{ border: '0.5px solid #C7C7C7', width: '320px', height: '40px', display: "flex", flexDirection: 'row', marginTop: '8px', marginLeft: '44px', justifyContent: 'center', alignItems: 'center' }}>
                                    <div style={{ width: '24px', height: '24px' }}><img alt="手机账号" src={require('../images/iphonenumber.png')} style={{ width: '100%', height: '100%' }} /></div>
                                    <input style={{ width: '272px', height: '90%', lineHeight: '40px', border: 'none' }} placeholder="请输入手机号码" type="tel" maxLength="11" ref='tel' />
                                </div>
                                <div style={{ border: '0.5px solid #C7C7C7', width: '320px', height: '40px', display: "flex", flexDirection: 'row', marginTop: '8px', marginLeft: '44px', justifyContent: 'center', alignItems: 'center' }}>
                                    <div style={{ marginLeft: '0px', width: '24px', height: '24px' }}><img alt="密码" src={require('../images/password.png')} style={{ width: '100%', height: '100%' }} /></div>
                                    <input style={{ width: '272px', height: '90%', lineHeight: '40px', border: 'none' }} placeholder="请输入密码" type="password" ref='password' />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '6px' }}>
                                    <div className="cousor" onClick={this.nextDl} style={{ width: '127px', marginLeft: '44px', fontSize: '12px', color: '#00C8DF' }} >找回密码</div>
                                    <div className="cousor" onClick={this.zhuce.bind(this)} style={{ width: '51px', marginLeft: 'auto', marginRight: '44px', fontSize: '12px', color: '#00C8DF' }}>立即注册</div>
                                </div>
                                <span style={{ display: 'flex', width: '100%', marginLeft: '50px', marginTop: '15px', color: '#ff0000', fontSize: '12px' }}>{this.state.err}</span>
                                <div style={{ width: '320px', height: '32px', marginLeft: '44px', marginTop: '40px' }}>
                                    <button key="submit" onClick={this.handleDl.bind(this)} className="ok-btndl cousor" type="primary" style={{ width: '100%', height: '100%', textAlign: 'center', backgroundColor: ' #00CBDF', borderRadius: '16px', color: '#ffffff', border: '0' }}>登录</button>
                                </div>
                                <div style={{ width: '100%', height: '81px', alignItems: 'center', display: 'flex', flexDirection: 'row', marginTop: '57px', borderTop: '0.5px solid rgba(0, 0, 0, 0.1)' }}>
                                    <div style={{ color: '#868686', marginLeft: '150px' }}>第三方登录</div>
                                    <div className="cousor" onClick={this.QQdl.bind(this)} style={{ width: '24px', height: '24px', marginLeft: '23px' }}>
                                        <img alt="qq" style={{ width: '100%', height: '100%' }} src={require('../images/qq.png')} />
                                    </div>
                                    <div className="cousor" onClick={this.Wechat.bind(this)} style={{ width: '22px', height: '19px', marginLeft: '23px' }}>
                                        <img alt="wx" style={{ width: '100%', height: '100%' }} src={require('../images/wechatcai.png')} />
                                    </div>
                                </div>
                            </Modal>
                            
                            <Modal
                                visible={this.state.isFindWord}
                                width={408}
                                onOk={this.passwordFind}
                                onCancel={this.passwordFindNone}
                                footer={[
                                    <div style={{ height: '60px', width: '320px', display: 'flex', flexDirection: 'row', justifyContent: "center", alignItems: 'center' }} key="ss">
                                        <button key="submit" onClick={this.passwordFind} className="ok-btn" type="primary" >完成</button>
                                    </div>
                                ]}>
                                <div style={{ width: '100%', height: '79px', color: 'rgba(0,0,0,0.8)', fontWeight: 'bold', fontSize: '16px', textAlign: 'center', lineHeight: '79px', padding: "0", borderBottom: '1px solid rgba(0, 0, 0, 0.1)' }} >设置新密码</div>
                                <div style={{ width: '100%', display: 'flex', flexDirection: "column", marginTop: '21px', justifyContent: 'center' }}>
                                    <div style={{ width: '320px', display: 'flex', flexDirection: 'column', marginLeft: '44px' }}>
                                        <div style={{ borderLeft: '4px solid #00C8DF', paddingLeft: '10px' }}>验证身份</div>
                                        <div style={{ width: '320px', height: '48px', border: '0.5px solid #DCDCDC', borderRadius: '4px', marginTop: '16px' }}>
                                            <img alt="手机账号" src={require('../images/iphonenumber.png')} style={{ width: '24px', height: '24px', marginLeft: '10px' }} />
                                            <input style={{ width: '260px', height: '90%', border: 'none', backgroundColor: '#fff', fontSize:'0.75rem',paddingLeft:'10px' }} maxLength="11" ref='telqr' minLength='11' onBlur={this.handleTel.bind(this)} placeholder="请输入手机号" type="tel" />
                                        </div>
                                        <div style={{ width: '320px', height: '48px', marginTop: '16px', display: 'flex', flexDirection: 'row' }}>
                                            <div style={{ width: '272px', height: '48px', border: '0.5px solid #DCDCDC', borderRadius: '4px' }}>
                                                <input style={{ width: '150px', height: '90%', border: 'none', backgroundColor: '#fff', fontSize:'0.75rem', paddingLeft:'10px'}} ref="yzmfind" placeholder="请输入验证码" />
                                            </div>
                                            <button onClick={this.findYzm.bind(this)} className={(this.state.isYzmsure === true ? '' : 'displayNone') + '  cousor'} style={{ outline: 'none', width: '139px', height: '100%', marginLeft: '10px', borderRadius: '4px', border: '0.5px solid #DCDCDC', textAlign: 'center', lineHeight: '48px', color: '#868686' }}>获取验证码</button>
                                            <button className={this.state.isYzmsure === false ? '' : 'displayNone'} style={{ outline: 'none', width: '139px', height: '100%', marginLeft: '10px', borderRadius: '4px', border: '0.5px solid #DCDCDC', textAlign: 'center', lineHeight: '48px', color: '#868686' }}>{this.state.times}s后重新发送</button>
                                        </div>
                                    </div>
                                    <div style={{ width: '320px', display: 'flex', flexDirection: 'column', marginLeft: '44px', marginTop: '20px' }}>
                                        <div style={{ borderLeft: '4px solid #00C8DF', paddingLeft: '10px' }}>设置新密码</div>
                                        <div style={{ width: '320px', height: '48px', border: '0.5px solid #DCDCDC', borderRadius: '4px', marginTop: '16px' }}>
                                            <img alt="手机账号" src={require('../images/password.png')} style={{ width: '24px', height: '24px', marginLeft: '10px' }} />
                                            <input style={{ width: '262px', height: '90%', border: 'none', backgroundColor: '#fff', fontSize:'0.75rem',paddingLeft:'10px' }} maxLength="11" ref='password' minLength='11' placeholder="请输入新密码" type="password" />
                                        </div>
                                        <div style={{ width: '320px', height: '48px', border: '0.5px solid #DCDCDC', borderRadius: '4px', marginTop: '16px' }}>
                                            <img alt="手机账号" src={require('../images/password.png')} style={{ width: '24px', height: '24px', marginLeft: '10px' }} />
                                            <input style={{ width: '262px', height: '90%', border: 'none', backgroundColor: '#fff', fontSize:'0.75rem',paddingLeft:'10px' }} maxLength="11" ref='passwordsure' minLength='11' onBlur={this.handlesurepassword.bind(this)} placeholder="请确认密码" type="password" />
                                        </div>
                                    </div>
                                    <span style={{ width: '320px', height: '20px', marginLeft: '44px', marginTop: '5px', color: '#ff0000', fontSize: '12px' }}>{this.state.errfind}</span>
                                </div>
                            </Modal>
                            <Modal
                                visible={this.state.duanxin}
                                width={408}
                                height={375}
                                onCancel={this.handleduanxinCancel}
                                footer={false}
                            >
                                <div style={{ width: '100%', height: '79px', color: 'rgba(0,0,0,0.8)', fontWeight: 'bold', fontSize: '16px', textAlign: 'center', lineHeight: '79px', padding: "0", borderBottom: '1px solid rgba(0, 0, 0, 0.1)' }}>登录</div>
                                <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '44px', marginTop: '27px' }}>
                                    <div>短信登录</div>
                                    <div className="cousor" onClick={this.showDlFh} style={{ fontSize: '12px', color: '#00C8DF', marginRight: '44px', marginLeft: 'auto', lineHeight: '22px' }}>账号密码登录</div>
                                </div>

                                <div style={{ border: '0.5px solid #C7C7C7', width: '320px', height: '40px', display: "flex", flexDirection: 'row', marginTop: '8px', marginLeft: '44px', justifyContent: 'center', alignItems: 'center' }}>
                                    <div style={{ width: '24px', height: '24px' }}><img alt="密码" src={require('../images/iphonenumber.png')} style={{ width: '100%', height: '100%' }} /></div>
                                    <input style={{ width: '272px', height: '97%', lineHeight: '40px', border: 'none' }} placeholder="请输入手机号码" maxLength="11" type="tel" ref='tel' />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', height: '40px', justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}>
                                    <div style={{ border: '0.5px solid #C7C7C7', width: '162px', height: '40px', display: "flex", flexDirection: 'row', marginTop: '8px', justifyContent: 'center', alignItems: 'center' }}>
                                        <input style={{ width: '98%', height: '97%', lineHeight: '40px', border: 'none',paddingLeft:'10px' }} placeholder="动态密码" ref='yzm' />
                                    </div>
                                    <button onClick={this.Yzm.bind(this)} className={(this.state.isYzm === true ? '' : 'displayNone') + ' cousor'} style={{ marginTop: '8px', outline: 'none', width: '139px', height: '100%', marginLeft: '19px', borderRadius: '4px', border: '0.5px solid #DCDCDC', textAlign: 'center', lineHeight: '40px', color: '#868686' }}>获取验证码</button>
                                    <button className={this.state.isYzm === true ? 'displayNone' : ''} style={{ marginTop: '8px', outline: 'none', width: '139px', height: '100%', marginLeft: '19px', borderRadius: '4px', border: '0.5px solid #DCDCDC', textAlign: 'center', lineHeight: '40px', color: '#868686' }}>{this.state.time}s后重新发送</button>
                                </div>
                                <span style={{ display: 'flex', width: '100%', marginLeft: '50px', marginTop: '15px', color: '#ff0000', fontSize: '12px' }}>{this.state.err}</span>
                                <div style={{ width: '320px', height: '32px', marginLeft: '44px', marginTop: '38px', marginBottom: '52px' }}>
                                    <button key="submit" onClick={this.handleYzmDl} className="ok-btndl coursor" type="primary" style={{ width: '100%', height: '100%', textAlign: 'center', backgroundColor: ' #00CBDF', borderRadius: '16px', color: '#ffffff', border: '0' }}>登录</button>
                                </div>
                                <div style={{ width: '100%', height: '30px' }}></div>
                            </Modal> */}
                            </div>
                        </div>
                        <Dropdown className={this.state.isShowDl === false ? 'displayNone' : 'displayFlex'} overlay={menu2}>
                            <div className="perison" >
                                <img src={this.state.head} alt="头像" style={{ width: '32px', height: '32px', borderRadius: '16px' }}></img>
                                <div style={{ minWidth: '100px', margin: '0 0 0 11px', color: '#fff', fontWeight: '700' }}>{this.state.username}</div>
                            </div>
                        </Dropdown>
                    </div >
                    <div className="headerbg"></div>
                    <div className={(this.state.scrollIf == true ? "headerft" : 'displaynone')}></div>
                </div>
                <Modal
                    visible={this.state.isbdtel}
                    width={408}
                    height={319}
                    onOk={this.bdtelOk.bind(this)}
                    onCancel={this.bdtelCancel.bind(this)}
                    footer={[
                        <div style={{ height: '60px', width: '100%', display: 'flex', flexDirection: 'row', justifyContent: "center", alignItems: 'center' }} key="ss">
                            <button key="submit" onClick={this.bdtelOk.bind(this)} className="ok-btn cousor" type="primary" >确定绑定</button>
                        </div>
                    ]}
                >
                    <div style={{ width: '100%', height: '79px', color: 'rgba(0,0,0,0.8)', fontWeight: 'bold', fontSize: '16px', textAlign: 'center', lineHeight: '79px', padding: "0", borderBottom: '1px solid rgba(0, 0, 0, 0.1)' }} >绑定手机号</div>
                    <div style={{ width: '320px', height: '48px', marginLeft: '50px', marginTop: '28px', border: '0.5px solid #DCDCDC', borderRadius: '4px' }}>
                        <img alt="手机账号" src={require('../images/iphonenumber.png')} style={{ width: '24px', height: '24px' }} />
                        <input style={{ width: '292px', height: '100%', border: 'none', backgroundColor: '#fff' }} maxLength="11" ref='telbd' minLength='11' onBlur={this.handleTel.bind(this)} placeholder="请输入手机号" type="tel" />
                        <span className={this.state.isTruetel === false ? '' : 'displayNone'} style={{ color: '#ff0000', fontSize: '12px' }}>请输入正确的电话号码</span>
                    </div>
                    <div style={{ width: '320px', height: '48px', marginLeft: '50px', marginTop: '28px', display: 'flex', flexDirection: 'row' }}>
                        <div style={{ width: '272px', height: '48px', border: '0.5px solid #DCDCDC', borderRadius: '4px' }}>
                            <input style={{ width: '162px', height: '100%', border: 'none', backgroundColor: '#fff' }} ref="yzmbd" placeholder="请输入验证码" />
                        </div>
                        <button onClick={this.Yzmbdiphone.bind(this)} className={(this.state.isYzm === true ? '' : 'displayNone') + ' cousor'} style={{ outline: 'none', width: '139px', height: '100%', marginLeft: '10px', borderRadius: '4px', border: '0.5px solid #DCDCDC', textAlign: 'center', lineHeight: '48px', color: '#868686' }}>获取验证码</button>
                        <button className={this.state.isYzm === true ? 'displayNone' : ''} style={{ outline: 'none', width: '139px', height: '100%', marginLeft: '10px', borderRadius: '4px', border: '0.5px solid #DCDCDC', textAlign: 'center', lineHeight: '48px', color: '#868686' }}>{this.state.time}s后重新发送</button>

                    </div>
                    <span style={{ marginLeft: '50px', marginTop: '5px', color: '#ff0000', fontSize: '12px' }}>{this.state.err}</span>
                </Modal>
            </Fragment >
        );
    };
}

export default withRouter(Headernav);