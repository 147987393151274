import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { Input, message, Upload, Select } from 'antd'
import '../Component-css/ReleaseContent.css'
import Headernav from './Headernav'
import api from '../api/api'
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';

const { Option } = Select;

class ReleaseContent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            title: '',
            text: '',
            cate_id: 0,
            img: "",
            loading: false,
            majorList:[]
        }
        this.beforeUpload = this.beforeUpload.bind(this)
        this.handlechange = this.handlechange.bind(this)
    }

    componentDidMount(){
        this.handleSelectList()
    }
    
    /**
     * 行业分类列表
     * @author zxy
     * @memberof ReleaseContent
     */
    handleSelectList(){
        api.post({
            str:'/talk/getTalkCategory'
        })
        .then(res => res.json())
        .then(res => {
            if(res.code == 200){
                this.setState({
                    majorList:res.data
                })
            }else{
                message.error(res.msg)
            }
        })
    }

    /**
     * 行业分类选择
     * @author zxy
     * @param {*} value
     * @memberof ReleaseContent
     */
    handleSelect(value){
        this.setState({
            cate_id:value
        })
    }

    /**
     * 标题
     * @author zxy
     * @param {*} e
     * @memberof ReleaseContent
     */
    titlechange(e) {
        this.setState({
            title: e.target.value
        })
    }

    /**
     * 内容
     * @author zxy
     * @param {*} e
     * @memberof ReleaseContent
     */
    handletextarea(e) {
        this.setState({
            text: e.target.value
        })
    }

    /**
     * 投稿
     * @author zxy
     * @memberof ReleaseContent
     */
    handlepost() {
        var cate_id = this.state.cate_id
        var title = this.state.title
        var content = this.state.text
        var cover = this.state.img
        if (cate_id == 0) {
            message.warning('请选择所属行业')
        } else if (title == '') {
            message.warning('请输入标题')
        } else if (content == '') {
            message.warning('请输入内容')
        } else {
            api.post({
                str: '/talk/releaseArticle'
            }, {
                cate_id,
                title,
                content,
                cover
            })
                .then(res => res.json())
                .then(res => {
                    if (res.code == 200) {
                        message.success(res.msg)
                        this.props.history.push('/Qishuo')
                    } else {
                        message.error(res.msg)
                    }
                })
        }

    }

    /**
     * 图片上传前预处理
     * @description 一般做字节限制和文件类型限制
     * @author zxy
     * @param {*} file
     * @memberof ReleaseContent
     */
    beforeUpload(file) {
        if (!(file.type == 'image/png' || file.type == 'image/jpeg')) {
            message.error('请上传png或jpg格式图片')
        }
        const limit2M = file.size / 1024 / 1024
        if (limit2M >= 2) {
            message.error('图片过大，已超过2M')
        }
    }

    /**
     * 图片改变后处理
     * @author zxy
     * @memberof ReleaseContent
     */
    handlechange(info) {
        console.log(info)
        if (info.file.status == 'uploading') {
            this.setState({ loading: true });
            return true;
        }
        if (info.file.status == 'done') {
            this.setState({
                img: info.file.response.data.url,
                loading: false
            })
        }
    }

    render() {
        const { beforeUpload, handlechange } = this
        const uploadimg = (
            <Fragment>
                {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
            </Fragment>
        )
        return (
            <Fragment>
                <Headernav />
                <div className='qs-release-content'>
                    <div className='qs-release-content-mark-wrap'>
                        <p>其说&gt;投稿</p><span className='qs-release-content-mark'>请投递知识产权相关的合法稿件</span>
                    </div>
                    <div className='qs-release-content-imagepost'>
                        <span>请上传封面</span>
                        <Upload
                            name='file'
                            listType='picture-card'
                            className='qs-release-content-imageupload'
                            showUploadList={false}
                            action="https://zlbb.qzhip.com/index.php/index/base/uploadFile"
                            beforeUpload={beforeUpload}
                            onChange={handlechange}
                        >
                            {this.state.img ? <img src={this.state.img} /> : uploadimg}
                        </Upload>

                    </div>
                    <div className="qs-release-content-title">
                        <Input onChange={this.titlechange.bind(this)} placeholder="请填写标题" className="qs-release-content-title-input" bordered={false} />
                        <span>{this.state.title.length}</span>
                    </div>
                    <div className='qs-release-content-select-wrap'>
                        <Select allowClear placeholder='请选择行业' className='qs-release-content-select' bordered={false} onChange={this.handleSelect.bind(this)}>
                            {
                                this.state.majorList.map((item,index) => {
                                    return <Option key={item.id} value={item.id}>{item.name}</Option>
                                })
                            }
                        </Select>
                    </div>
                    <textarea onChange={this.handletextarea.bind(this)} className='qs-release-content-textarea' placeholder='请填写内容'></textarea>
                    <div onClick={this.handlepost.bind(this)} className='qs-release-content-button'>投稿</div>
                </div>
            </Fragment>
        )
    }
}

export default withRouter(ReleaseContent)