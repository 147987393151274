import React, { Fragment, Component } from 'react';
import Headernav from '../Component/Headernav';
import Sider from '../Component/Sider';
import Content from '../Component/Content'

/**
 *
 *
 * @class Zllb 专利列表
 * @extends {Component} zxy
 */
class Zllb extends Component {
    constructor(props) {
        super(props)

        this.state = {

        }
    }

    render() {
        return (
            <Fragment>
                <Headernav style={{ zIndex:'1'}} />
                <div style={{ width: '100%', height: '100%', backgroundColor: '#F8F8F8',zIndex:'2' }}>
                    <div style={{ width:'100%',height:'10px'}}></div>
                    <div style={{width:'100%',display:"flex",flexDirection:"row"}}>
                        <Content/>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default Zllb