import React, { Fragment, Component } from 'react'
import { withRouter } from 'react-router-dom'
import Headernav from './Headernav'
import '../Component-css/QzxyXq.css'
import Icon from '@ant-design/icons'
import api from '../api/api'
import { message } from 'antd'

const back = () => (
    <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 1024 1024"><path d="M468.394667 18.090667a25.6 25.6 0 0 1 36.181333 0l38.656 38.656a25.6 25.6 0 0 1 0 36.181333l-375.466667 375.552a25.6 25.6 0 0 0 0 36.181333l375.466667 375.466667a25.6 25.6 0 0 1 0 36.266667l-38.656 38.656a25.6 25.6 0 0 1-36.181333 0L18.005333 504.661333a25.6 25.6 0 0 1 0-36.181333L468.48 18.090667z"></path>
    </svg>
)

const Backicon = props => <Icon component={back} {...props} />;

class QzxyXq extends Component {
    constructor(props) {
        super(props)

        this.state = {
            cover_img: '',
            title: '',
            author: '',
            content: '',
            videolist:[],
            videoevaluate:[{head:'',username:'sss',time:'3tianqian',content:'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx'}],
            username:'',
            head:''
        }
    }

    videodetail() {
        var id = this.props.match.params.id
        api.post({
            str: '/college/getCollegeInfo'
        }, {
            id: id
        })
            .then(res => res.json())
            .then(res => {
                if (res.code === 200) {
                    this.setState({
                        title: res.data.title,
                        author: res.data.author,
                        content: res.data.content,
                        cover_img: res.data.cover_img
                    })
                } else {
                    message.error(res.msg)
                }
            })
    }

    componentDidMount() {
        this.videolist()
        this.videodetail()
        this.username()
        // videojs('myVideo', {
        //     playbackRates: [0.5, 1, 1.5, 2]
        //   })
    }

    videolist(){
        api.post({
            str:'/college/getCollegeList'
        },{
            page:1,
            ps:50,
            category_id:8
        })
        .then(res => res.json())
        .then(res => {
            if(res.code == 200){
                this.setState({
                    videolist:res.data.dataList
                })
            }else{
                message.error(res.msg)
            }
        })
    }

    username(){
        api.get({
            str:'/user/getUserInfo'
        })
        .then(res => res.json())
        .then(res => {
            if(res.code === 200){
                this.setState({
                    username:res.data.username,
                    head:res.data.head
                })
            }else{
                message.error(res.msg)
            }
        })
    }

    QzxyXq(id){
        const w = window.open('about:blank');
        w.location.href = `https://www.qzhip.com/#/QzxyXq/${id}`
    }

    backCallList() {
        this.props.history.push('/Qzxy')
    }

    render() {
        return (
            <Fragment>
                <Headernav />
                <div className='ss'>
                    <div style={{ width: '100%', maxHeight: '100%', display: "flex",flexDirection:'row' ,}}>
                        <div className='list-video-title' style={{ zIndex: '22', overflow: "hidden", background: '#fff',width: '828px', maxHeight: '100%', justifyContent: 'center', display: "flex", position: "relative" }}>
                            <div className='video-show-wrap'>
                                <p>{this.state.title}</p>
                                <div className='video-show-information'>
                                    <img src={this.state.cover_img} />
                                    <span>{this.state.author}</span>
                                    <b>发布时间：{this.state.time}</b>
                                    <div>收藏</div>
                                </div>
                                <video
                                    id="myVideo"
                                    // className="video-js vjs-default-skin vjs-big-play-centered"
                                    style={{ width: '100%', height: '448px',outline:'none' }}
                                    controls='controls'
                                    preload="preload"
                                    src={this.state.content}
                                    // playsInline
                                    controlsList='nodownload'
                                // fluid='true'
                                // poster={this.state.cover_img}
                                // data-setup="{}"
                                >
                                    {/* <source style={{zIndex:'1'}} id="source"  type="video/mp4" /> */}
                                </video>
                            </div>
                        </div>
                        <div className='video-list'>
                            <ul>
                                {
                                    this.state.videolist.map((item,index)=>{
                                        return <li onClick={this.QzxyXq.bind(this,item.id)} key={index}>
                                            <img src={item.cover_img} />
                                            <div>
                                                <p>{item.title}</p>
                                                <span><span>{item.author}</span><b>{item.duration}</b></span>
                                            </div>
                                        </li>
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                    {/* <div className='video-evaluate'>
                        <div className='video-evaluate-title'>
                          <p>评论</p>
                          <span>{this.state.evaluatetotal}  条评论</span>
                        </div>
                        <div className='video-evaluate-persion'>
                            <img src={this.state.head} />
                            <p>{this.state.username}</p>
                            <div>发布</div>
                        </div>
                        <textarea className='video-evaluate-textarea'></textarea>
                        <ul>
                            {
                                this.state.videoevaluate.map((item,index)=> {
                                    return <li key={index}>
                                        <div><img src={item.head} /><span><p>{item.username}</p><b>{item.time}</b></span></div>
                                        <div style={{marginTop:'10px',marginBottom:'30px',width:'1120px',marginLeft:'40px'}}>
                                            {item.content}
                                        </div>
                                    </li>
                                })
                            }
                        </ul>
                    </div> */}
                </div>
            </Fragment>
        )
    }
}

export default withRouter(QzxyXq)