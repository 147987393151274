import React, { Component, Fragment } from 'react'
import Fixednav from './Fixednav'
import '../Component-css/Content.css'
import { Checkbox, Row, Col, Modal, Cascader } from 'antd'
import { Link } from 'react-router-dom'
import { Image } from 'antd'
import { Select, message } from 'antd'
import { Pagination } from 'antd'
import Icon from '@ant-design/icons'

import { Tabs } from 'antd'
import { Radio } from 'antd'
import { Table } from 'antd'
import { Space, Spin } from 'antd'
import api from '../api/api.js'
import { getCurrentDate } from '../api/currentDate.js'
import { withRouter } from 'react-router-dom'
import SearchFixed from './SearchFixed'
import Sider from '../Component/Sider'

const psOptions = [10, 20, 50]
const search_arr = []
const search_data = {}
const columns = [
    {
        title: '序号',
        dataIndex: 'id',
        width: 128,
        align: 'center'
    },
    {
        title: '专利号',
        dataIndex: 'id',
        width: 128,
        align: 'center'
        
    },
    {
        title: '专利名称',
        dataIndex: 'applicant',
        width: 168,
        align: 'center'
    },
    {
        title: '专利权人',
        dataIndex: 'inventor',
        width: 78,
        align: 'center'
    },
    {
        title: '专利状态',
        dataIndex: 'type',
        width: 107,
        align: 'center'
    }
]

const optionsFontSize = ['12', '14', '16', '18', '20', '24', '30', '38', '48']
const { TabPane } = Tabs;
const { Option } = Select
// const CheckboxGroup = Checkbox.Group;
const heightShowOptions1 = [
    { value: '关键词一：', class: 'bg1' },
    { value: '关键词二：', class: 'bg2' },
    { value: '关键词三：', class: 'bg3' },
    { value: '关键词四：', class: 'bg4' },
    { value: '关键词五：', class: 'bg5' },
]
// const plainOptions = [
//     { label: '标题', value: '标题' },
//     { label: '类型', value: '类型' },
//     { label: '法律状态', value: '法律状态' },
//     { label: '摘要', value: '摘要' },
//     { label: '摘要附图', value: '摘要附图' },
//     { label: '代理人', value: '代理人' },
//     { label: '申请号', value: '申请号' },
//     { label: '申请日', value: '申请日' },
//     { label: '代理机构', value: '代理机构' },
//     { label: '公开号', value: '公开号' },
//     { label: '公开日', value: '公开日' },
//     { label: '分类号', value: '分类号' },
//     { label: '申请人', value: '申请人' },
//     { label: '申请人地址', value: '申请人地址' },
//     { label: '权利要求数', value: '权利要求数' },
//     { label: '发明人', value: '发明人' },
//     { label: '专利权人', value: '专利权人' },
//     { label: '当前专利权人', value: '当前专利权人' },
//     { label: '优先权', value: '优先权' },
//     { label: '授权日', value: '授权日' }
// ]

const information = () => (
    <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 1024 1024">
        <path d="M1004.608 461.824a72.832 72.832 0 0 1 0 100.352l-306.368 317.696a66.56 66.56 0 0 1-96.704 0l-142.208-147.456S579.2 608.64 623.808 561.024c44.672-47.552 22.528-76.928 14.464-88.384-8-11.456-177.088-183.04-177.088-183.04l140.352-145.408a66.688 66.688 0 0 1 96.704 0l306.368 317.632z" />
        <path d="M515.84 495.488a48.384 48.384 0 0 1 0 68.352l-216.64 216.64a48.448 48.448 0 0 1-68.416 0L14.144 563.84a48.384 48.384 0 0 1 0-68.352l216.64-216.704a48.384 48.384 0 0 1 68.416 0L515.776 495.36v0.128z"  ></path>
    </svg>
);
const sore = () => (
    <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 1024 1024">
        <path d="M325.818182 372.363636h93.090909v623.709091a27.927273 27.927273 0 0 1-27.927273 27.927273h-37.236363a27.927273 27.927273 0 0 1-27.927273-27.927273V372.363636zM698.181818 651.636364H605.090909V27.927273a27.927273 27.927273 0 0 1 27.927273-27.927273h37.236363a27.927273 27.927273 0 0 1 27.927273 27.927273V651.636364zM368.64 67.025455A27.927273 27.927273 0 0 1 418.909091 83.781818V372.363636H195.490909a27.927273 27.927273 0 0 1-22.341818-44.683636l195.490909-260.654545zM655.36 956.974545A27.927273 27.927273 0 0 1 605.090909 940.218182V651.636364h223.418182a27.927273 27.927273 0 0 1 22.341818 44.683636l-195.490909 260.654545z" />
    </svg>
);
// const soretop = () => (
//     <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 1024 1024">
//         <path d="M558.545455 372.363636h93.090909v623.709091a27.927273 27.927273 0 0 1-27.927273 27.927273h-37.236364a27.927273 27.927273 0 0 1-27.927272-27.927273V372.363636zM601.367273 67.025455A27.927273 27.927273 0 0 1 651.636364 83.781818V372.363636H428.218182a27.927273 27.927273 0 0 1-22.341818-44.683636l195.490909-260.654545z" />
//     </svg>
// );
// const soredown = () => (
//     <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 1024 1024">
//         <path d="M465.454545 651.636364h-93.090909V27.927273A27.927273 27.927273 0 0 1 400.290909 0h37.236364a27.927273 27.927273 0 0 1 27.927272 27.927273V651.636364zM422.632727 956.974545a27.927273 27.927273 0 0 1-50.269091-16.756363V651.636364h223.418182a27.927273 27.927273 0 0 1 22.341818 44.683636l-195.490909 260.654545z" />
//     </svg>
// );
const requireBook = () => (
    <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 1024 1024">
        <path d="M167.935895 182.016526v-59.903962a28.159982 28.159982 0 0 1 28.159982-28.159983H895.99944A102.399936 102.399936 0 0 0 793.599504 0.00064H230.399856A102.399936 102.399936 0 0 0 127.99992 102.400576v819.199488A102.399936 102.399936 0 0 0 219.391863 1024V210.176509h-23.295986a28.415982 28.415982 0 0 1-28.159982-28.159983z" />
        <path d="M895.99944 860.416102l-230.399856-193.023879-46.591971 55.039965-143.35991-120.319924 146.687908-173.055892 143.35991 120.575924-44.607972 51.199968L895.99944 748.800172V211.200508H313.343804V1024h480.1917a102.399936 102.399936 0 0 0 102.399936-102.399936v-61.183962z m-255.99984-488.447694l20.223987-23.807986a30.975981 30.975981 0 0 1 43.711973-3.839997l140.799912 120.575924a31.103981 31.103981 0 0 1 3.583998 44.031973l-19.967988 23.807985a31.48798 31.48798 0 0 1-44.031972 3.839998L640.959599 416.00038a31.48798 31.48798 0 0 1-2.751998-44.031972h1.727999z m-236.031852 281.599824l19.967987-24.063985a31.48798 31.48798 0 0 1 44.031973-3.839998l143.35991 120.575925a31.48798 31.48798 0 0 1 3.071998 43.263973l-20.223987 24.063985a30.911981 30.911981 0 0 1-43.775973 3.583997l-143.35991-120.575924a30.911981 30.911981 0 0 1-4.863997-43.775973l1.791999 0.768z m316.607802 252.671842a27.839983 27.839983 0 0 1-29.439982 27.391982H374.847766a28.031982 28.031982 0 0 1-28.159983-28.159982V896.00008a28.415982 28.415982 0 0 1 28.159983-28.159982H691.199568a28.159982 28.159982 0 0 1 27.583983 28.159982l1.791999 10.239994z"  ></path>
    </svg>
);
const instructions = () => (
    <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 1024 1024">
        <path d="M559.36 37.952v379.2l127.68-94.72 109.44 94.72V40.32a170.688 170.688 0 0 1 142.144 168.32v606.784A170.624 170.624 0 0 1 768 986.048H256a170.688 170.688 0 0 1-170.688-170.688V208.64A170.688 170.688 0 0 1 256 37.888h303.36z" />
    </svg>
);
const drawings = () => (
    <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 1024 1024">
        <path d="M696.384 359.04l276.224 506.368a64 64 0 0 1-56.192 94.72H363.968a63.936 63.936 0 0 1-56.192-94.72L584 359.04a64 64 0 0 1 112.384 0z" />
        <path d="M341.888 595.712l170.88 265.792a64 64 0 0 1-53.888 98.56H117.312a64 64 0 0 1-53.952-98.56l170.944-265.856a64 64 0 0 1 107.648 0v0.064z"  ></path>
        <path d="M403.008 690.688l109.824 170.88a64 64 0 0 1-53.952 98.56H363.968a64 64 0 0 1-56.192-94.72l95.232-174.72zM128.064 191.936a128 128 0 1 0 256 0 128 128 0 0 0-256 0z"  ></path>
    </svg>
);
const PDF = () => (
    <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 1024 1024">
        <path d="M860.16 0H163.84C54.592 0 0 54.592 0 163.84v696.32C0 969.408 54.592 1024 163.84 1024h696.32c109.248 0 163.84-54.592 163.84-163.84V163.84C1024 54.592 969.408 0 860.16 0z m-186.56 559.36c28.992 0.32 48.064 4.736 56.704 12.928a21.504 21.504 0 0 1 6.4 10.624c1.472 11.648 0.32 22.976-5.312 31.232a22.464 22.464 0 0 1-13.12 8.64 53.248 53.248 0 0 1-13.184 1.472c-28.928 0-74.88-14.208-112.192-34.688-52.16 7.808-103.68 19.712-153.984 35.584-42.496 72.256-73.344 104.448-99.968 104.448a32.768 32.768 0 0 1-14.4-3.392l-10.56-5.12c-6.656-3.712-7.616-11.712-6.272-17.92 4.032-19.584 28.8-44.16 66.112-65.92 3.84-2.24 18.624-9.856 22.848-12.032 0.64-0.32 1.024-0.512 1.088-0.64l10.944-5.568c12.352-20.672 24.064-41.728 35.2-63.04l1.6-3.008c12.224-23.36 26.112-49.856 37.248-76.16l3.904-9.344c-15.424-57.536-24-101.952-15.68-131.648C473.6 326.336 484.032 320 496.768 320h0.192l6.72 0.256c11.392-0.512 18.688 8.32 22.4 16.64l1.152 2.944a29.504 29.504 0 0 1 1.792 7.296C532.608 372.608 518.976 448 518.976 448l-2.176 8.704c12.608 33.6 32.768 64.192 57.024 86.208 8.96 8.192 20.48 14.976 31.616 21.184 24.832-3.328 48.448-5.056 68.16-4.8z m-288 89.6c-28.416 22.592-47.616 48.128-52.48 60.992 13.056-5.76 32.96-24.064 62.656-70.144-4.032 3.712-8.448 7.68-10.24 9.152z m129.408-275.008c-0.384-19.648-5.248-40.576-13.76-40.576-3.968 0-3.584 29.76-3.584 29.76 0 15.552 2.304 39.36 7.168 54.464a81.28 81.28 0 0 0 10.24-43.648z m-45.44 194.88c-6.336 11.584-12.544 22.528-18.496 32.896 32.704-12.16 69.824-22.272 112.832-30.528a228.736 228.736 0 0 1-61.888-71.232 623.488 623.488 0 0 1-32.448 68.864z m237.44 33.088a39.552 39.552 0 0 0 14.976-2.56c2.624-1.6 2.432-9.216-12.8-12.992-15.04-3.776-19.84-3.776-34.816-3.776h-0.704a403.2 403.2 0 0 0-28.416 1.152c27.008 11.264 46.272 18.176 61.824 18.176z" />
    </svg>
);
const legal = () => (
    <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 1024 1024">
        <path d="M180.177455 867.793455c-22.295273 0-40.541091-16.011636-40.541091-35.607273 0-19.549091 18.245818-35.560727 40.541091-35.560727h630.458181c22.341818 0 40.587636 16.011636 40.587637 35.560727 0 19.549091-18.245818 35.607273-40.587637 35.607273H180.177455zM175.243636 760.552727v-88.064a84.992 84.992 0 0 1 84.898909-84.852363h109.149091a58.321455 58.321455 0 0 0 51.572364-85.504 57.018182 57.018182 0 0 0-18.944-20.805819A175.383273 175.383273 0 0 1 322.094545 302.545455a175.476364 175.476364 0 0 1 134.609455-141.963637 176.593455 176.593455 0 0 1 198.004364 95.185455c11.496727 23.831273 17.408 49.989818 17.268363 76.427636 0 52.829091-23.319273 102.446545-64.465454 136.238546a24.389818 24.389818 0 0 0-1.954909 1.396363c-3.723636 2.699636-11.403636 8.378182-16.011637 11.264a56.925091 56.925091 0 0 0-25.460363 48.407273 58.181818 58.181818 0 0 0 58.181818 57.902545h107.054545c23.086545 0 44.823273 9.076364 61.207273 25.460364 16.384 16.523636 25.274182 38.260364 25.134545 61.346909v86.248727H175.197091v0.046546z" />
        <path d="M665.320727 697.018182a35.607273 35.607273 0 1 0 71.214546 0 35.607273 35.607273 0 0 0-71.214546 0z"  ></path>
    </svg>
);
const same = () => (
    <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 1024 1024">
        <path d="M81.0496 914.2784c-22.528 0-37.1712-7.3216-37.1712-21.248 0-13.9776 14.6432-21.6064 37.1712-21.6064H956.928c21.9648 0 37.1712 7.3216 37.1712 21.6064 0 14.2336-14.6432 21.504-37.1712 21.504l-875.8784-0.256zM117.0432 768v-182.8352h365.7216V768H117.0432z m73.1648-219.4432V365.7216h292.5568v182.8352H190.208zM336.4864 329.216V146.2784h146.2784V329.216H336.4864z" />
        <path d="M555.52 585.1648h365.7216V768H555.52v-182.8352z m0-219.4432h292.5568v182.8352H555.52V365.7216z m0-219.4432h146.2784V329.216H555.52V146.2784z"  ></path>
    </svg>
);

const downloadbutton = () => (
    <svg fill="currentColor" width="1.5em" height="1em" viewBox="0 0 1024 1024">
        <path d="M923.53181 234.301098c74.566776 0 146.061739 28.911189 198.764427 80.349014A271.102633 271.102633 0 0 1 1204.632896 508.596009a271.102633 271.102633 0 0 1-82.336659 194.066359 284.594522 284.594522 0 0 1-198.764427 80.349014h-40.114276a40.65636 40.65636 0 0 1-28.429336-11.444012 38.728948 38.728948 0 0 1 0-55.413113 40.65636 40.65636 0 0 1 28.429336-11.564476h40.114276c53.244774 0 104.321209-20.599223 141.965986-57.400758 37.644778-36.741303 58.846317-86.552874 58.846317-138.532783 0-51.979909-21.201539-101.79148-58.846317-138.593014a203.281801 203.281801 0 0 0-176.90034-54.208481 41.077982 41.077982 0 0 1-31.440919-6.685712 39.572191 39.572191 0 0 1-16.322776-27.10424 233.698782 233.698782 0 0 0-80.650172-144.435485A244.420015 244.420015 0 0 0 602.316448 80.108088c-58.003074 0-114.078735 20.418528-157.867141 57.52122A233.698782 233.698782 0 0 0 363.799135 282.064793a38.548253 38.548253 0 0 1-16.322776 27.10424 40.65636 40.65636 0 0 1-31.440919 6.625481 203.281801 203.281801 0 0 0-176.900341 54.20848 193.644738 193.644738 0 0 0-58.846316 138.653246c0 51.979909 21.201539 101.79148 58.846316 138.532783 37.644778 36.741303 88.721213 57.460989 141.965987 57.460989h40.114276c10.661001 0 20.900381 4.095752 28.429336 11.444013a38.728948 38.728948 0 0 1 0 55.413113 40.65636 40.65636 0 0 1-28.429336 11.504244h-40.114276a284.594522 284.594522 0 0 1-198.764428-80.349014A271.042402 271.042402 0 0 1 0 508.65624a271.042402 271.042402 0 0 1 82.336658-194.006128 284.594522 284.594522 0 0 1 198.764428-80.349014h10.420075a313.204553 313.204553 0 0 1 114.440125-168.889532A326.335051 326.335051 0 0 1 602.316448 0c71.073341 0 140.098806 23.008488 196.355162 65.411566a313.204553 313.204553 0 0 1 114.440125 168.889532h10.420075z" />
        <path d="M542.084803 525.641564c0-11.624707 6.324323-22.767562 17.647872-30.959065C570.995993 486.490995 586.355062 481.853158 602.316448 481.853158c15.961386 0 31.320455 4.637837 42.583773 12.829341 11.323549 8.191504 17.647872 19.334358 17.647872 30.959065v394.276347c0 11.624707-6.324323 22.767562-17.647872 30.959065A73.181448 73.181448 0 0 1 602.316448 963.706317c-15.961386 0-31.320455-4.637837-42.583773-12.829341-11.323549-8.191504-17.647872-19.334358-17.647872-30.959065V525.641564z" ></path>
        <path d="M713.865454 797.888599a40.234739 40.234739 0 0 1 69.025465 24.273352 39.813117 39.813117 0 0 1-8.79382 28.489568l-140.520427 159.794554a40.234739 40.234739 0 0 1-60.231645 0l-140.520428-159.794554a39.873349 39.873349 0 0 1 32.404625-67.459442 40.355202 40.355202 0 0 1 27.82702 14.696522l110.404605 125.462516 110.404605-125.462516z" ></path>
    </svg>
);

const monitor = () => (
    <svg id="Frame_2" data-name="Frame2" xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="1em" height="1em" viewBox="0 0 1024 1024">
        <path d="M680.32 481.43c0-92.75-75.47-168.22-168.22-168.22s-168.22 75.47-168.22 168.22c0 44.95 17.49 87.2 49.27 118.95 31.77 31.78 74.01 49.27 118.94 49.27 92.76 0.01 168.23-75.46 168.23-168.22z m-271.96 0c0-57.21 46.53-103.74 103.74-103.74s103.74 46.53 103.74 103.74-46.53 103.75-103.74 103.75c-27.71 0-53.76-10.79-73.36-30.39-19.59-19.58-30.38-45.63-30.38-73.36z" />
        <path d="M763.02 667.49c65.28-66.35 100.77-154.14 99.93-246.93v-5.37C861.39 221.73 702.41 65.27 509.28 67.14c-193.4 1.55-349.51 160.15-348.04 353.54v5.87c1.38 154.14 103.51 284.32 243.02 328.61l-51.45 137.48H252.48c-17.81 0-32.24 14.43-32.24 32.24s14.43 32.24 32.24 32.24h122.63c0.02 0 0.04 0.01 0.06 0.01 0.02 0 0.05-0.01 0.07-0.01h273.71c0.02 0 0.05 0.01 0.07 0.01 0.02 0 0.04-0.01 0.06-0.01h122.64c17.81 0 32.24-14.43 32.24-32.24s-14.43-32.24-32.24-32.24H671.38L620 755.31c53.55-16.88 102.52-46.66 143.02-87.82z m-537.3-241.24v-5.96c-1.26-157.9 126.17-287.4 284.09-288.67 0.78-0.01 1.54-0.01 2.32-0.01 156.84 0 285.09 126.96 286.35 283.83v5.4c0.68 75.83-28.23 147.36-81.42 201.42-53.18 54.05-124.23 84.12-199.84 84.67h-5.92c-0.69 0.01-1.39 0.01-2.08 0.01-155.12 0.01-282.11-125.61-283.5-280.69z m376.82 466.39H421.66l46.35-123.86c13.47 1.6 27.1 2.65 40.98 2.65 0.84 0 1.71 0 2.55-0.01h5.92c13.07-0.09 25.98-1.08 38.77-2.59l46.31 123.81z" ></path>
        <path d="M512.1 275.48c27.65 0 50.07-22.42 50.07-50.07s-22.42-50.07-50.07-50.07a56.94 56.94 0 0 0-25.03 6.83 50.655 50.655 0 0 0-25.04 43.24c0 27.65 22.42 50.07 50.07 50.07z" ></path>
    </svg>
);

const pay = () => (
    <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 1024 1024">
        <path d="M503.594667 478.805333a31.445333 31.445333 0 0 1-22.272-9.216L394.922667 383.146667A31.530667 31.530667 0 1 1 439.466667 338.56l86.4 86.4a31.530667 31.530667 0 0 1-22.314667 53.845333z" />
        <path d="M522.282667 478.805333a31.530667 31.530667 0 0 1-22.272-53.845333l86.357333-86.4a31.573333 31.573333 0 1 1 44.586667 44.586667l-86.357334 86.4a31.402667 31.402667 0 0 1-22.314666 9.258666z" ></path>
        <path d="M625.578667 519.68h-225.28a31.530667 31.530667 0 1 1 0-63.061333h225.28a31.530667 31.530667 0 0 1 0 63.061333z m0 105.130667h-225.28a31.530667 31.530667 0 1 1 0-63.061334h225.28a31.530667 31.530667 0 1 1 0 63.061334z" ></path>
        <path d="M512.938667 712.362667a31.530667 31.530667 0 0 1-31.530667-31.573334v-189.653333a31.530667 31.530667 0 1 1 63.061333 0v189.696c0 17.408-14.08 31.530667-31.530666 31.530667z"  ></path>
        <path d="M512.853333 846.890667a335.402667 335.402667 0 0 1-134.528-28.288c-169.984-74.24-247.893333-272.896-173.653333-442.88a334.08 334.08 0 0 1 185.472-178.517334 334.08 334.08 0 0 1 257.365333 4.906667 31.530667 31.530667 0 1 1-25.216 57.770667 271.488 271.488 0 0 0-209.109333-3.968 271.488 271.488 0 0 0-150.698667 145.066666 273.621333 273.621333 0 0 0 141.098667 359.808 271.573333 271.573333 0 0 0 209.066667 3.968 271.488 271.488 0 0 0 150.698666-145.066666 274.261333 274.261333 0 0 0-46.08-290.986667 31.573333 31.573333 0 0 1 47.146667-41.813333 337.493333 337.493333 0 0 1 56.746667 358.058666 334.08 334.08 0 0 1-185.472 178.517334 336.128 336.128 0 0 1-122.88 23.424z"  ></path>
    </svg>
);

const remind = () => (
    <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 1024 1024">
        <path d="M513.578667 197.973333a79.744 79.744 0 0 0-52.650667 28.629334L320.213333 365.866667H257.109333c-19.328 0-37.930667 7.722667-51.669333 21.418666-13.610667 13.653333-21.333333 32.341333-21.333333 51.669334v146.645333a73.258667 73.258667 0 0 0 73.130666 73.130667h63.018667l139.392 139.264c13.525333 16.213333 32.938667 26.368 53.973333 28.16 17.749333 0 38.101333-11.776 38.101334-45.44V243.882667c0-34.090667-20.437333-45.397333-37.717334-45.397334l-0.426666-0.512z m-256.426667 411.648a24.661333 24.661333 0 0 1-24.618667-24.576v-146.133333a24.661333 24.661333 0 0 1 24.576-24.618667h74.88a24.234667 24.234667 0 0 0 17.194667-7.253333l146.56-146.56 7.253333-6.314667v515.669334l-7.253333-6.314667-147.84-146.688a24.746667 24.746667 0 0 0-15.914667-7.253333H257.109333z m377.984-264.106666a24.490667 24.490667 0 0 0-19.968 38.528l9.6 13.141333A177.92 177.92 0 0 1 670.165333 512a161.706667 161.706667 0 0 1-45.397333 110.762667l-12.757333 17.194666a24.576 24.576 0 0 0 6.826666 32.085334 24.405333 24.405333 0 0 0 32.597334-3.413334l12.245333-16.384A207.872 207.872 0 0 0 720.896 512a226.816 226.816 0 0 0-56.746667-143.36l-9.6-12.8a24.490667 24.490667 0 0 0-19.968-9.941333l0.554667-0.426667z m83.029333-83.114667a24.618667 24.618667 0 0 0-21.888 14.421333 24.32 24.32 0 0 0 3.669334 25.898667 310.912 310.912 0 0 1 93.909333 209.194667 310.357333 310.357333 0 0 1-94.378667 208.768 24.533333 24.533333 0 0 0 36.693334 32.256 359.552 359.552 0 0 0 106.666666-241.024 359.509333 359.509333 0 0 0-106.197333-240.981334 24.448 24.448 0 0 0-18.176-8.106666l-0.298667-0.426667z" />
    </svg>
);

const collection = () => (
    <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 1024 1024">
        <path d="M777.728 1023.488c-12.224 0-24.512-3.2-37.824-10.688l-206.592-113.088a39.04 39.04 0 0 0-20.48-5.312 58.432 58.432 0 0 0-20.48 4.288L286.72 1012.8c-11.712 7.232-25.152 10.88-38.848 10.688a85.632 85.632 0 0 1-82.88-101.376l39.936-238.912a49.152 49.152 0 0 0-13.312-40.512L25.856 475.2a87.808 87.808 0 0 1-21.44-89.6c10.24-32 35.84-54.4 67.52-59.776l229.12-35.2a45.696 45.696 0 0 0 33.792-24.512L437.12 48.512a82.56 82.56 0 0 1 74.688-48c31.68 0 60.352 18.112 74.688 48l102.272 217.6a44.8 44.8 0 0 0 33.728 24.512l229.12 35.2c31.808 5.376 57.344 27.776 67.584 58.688a89.536 89.536 0 0 1-20.48 89.6l-164.736 168.576a46.08 46.08 0 0 0-13.312 40.512l38.912 237.888c4.16 25.6-1.984 51.2-18.368 70.4-15.36 20.224-38.912 32-63.488 32z m-13.248-65.088c5.12 2.112 9.152 3.2 12.224 3.2 7.232 0 14.4-3.2 19.52-9.6 5.12-6.4 6.08-14.912 5.12-20.288l-38.976-237.824c-6.144-34.176 5.12-71.488 29.696-94.976l164.736-169.6c6.144-6.4 8.192-16 6.144-26.688a23.36 23.36 0 0 0-20.48-17.024l-229.12-35.2a102.016 102.016 0 0 1-77.76-58.688L533.312 75.2a22.144 22.144 0 0 0-21.504-13.888c-9.216 0-17.408 5.312-22.528 14.912L387.008 291.712a104.384 104.384 0 0 1-77.76 58.688l-229.12 35.2a26.88 26.88 0 0 0-19.456 17.024v1.088a24.064 24.064 0 0 0 6.144 25.6l165.76 169.6c24.512 24.576 34.752 59.776 29.632 94.976l-38.912 237.824a20.864 20.864 0 0 0 5.12 19.2c5.12 6.4 12.288 9.6 19.456 9.6a30.08 30.08 0 0 0 11.264-2.112l206.656-113.088c13.248-8.512 29.632-12.8 47.04-12.8 15.36 0 31.68 4.288 47.04 12.8l204.608 113.088z" />
    </svg>
);

const collectionfb = () => (
    <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 1024 1024">
        <path d="M762.096941 993.400471c-11.504941 0-23.070118-3.011765-35.599059-10.059295l-194.439529-106.435764a36.743529 36.743529 0 0 0-19.275294-4.99953 54.994824 54.994824 0 0 0-19.275294 4.035765L299.971765 983.341176c-11.023059 6.806588-23.672471 10.24-36.562824 10.059295a80.594824 80.594824 0 0 1-78.004706-95.412706l37.586824-224.858353a46.260706 46.260706 0 0 0-12.528941-38.128941L54.452706 477.364706a82.642824 82.642824 0 0 1-20.178824-84.329412c9.637647-30.117647 33.731765-51.2 63.548236-56.259765l215.642353-33.129411a43.008 43.008 0 0 0 31.804235-23.070118l96.256-204.8a77.703529 77.703529 0 0 1 70.294588-45.176471c29.816471 0 56.801882 17.046588 70.294588 45.176471l96.256 204.8a42.164706 42.164706 0 0 0 31.744 23.070118l215.642353 33.129411c29.936941 5.059765 53.970824 26.142118 63.608471 55.235765a84.269176 84.269176 0 0 1-19.275294 84.329412l-155.045647 158.659765a43.369412 43.369412 0 0 0-12.528941 38.128941l36.623058 223.894588c3.915294 24.094118-1.867294 48.188235-17.287529 66.258824-14.456471 19.034353-36.623059 30.117647-59.753412 30.117647z" />
    </svg>
);

const dataArray = [
    {
        value: 'applicationDate',
        label: '申请日',
        children: [
            {
                value: '1',
                label: '申请日(升序)',
                icon: '<Soretop/>'
            }, {
                value: '2',
                label: '申请日(降序)',
                icon: '<Soredown/>'
            }
        ]
    }, {
        value: 'documentDate',
        label: '授权日',
        children: [
            {
                value: '1',
                label: '授权日(升序)',
            }, {
                value: '2',
                label: '授权日(降序)',
            }
        ]
    }
];

const InformationConfig = props => <Icon component={information} {...props} />;
const RequireBook = props => <Icon component={requireBook} {...props} />;
const Instructions = props => <Icon component={instructions} {...props} />;
const Drawings = props => <Icon component={drawings} {...props} />;
const Pbook = props => <Icon component={PDF} {...props} />;
const Legal = props => <Icon component={legal} {...props} />;
const Same = props => <Icon component={same} {...props} />;
const Sore = props => <Icon component={sore} {...props} />
const Download = props => <Icon component={downloadbutton} {...props} />
const Monitor = props => <Icon component={monitor} {...props} />
const Pay = props => <Icon component={pay} {...props} />
const Remind = props => <Icon component={remind} {...props} />
const Collection = props => <Icon component={collection} {...props} />
const Collectionfb = props => <Icon component={collectionfb} {...props} />
// const Soretop = props => <Icon component ={soretop} {...props} />
// const Soredown = props => <Icon component ={soredown} {...props} />

class Content extends Component {
    constructor(props) {
        super(props)

        this.state = {
            indeterminate: true,
            checkAll: false,
            isSelect: false,
            checkedValues: [],
            selectList: [],
            visibleText: false,
            link: "http://baidu.com",
            heightShow: false,
            ZldcShow: false,
            value4: 12,
            Zlxq: '/Zlxq',
            id: "",
            total: 0,
            search: '',
            displayMode: 0,
            zlmc: '',
            zllx: '',
            zlyxx: '',
            zlsqh: '',
            zlsqr: [],
            zlsqrq: '',
            zlwyid: '',
            zlgkr: '',
            zlfmr: [],
            zlzflh: '',
            zldqr: '',
            sqrdz: '',
            dlre: '',
            dljg: '',
            zy: '',
            qlyqs: [],
            qls: '',
            zlsms: [],
            zlflxx: [],
            zltp: [],
            flzt_code: 0,
            zlqr: '',
            zlipcfl: '',
            pdf_url: '',
            sameData: [],
            loadingCompent: false,
            page: 1,
            ps: 10,
            type: 1,
            selectValue: '',
            DcValue: 1,
            fileName: '',
            DcValueStart: 0,
            DcValueEnd: 0,
            listGX: [],
            showPlxz: false,
            xzgsValue: '',
            showHistory: false,
            historyList: [],
            isCollection: false,
            _zlbh: '',
            _gkh: '',
            _zlmc: '',
            _sqr: '',
            _fmr: '',
            _dljg: '',
            _zllx: '',
            _zlyxx: '',
            _region: '',
            _sqkstime: '',
            _sqjstime: '',
            _gkkstime: '',
            _gkjstime: ''
        }
        this.handleChangeOption = this.handleChangeOption.bind(this)
        this.onPageChange = this.onPageChange.bind(this)
        this.handleTextShow = this.handleTextShow.bind(this)
        this.handleTextOk = this.handleTextOk.bind(this)
        this.handelTextCancel = this.handelTextCancel.bind(this)
        this.handleheightShow = this.handleheightShow.bind(this)
        this.handleheightShowOk = this.handleheightShowOk.bind(this)
        this.handelheightShowCancel = this.handelheightShowCancel.bind(this)
        this.callback = this.callback.bind(this)
        this.onChangeFontSize = this.onChangeFontSize.bind(this)
        this.handleChangeOptionDate = this.handleChangeOptionDate.bind(this)
        this.handleClickSjfx = this.handleClickSjfx.bind(this)
        this.handleZldc = this.handleZldc.bind(this)
        this.handleZldcOk = this.handleZldcOk.bind(this)
        this.handleZldcCancel = this.handleZldcCancel.bind(this)
        this.onDcChange = this.onDcChange.bind(this)
        this.onXzChange = this.onXzChange.bind(this)
        this.onChangeWord = this.onChangeWord.bind(this)
        this.inputvalue = this.inputvalue.bind(this)
        this.onDcChangeStart = this.onDcChangeStart.bind(this)
        this.onDcChangeEnd = this.onDcChangeEnd.bind(this)
        this.handlePlxz = this.handlePlxz.bind(this)
        this.handlePlxzCancel = this.handlePlxzCancel.bind(this)
        this.handleHistory = this.handleHistory.bind(this)
        this.handleHistoryCancel = this.handleHistoryCancel.bind(this)
        this.handlePlxzOk = this.handlePlxzOk.bind(this)
        this.pay = this.pay.bind(this)
    }

    /**
     * 专利详情跳转
     * @author zxy
     * @param {*} zlwyid
     * @memberof Content
     */
    toZlxq(zlwyid) {
        var search = this.state.search
        var ss = search.replace('/', '_')
        this.props.history.push(`/Zlxq/${ss}/${zlwyid}`)
    }

    /**
     * 收藏
     * @author zxy
     * @param {*} is_favorite
     * @param {*} value
     * @memberof Content
     */
    collection(is_favorite, value) {
        var zlwyid = value
        var favorite = is_favorite
        if (favorite === '1') {
            api.post({
                str: '/user/addFavorite'
            }, {
                zlwyid: zlwyid
            })
                .then(res => res.json())
                .then(res => {
                    if (res.code === 200) {
                        message.success(res.msg)
                        this.componentDidMount()
                    } else if (res.code === 4000) {
                        message.error(res.msg)
                    } else if (res.code === 4001) {
                        message.error(res.msg)
                    }
                })
        } else {
            api.post({
                str: '/user/delFavoriteOne'
            }, {
                id: zlwyid
            })
                .then(res => res.json())
                .then(res => {
                    if (res.code === 200) {
                        message.success(res.msg)
                        this.componentDidMount()
                    } else if (res.code === 4000) {
                        message.error(res.msg)
                    } else if (res.code === 4001) {
                        message.error(res.msg)
                    } else {
                        message.error(res.msg)
                    }
                })
        }
    }

    /**
     * 专利下载
     * @author zxy
     * @param {*} value
     * @memberof Content
     */
    download(value) {
        var data = {
            'id': value
        }
        api.post({
            str: '/retrieve/checkLogin'
        })
            .then(res => res.json())
            .then(res => {
                if (res.code === 200) {
                    api.onexz({
                        str: '/retrieve/exportPatent'
                    }, data)
                } else if (res.code === 4000) {
                    message.error(res.msg)
                } else if (res.code === 4001) {
                    message.error(res.msg)
                } else {
                    message.error(res.msg)
                }
            })

    }

    /**
     * 提醒
     * @author zxy
     * @param {*} zlbh
     * @param {*} flzt_code
     * @memberof Content
     */
    remind(zlbh, flzt_code) {
        if (flzt_code === '1') {
            api.post({
                str: '/monitor/addPatentMonitor'
            }, {
                zlbh: zlbh
            })
                .then(res => res.json())
                .then(res => {
                    if (res.code === 200) {
                        message.success(res.msg)
                    } else {
                        message.error(res.msg)
                    }
                })
        } else {
            message.error('这不是一个有效专利')
        }
    }

    /**
     * 监控
     * @author zxy
     * @param {*} zlbh
     * @param {*} flzt_code
     * @memberof Content
     */
    monitor(zlbh, flzt_code) {
        api.post({
            str: '/monitor/addPatentMonitor'
        }, {
            zlbh: zlbh
        })
            .then(res => res.json())
            .then(res => {
                if (res.code === 200) {
                    message.success(res.msg)
                } else if (res.code === 3001) {
                    this.props.history.push('/RemindSet')
                } else {
                    message.error(res.msg)
                }
            })
    }

    /**
     * 支付
     * @author zxy
     * @param {*} zlbh
     * @memberof Content
     */
    pay(zlbh) {
        var arr = []
        arr.push(zlbh)
        var ids = JSON.stringify(arr)
        this.props.history.push(`/PayConfirm/${ids}`)
    }

    /**
     * 检索历史
     * @author zxy
     * @param {*} value
     * @memberof Content
     */
    historySearch(value) {
        this.props.history.push(`/Zllb/${value}/${1}`)
    }

    /**
     * 删除历史
     * @author zxy
     * @description 删除历史后对historySearch进行处理，并重新存入本地缓存，替换原来的文档
     * @param {*} index
     * @memberof Content
     */
    delectHistory(index) {
        var list = this.state.historyList
        list.splice(index, 1)
        this.setState({
            historyList: list
        })
        var search = JSON.stringify(list)
        localStorage.setItem('search', search)
    }

    /**
     * 打开历史检索
     * @author zxy
     * @memberof Content
     */
    handleHistory() {
        this.setState({
            showHistory: true
        })
    }

    /**
     * 关闭历史检索
     * @author zxy
     * @memberof Content
     */
    handleHistoryCancel() {
        this.setState({
            showHistory: false
        })
    }

    /**
     * 专利导出名称
     * @author zxy
     * @param {*} e
     * @memberof Content
     */
    inputvalue = (e) => {
        this.setState({
            fileName: this.refs.input.value.trim()
        })
    }

    /**
     * 专利导出选择字段
     * @author zxy
     * @param {*} e
     * @memberof Content
     */
    onChangeWord = (e) => {
        var selectValue = JSON.stringify(e)
        this.setState({
            selectValue: selectValue
        })
    }

    /**
     * 专利导出方式选择
     * @author zxy
     * @param {*} e
     * @memberof Content
     */
    onDcChange = (e) => {
        this.setState({
            DcValue: e.target.value,
        });
    }

    /**
     * 专利导出字段输入初始
     * @author zxy
     * @param {*} e
     * @memberof Content
     */
    onDcChangeStart = (e) => {
        this.setState({
            DcValueStart: this.refs.inputStart.value.trim()
        })
    }

    /**
     * 专利导出字段输入结束
     * @author zxy
     * @param {*} e
     * @memberof Content
     */
    onDcChangeEnd = (e) => {
        this.setState({
            DcValueEnd: this.refs.inputEnd.value.trim()
        })
    }

    /**
     * 打开批量下载
     * @author zxy
     * @param {*} e
     * @memberof Content
     */
    onXzChange(e) {
        this.setState({
            xzgsValue: e.target.value
        })
    }

    /**
     * 申请日期查询
     * @author zxy
     * @param {*} value
     * @memberof Content
     */
    sqrqSearch(value) {
        this.props.history.push(`/Zllb/sqrq:${value}/${2}`)
    }

    /**
     * 公开日期查询
     * @author zxy
     * @param {*} value
     * @memberof Content
     */
    gkrqSearch(value) {
        this.props.history.push(`/Zllb/gkrq:${value}/${2}`)
    }

    /**
     * 主分类号查询
     * @author zxy
     * @param {*} value
     * @memberof Content
     */
    zflhSearch(value) {
        var ss = value.replace('/', '_')
        this.props.history.push(`/Zllb/zflh:${ss}/${2}`)
    }

    /**
     * 申请日查询
     * @author zxy
     * @param {*} item
     * @memberof Content
     */
    sqrSearch(item) {
        this.props.history.push(`/Zllb/sqr:${item}/${2}`)
    }

    /**
     * 专利名称查询
     * @author zxy
     * @param {*} item
     * @memberof Content
     */
    nameSearch(item) {
        this.props.history.push(`/Zllb/fmr:${item}/${2}`)
    }

    shouldComponentUpdate(nextprops, nextstate) {
        if (nextprops !== this.props) {
            window.location.reload();
            var str = this.props.match.params.search
            var arr = str.split(':')
            var type = this.props.match.params.type
            api.post({
                str: '/retrieve/patentRetrieval'
            }, {
                type: type,
                sqr: arr[1]
            })
                .then(res => res.json())
                .then(res => {
                    if (res.code === 200) {
                        if (res.data.dataList.length > 0) {
                            this.setState({
                                selectList: res.data.dataList,
                                total: res.data.total,
                                zlwyid: res.data.dataList[0].zlwyid,
                                loadingCompent: true
                            })
                        } else if (res.code === 4000) {
                            message.error(res.msg)
                        } else if (res.code === 4001) {
                            message.error(res.msg)
                        }
                    }
                })
        }
        return true
    }

    componentDidMount() {
        var that = this
        var search = this.props.match.params.search
        var list = JSON.parse(localStorage.getItem('search'))
        var arr1 = search.replace('_', "/")
        var arr = arr1.split(":")
        var type = this.props.match.params.type
        var page = this.state.page
        var ps = this.state.ps
        that.setState({
            historyList: list,
            type: type
        })
        if (type == '2') {
            var _search = JSON.parse(search)
            api.post({
                str: '/retrieve/patentRetrieval'
            }, {
                page: 1,
                ps: 10,
                type,
                zlbh: _search[0].sqh,
                gkh: _search[0].gkh,
                zlmc: _search[0].zlmc,
                sqr: _search[0].sqr,
                fmr: _search[0].fmr,
                dljg: _search[0].dljg,
                zllx: _search[0].zllx,
                zlyxx: _search[0].zlyxx,
                region: _search[0].region[0],
                sqkstime: _search[0].sqrq[0],
                sqjstime: _search[0].sqrq[1],
                gkkstime: _search[0].gkrq[0],
                gkjstime: _search[0].gkrq[1]
            })
                .then(res => res.json())
                .then(res => {
                    if (res.code == 200) {
                        if (res.data.dataList.length > 0) {
                            that.setState({
                                selectList: res.data.dataList,
                                total: res.data.total,
                                zlwyid: res.data.dataList[0].zlwyid,
                                loadingCompent: true,
                                _zlbh: _search[0].sqh,
                                _gkh: _search[0].gkh,
                                _zlmc: _search[0].zlmc,
                                _sqr: _search[0].sqr,
                                _fmr: _search[0].fmr,
                                _dljg: _search[0].dljg,
                                _zllx: _search[0].zllx,
                                _zlyxx: _search[0].zlyxx,
                                _region: _search[0].region[0],
                                _sqkstime: _search[0].sqrq[0],
                                _sqjstime: _search[0].sqrq[1],
                                _gkkstime: _search[0].gkrq[0],
                                _gkjstime: _search[0].gkrq[1]
                            })
                            api.post({
                                str: '/retrieve/patentInfoBasic'
                            }, {
                                id: res.data.dataList[0].zlwyid
                            })
                                .then(ret => ret.json())
                                .then(ret => {
                                    if (ret.code === 200) {
                                        this.setState({
                                            zlmc: ret.data.zlmc,
                                            zllx: ret.data.zllx,
                                            zlyxx: ret.data.zlyxx,
                                            zlsqr: ret.data.zlsqr,
                                            zlsqh: ret.data.zlsqh,
                                            zlsqrq: ret.data.zlsqrq,
                                            zlgkr: ret.data.zlgkr,
                                            zlfmr: ret.data.zlfmr,
                                            zlzflh: ret.data.zlzflh,
                                            zldqr: ret.data.zldqr,
                                            zlqr: ret.data.zlqr,
                                            sqrdz: ret.data.sqrdz,
                                            dlre: ret.data.dlre,
                                            dljg: ret.data.dljg,
                                            zy: ret.data.zy,
                                            qls: ret.data.qls,
                                            zlflxx: ret.data.zlflxx,
                                            zltp: ret.data.zltp,
                                            flzt_code: ret.data.flzt_code,
                                            zlipcfl: ret.data.zlipcfl
                                        })
                                    } else if (ret.code === 4000) {
                                        message.error(ret.msg)
                                    } else if (ret.code === 4001) {
                                        message.error(ret.msg)
                                    }
                                })
                        } else {
                            message.warn('未查到专利')
                        }
                    } else if (res.code == 4000 || res.code == 4001 || res.code == 4005) {
                        this.props.history.push('/Signin')
                    } else {
                        message.error(res.msg)
                    }
                })
        } else if (type == 1) {
            this.setState({
                search: search,
                loadingCompent: false,
            })

            api.post({
                str: '/retrieve/patentRetrieval'
            }, {
                type: this.state.type,
                ps: ps,
                page: page,
                search: search
            })
                .then(res => res.json())
                .then(res => {
                    if (res.code === 200) {
                        if (res.data.dataList.length > 0) {
                            this.setState({
                                selectList: res.data.dataList,
                                total: res.data.total,
                                zlwyid: res.data.dataList[0].zlwyid,
                                loadingCompent: true
                            })
                            api.post({
                                str: '/retrieve/patentInfoBasic'
                            }, {
                                id: res.data.dataList[0].zlwyid
                            })
                                .then(ret => ret.json())
                                .then(ret => {
                                    if (ret.code === 200) {
                                        this.setState({
                                            zlmc: ret.data.zlmc,
                                            zllx: ret.data.zllx,
                                            zlyxx: ret.data.zlyxx,
                                            zlsqr: ret.data.zlsqr,
                                            zlsqh: ret.data.zlsqh,
                                            zlsqrq: ret.data.zlsqrq,
                                            zlgkr: ret.data.zlgkr,
                                            zlfmr: ret.data.zlfmr,
                                            zlzflh: ret.data.zlzflh,
                                            zldqr: ret.data.zldqr,
                                            zlqr: ret.data.zlqr,
                                            sqrdz: ret.data.sqrdz,
                                            dlre: ret.data.dlre,
                                            dljg: ret.data.dljg,
                                            zy: ret.data.zy,
                                            qls: ret.data.qls,
                                            zlflxx: ret.data.zlflxx,
                                            zltp: ret.data.zltp,
                                            flzt_code: ret.data.flzt_code,
                                            zlipcfl: ret.data.zlipcfl
                                        })
                                    } else if (ret.code === 4000) {
                                        message.error(ret.msg)
                                    } else if (ret.code === 4001) {
                                        message.error(ret.msg)
                                    }
                                })
                        } else if (res.code === 4000) {
                            message.error(res.msg)
                        } else if (res.code === 4001) {
                            message.error(res.msg)
                        } else if (res.code === 0) {
                            message.error(res.msg)
                        }
                    } else {
                        message.error('未找到符合条件的专利')
                    }
                })
        }

        //         let formdata = new FormData();
        //         formdata.append('id','CN111731499A')
        //         fetch('http://192.168.0.158/index.php/index/retrieve/patentInfoBasic',{
        //             method:'POST',
        //             // headers:{
        //             //     'content-type':'application/x-www-form-urlencoded'
        //             // },
        //             mode: "cors",
        //             body:formdata,
        //             dataType:'text'
        //         })
        //         .then(res => res.json())
        //         .then(res => console.log(res))
        //     }
        // })

        clearTimeout(this.pwdErrorTimer);
    }

    /**
     * 点击下载PDF
     * @author zxy
     * @param {*} zlwyid
     * @memberof Content
     */
    handleClickPdf(zlwyid) {
        api.post({
            str: '/retrieve/getPatentPdf'
        }, {
            id: zlwyid
        })
            .then(res => res.json())
            .then(res => {
                if (res.code === 200) {
                    this.setState({
                        pdf_url: res.data.pdf_url
                    })
                } else if (res.code === 4000) {
                    message.error(res.msg)
                } else if (res.code === 4001) {
                    message.error(res.msg)
                } else {
                    message.error(res.msg)
                }
            })
    }

    /**
     * 相似专利 
     * @author zxy
     * @param {*} value
     * @memberof Content
     */
    handelClickSame(value) {
        api.post({
            str: '/retrieve/getPatentLike'
        }, {
            id: value
        })
            .then(res => res.json())
            .then(res => {
                if (res.code === 200) {
                    this.setState({
                        sameData: res.data
                    })
                } else if (res.code === 4000) {
                    message.error(res.msg)
                } else if (res.code === 4001) {
                    message.error(res.msg)
                } else {
                    message.error(res.msg)
                }
            })
    }

    /**
     * 获取专利详情
     * @author zxy
     * @param {*} zlwyid
     * @memberof Content
     */
    handleClickZlxq(zlwyid) {
        this.setState({
            loadingCompent: false,
            zlwyid: zlwyid
        })
        api.post({
            str: '/retrieve/patentInfoBasic'
        }, {
            id: zlwyid
        })
            .then(res => res.json())
            .then(res => {
                if (res.code === 200) {
                    var loadingCompent = true
                    this.setState({
                        loadingCompent: loadingCompent,
                        zlmc: res.data.zlmc,
                        zllx: res.data.zllx,
                        zlyxx: res.data.zlyxx,
                        zlsqr: res.data.zlsqr,
                        zlsqh: res.data.zlsqh,
                        zlsqrq: res.data.zlsqrq,
                        zlgkr: res.data.zlgkr,
                        zlfmr: res.data.zlfmr,
                        zlzflh: res.data.zlzflh,
                        zldqr: res.data.zldqr,
                        zlqr: res.data.zlqr,
                        sqrdz: res.data.sqrdz,
                        dlre: res.data.dlre,
                        dljg: res.data.dljg,
                        zy: res.data.zy,
                        qls: res.data.qls,
                        zlflxx: res.data.zlflxx,
                        zltp: res.data.zltp,
                        flzt_code: res.data.flzt_code,
                        zlipcfl: res.data.zlipcfl
                    })
                } else if (res.code === 4000) {
                    message.error(res.msg)
                } else if (res.code === 4001) {
                    message.error(res.msg)
                } else if (res.code === 0) {
                    message.error(res.msg)
                }
            })
    }

    /**
     * 权利要求书
     * @author zxy
     * @param {*} zlwyid
     * @memberof Content
     */
    handleClickQlyqs(zlwyid) {
        this.setState({
            loadingCompent: false
        })
        api.post({
            str: '/retrieve/getClaims'
        }, {
            id: zlwyid
        })
            .then(res => res.json())
            .then(res => {
                if (res.code === 200) {
                    this.setState({
                        qlyqs: res.data,
                        loadingCompent: true
                    })
                } else if (res.code === 4000) {
                    message.error(res.msg)
                } else if (res.code === 4001) {
                    message.error(res.msg)
                } else {
                    message.error(res.msg)
                }
            })
    }

    /**
     * 数据分析
     * @author zxy
     * @memberof Content
     */
    handleClickSjfx() {
        message.warning('暂未开放，敬请期待')
    }

    /**
     * 专利说明书
     * @author zxy
     * @param {*} zlwyid
     * @memberof Content
     */
    handleClickZlsms(zlwyid) {
        api.post({
            str: '/retrieve/getInstructions'
        }, {
            id: zlwyid
        })
            .then(res => res.json())
            .then(res => {
                if (res.code === 200) {
                    this.setState({
                        zlsms: res.data
                    })
                } else if (res.code === 4000) {
                    message.error(res.msg)
                } else if (res.code === 4001) {
                    message.error(res.msg)
                }
            })
    }

    /**
     * 时间筛选
     * @author zxy
     * @param {*} e
     * @memberof Content
     */
    handleChangeOptionDate = e => {
        var type = this.props.match.params.type
        var str = this.props.match.params.search
        var _search = ''
        var arr = str.split(":")
        if (arr[0] === 'sqr' || arr[0] === 'fmr') {
            _search = arr[1]
        } else {
            _search = str
        }
        api.post({
            str: "/retrieve/secondPatentRetrieval"
        }, {
            type: type,
            search: _search,
            sort: e[0],
            ps: this.state.ps,
            page: this.state.page,
            sortType: e[1]
        })
            .then(res => res.json())
            .then(res => {
                if (res.code === 200) {
                    if (res.data.dataList.length > 0) {
                        this.setState({
                            selectList: res.data.dataList,
                            total: res.data.total,
                            loadingCompent: true
                        })
                        api.post({
                            str: '/retrieve/patentInfoBasic'
                        }, {
                            id: res.data.dataList[0].zlwyid
                        })
                            .then(ret => ret.json())
                            .then(ret => {
                                if (ret.code === 200) {
                                    this.setState({
                                        zlmc: ret.data.zlmc,
                                        zllx: ret.data.zllx,
                                        zlyxx: ret.data.zlyxx,
                                        zlsqr: ret.data.zlsqr,
                                        zlsqh: ret.data.zlsqh,
                                        zlsqrq: ret.data.zlsqrq,
                                        zlgkr: ret.data.zlgkr,
                                        zlfmr: ret.data.zlfmr,
                                        zlzflh: ret.data.zlzflh,
                                        zldqr: ret.data.zldqr,
                                        zlqr: ret.data.zlqr,
                                        sqrdz: ret.data.sqrdz,
                                        dlre: ret.data.dlre,
                                        dljg: ret.data.dljg,
                                        zy: ret.data.zy,
                                        qls: ret.data.qls,
                                        zlflxx: ret.data.zlflxx,
                                        zltp: ret.data.zltp,
                                        flzt_code: ret.data.flzt_code,
                                        zlipcfl: ret.data.zlipcfl
                                    })
                                } else if (ret.code === 4000) {
                                    message.error(ret.msg)
                                } else if (ret.code === 4001) {
                                    message.error(ret.msg)
                                }
                            })
                    }
                } else if (res.code === 4000) {
                    message.error(res.msg)
                } else if (res.code === 4001) {
                    message.error(res.msg)
                }
            })
    }

    /**
     * 改变文字大小
     * @author zxy
     * @param {*} e
     * @memberof Content
     */
    onChangeFontSize = e => {
        this.setState({
            value4: e.target.value,
        });
    }

    callback(key) {
    };

    handleClick() {
    };

    /**
     * 高光显示输入
     * @author zxy
     * @memberof Content
     */
    handleheightShowOk() {
        this.setState({
            heightShow: false
        })
        message.warning('暂未开放，敬请期待')
    }

    /**
     * 取消高光显示
     * @author zxy
     * @memberof Content
     */
    handelheightShowCancel() {
        this.setState({
            heightShow: false
        })
    }

    /**
     * 打开高光显示列表
     * @author zxy
     * @memberof Content
     */
    handleheightShow() {
        this.setState({
            heightShow: true
        })

    }

    /**
     * 专利导出
     * @author zxy
     * @memberof Content
     */
    handleZldc() {
        this.setState({
            ZldcShow: true
        })
    }

    /**
     * 批量下载
     * @author zxy
     * @memberof Content
     */
    handlePlxz() {
        this.setState({
            showPlxz: true
        })
    }

    /**
     * 批量下载确认
     * @author zxy
     * @memberof Content
     */
    handlePlxzOk() {
        // var dcType = this.state.DcValue
        // var ids = []
        // var xzgsValue = this.state.xzgsValue
        // if(dcType === 1){
        //     ids = this.state.listGX
        // }else if(dcType === 2){
        //     var inputStart = this.state.DcValueStart
        //     var inputEnd = this.state.DcValueEnd
        //     var list = this.state.selectList
        //     var listId = []
        //     list.map((item,index) => {
        //        return listId = listId.concat(item.zlwyid)
        //     })
        //      var num = inputEnd - inputStart + 1
        //      var indexStart = inputStart - 1
        //         ids = listId.splice(indexStart,num)
        // }
        // ids = JSON.stringify(ids)
        // var data = {
        //     'id':ids
        // }
        // api.onexz({
        //     str:'/retrieve/exportPatent'
        // },data)
        this.setState({
            showPlxz: false
        })
        message.warning('暂未开放，敬请期待')
    }

    /**
     * 取消批量下载
     * @author zxy
     * @memberof Content
     */
    handlePlxzCancel() {
        this.setState({
            showPlxz: false
        })
    }

    /**
     * 著录导出
     * @author zxy
     * @memberof Content
     */
    handleZldcOk() {
        var fileName = this.state.fileName
        var dcType = this.state.DcValue
        var ids = []
        //var selectValue = this.state.selectValue
        if (dcType === 1) {
            ids = this.state.listGX
        } else if (dcType === 2) {
            var inputStart = this.state.DcValueStart
            var inputEnd = this.state.DcValueEnd
            var list = this.state.selectList
            var listId = []
            list.map((item, index) => {
                return listId = listId.concat(item.zlwyid)
            })
            var num = inputEnd - inputStart + 1
            var indexStart = inputStart - 1
            ids = listId.splice(indexStart, num)
        }
        ids = JSON.stringify(ids)
        var data = {
            'ids': ids,
            'fileName': fileName
        };
        api.post({
            str: '/retrieve/checkLogin'
        })
            .then(res => res.json())
            .then(res => {
                if (res.code === 200) {
                    api.xz({
                        str: '/retrieve/exportPatentExcel'
                    }, data)
                    this.setState({
                        ZldcShow: false
                    })
                } else if (res.code === 4000) {
                    message.error(res.msg)
                } else if (res.code === 4001) {
                    message.error(res.msg)
                }
            })
    }

    /**
     * 著录导出取消
     * @author zxy
     * @memberof Content
     */
    handleZldcCancel() {
        this.setState({
            ZldcShow: false
        })
    }

    handleTextShow() {
        this.setState({
            visibleText: true
        })
    }

    handleTextOk() {
        this.setState({
            visibleText: false
        })
    }

    handelTextCancel() {
        this.setState({
            visibleText: false
        })
    }

    /**
     * 模式选择
     * @author zxy
     * @param {*} e
     * @memberof Content
     */
    handleChangeOption(e) {
        this.setState({
            displayMode: e
        })
    }

    /**
     * 专利列表分页处理
     * @author zxy
     * @param {*} pageNumber
     * @param {*} pageSize
     * @memberof Content
     */
    onPageChange(pageNumber, pageSize) {
        var type = this.props.match.params.type
        if (type == '1') {
            this.setState({
                page: pageNumber,
                ps: pageSize
            })
            api.post({
                str: '/retrieve/secondPatentRetrieval'
            }, {
                ps: pageSize,
                page: pageNumber,
                search: this.state.search,
                type: type,
                zlbh: this.state._zlbh,
                gkh: this.state._gkh,
                zlmc: this.state._zlmc,
                sqr: this.state._sqr,
                fmr: this.state._fmr,
                sqkstime: this.state._sqkstime,
                sqjstime: this.state._sqjstime,
                gkkstime: this.state._gkkstime,
                gkjstime: this.state._gkjstime,
                dljg: this.state._dljg,
                zllx: this.state._zllx,
                zlyxx: this.state._zlyxx,
                region: this.state._region
            })
                .then(res => res.json())
                .then(res => {
                    if (res.code === 200) {
                        if (res.data.dataList.length > 0) {
                            this.setState({
                                selectList: res.data.dataList,
                                total: res.data.total,
                                loadingCompent: true
                            })
                            api.post({
                                str: '/retrieve/patentInfoBasic'
                            }, {
                                id: res.data.dataList[0].zlwyid
                            })
                                .then(ret => ret.json())
                                .then(ret => {
                                    if (ret.code === 200) {
                                        this.setState({
                                            zlmc: ret.data.zlmc,
                                            zllx: ret.data.zllx,
                                            zlyxx: ret.data.zlyxx,
                                            zlsqr: ret.data.zlsqr,
                                            zlsqh: ret.data.zlsqh,
                                            zlsqrq: ret.data.zlsqrq,
                                            zlgkr: ret.data.zlgkr,
                                            zlfmr: ret.data.zlfmr,
                                            zlzflh: ret.data.zlzflh,
                                            zldqr: ret.data.zldqr,
                                            zlqr: ret.data.zlqr,
                                            sqrdz: ret.data.sqrdz,
                                            dlre: ret.data.dlre,
                                            dljg: ret.data.dljg,
                                            zy: ret.data.zy,
                                            qls: ret.data.qls,
                                            zlflxx: ret.data.zlflxx,
                                            zltp: ret.data.zltp,
                                            flzt_code: ret.data.flzt_code,
                                            zlipcfl: ret.data.zlipcfl
                                        })
                                    } else if (ret.code === 4000) {
                                        message.error(ret.msg)
                                    } else if (ret.code === 4001) {
                                        message.error(ret.msg)
                                    }
                                })
                        }
                    } else if (res.code === 4000) {
                        message.error(res.msg)
                    } else if (res.code === 4001) {
                        message.error(res.msg)
                    }
                })
        } else if (type == '2') {
            var _search = JSON.parse(this.props.match.params.search)
            api.post({
                str: '/retrieve/patentRetrieval'
            }, {
                page: pageNumber,
                ps: pageSize,
                type,
                zlbh: _search[0].sqh,
                gkh: _search[0].gkh,
                zlmc: _search[0].zlmc,
                sqr: _search[0].sqr,
                fmr: _search[0].fmr,
                dljg: _search[0].dljg,
                zllx: _search[0].zllx,
                zlyxx: _search[0].zlyxx,
                region: _search[0].region[0],
                sqkstime: _search[0].sqrq[0],
                sqjstime: _search[0].sqrq[1],
                gkkstime: _search[0].gkrq[0],
                gkjstime: _search[0].gkrq[1]
            })
                .then(res => res.json())
                .then(res => {
                    if (res.code == 200) {
                        if (res.data.dataList.length > 0) {
                            this.setState({
                                selectList: res.data.dataList,
                                total: res.data.total,
                                zlwyid: res.data.dataList[0].zlwyid,
                                loadingCompent: true,
                                _zlbh: _search[0].sqh,
                                _gkh: _search[0].gkh,
                                _zlmc: _search[0].zlmc,
                                _sqr: _search[0].sqr,
                                _fmr: _search[0].fmr,
                                _dljg: _search[0].dljg,
                                _zllx: _search[0].zllx,
                                _zlyxx: _search[0].zlyxx,
                                _region: _search[0].region[0],
                                _sqkstime: _search[0].sqrq[0],
                                _sqjstime: _search[0].sqrq[1],
                                _gkkstime: _search[0].gkrq[0],
                                _gkjstime: _search[0].gkrq[1]
                            })
                            api.post({
                                str: '/retrieve/patentInfoBasic'
                            }, {
                                id: res.data.dataList[0].zlwyid
                            })
                                .then(ret => ret.json())
                                .then(ret => {
                                    if (ret.code === 200) {
                                        this.setState({
                                            zlmc: ret.data.zlmc,
                                            zllx: ret.data.zllx,
                                            zlyxx: ret.data.zlyxx,
                                            zlsqr: ret.data.zlsqr,
                                            zlsqh: ret.data.zlsqh,
                                            zlsqrq: ret.data.zlsqrq,
                                            zlgkr: ret.data.zlgkr,
                                            zlfmr: ret.data.zlfmr,
                                            zlzflh: ret.data.zlzflh,
                                            zldqr: ret.data.zldqr,
                                            zlqr: ret.data.zlqr,
                                            sqrdz: ret.data.sqrdz,
                                            dlre: ret.data.dlre,
                                            dljg: ret.data.dljg,
                                            zy: ret.data.zy,
                                            qls: ret.data.qls,
                                            zlflxx: ret.data.zlflxx,
                                            zltp: ret.data.zltp,
                                            flzt_code: ret.data.flzt_code,
                                            zlipcfl: ret.data.zlipcfl
                                        })
                                    } else if (ret.code === 4000) {
                                        message.error(ret.msg)
                                    } else if (ret.code === 4001) {
                                        message.error(ret.msg)
                                    }
                                })
                        } else {
                            message.warn('未查到专利')
                        }
                    } else {
                        message.error(res.msg)
                    }
                })

        }
    }



    onChange = e => {
        this.setState({
            listGX: e
        })
    };

    onCheckAllChange = e => {
        this.setState({
            checkedList: e.target.checked ? this.state.selectList : [],
            indeterminate: false,
            checkAll: e.target.checked,
        })
    }

    onKeyUp = (e) => {
        var type = 1;
        var id = this.refs.input.value.trim()
        var time = getCurrentDate()
        if (e.keyCode === 13) {
            if (localStorage.search) {
                var sa = localStorage.getItem('search')
                var ss = JSON.parse(sa)
                search_data.search = id
                search_data.time = time
                if (ss.length > 9) {
                    ss.splice(0, 1, search_data)
                    var search = JSON.stringify(ss)
                    localStorage.setItem('search', search)
                } else {
                    ss.push(search_data)
                    var searcht = JSON.stringify(ss)
                    localStorage.setItem('search', searcht)
                }
            } else {
                search_data.search = id
                search_data.time = time
                search_arr.push(search_data)
                var searchs = JSON.stringify(search_arr)
                localStorage.setItem('search', searchs)
            }
            this.props.history.push('/Zllb/' + id + '/' + type)
        }
    }

    inputSearch = (e) => {
        var type = 1;
        var id = this.refs.input.value.trim()
        var time = getCurrentDate()
        if (id == '') {
            message.error('搜索框不能为空')
        } else {
            if (localStorage.search) {
                var sa = localStorage.getItem('search')
                var ss = JSON.parse(sa)
                search_data.search = id
                search_data.time = time
                if (ss.length > 9) {
                    ss.splice(0, 1, search_data)
                    var search = JSON.stringify(ss)
                    localStorage.setItem('search', search)
                } else {
                    ss.push(search_data)
                    var searcht = JSON.stringify(ss)
                    localStorage.setItem('search', searcht)
                }
            } else {
                search_data.search = id
                search_data.time = time
                search_arr.push(search_data)
                var searchs = JSON.stringify(search_arr)
                localStorage.setItem('search', searchs)
            }
            this.props.history.push('/Zllb/' + id + '/' + type)
        }
    }

    zanweikaitong() {
        message.warn('暂未开放')
    }

    secondsearch = (data) => {
        var address = data.address
        var region = address[address.length - 1]
        this.setState({
            _search: data.search,
            _zlbh: data.sqh,
            _gkh: data.zlwyid,
            _zlmc: data.zlmc,
            _sqr: data.sqr,
            _fmr: data.fmr,
            _sqkstime: data.sqrq[0],
            _sqjstime: data.sqrq[1],
            _gkkstime: data.gkrq[0],
            _gkjstime: data.gkrq[1],
            _dljg: data.dljg,
            _zllx: data.zllx,
            _zlyxx: data.zlyxx,
            _region: region
        })
        api.post({
            str: '/retrieve/secondPatentRetrieval'
        }, {
            page: 1,
            ps: 10,
            search: data.search,
            zlbh: data.sqh,
            gkh: data.zlwyid,
            zlmc: data.zlmc,
            sqr: data.sqr,
            fmr: data.fmr,
            sqkstime: data.sqrq[0],
            sqjstime: data.sqrq[1],
            gkkstime: data.gkrq[0],
            gkjstime: data.gkrq[1],
            dljg: data.dljg,
            zllx: data.zllx,
            zlyxx: data.zlyxx,
            region
        })
            .then(res => res.json())
            .then(res => {
                if (res.code == 200) {
                    this.setState({
                        selectList: res.data.dataList,
                        total: res.data.total,
                        loadingCompent: true
                    })
                    api.post({
                        str: '/retrieve/patentInfoBasic'
                    }, {
                        id: res.data.dataList[0].zlwyid
                    })
                        .then(ret => ret.json())
                        .then(ret => {
                            if (ret.code === 200) {
                                this.setState({
                                    zlmc: ret.data.zlmc,
                                    zllx: ret.data.zllx,
                                    zlyxx: ret.data.zlyxx,
                                    zlsqr: ret.data.zlsqr,
                                    zlsqh: ret.data.zlsqh,
                                    zlsqrq: ret.data.zlsqrq,
                                    zlgkr: ret.data.zlgkr,
                                    zlfmr: ret.data.zlfmr,
                                    zlzflh: ret.data.zlzflh,
                                    zldqr: ret.data.zldqr,
                                    zlqr: ret.data.zlqr,
                                    sqrdz: ret.data.sqrdz,
                                    dlre: ret.data.dlre,
                                    dljg: ret.data.dljg,
                                    zy: ret.data.zy,
                                    qls: ret.data.qls,
                                    zlflxx: ret.data.zlflxx,
                                    zltp: ret.data.zltp,
                                    flzt_code: ret.data.flzt_code,
                                    zlipcfl: ret.data.zlipcfl
                                })
                            } else if (ret.code === 4000) {
                                message.error(ret.msg)
                            } else if (ret.code === 4001) {
                                message.error(ret.msg)
                            }
                        })
                } else {
                    message.error(res.msg)
                }
            })
    }

    render() {
        const selectList = this.state.selectList
        return (
            <Fragment>
                <Sider secondsearch={this.secondsearch.bind(this)} />
                <div style={{ width: '85.8%', height: '100%', backgroundColor: "#fff", marginLeft: '10px' }}>
                    <div style={{ height: '82px', alignItems: 'center', display: 'flex', marginLeft: '24px', borderBottom: '0.5px solid rgba(0, 0, 0, 0.1)' }} className="content-search-zllb">
                        <input style={{ border: '0.5px solid #00C8DF' }} ref='input' placeholder={this.state.search} defaultValue={this.state.search} onKeyUp={this.onKeyUp} />
                        <button className="cousor" onClick={this.inputSearch} style={{ backgroundColor: '#00c8df', marginLeft: '10px', color: '#fff', border: '1px solid #00c8df' }}>搜索</button>
                        <div id='top' style={{ display: "flex", flexDirection: 'row', marginLeft: 'auto', marginRight: '10px' }}>用时&lt;<div style={{ color: '#00C8DF' }}>&nbsp;0.5&nbsp;</div>&gt;秒,共检测到&lt;<div style={{ color: '#00C8DF' }}>&nbsp;{this.state.total}&nbsp;</div>&gt;条信息</div>
                    </div>
                    <div style={{ height: '44px', display: 'flex', flexDirection: 'row', borderBottom: '0.5px solid rgba(0, 0, 0, 0.1)', marginLeft: '24px', lineHeight: '44px' }}>
                        <div className="flex_row" style={{ marginLeft: '42px' }}>
                            <Select showArrow={false} defaultValue={0} className='flex_row' bordered={false} style={{ width: '105px' }} onChange={this.handleChangeOption}>
                                <Option value={0} >
                                    <div className="flex_row" >
                                        <img src={require('../images/twms.png')} alt="图文模式" className="width20h20" />
                                        <div style={{ marginLeft: '8px' }}>图文模式</div>
                                    </div>
                                </Option>
                                <Option value={1}>
                                    <div className="flex_row">
                                        <img src={require('../images/lbms.png')} alt="列表模式" className="width20h20" />
                                        <div style={{ marginLeft: '8px' }}>列表模式</div>
                                    </div>
                                </Option>
                                <Option value={2}>
                                    <div className="flex_row">
                                        <img src={require('../images/stms.png')} alt="首图模式" className="width20h20" />
                                        <div style={{ marginLeft: '8px' }}>首图模式</div>
                                    </div>
                                </Option>
                                <Option value={3}>
                                    <div className="flex_row">
                                        <img src={require('../images/lzms.png')} alt="联展模式" className="width20h20" />
                                        <div style={{ marginLeft: '8px' }}>联展模式</div>
                                    </div>
                                </Option>
                            </Select>
                            <img src={require('../images/Downdrop.png')} alt='xl' style={{ width: '12px', height: '8px' }} />
                        </div>
                        <div className="flex_row" style={{ marginLeft: '34px' }}>
                            <img src={require('../images/sqr.png')} alt="申请日" className="width20h20" />
                            <Cascader expandIcon={<Sore />} popupClassName='sore_item' options={dataArray} defaultValue={['申请日', '申请日(降序)']} className='flex_row sort' style={{ width: '140px', marginLeft: '5px', height: '32px', lineHeight: '32px' }} bordered={false} allowClear={false} expandTrigger="hover" onChange={this.handleChangeOptionDate}></Cascader>
                            <img src={require('../images/Downdrop.png')} alt='xl' style={{ width: '12px', height: '8px' }} />
                        </div>
                        {/* <div className="flex_row" style={{ marginLeft: '35px', cursor: 'pointer' }} onClick={this.handleTextShow} >
                                <img src={require('../images/zdxs.png')} className="width20h20" alt="字段显示" />
                                <div style={{ marginLeft: '8px' }}>字段显示</div>
                            </div>
                            <Modal visible={this.state.visibleText}
                                onOk={this.handleTextOk}
                                onCancel={this.handelTextCancel}
                                width={932}
                                height={400}
                                footer={[
                                    <div style={{ height: '60px', width: '100%', display: 'flex', flexDirection: 'row', justifyContent: "center", alignItems: 'center' }} key="ss">
                                        <button key="cancel" onClick={this.handelTextCancel} className="cancle-btn" style={{ marginLeft: '552px' }}>取消筛选</button>,
                                        <Link to={this.state.link} key="submit" onClick={this.handleOk} className="ok-btn" type="primary" >立即筛选</Link>
                                    </div>
                                ]}>
                                <div style={{ width: '100%', height: '79px', color: 'rgba(0,0,0,0.8)', fontWeight: 'bold', fontSize: '16px', textAlign: 'center', lineHeight: '79px', padding: "0", borderBottom: '1px solid rgba(0, 0, 0, 0.1)' }} >字段显示</div>
                                <CheckboxGroup style={{ width: '100%' }} onChange={this.onChangeTextShow} >
                                    <Row style={{ marginLeft: "135px", marginTop: "46px", lineHeight: '50px', marginBottom: '51px' }}>
                                        {
                                            plainOptions.map((item, index) => {
                                                return <Col key={index} span={8}>
                                                    <Checkbox value={item.value}>{item.label}</Checkbox>
                                                </Col>
                                            })
                                        }
                                    </Row>
                                </CheckboxGroup>
                            </Modal> */}
                        <div className="flex_row" style={{ marginLeft: '30px', cursor: 'pointer' }} onClick={this.handleheightShow} >
                            <img src={require('../images/glxs.png')} style={{ width: '17px', height: '24px' }} alt="高亮显示" />
                            <div style={{ marginLeft: '8px' }}>高亮显示</div>
                        </div>
                        <Modal visible={this.state.heightShow}
                            onOk={this.handleheightShowOk}
                            onCancel={this.handelheightShowCancel}
                            width={474}
                            height={400}
                            footer={[
                                <div style={{ height: '60px', width: '100%', display: 'flex', flexDirection: 'row', justifyContent: "center", alignItems: 'center' }} key="ss">
                                    <button key="cancel" onClick={this.handelheightShowCancel} className="cancle-btn" >取消</button>
                                    <button key="submit" onClick={this.handleheightShowOk} className="ok-btn" type="primary" >应用</button>
                                </div>
                            ]}>
                            <div style={{ width: '100%', height: '79px', color: 'rgba(0,0,0,0.8)', fontWeight: 'bold', fontSize: '16px', textAlign: 'center', lineHeight: '79px', padding: "0", borderBottom: '1px solid rgba(0, 0, 0, 0.1)' }} >高亮显示</div>
                            <div style={{ width: '100%', display: 'flex', flexDirection: "row", marginTop: '21px', justifyContent: 'center', alignItems: 'center' }}>
                                <div style={{ width: '260px', display: 'flex', flexDirection: 'column', }}>
                                    {
                                        heightShowOptions1.map((item, index) => {

                                            return <div key={index} style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.1)', width: '260px', height: '47px', display: 'flex', flexDirection: 'column', alignItems: "center" }}>
                                                <div style={{ width: '260px', height: '47px', display: 'flex', flexDirection: 'row', alignItems: "center" }} >
                                                    <div style={{ width: '12px', height: '12px' }} className={item.class}></div>
                                                    <div style={{ fontSize: '12px', marginLeft: '10px' }}>{item.value}</div>
                                                    <input placeholder="请输入关键词" style={{ border: 'none', width: '175px', height: '90%' }} />
                                                </div>
                                            </div>
                                        })
                                    }
                                </div>

                                {/* <div style={{ width: '384px', display: 'flex', flexDirection: 'column', marginLeft: '50px' }}>
                                        {
                                            heightShowOptions2.map((item, index) => {

                                                return <div key={index} style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.1)', width: '384px', height: '47px', display: 'flex', flexDirection: 'column', alignItems: "center" }}>
                                                    <div style={{ width: '384px', height: '47px', display: 'flex', flexDirection: 'row', alignItems: "center" }} >
                                                        <div style={{ width: '12px', height: '12px' }} className={item.class}></div>
                                                        <div style={{ fontSize: '12px', marginLeft: '10px' }}>{item.value}</div>
                                                        <input placeholder="请输入关键词" style={{ border: 'none', width: '300px', height: '90%' }} />
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div> */}
                            </div>
                        </Modal>
                        <div className="flex_row" style={{ marginLeft: '27px', cursor: 'pointer' }} >
                            <img src={require('../images/sjfx.png')} className="width20h20" alt="数据分析" />
                            <div onClick={this.handleClickSjfx} style={{ marginLeft: '8px' }}>数据分析</div>
                        </div>

                        <div className="flex_row" style={{ marginLeft: '28px', cursor: 'pointer' }} onClick={this.handleZldc}>
                            <img src={require('../images/zldc.png')} className="width20h20" alt="著录导出" />
                            <div style={{ marginLeft: '8px' }}>著录导出</div>
                        </div>
                        <Modal visible={this.state.ZldcShow}
                            onOk={this.handleZldcOk}
                            onCancel={this.handleZldcCancel}
                            width={932}
                            height={444}
                            footer={
                                <div style={{ height: '60px', width: '100%', display: 'flex', flexDirection: 'row', justifyContent: "center", alignItems: 'center' }} key="ss">
                                    <button key="cancel" onClick={this.handleZldcCancel} className="cancle-btn" >取消导出</button>
                                    <button key="submit" onClick={this.handleZldcOk} className="ok-btn" type="primary" >立即导出</button>
                                </div>
                            }>
                            <div style={{ width: '100%', height: '79px', color: 'rgba(0,0,0,0.8)', fontWeight: 'bold', fontSize: '16px', textAlign: 'center', lineHeight: '79px', padding: "0", borderBottom: '1px solid rgba(0, 0, 0, 0.1)' }} >著录导出</div>
                            <div style={{ width: '100%', display: 'flex', flexDirection: "column", marginTop: '23px', marginLeft: '55px', justifyContent: 'center' }}>
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                    <div style={{ width: '100px', textAlign: 'right' }}>标题：</div>
                                    <input style={{ width: '421px', height: '24px', lineHeight: '24px', borderTop: '0', borderLeft: '0', borderRight: '0', borderBottom: '0.5px solid #919191' }} placeholder='无标题将自动生成编号' onChange={this.inputvalue} ref='input' />
                                </div>
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', marginTop: '31px' }}>
                                    <div style={{ width: '100px', textAlign: 'right' }}>导出范围：</div>
                                    <Radio.Group onChange={this.onDcChange} value={this.state.DcValue}>
                                        <Radio value={1}>已勾选的专利</Radio>
                                        <Radio value={2}>从第<input disabled={this.state.DcValue === 2 ? '' : 'disabled'} style={{ width: '36px', height: '24px', borderBottom: '1px solid #E6E6E6', borderTop: '0', borderLeft: '0', borderRight: '0', textAlign: 'center' }} onChange={this.onDcChangeStart} ref='inputStart' max={10} min={1} />到<input disabled={this.state.DcValue === 2 ? '' : 'disabled'} style={{ width: '36px', height: '24px', borderBottom: '1px solid #E6E6E6', borderTop: '0', borderLeft: '0', borderRight: '0', textAlign: 'center' }} onChange={this.onDcChangeEnd} ref='inputEnd' />条 (只限当前页)</Radio>
                                    </Radio.Group>
                                </div>
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', marginTop: '31px', marginBottom: '23px' }}>
                                    <div style={{ width: '100px', textAlign: 'right' }}>导出字段：</div>
                                    <Checkbox.Group style={{ width: '698px' }} disabled onChange={this.onChangeWord}>
                                        <Row style={{ lineHeight: '30px' }}>
                                            <Col span={5}>
                                                <Checkbox value="申请日">申请日</Checkbox>
                                            </Col>
                                            <Col span={5}>
                                                <Checkbox value="授权日">授权日</Checkbox>
                                            </Col>
                                            <Col span={5}>
                                                <Checkbox value="申请人">申请人</Checkbox>
                                            </Col>
                                            <Col span={5}>
                                                <Checkbox value="发明人">发明人</Checkbox>
                                            </Col>
                                            <Col span={4}>
                                                <Checkbox value="申请号">申请号</Checkbox>
                                            </Col>
                                            <Col span={5}>
                                                <Checkbox value="授权号">授权号</Checkbox>
                                            </Col>
                                            <Col span={5}>
                                                <Checkbox value="法律状态">法律状态</Checkbox>
                                            </Col>
                                            <Col span={5}>
                                                <Checkbox value="专利类型">专利类型</Checkbox>
                                            </Col>
                                            <Col span={5}>
                                                <Checkbox value="标题">标题</Checkbox>
                                            </Col>
                                            <Col span={4}>
                                                <Checkbox value="摘要">摘要</Checkbox>
                                            </Col>
                                            <Col span={5}>
                                                <Checkbox value="权利要求">权利要求</Checkbox>
                                            </Col>
                                            <Col span={5}>
                                                <Checkbox value="摘要附图">摘要附图</Checkbox>
                                            </Col>
                                            <Col span={5}>
                                                <Checkbox value="分类号">分类号</Checkbox>
                                            </Col>
                                            <Col span={5}>
                                                <Checkbox value="当前专利权人">当前专利权人</Checkbox>
                                            </Col>
                                            <Col span={4}>
                                                <Checkbox value="优先权">优先权</Checkbox>
                                            </Col>
                                            <Col span={5}>
                                                <Checkbox value="优先权日">优先权日</Checkbox>
                                            </Col>
                                            <Col span={5}>
                                                <Checkbox value="优先权数量">优先权数量</Checkbox>
                                            </Col>
                                            <Col span={5}>
                                                <Checkbox value="第一申请人">第一申请人</Checkbox>
                                            </Col>
                                            <Col span={5}>
                                                <Checkbox value="第一发明人">第一发明人</Checkbox>
                                            </Col>
                                            <Col span={4}>
                                                <Checkbox value="发明人数量">发明人数量</Checkbox>
                                            </Col>
                                            <Col span={5}>
                                                <Checkbox value="申请人地址">申请人地址</Checkbox>
                                            </Col>
                                            <Col span={5}>
                                                <Checkbox value="代理人">代理人</Checkbox>
                                            </Col>
                                            <Col span={5}>
                                                <Checkbox value="代理机构">代理机构</Checkbox>
                                            </Col>
                                            <Col span={5}>
                                                <Checkbox value="审查员">审查员</Checkbox>
                                            </Col>
                                            <Col span={4}>
                                                <Checkbox value="分类号">分类号</Checkbox>
                                            </Col>
                                        </Row>
                                    </Checkbox.Group>
                                </div>
                            </div>
                        </Modal>

                        <div className="flex_row cousor" style={{ marginLeft: '28px' }} onClick={this.handlePlxz} >
                            <img src={require('../images/plxz.png')} className="width20h20" alt="批量下载" />
                            <div style={{ marginLeft: '8px' }}>批量下载</div>
                        </div>
                        <Modal visible={this.state.showPlxz}
                            onOk={this.handlePlxzOk}
                            onCancel={this.handlePlxzCancel}
                            width={434}
                            height={332}
                            footer={
                                <div style={{ height: '60px', width: '100%', display: 'flex', flexDirection: 'row', justifyContent: "center", alignItems: 'center' }} key="ss">
                                    <button key="cancel" onClick={this.handlePlxzCancel} className="cancle-btn cousor" >取消检索</button>
                                    <button key="submit" onClick={this.handlePlxzOk} className="ok-btn cousor" type="primary" >立即下载</button>
                                </div>
                            }>
                            <div style={{ width: '100%', height: '79px', color: 'rgba(0,0,0,0.8)', fontWeight: 'bold', fontSize: '16px', textAlign: 'center', lineHeight: '79px', padding: "0", borderBottom: '1px solid rgba(0, 0, 0, 0.1)' }} >著录导出</div>
                            <div style={{ width: '100%', display: 'flex', flexDirection: "column", marginTop: '23px', marginLeft: '26px', justifyContent: 'center' }}>
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', marginTop: '31px' }}>
                                    <div style={{ width: '100px', textAlign: 'right' }}>下载范围：</div>
                                    <Radio.Group onChange={this.onDcChange} value={this.state.DcValue}>
                                        <Radio value={1}>已勾选的专利</Radio>
                                        <Radio value={2}>从第<input disabled={this.state.DcValue === 2 ? '' : 'disabled'} style={{ width: '36px', height: '24px', borderBottom: '1px solid #E6E6E6', borderTop: '0', borderLeft: '0', borderRight: '0', textAlign: 'center' }} onChange={this.onDcChangeStart} ref='inputStart' max={10} min={1} />到<input disabled={this.state.DcValue === 2 ? '' : 'disabled'} style={{ width: '36px', height: '24px', borderBottom: '1px solid #E6E6E6', borderTop: '0', borderLeft: '0', borderRight: '0', textAlign: 'center' }} onChange={this.onDcChangeEnd} ref='inputEnd' />条 (只限当前页)</Radio>
                                    </Radio.Group>
                                </div>
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', marginTop: '31px' }}>
                                    <div style={{ width: '100px', textAlign: 'right' }}>下载格式：</div>
                                    <Radio.Group onChange={this.onXzChange} value={this.state.xzgsValue}>
                                        <Radio value='WORD'>WORD</Radio>
                                    </Radio.Group>
                                </div>
                            </div>
                        </Modal>
                        <div className="flex_row cousor" style={{ marginLeft: '29px' }} onClick={this.handleHistory}>
                            <img src={require('../images/jsls.png')} className="width20h20" alt="检索历史" />
                            <div style={{ marginLeft: '8px' }}>检索历史</div>
                        </div>
                        <Modal visible={this.state.showHistory}
                            width={1160}
                            onCancel={this.handleHistoryCancel}
                            height={865}
                            footer={
                                <div style={{ height: '60px', width: '100%', display: 'flex', flexDirection: 'row', justifyContent: "center", alignItems: 'center' }} key="ss">
                                    <button key="cancel" onClick={this.handleHistoryCancel} className="ok-btn" >关闭</button>
                                </div>
                            }>
                            <div style={{ width: '100%', height: '79px', color: 'rgba(0,0,0,0.8)', fontWeight: 'bold', fontSize: '16px', textAlign: 'center', lineHeight: '79px', padding: "0", borderBottom: '1px solid rgba(0, 0, 0, 0.1)' }} >检索历史</div>
                            <div style={{ width: '100%', height: '610px', display: "flex", justifyContent: 'center', alignItems: 'center' }}>
                                <div style={{ width: '948px', height: '610px', display: 'flex', flexDirection: 'column', }}>
                                    <div style={{ width: '100%x', height: '610px', display: 'flex', flexDirection: 'column' }}>
                                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: '30px' }}>
                                            <div style={{ width: '67px', marginLeft: '35px' }}>序号</div>
                                            <div style={{ marginLeft: 'auto', width: '405px', textAlign: 'center' }}>检索式</div>
                                            <div style={{ marginLeft: 'auto', width: '179px', textAlign: 'center' }}>检索时间</div>
                                            <div style={{ marginLeft: 'auto', marginRight: '26px', width: '123px', textAlign: 'center' }}>删除</div>
                                        </div>
                                        <div style={{ marginTop: '30px' }}>
                                            {
                                                // eslint-disable-next-line array-callback-return
                                                this.state.historyList.map((item, index) => {
                                                    return <div key={index} style={{ width: '100%', display: "flex", flexDirection: 'row', alignItems: 'center', lineHeight: '48px' }}>
                                                        <div style={{ width: '67px', marginLeft: '35px' }}>{index + 1}</div>
                                                        <div onClick={this.historySearch.bind(this, item.search)} style={{ marginLeft: 'auto', width: '405px', textAlign: 'center', cursor: "pointer" }}>{item.search}</div>
                                                        <div style={{ marginLeft: 'auto', width: '179px', textAlign: 'center' }}>{item.time}</div>
                                                        <div style={{ marginLeft: 'auto', marginRight: '26px', width: '123px', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                                            <div style={{ width: '52px', height: '52px', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: "pointer" }} onClick={this.delectHistory.bind(this, index)}>
                                                                <img style={{ width: '14px', height: '14px' }} src={require('../images/delete.png')} alt='ss' />
                                                            </div>
                                                        </div>
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                    </div>
                    <div style={{ height: '100%' }} className={(this.state.displayMode === 0 ? 'displayBlock' : 'displayNone')}>
                        <Checkbox.Group className='list-item' style={{ marginLeft: '24px', display: 'flex', flexDirection: 'column', height: '100%', borderBottom: '.5px solid rgba(0, 0, 0, 0.1)' }} onChange={this.onChange} >
                            {
                                selectList.map((item, index) => {
                                    return <Row className='contentlist' key={index} style={{ width: '100%', height: '100%' }}>
                                        <Col style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }} className='check_type'>
                                                <Checkbox value={item.zlwyid}>
                                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                        <div className={item.zllx_code === '1' ? 'bg20B24D' : (item.zllx_code === '2' ? 'bgF9931A' : 'bgDA71C8')} style={{ width: '68px', color: '#fff', borderRadius: '4PX', height: '16px', fontSize: '12px', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>{item.zllx}</div>
                                                        <div className={item.flzt_code === '1' ? 'bg6D72E8' : (item.flzt_code === '2' ? 'bgF56F23' : 'bgBFBFBF')} style={{ width: "68px", color: '#fff', borderRadius: '4PX', height: '16px', fontSize: '12px', justifyContent: 'center', display: 'flex', alignItems: 'center', marginLeft: '4px' }}>{item.flzt}</div>
                                                    </div>
                                                </Checkbox>
                                                <div className='cousor' style={{ width: '400px', marginLeft: '27px', color: 'rgba(40, 40, 40, 1)', fontWeight: 'bolder', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}><div onClick={this.toZlxq.bind(this, item.zlwyid)} style={{ width: '100%', height: '100%', justifyContent: 'left', cousor: 'pointer', fontWeight: '900', fontSize: '0.875rem' }} rel='noopener noreferrer'>{index + 1}. {item.zlmc}</div></div>
                                                <div className='gongnengzujian' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '400px', height: '30px' }}>
                                                    <div className='cousor' style={{ width: '56px', height: '24px', flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={this.download.bind(this, item.zlwyid)}>
                                                        <Download style={{ fontSize: '14px', color: '#00C8DF', width: '16px', height: '14px' }} alt='下载' />
                                                        <div style={{ marginLeft: '5px', fontSize: '12px', color: '#00C8DF' }}>下载</div>
                                                    </div>
                                                    <div className='cousor' style={{ width: '56px', height: '24px', flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: '20px' }} onClick={this.monitor.bind(this, item.zlbh, item.flzt_code)}>
                                                        <Monitor style={{ fontSize: '16px', color: '#00C8DF' }} alt='下载' />
                                                        <div style={{ marginLeft: '5px', fontSize: '12px', color: '#00C8DF' }}>监控</div>
                                                    </div>
                                                    <div className='cousor' style={{ width: '56px', height: '24px', flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: '20px', cousor: 'pointer' }} onClick={this.pay.bind(this, item.zlbh)}>
                                                        <Pay style={{ fontSize: '24px', color: '#00C8DF' }} alt='下载' />
                                                        <div style={{ marginLeft: '5px', fontSize: '12px', color: '#00C8DF' }}>缴费</div>
                                                    </div>
                                                    <div className='cousor' style={{ width: '56px', height: '24px', flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: '20px', cousor: 'pointer' }} onClick={this.remind.bind(this, item.zlbh, item.flzt_code)} >
                                                        <Remind style={{ fontSize: '24px', color: '#00C8DF' }} alt='下载' />
                                                        <div style={{ marginLeft: '5px', fontSize: '12px', color: '#00C8DF' }}>提醒</div>
                                                    </div>
                                                    <div className='cousor' style={{ width: '56px', height: '24px', flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: '20px', cousor: 'pointer' }} onClick={this.collection.bind(this, item.is_favorite, item.zlwyid)}>
                                                        <Collection className={item.is_favorite === '1' ? '' : 'displayNone'} style={{ color: '#00C8DF' }} alt='下载' />
                                                        <Collectionfb className={item.is_favorite === '2' ? '' : 'displayNone'} style={{ color: '#F9931A' }} alt='下载' />
                                                        <div style={{ marginLeft: '5px', fontSize: '12px', color: '#00C8DF' }}>收藏</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', minHeight: '150px', marginBottom: '10px', lineHeight: '30px', fontSize: '0.75rem' }}>
                                                <Image width={154} height={154} alt='专利图片' src={item.zltp} />
                                                <div style={{ width: '928px', minHeight: '150px', display: 'flex', flexDirection: 'column', marginLeft: '27px' }}>
                                                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                                        <div style={{ width: '200px' }}><span className="little-title">申请号：</span>{item.zlbh}</div>
                                                        <div style={{ marginLeft: '110px', width: '230px' }}><span className="little-title">公开（公告）号：</span>{item.zlwyid}</div>
                                                    </div>
                                                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                                        <div className='hoverCss cousor' onClick={this.sqrqSearch.bind(this, item.sqrq)} style={{ width: '200px', cousor: 'pointer' }}><span className="little-title">申请日：</span>{item.sqrq}</div>
                                                        <div className='hoverCss cousor' onClick={this.gkrqSearch.bind(this, item.gkr)} style={{ marginLeft: '110px', width: '230px', cousor: 'pointer' }}><span className="little-title">公开（公告）日：</span>{item.gkr}</div>
                                                        {/* <div style={{ marginLeft: '118px' }}>权项数：{item.qls}</div> */}
                                                    </div>
                                                    <div style={{ width: '200px' }} className='hoverCss cousor' onClick={this.zflhSearch.bind(this, item.zflh)}><span className="little-title">分类号：</span>{item.zflh}</div>
                                                    <div><span className="little-title">申请人：</span>
                                                        {
                                                            item.sqr.map((item, index) => {
                                                                return <span className='hoverCss cousor' key={index} onClick={this.sqrSearch.bind(this, item)} style={{ cousor: 'pointer', marginLeft: '5px' }}>{item}</span>
                                                            })
                                                        }
                                                    </div>
                                                    <div><span className="little-title">发明人：</span>
                                                        {
                                                            item.fmr.map((item, index) => {
                                                                return <span className='hoverCss cousor' key={index} onClick={this.nameSearch.bind(this, item)} style={{ cousor: 'pointer', marginLeft: '5px' }}>{item}</span>
                                                            })
                                                        }
                                                    </div>
                                                    <div style={{ height: '125px', width: '100%', lineHeight: '25px', overflow: 'hidden' }}><span className="little-title">摘要：</span>{item.zy}</div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                })
                            }
                        </Checkbox.Group>
                    </div>


                    <div style={{ width: '1287px', height: '100%' }} className={(this.state.displayMode === 1 ? 'displayBlock' : 'displayNone')}>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', marginTop: '20px' }}>
                            <div style={{ marginLeft: '60px', width: '30px', textAlign: 'center' }}>序号</div>
                            <div style={{ marginLeft: '45px', width: '50px', textAlign: 'center' }}>类型</div>
                            <div style={{ marginLeft: '45px', width: '56px', textAlign: 'center' }}>状态</div>
                            <div style={{ marginLeft: '54px', width: '248px', textAlign: 'center' }}>名称</div>
                            <div style={{ marginLeft: '51px', width: '120px', textAlign: 'center' }}>申请号</div>
                            <div style={{ marginLeft: '40px', width: '72px', textAlign: 'center' }}>申请日</div>
                            <div style={{ marginLeft: '10px', width: '248px', textAlign: 'center' }}>申请人</div>
                            <div style={{ marginLeft: '6px', width: '140px', textAlign: 'center' }}>发明人</div>
                        </div>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                            <Checkbox.Group className='list-item' style={{ width: '100%', marginLeft: '24px', display: 'flex', flexDirection: 'column' }} onChange={this.onChange} >
                                {
                                    selectList.map((item, index) => {
                                        return <div key={index} style={{ borderBottom: '0.5px solid #E7E7E7', width: '1263px', display: 'flex', flexDirection: 'row', alignItems: "center", fontSize: '12px', lineHeight: '40px' }}>
                                            <Checkbox value={item.zlwyid} style={{ width: '66px' }}>
                                                <div style={{ marginLeft: '10px', width: '30px', textAlign: 'center' }}>{index + 1}</div>
                                            </Checkbox>
                                            <div style={{ width: '50px', marginLeft: '45px', textAlign: 'center' }}>{item.zllx}</div>
                                            <div style={{ width: '56px', marginLeft: '45px', textAlign: 'center' }}>{item.flzt}</div>
                                            <div style={{ width: '248px', marginLeft: '54px', textAlign: 'center' }}><Link to={this.state.Zlxq} style={{ width: '100%', height: '100%', textAlign: 'center' }} rel="noopener noreferrer">{item.zlmc}</Link></div>
                                            <div style={{ width: '120px', marginLeft: '51px', textAlign: 'center' }}>{item.zlbh}</div>
                                            <div className='hoverCss cousor' onClick={this.sqrqSearch.bind(this, item.sqrq)} style={{ width: '72px', marginLeft: '40px', textAlign: 'center' }}>{item.sqrq}</div>
                                            <div style={{ width: '248px', marginLeft: '10px', textAlign: 'center' }}>

                                                {
                                                    item.sqr.map((item, index) => {
                                                        return <span className='hoverCss cousor' key={index} onClick={this.sqrSearch.bind(this, item)} style={{ cousor: 'pointer', marginLeft: '5px' }}>{item}</span>
                                                    })
                                                }
                                            </div>
                                            <div style={{ width: '140px', marginLeft: '6px', textAlign: 'center' }}>

                                                {
                                                    item.fmr.map((item, index) => {
                                                        return <span className='hoverCss cousor' key={index} onClick={this.nameSearch.bind(this, item)} style={{ cousor: 'pointer', marginLeft: '5px' }}>{item}</span>
                                                    })
                                                }
                                            </div>
                                        </div>

                                    })
                                }
                            </Checkbox.Group>
                        </div>
                    </div>

                    <Checkbox.Group style={{ width: '1109px', height: '100%', columnCount: '3', columnGap: '35px', margin: '0 0 10px 16px' }} className={(this.state.displayMode === 2 ? 'displayBlock' : 'displayNone')}>
                        {
                            selectList.map((item, index) => {
                                return <div className='list-item' key={index} style={{ width: '347px', display: "inline-block" }} onChange={this.onChange} >
                                    <div style={{ width: '347px', display: 'flex', flexDirection: "column", alignItems: 'center', justifyContent: 'center', textAlign: 'left', marginTop: '35px' }}>
                                        <Checkbox value={item.zlwyid} style={{ width: '347px', color: '#ffffff', backgroundColor: '#00C8DF', borderRadius: '4px 4px 0 0', height: '42px' }} className="title_select" >{index + 1}.{item.zlmc}</Checkbox>
                                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.24)', flexDirection: 'column' }}>
                                            <img alt="专利首页图" src={item.zltp} style={{ marginTop: '6px', width: 'auto', border: ' 0.5px solid #D9D9D9', height: 'auto', maxWidth: '320px', maxHeight: '100%' }} />
                                            <div style={{ display: "flex", flexDirection: 'row', height: '48px', fontSize: '12px', width: '100%', alignItems: 'center' }}>
                                                <div style={{ marginLeft: "14px" }}>{item.zlbh}</div>
                                                <div style={{ marginRight: '14px', marginLeft: 'auto' }}>{item.sqrq}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            })
                        }
                    </Checkbox.Group>
                    <div style={{ width: '100%', flexDirection: 'row' }} className={(this.state.displayMode === 3 ? 'displayFlex' : 'displayNone')}>
                        <div style={{ width: '910px', height: '100%', borderRight: '0.5px solid #CFCFCF ' }}>
                            <Checkbox.Group className='list-item' style={{ width: '851px', marginLeft: '24px', display: 'flex', flexDirection: 'column', height: '100%', borderBottom: '.5px solid rgba(0, 0, 0, 0.1)' }} onChange={this.onChange} >
                                {
                                    selectList.map((item, index) => {
                                        return <Row style={{ width: '100%', height: '100%' }} key={index} >
                                            <Col style={{ width: '100%', display: "flex", flexDirection: 'column', alignItems: 'center' }}>
                                                <div style={{ width: '100%', display: "flex", flexDirection: 'row', alignItems: 'center' }} className="text_content">
                                                    <Checkbox value={item.zlwyid}>
                                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: "center" }}>
                                                            <div className={item.zllx_code === '1' ? 'bg20B24D' : (item.zllx_code === '2' ? 'bgF9931A' : 'bgDA71C8')} style={{ width: '68px', color: '#fff', borderRadius: '4PX', height: '16px', fontSize: '12px', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>{item.zllx}</div>
                                                            <div className={item.flzt_code === '1' ? 'bg6D72E8' : (item.flzt_code === '2' ? 'bgF56F23' : 'bgBFBFBF')} style={{ width: '68px', color: '#fff', borderRadius: '4PX', height: '16px', fontSize: '12px', justifyContent: 'center', display: 'flex', alignItems: 'center', marginLeft: '4px' }}>{item.flzt}</div>
                                                        </div>
                                                    </Checkbox>
                                                    <div className="cousor" onClick={this.handleClickZlxq.bind(this, item.zlwyid)} style={{ marginLeft: '30px', width: '463px', color: 'rgba(40, 40, 40, 1)', fontWeight: 'bolder', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', cursor: 'pointer' }}>{index + 1}. {item.zlmc}</div>
                                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: "center", width: '114px', height: '30px', marginLeft: '51px' }}>
                                                        <div className="cousor" style={{ width: '15px', height: '15px', flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={this.download.bind(this, item.zlwyid)}>
                                                            <img src={require('../images/xz.png')} style={{ width: '14px', height: '13.5px' }} alt="下载" />
                                                        </div>
                                                        <div className="cousor" style={{ width: '15px', height: '16px', flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: '15px' }} onClick={this.monitor.bind(this, item.zlbh, item.flzt_code)} >
                                                            <img src={require('../images/jk.png')} style={{ width: '14px', height: '16px' }} alt="监控" />
                                                        </div>
                                                        <div className="cousor" style={{ width: '16px', height: '16px', flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: '15px' }} onClick={this.pay.bind(this, item.zlbh)} >
                                                            <img src={require('../images/jf.png')} style={{ width: '16px', height: '16px' }} alt="缴费" />
                                                        </div>
                                                        <div className="cousor" style={{ width: '15px', height: '15px', flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: '15px' }} onClick={this.remind.bind(this, item.zlbh, item.flzt_code)}>
                                                            <img src={require('../images/alt.png')} style={{ width: '15px', height: '15px' }} alt="提醒" />
                                                        </div>
                                                        <div className="cousor" style={{ width: '15px', height: '15px', flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: '15px' }} onClick={this.collection.bind(this, item.is_favorite, item.zlwyid)} >
                                                            <img src={require(item.is_favorite === '2' ? '../images/shoucangft.png' : '../images/shoucang.png')} style={{ width: '15px', height: '15px' }} alt="收藏" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{ width: '851px', display: 'flex', flexDirection: 'column', minHeight: '150px', marginBottom: '10px', lineHeight: '30px' }}>
                                                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row', minHeight: '150px', marginBottom: '10px', lineHeight: '30px' }}>
                                                        <Image width={154} height={154} alt="专利图片" src={item.zltp} />
                                                        <div style={{ width: '621px', minHeight: '150px', display: "flex", flexDirection: 'column', marginLeft: '27px' }}>
                                                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                                                <div style={{ width: '180px' }}>申请号：{item.zlbh}</div>
                                                                <div style={{ marginLeft: '118px', width: '230px' }}>公开（公告）号：{item.zlwyid}</div>
                                                            </div>
                                                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                                                <div className='hoverCss cousor' onClick={this.sqrqSearch.bind(this, item.sqrq)} style={{ width: '180px' }}>申请日：{item.sqrq}</div>
                                                                <div className='hoverCss cousor' onClick={this.gkrqSearch.bind(this, item.gkr)} style={{ marginLeft: '118px', width: '230px' }}>公开（公告）日：{item.gkr}</div>
                                                            </div>
                                                            {/* <div>权项数：6</div> */}
                                                            <div className='hoverCss cousor' onClick={this.zflhSearch.bind(this, item.zflh)} >分类号：{item.zflh}</div>
                                                            <div>申请人：
                                                                {
                                                                    item.sqr.map((item, index) => {
                                                                        return <span className='hoverCss cousor' key={index} onClick={this.sqrSearch.bind(this, item)} style={{ cousor: 'pointer', marginLeft: '5px' }}>{item}</span>
                                                                    })
                                                                }
                                                            </div>
                                                            <div>发明人：
                                                                {
                                                                    item.fmr.map((item, index) => {
                                                                        return <span className='hoverCss cousor' key={index} onClick={this.nameSearch.bind(this, item)} style={{ cousor: 'pointer', marginLeft: '5px' }}>{item}</span>
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style={{ minHeight: '77px', maxWidth: '851px', lineHeight: '25px', overflow: 'hidden' }}>摘要：{item.zy}</div>
                                                </div>
                                            </Col>
                                        </Row>
                                    })
                                }
                            </Checkbox.Group>
                        </div>
                        <div style={{ width: '759px' }}>
                            <Tabs tabBarGutter={27} centered={true} size="small" defaultActiveKey="1" onChange={this.callback} style={{ width: '100%', backgroundColor: '#F4F4F4', border: '1px solid #E7E7E7', fontSize: '12px', color: '#A9A9A9' }} >
                                <TabPane tab={<span className='tabBlick'><InformationConfig style={{ fontSize: '16px', marginRight: '5px', marginLeft: "10px" }} />基本信息</span>} key="1" >
                                    <div className={this.state.loadingCompent === false ? 'displayFlex' : 'displayNone'} style={{ width: '100%', height: '500px', alignItems: 'center', justifyContent: 'center' }}>
                                        <Space size="middle" >
                                            <Spin size="large" />
                                        </Space>
                                    </div>
                                    <div className={this.state.loadingCompent === true ? '' : 'displayNone'}>
                                        <div style={{ width: '100%', flexDirection: 'row', margin: '32px 18px 0 18px' }} >
                                            <span style={{ width: '20px', height: '20px', borderRadius: "4px", background: '#00C8DF' }}></span>
                                            <div style={{ marginLeft: '9px', fontWeight: '700' }}>基本信息:</div>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: 'column', width: '581px', lineHeight: '48px', margin: '16px 0 0 47px' }}>
                                            <div >专利标题： {this.state.zlmc}</div>
                                            <div>申请号：{this.state.zlsqh}</div>
                                            <div className='hoverCss cousor' onClick={this.sqrqSearch.bind(this, this.state.zlsqrq)}>申请日：{this.state.zlsqrq}</div>
                                            <div>公开（公告）号：{this.state.zlwyid}</div>
                                            <div className='hoverCss cousor' onClick={this.gkrqSearch.bind(this, this.state.zlgkr)}>公开（公告）日：{this.state.zlgkr}</div>
                                            <div>发明人 ：
                                                {
                                                    this.state.zlfmr.map((item, index) => {
                                                        return <span className='hoverCss cousor' key={index} onClick={this.nameSearch.bind(this, item)} style={{ cousor: 'pointer', marginLeft: '5px' }}>{item}</span>
                                                    })
                                                }
                                            </div>
                                            <div>申请人：
                                                {
                                                    this.state.zlsqr.map((item, index) => {
                                                        return <span className='hoverCss cousor' key={index} onClick={this.sqrSearch.bind(this, item)} style={{ cousor: 'pointer', marginLeft: '5px' }}>{item}</span>
                                                    })
                                                }
                                            </div>
                                            <div>申请人地址： {this.state.sqrdz}</div>
                                            <div>专利权人： {this.state.zlqr}</div>
                                            <div>当前专利权人： {this.state.zldqr}</div>
                                            <div>代理机构： {this.state.dljg}</div>
                                            <div> 代理人： {this.state.dlre}</div>
                                            <div className='hoverCss cousor' onClick={this.zflhSearch.bind(this, this.state.zlzflh)} >主分类号： {this.state.zlzflh}</div>
                                            <div> I PC分类号： {this.state.zlipcfl}</div>
                                        </div>
                                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', margin: '30px 18px 30px 18px' }}>
                                            <span style={{ width: '20px', height: '20px', borderRadius: "4px", background: '#00C8DF' }}></span>
                                            <div style={{ marginLeft: '9px', fontWeight: '700' }}>摘要:</div>
                                        </div>
                                        <div style={{ width: '581px', marginLeft: '47px', lineHeight: '30px', marginBottom: '50px' }}>{this.state.zy}</div>
                                    </div>
                                </TabPane>
                                <TabPane tab={<span className='tabBlick' onClick={this.handleClickQlyqs.bind(this, this.state.zlwyid)}><RequireBook style={{ width: '16px', height: '16px', fontSize: '16px', marginRight: '5px' }} />权利要求书</span>} key="2" >
                                    <div style={{ width: '600px' }}>
                                        <div className={this.state.loadingCompent === false ? 'displayFlex' : 'displayNone'} style={{ width: '100%', height: '500px', alignItems: 'center', justifyContent: 'center' }}>
                                            <Space size="middle" >
                                                <Spin size="large" />
                                            </Space>
                                        </div>
                                        <div className={this.state.loadingCompent === true ? '' : 'displayNone'}>
                                            <div style={{ width: '100%', display: "flex", flexDirection: 'row', margin: '30px 0 20px 18px' }}>
                                                <div>字体大小</div>
                                                <Radio.Group options={optionsFontSize} onChange={this.onChangeFontSize} value={this.state.value4} optionType="button" style={{ width: '300px', display: "flex", flexDirection: 'row', marginLeft: '12px' }} className="fontSizeSelect" ></Radio.Group>
                                            </div>
                                            {/* <div style={{ width: '125px', height: '24px', border: '0.5px solid #D9D9D9', color: '#666666', display: "flex", flexDirection: "row", alignItems: 'center', justifyContent: 'center', marginLeft: '18px' }}>权利要求数量 <div style={{ fontSize: '12px', color: '#00C8DF', borderBottom: '1px solid #00C8DF', marginLeft: '5px' }}>{this.state.qls}</div></div> */}
                                            <div style={{ width: '100%' }}>
                                                {
                                                    this.state.qlyqs.map((item, index) => {
                                                        return <div key={index} style={{ width: '100%', margin: '20px 0 20px 18px', display: 'flex', flexDirection: 'column' }} >
                                                            <div style={{ color: '#404040', 'fontSize': this.state.value4 + 'px', marginTop: '15px', 'lineHeight': this.state.value4 * 2 + 'px' }}>{item}</div>
                                                        </div>
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </TabPane>
                                <TabPane className='tabBlick' tab={<span onClick={this.handleClickZlsms.bind(this, this.state.zlwyid)}><Instructions style={{ width: '16px', height: '16px', fontSize: '16px', marginRight: '5px' }} />说明书</span>} key="3" >
                                    <div className={this.state.loadingCompent === false ? 'displayFlex' : 'displayNone'} style={{ width: '100%', height: '500px', alignItems: 'center', justifyContent: 'center' }}>
                                        <Space size="middle" >
                                            <Spin size="large" />
                                        </Space>
                                    </div>
                                    {
                                        this.state.zlsms.map((item, index) => {
                                            return <div key={index} style={{ width: '600px' }} className={(this.state.loadingCompent === true ? '' : 'displayNone') + ' smsStyle'} >
                                                {/* <div style={{ width: '100%', display: 'flex', flexDirection: 'row', margin: '32px 18px 0 18px' }}>
                                                        <span style={{ width: '20px', height: '20px', borderRadius: "4px", background: '#00C8DF' }}></span>
                                                        <div style={{ marginLeft: '9px', fontWeight: '700' }}>{item.name}:</div>
                                                    </div> */}
                                                <div key={index} style={{ display: "flex", flexDirection: 'column', width: '581px', lineHeight: '48px', margin: '16px 0 0 47px' }}>
                                                    <div dangerouslySetInnerHTML={{ __html: item }} ></div>
                                                </div>
                                            </div>
                                        })
                                    }

                                </TabPane>
                                <TabPane tab={<span className='tabBlick' ><Drawings style={{ width: '16px', height: '16px', fontSize: '16px', marginRight: '5px' }} />附图</span>} key="4" >
                                    <div style={{ width: '600px', display: 'flex', flexWrap: 'wrap' }}>
                                        {
                                            this.state.zltp.map((item, index) => {
                                                return <div key={index} style={{ width: '280px', height: '260px', marginLeft: '18px', marginTop: '31px', display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
                                                    <div className="imgSwipper" style={{ width: '100%', height: '220px', display: "flex", justifyContent: 'center', alignItems: 'center', border: '1px solid #DFDFDF', borderRadius: '4px', boxShadow: ' 0px 0px 4px rgba(0, 0, 0, 0.24)' }}>
                                                        <Image width={250} height={189} style={{ border: '0.5px solid #E3E3E3', borderRadius: "4px" }} src={item} />
                                                    </div>
                                                    <div style={{ marginTop: '24px', fontWeight: '700' }}>图{index + 1}</div>
                                                </div>
                                            })
                                        }
                                    </div>
                                </TabPane>
                                <TabPane tab={<span className='tabBlick' onClick={this.handleClickPdf.bind(this, this.state.zlwyid)}><Pbook style={{ width: '16px', height: '16px', fontSize: '16px', marginRight: '5px' }} />PDF全文</span>} key="5" >
                                    <iframe title="pdf" src={this.state.pdf_url} style={{ width: '600px', height: '924px', margin: "30px 0 30px 18px" }} />
                                </TabPane>
                                <TabPane tab={<span className='tabBlick'><Legal style={{ width: '24px', height: '24px', fontSize: '24px', marginRight: '5px' }} />法律信息</span>} key="6" >
                                    <div style={{ width: '600px', border: '0.5px solid #E3E3E3', borderRadius: '4px', display: 'flex', flexDirection: 'column', margin: "30px 0 30px 18px" }}>
                                        <div style={{ width: '526px', height: '50px', borderBottom: '0.5px solid rgba(0, 0, 0, 0.1)', display: "flex", flexDirection: 'row', lineHeight: '50px', marginLeft: '37px' }}>当前法律状态：<div style={{ marginLeft: '22px', fontWeight: '700' }}>{this.state.zlyxx}</div></div>

                                        {
                                            this.state.zlflxx.map((item, index) => {
                                                return <div key={index} style={{ width: '526px', display: "flex", flexDirection: 'row', borderBottom: '0.5px solid rgba(0, 0, 0, 0.1)', marginLeft: '42px', marginTop: '29px' }}>
                                                    <div style={{ width: '90px', display: 'flex', flexDirection: 'column', lineHeight: '28px' }}>
                                                        <div>法律状态公开日</div>
                                                        <div>法律状态信息</div>
                                                        <div>法律状态</div>
                                                    </div>
                                                    <div style={{ width: '90px', display: 'flex', flexDirection: 'column', marginLeft: '308px', lineHeight: '28px' }}>
                                                        <div>{item.date}</div>
                                                        <div>{item.content}</div>
                                                        <div>{item.type}</div>
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                                </TabPane>
                                <TabPane tab={<span className='tabBlick' onClick={this.handelClickSame.bind(this, this.state.zlwyid)}><Same style={{ width: '20px', height: '20px', fontSize: '20px', marginRight: '5px' }} />相似专利</span>} key="7" >
                                    <Table bordered="true" columns={columns} dataSource={this.state.sameData} size="small" style={{ fontSize: '12px', textAlign: 'center', lineHeight: '116px', height: '116px', width: '600px', margin: '30px 0 0 18px' }} />
                                </TabPane>
                            </Tabs>
                        </div>
                    </div>
                    <Fixednav />
                    <Pagination href="#top" style={{ width: '875px', height: '60px', marginTop: '24px', marginLeft: '20px', paddingLeft: '24px', borderTop: '1px solid #D9D9D9', display: "flex", alignItems: 'center' }} showQuickJumper defaultCurrent={1} total={1000} pageSizeOptions={psOptions} showSizeChanger onChange={this.onPageChange} />
                </div>
                <SearchFixed />
            </Fragment>
        )
    }
}

export default withRouter(Content)