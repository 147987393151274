import React, { Fragment, Component } from 'react'
import { withRouter } from 'react-router-dom'
import QzxyXq from '../Component/QzxyXq.js'

class QZXYxq extends Component {
    constructor(props) {
        super(props)

        this.state = {
            
        }
    }

    /**
     *
     *
     * @return {*} 其知学院详情
     * @memberof QZXYxq zxy
     */
    render() {
        return (
            <Fragment>
                <QzxyXq />
            </Fragment>
        )
    }
}

export default withRouter(QZXYxq)