import React, { Component,Fragment } from 'react'
import api from '../api/api'
import { message } from 'antd'
import '../page-css/Signin.css'
import md5 from 'js-md5'

/**
 *
 *
 * @class Signin  登录
 * @extends {Component} zxy
 */
class Signin extends Component {
    constructor(props) {
        super(props)

        this.state = {
            hg:0,
            isYzm:false,
            err:'',
            iserr:false,
            time:60
        }
    }

    record() {
        const w = window.open('about:blank');
        w.location.href = `https://beian.miit.gov.cn`
    }

    hgchange(index){
        this.setState({
            hg:index,
            iserr:false
        })
    }
    
    submit(){
        var mobile = this.refs.username.value
        var yzm = this.refs.yzm.value
        if(mobile == ''){
            this.setState({
                err:'请输入电话',
                iserr:true
            })
        }else if(yzm == ''){
            this.setState({
                err:'请输入验证码',
                iserr:true
            })    
        }else{
            api.post({
                str:'/login/login'
            },{
                mobile,
                code:yzm,
                type:2
            })
            .then(res => res.json())
            .then(res => {
                if(res.code == 200){
                    localStorage.setItem('token', res.data.token)
                    this.props.history.push('/')
                }else{
                    this.setState({
                        err: res.msg,
                        iserr: true
                    })
                }
            })
        }
    }

    yzm(){
        const regs = /^((13[0-9])|(14[57])|(15[^4,\\D])|(16[0-9])|(17[0-1,6-8])|(18[0-9])|(19[0-9]))\d{8}$/
        var mobile = this.refs.username.value.replace(/\s/g, '')
        if (mobile === '') {
            var err = '请输入手机号'
            this.setState({
                err: err,
                iserr: true
            })
        } else {
            if (!regs.test(mobile) || mobile.length !== 11) {
                this.setState({
                    err: '请输入正确的电话',
                    iserr:true
                })
            } else {
                this.lastTime()
                api.post({
                    str: '/login/getCode'
                }, {
                    mobile: mobile,
                    scene:'login'
                })
                    .then(res => res.json())
                    .then(res => {
                        if (res.code === 200) {
                            this.setState({
                                err: res.msg,
                                iserr: false
                            })
                        } else {
                            this.setState({
                                err: res.msg,
                                iserr: true
                            })
                        }
                    })
            }
           
        }
    }

    lastTime() {
        let time = 60
        this.interval = setInterval(() => {
            if (time > 0) {
                time--
                this.setState({
                    isYzm: true,
                    time: time
                })
            } else if (time === 0) {
                this.setState({
                    isYzm: false,
                })
                clearInterval(this.interval)
            }
        }, 1000)
    }

    zhuce(){
        this.props.history.push('/ZhuCe')
    }

    QQdl(){
        api.post({
            str: '/login/login_link'
        }, {
            type: 2,
            return_url: 'https://www.qzhip.com/'
        })
            .then(res => res.json())
            .then(res => {
                if (res.code === 200) {
                    var link = res.data
                    window.location.href=link
                } else {
                    message.error(res.msg)
                }
            })
    }

    Wechat() {
        api.post({
            str: '/login/login_link'
        }, {
            type: 1,
            return_url: 'https://www.qzhip.com/'
        })
            .then(res => res.json())
            .then(res => {
                if (res.code === 200) {
                    var link = res.data
                    window.location.href=link
                } else {
                    message.error(res.msg)
                }
            })
    }

    forgetpassword(){
        this.setState({
            hg:3
        })
    }

    submit2(){
        var username2 = this.refs.username2.value
        var password = this.refs.password.value
        if(username2 == ''){
            this.setState({
                err:'请输入账号',
                iserr:true
            })
        }else if(password == ''){
            this.setState({
                err:'请输入密码',
                iserr:true
            })    
        }else{
            api.post({
                str:'/login/login'
            },{
                mobile:username2,
                password:md5(password),
                type:1
            })
            .then(res => res.json())
            .then(res => {
                if(res.code == 200){
                    localStorage.setItem('token', res.data.token)
                    this.props.history.push('/')
                }else{
                   this.setState({
                       err:res.msg,
                       iserr: true
                   })
                }
            })
        }
    }

    submitchange(){
        var tel = this.refs.tel.value
        var yzm = this.refs.yzm2.value
        var newpassword = this.refs.newpassword.value
        var newpassword2 = this.refs.newpassword2.value
        const regs = /^((13[0-9])|(14[57])|(15[^4,\\D])|(16[0-9])|(17[0-1,6-8])|(18[0-9])|(19[0-9]))\d{8}$/
        var mobile = tel.replace(/\s/g, '')
        if (mobile === '') {
            var err = '请输入手机号'
            this.setState({
                err: err,
                iserr: true
            })
        }else if(yzm == ''){
            this.setState({
                err: '请输入验证码',
                iserr:true
            })
        } else {
            if (!regs.test(mobile) || mobile.length !== 11) {
                this.setState({
                    err: '请输入正确的电话',
                    iserr:true
                })
            } else if(newpassword != newpassword2) {
                this.setState({
                    err:'两次密码不一致',
                    iserr:true
                })
            }else{
                api.post({
                    str:'/login/forgetPassword'
                },{
                    mobile,
                    password:md5(newpassword),
                    code:yzm
                })
                .then(res => res.json())
                .then(res => {
                    if(res.code == 200){
                        message.success('修改成功，正在返回登录页面')
                        setTimeout((e) => {
                           this.setState({
                            hg:1
                        })
                       }, 1000);
                    }else{
                        this.setState({
                            err:res.msg,
                            iserr:true
                        })
                    }
                })
            }
        }
    }

    handlesign(){
        this.setState({
            hg:1
        })
    }

    handleindex(){
        this.props.history.push('/')
    }

    yzm2(){
        const regs = /^((13[0-9])|(14[57])|(15[^4,\\D])|(16[0-9])|(17[0-1,6-8])|(18[0-9])|(19[0-9]))\d{8}$/
        var mobile = this.refs.tel.value.replace(/\s/g, '')
        if (mobile === '') {
            var err = '请输入手机号'
            this.setState({
                err: err,
                iserr: true
            })
        } else {
            if (!regs.test(mobile) || mobile.length !== 11) {
                this.setState({
                    err: '请输入正确的电话',
                    iserr:true
                })
            } else {
                this.lastTime()
                api.post({
                    str: '/login/getCode'
                }, {
                    mobile: mobile,
                    scene:'forget'
                })
                    .then(res => res.json())
                    .then(res => {
                        if (res.code === 200) {
                            this.setState({
                                err: res.msg,
                                iserr: false
                            })
                        } else {
                            this.setState({
                                err: res.msg,
                                iserr: true
                            })
                        }
                    })
            }
           
        }
    }

    render() {
        return (
            <Fragment>
                <div className='Sign-in'>
                    <div className='Sign-in-header'>
                        <img onClick={this.handleindex.bind(this)} src={require('../images/LOGO.png')} />
                        <span>用户登录</span>
                    </div>
                    <div className='Sign-in-content'>
                        <img src='http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20211011/12849c0c5b0c4871fd43711e9bdfcd31f9d6e5f2.png' />
                        <div className={this.state.hg != 3 ? 'Sign-in-mobile-sign' : 'displaynone'} >
                            <div className='Sign-in-mobile-sign-header'>
                                <ul>
                                    <li onClick={this.hgchange.bind(this,0)} className={this.state.hg == 0 ? 'active-sign' : ''}><span className={this.state.hg == 0 ? 'active-sign-span' : ''}>手机</span>登录</li>
                                    <li onClick={this.hgchange.bind(this,1)} className={this.state.hg == 1 ? 'active-sign' : ''}><span className={this.state.hg == 1 ? 'active-sign-span' : ''}>密码</span>登录</li>
                                </ul>
                            </div>
                            <div className={this.state.hg == 0 ? 'Sign-in-mobile-sign-input' : 'displaynone'}>
                                <span>用户名：</span>
                                <input placeholder='请输入用户名' ref='username' />
                            </div>
                            <div className={this.state.hg == 1 ? 'Sign-in-mobile-sign-input' : 'displaynone'}>
                                <span>用户名：</span>
                                <input placeholder='请输入用户名' ref='username2' />
                            </div>
                            <div className={this.state.hg == 0 ? 'Sign-in-mobile-sign-input2' : 'displaynone'} >
                                <span>验证码：</span>
                                <input placeholder='请输入验证码' ref='yzm' />
                                <div className={this.state.isYzm == false ? '' : 'displaynone'} onClick={this.yzm.bind(this)}>发送验证码</div>
                                <div className={this.state.isYzm == true ? '' : 'displaynone'}>{this.state.time}s</div>
                            </div>
                            <div className={this.state.hg == 1 ? 'Sign-in-mobile-sign-input3' : 'displaynone'} >
                                <span>密码：</span>
                                <input placeholder='请输入密码' ref='password' type='password' />
                            </div>
                            <span onClick={this.zhuce.bind(this)} className={this.state.hg == 0 ? 'Sign-in-mobile-sign-register' : 'displaynone'} >免费注册</span>
                            <div className={this.state.hg == 1 ? 'Sign-in-mobile-sign-forget' : 'displaynone'}>
                                <span onClick={this.forgetpassword.bind(this)}>忘记密码</span>
                                <span onClick={this.zhuce.bind(this)}>免费注册</span>
                            </div>
                            <span className={this.state.iserr == true ? ' ' : 'displaynone'} style={{ marginLeft: '50px', marginTop: '5px', color: '#ff0000', fontSize: '12px' }}>{this.state.err}</span>
                            <div onClick={this.submit.bind(this)} className={this.state.hg == 0 ? 'Sign-in-mobile-sign-submit' : 'displaynone'}>登录</div>
                            <div onClick={this.submit2.bind(this)} className={this.state.hg == 1 ? 'Sign-in-mobile-sign-submit' : 'displaynone'}>登录</div>
                            <div className='Sign-in-mobile-sign-quick'>
                                <img onClick={this.QQdl.bind(this)} src={require('../images/qq.png')} />
                                <img onClick={this.Wechat.bind(this)} src={require('../images/wechatcai.png')} />
                            </div>
                        </div>
                        <div className={this.state.hg == 3 ? 'Sign-in-mobile-sign-forget-content' : 'displaynone'}>
                            <p>忘记密码</p>
                            <div className='Sign-in-mobile-sign-forget-input'>
                                <span>手机号：</span>
                                <input placeholder='请输入手机号' ref='tel' />
                            </div>
                            <div className='Sign-in-mobile-sign-forget-input2'>
                                <span>验证码：</span>
                                <input placeholder='请输入验证码' ref='yzm2' />
                                <div className={this.state.isYzm == false ? '' : 'displaynone'} onClick={this.yzm2.bind(this)}>发送验证码</div>
                                <div className={this.state.isYzm == true ? '' : 'displaynone'}>{this.state.time}s</div>
                            </div>
                            <div className='Sign-in-mobile-sign-forget-input'>
                                <span>新密码：</span>
                                <input placeholder='请输入新密码' ref='newpassword' />
                            </div>
                            <div className='Sign-in-mobile-sign-forget-input'>
                                <span>重复密码：</span>
                                <input placeholder='请输入新密码' ref='newpassword2' />
                            </div>
                            <span onClick={this.handlesign.bind(this)} style={{marginLeft:'auto',marginRight:'30px',fontSize:'1.4rem',fontWeight:'400',color:"#00C8DF",marginTop:'1.8rem',cursor:'pointer'}}>前往登陆</span>
                            <span className={this.state.iserr == true ? ' ' : 'displaynone'} style={{ marginLeft: '30px', marginTop: '20px', color: '#ff0000', fontSize: '12px' }}>{this.state.err}</span>
                            <div onClick={this.submitchange.bind(this)} className='Sign-in-mobile-sign-forget-submit'>确认修改</div>
                        </div>
                    </div>
                    <div className='cousor'  onClick={this.record.bind(this)} style={{width:'500px',color:'#333',fontSize:'1.2rem',marginTop:'auto',marginBottom:'2.8vh',textAlign:'center',zIndex:'5'}}>Copyright2018四川中索汇智信息技术有限公司，AllRightReserved蜀ICP备18026701号-3</div>
                </div>
            </Fragment>
        )
    }
}

export default Signin