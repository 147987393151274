import React, { Component, Fragment } from 'react';
import { message, Modal } from 'antd';
import { BackTop } from 'antd';
import '../Component-css/fixednav.css';
import Icon from '@ant-design/icons'
import api from '../api/api';

const kefu = () => (
    <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 1024 1024">
        <path d="M634.24797 698.51718a5.373537 5.373537 0 0 0-8.785307-5.117655c-18.50885 15.907376-58.725084 45.205947-104.186914 45.205947-46.783891 0-92.458956-17.314731-112.375162-25.929449a5.373537 5.373537 0 0 0-7.164716 6.780893c8.102953 22.176502 35.39711 68.832452 119.923702 68.832451 84.441298-0.085294 106.745742-60.772146 112.588397-89.772187z" fill="#2F80ED" />
        <path d="M952.736662 349.749032C905.398359 149.478156 725.768689 0 511.765441 0 297.932781 0 118.132523 149.478156 70.794219 349.749032 30.620632 356.999042 0 392.182916 0 434.659448v156.81346c0 47.67948 38.552997 86.360418 86.147183 86.360418 28.147099 0 53.01037-13.647078 68.747157-34.586814a374.996127 374.996127 0 0 0 205.132648 206.497355c6.482362 2.686769 11.386781-6.055891 5.800008-10.235308-62.776561-47.551539-123.889884-124.273708-119.710466-241.851818 0-25.460331 25.375036-66.700096 77.14864-74.120695 51.730957-7.420599 287.313648-3.155887 316.868102-128.197243 6.397068-33.90446 3.41177-59.151556 24.991213-41.666236 9.510308 8.316188 82.735413 91.478073 92.629544 227.053267 5.92795 81.541294-5.288243 170.375245-66.103036 235.667985-4.691183 4.989713 1.450002 12.666195 7.250011 9.169131a373.46083 373.46083 0 0 0 161.632585-195.537045c9.638249 19.319145 26.270626 34.458873 46.528008 42.220649-10.576486 43.116238-33.733872 101.713381-84.483945 151.354629-62.478031 61.198617-152.292866 94.889842-267.184207 100.39132a70.794219 70.794219 0 0 0-64.780976-42.433884c-39.064762 0-70.794219 31.772104-70.794219 71.007455 0 39.150056 31.68681 70.964808 70.794219 70.964808 29.597101 0 55.014785-18.338262 65.548624-44.225064 129.647245-5.970597 232.085627-44.86477 304.926908-116.085461 64.482446-63.117738 91.179543-137.536962 102.267794-189.353213A86.360418 86.360418 0 0 0 1023.530882 591.515555v-156.856107a86.147183 86.147183 0 0 0-70.79422-84.867769zM505.240431 135.4899c-162.314939 0-300.576902 101.329557-353.501978 243.344468a86.786889 86.786889 0 0 0-26.185332-20.897089C168.754654 184.3635 325.525467 55.313315 511.765441 55.313315s343.053434 129.007538 386.212319 302.623964c-14.627962 7.591187-26.782391 19.191204-34.970638 33.520636-49.257424-148.582566-190.803215-255.968015-357.809338-255.968015z" fill="#2F80ED" />
    </svg>
);

const top = () => (
    <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 1024 1024">
        <path d="M726.1952 446.3616l-173.1584-174.0288A54.7584 54.7584 0 0 0 512 256.0512a55.552 55.552 0 0 0-41.0368 16.2816l-173.1584 174.0288a55.936 55.936 0 0 0 0 78.7456 55.552 55.552 0 0 0 78.9248 0.0512l79.5392-79.9232v267.008c0 30.72 24.9856 55.7568 55.7312 55.7568a55.808 55.808 0 0 0 55.7312-55.7568V445.184l79.5392 79.9232a55.552 55.552 0 0 0 78.9248 0.0512c21.6064-21.76 21.6064-57.088 0-78.7968z" />
    </svg>
);

const meet = () => (
    <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 1024 1024">
        <path d="M0 972.8a51.2 51.2 0 0 0 51.2 51.2h153.6a51.2 51.2 0 0 0 51.2-51.2V51.2a51.2 51.2 0 0 0-51.2-51.2H51.2a51.2 51.2 0 0 0-51.2 51.2v921.6zM384 972.8a51.2 51.2 0 0 0 51.2 51.2h153.6a51.2 51.2 0 0 0 51.2-51.2V563.2a51.2 51.2 0 0 0-51.2-51.2H435.2a51.2 51.2 0 0 0-51.2 51.2v409.6zM768 972.8a51.2 51.2 0 0 0 51.2 51.2h153.6a51.2 51.2 0 0 0 51.2-51.2V307.2a51.2 51.2 0 0 0-51.2-51.2h-153.6a51.2 51.2 0 0 0-51.2 51.2v665.6z" fill="#00C8DF" />
    </svg>
);

const Kefu = props => <Icon component={kefu} {...props} />
const Top = props => <Icon component={top} {...props} />
const Meet = props => <Icon component={meet} {...props} />

class Fixednav extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            isHover: false,
            visible: false,
            isHoverGzh: false,
            isTopHover: false,
            problems: '',
            isKefu: false,
            textArea: '',
            iswechat:false
        }
        this.handlemouseEnter = this.handlemouseEnter.bind(this);
        this.handlemouseLeave = this.handlemouseLeave.bind(this);
        this.handlemouseGzhEnter = this.handlemouseGzhEnter.bind(this);
        this.handlemouseGzhLeave = this.handlemouseGzhLeave.bind(this);
        this.handlemouseTopEnter = this.handlemouseTopEnter.bind(this);
        this.handlemouseTopLeave = this.handlemouseTopLeave.bind(this);
        this.showModal = this.showModal.bind(this);
        this.handleCancel = this.handleCancel.bind(this)
        this.handleTextarea = this.handleTextarea.bind(this)
        this.handleKefuOk = this.handleKefuOk.bind(this)
    }

    handleTextarea = (e) => {
        this.setState({
            problems: e.target.value
        })
    }

    handlemouseEnter() {
        const isHover = true
        this.setState({
            isHover: isHover
        })
    }

    handlemouseLeave() {
        const isHover = false
        this.setState({
            isHover: isHover
        })
    }

    handlemouseTopEnter() {
        const isTopHover = true
        this.setState({
            isTopHover: isTopHover
        })
    }

    handlemouseTopLeave() {
        const isTopHover = false
        this.setState({
            isTopHover: isTopHover
        })
    }

    handlemouseGzhLeave() {
        const isHoverGzh = false
        this.setState({
            isHoverGzh: isHoverGzh
        })
    }

    handlemouseGzhEnter() {
        const isHoverGzh = true
        this.setState({
            isHoverGzh: isHoverGzh
        })
    }

    handleCancel = e => {
        this.setState({
            visible: false,
            isKefu: false
        });
    };

    kefu() {
        this.setState({
            isKefu: true
        })
    }

    handleKefuTextarea(e) {
        this.setState({
            textArea: e.target.value
        })
    }

    handleKefuOk() {
        const regs = /^((13[0-9])|(14[57])|(15[^4,\\D])|(16[0-9])|(17[0-1,6-8])|(18[0-9])|(19[0-9]))\d{8}$/
        var number = this.refs.tel.value.replace(/\s/g, '')
        // var name = this.refs.name.value
        var text = this.state.textArea
        const visible = false

        if (!regs.test(number) || number.length !== 11) {
            message.error('联系电话无效')
        } else {
            //     let formData = new FormData()
            //     formData.append('phone',number)
            //     formData.append('name',name)
            //     formData.append('content',text)
            //     formData.append('title','急速回馈')
            //     formData.append('key','05F7CCD2-3798-68C7-85E6-8E709BA614B5')
            //   fetch('https://cdn.wininsales.com/Api/OpenSvrApi/add_opportunity',{
            //       method:'post',
            //       body:formData
            //   })
            //   .then(res => res.json())
            //   .then(res => {
            //       if(res.data === '0'){
            //           message.success(res.info)
            //       }else{
            //           message.error(res.info)
            //       }
            //   })
            // api.post({
            //     str:'https://cdn.wininsales.com/Api/OpenSvrApi/add_opportunity'
            // },{
            //     phone:number,
            //     name:name,
            //     content:text,
            //     title:'急速回馈',
            //     key:'05F7CCD2-3798-68C7-85E6-8E709BA614B5'
            // })
            api.post({
                str: '/news/addProblem'
            }, {
                content: text,
                mobile: number
            })
                .then(res => res.json())
                .then(res => {
                    if (res.code === 200) {
                        this.setState({
                            visible: visible
                        })
                    } else {
                        message.error('提交失败')
                    }
                })
        }
        this.setState({
            isKefu: false
        })
    }

    showModal() {
        this.setState({
            visible: true,
        });
    }
    setwechat() {
        this.setState({
            iswechat: true
        })
    }
    removewechat() {
        this.setState({
            iswechat: false
        })
    }

    render() {
        return (
            <Fragment>
                <div style={{zIndex:90}} className={this.state.iswechat == true ? 'fixedtop-left' : 'opacitynone2'}>
                    <img style={{width:'140px',height:140}} alt='' src='http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210830/3640774c04e4e3d6294d27bb82012f4db96378a6.png' />
                </div>
                <ul className="fixedtop">
                    <li style={{ borderRadius: '50%' }}>
                        <a style={{ width: '48px', height: '48px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} rel="noopener noreferrer" target="_blank" href="http://wpa.qq.com/msgrd?v=3&uin=407650541&site=qq&menu=yes"><img src={require('../images/qq.png')} style={{ width: '24px', height: '24px' }} alt='QQ' /></a>
                    </li> 
                    <li onMouseOver={this.setwechat.bind(this)} onMouseOut={this.removewechat.bind(this)} className='fixedwechat' style={{ fontSize: '1.5rem', borderRadius: '50%', marginTop: '20px' }}>
                        <img style={{width:'28',height:'24px'}} src={require('../images/wechatcai.png')} />
                    </li>
                    {/* <li onClick={this.kefu.bind(this)} style={{fontSize:'1.5rem',borderRadius:'50%',marginTop:'20px'}}>
                        <Kefu />
                    </li> */}
                    {/* <Modal 
                        visible={this.state.isKefu}
                        width={615}
                        height={591}
                        onOk={this.handleKefuOk}
                        onCancel={this.handleCancel}
                        className='meet-professor-wrap'
                        centered
                        footer={[

                        ]}
                    >
                         <div className='meet-professor'>
                            <div className='meet-professor-content'></div>
                            <div className='meet-professor-title'><Meet style={{marginRight:'10px',fontSize:'1rem'}} />专家答疑</div>
                            <textarea className='meet-professor-content-content' placeholder='请简要描述您所遇到的问题，我们的专业人员将第一时间为您答疑。'  onChange={this.handleKefuTextarea.bind(this)}></textarea>
                            <div className='meet-professor-conver'></div> */}
                    {/* <img src={require('../images/professor.png')} className='meet-professor-img' alt=' ' /> */}
                    {/* <div className='meet-professor-footer-wrap' key='input'>
                                <div className='meet-professor-footer-tel-wrap'>
                                    <span style={{color:'#fff',fontSize:'0.875rem',fontWeight:'700'}}>电话：<input ref='tel' className='meet-professor-footer-tel' /></span>
                                    <span style={{fontWeight:'400',fontSize:'0.75rem',color:'#BDBDBD',marginTop:'20px'}}>*填写电话有助于快速解决您的问题</span>
                                </div>
                                <div onClick={this.handleCancel} className='meet-professor-footer-exit cousor'>暂不询问</div>
                                <div onClick={this.handleKefuOk} className='meet-professor-footer-submit cousor'>立即提交</div>
                           </div>
                        </div> */}
                    {/* <div style={{ width: '932px', height: '79px', color: 'rgba(0,0,0,0.8)', fontWeight: 'bold', fontSize: '16px', textAlign: 'center', lineHeight: '79px', padding: "0", borderBottom: '1px solid rgba(0, 0, 0, 0.1)' }}>快速反馈</div>
                        <textarea onChange={this.handleKefuTextarea.bind(this)} className="question" placeholder="请简要描述您所遇到的问题，我们将第一时间为您答疑" style={{ width: '852px', height: '150px', padding: '20px', margin: '31px 40px', resize: 'none' }}></textarea> */}
                    {/* </Modal> */}
                    <BackTop style={{ borderRadius: '50%' }} visibilityHeight={200} className={(this.state.isTopHover ? 'background00C8DF' : '') + " backtop"} onMouseEnter={this.handlemouseTopEnter} onMouseLeave={this.handlemouseTopLeave}>
                        <Top style={{ color: '#00C8FD', fontSize: '35px' }} />
                    </BackTop>
                </ul>
            </Fragment>
        )
    }
};

export default Fixednav;