import React, { useEffect,useState } from 'react'
import { useHistory, withRouter } from 'react-router-dom'
import Icon from '@ant-design/icons'
import '../Component-css/SuccessPayContent.css'

const success = () => (
    <svg fill="currentColor" width="1.5em" height="1em" viewBox="0 0 1024 1024">
        <path d="M512 0C250.244741 0 37.925926 212.224 37.925926 474.074074c0 261.755259 212.224 473.979259 474.074074 473.979259S986.074074 735.829333 986.074074 474.074074c0-261.850074-212.224-474.074074-474.074074-474.074074z" fill="#08D0C9"/>
        <path d="M784.402963 351.668148L477.297778 656.734815a43.377778 43.377778 0 0 1-52.224 6.570666 48.64 48.64 0 0 1-8.523852-6.570666L239.701333 481.175704a42.363259 42.363259 0 0 1 0-60.368593 43.140741 43.140741 0 0 1 60.728889 0l146.394074 145.464889 276.66963-274.915556a43.254519 43.254519 0 0 1 60.833185 0 42.344296 42.344296 0 0 1 0.085333 60.28326v0.018963z" fill="#FFFFFF"/>
    </svg>
);

const Success = props => <Icon component={success} {...props} />;

function SuccessPayContent() {
    const [order_sn,setOrder_sn] = useState('')
    const hist = useHistory()

    useEffect(() => {
        var order_sn = localStorage.getItem('order_sn')
        setOrder_sn(order_sn)
    })

    const handleddlook = () => {
        hist.push('/Grzx/order')
    }
    
    return (
        <div className='successpay'>
            <div className='successpaycontent'>
                <Success className='successIcon' />
                <div className='successtext'>恭喜你</div>
                <div className='successtext'>本次交易成功支付</div>
                <div className='successddh'>订单号：{order_sn}</div>
                <div onClick={() => {handleddlook()}} className='successdd'>查看订单</div>
                <a rel="noopener noreferrer" target="_blank" href="http://wpa.qq.com/msgrd?v=3&uin=407650541&site=qq&menu=yes" className='successkf cousor'>联系客服</a>
            </div>
        </div>
)
}

export default withRouter(SuccessPayContent)