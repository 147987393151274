import React, { Component, Fragment } from 'react'
import RouterMap from './router';

class Home extends Component {
    constructor(props) {
        super(props)

        this.state = {
            
        }
    }

    render() {
        return (
            <Fragment>
                <RouterMap/>
            </Fragment>
            
        )
    }
}

export default Home