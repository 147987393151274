import React, { Component, Fragment } from 'react'
import Fixednav from './Fixednav'
import Headernav from './Headernav'
import { Table } from 'antd'
import SearchFixed from './SearchFixed'
import '../Component-css/Bookdownload.css'

class Bookdownload extends Component {
    constructor(props) {
        super(props)

        this.state = {
            Bookdownload:[
                '专利申请',
                '专利运营',
                // '商标表格',
                // '版权表格',
            ],
            datalist:[
                {id:'100001',title:'权利要求书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/16f23060947d939f602d49bea6e9da917580ef0b.doc'},
                {id:'100002',title:'说明书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/ab20ddfdd922a8986f4ae1b5c3118cc39483b436.doc'},
                {id:'100003',title:'说明书附图',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/d395929d50992550dc1e9d77082896f7c17f9e92.doc'},
                {id:'100004',title:'说明书摘要',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/37c2f6e471f6602c9ea74b74a654d99e6d6ab5bc.doc'},   
                {id:'100005',title:'摘要附图',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/b21a9a4df5e2cd9f04de6dc885f69be65b0f95fc.doc'},
                {id:'100006',title:'补正书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/4d84bfcf1a6a90f1c13b8adc45d5651ad0ebd6ee.doc'},
                {id:'100007',title:'专利代理委托书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/53f5951de897d61c1df382787619fc8fd0f753be.doc'},
                {id:'100008',title:'费用减缴请求书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/addcc80f3957a1edd1b1f73fa309637a48a57a57.doc'},
                {id:'100009',title:'延长期限请求书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/27792e558a8b5b8234b6d580150bcbf9757df067.doc'},
                {id:'100010',title:'恢复权利请求书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/2a2e3db76f22a3aa8be43a039245df637256a279.doc'},
                {id:'100011',title:'意见陈述书（关于费用）',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/aa755047dd1fc16071cb8a2e2a4ce63b2cdbdaa1.doc'},
                {id:'100012',title:'意见陈述书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/218c00c8c659606fdb64d32aca77ca5c3ca51893.doc'},
                {id:'100013',title:'撤回专利申请声明',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/0f7735f92fd752d15a3ab70ca2ecbc347843f294.doc'},
                {id:'100014',title:'附页',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/61054e2ab5dd52158e41c5fe230a879d4419f929.doc'},
                {id:'100015',title:'更正错误请求书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/45b1e80e36b6c105a584c542f54296511fb74b69.doc'},
                {id:'100016',title:'著录项目变更申报书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/cab117c5536d992a15104cf9df5eac8b78bfeb5d.doc'},
                {id:'100017',title:'中止程序请求书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/10e2f849d3643fcda71426a5093c2274b137b5f7.doc'},
                {id:'100018',title:'撤回优先权声明',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/1407d8b8b51b2f09fa783ad5389f58e8d633b7a3.doc'},
                {id:'100019',title:'强制许可请求书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/48074ae4addcbf52a90b4c324d5f2b5884e79d1a.doc'},
                {id:'100020',title:'强制许可使用费数额裁决请求书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/33089e4fad6fe4bfbe1a22d0fddcfe786d08a670.doc'},
                {id:'100021',title:'专利代理委托书（中英文）',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/e27aeb1e98fa59ceb7d4a4bfff19ab22fe016db9.doc'},
                {id:'100022',title:'总委托书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/4cd25bd363c881f2a8f43ccc26509c84c2c9e0fe.doc'},
                {id:'100023',title:'遗传资源来源披露登记表',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/15d5d4d0a4c8590756e512e4cc789d8d0aca9de9.doc'},
                {id:'100026',title:'技术方案说明书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/f578275c23cd66a1389fc2eb3a61210f25ea71d2.doc'},
                {id:'100027',title:'向外国申请专利保密审查请求书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/27b5eada20c1b64eb97c5ce3a499795952ac0f8a.doc'},
                {id:'100030',title:'办理证明文件请求书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/c6c7e68fe9f4c20efe9a49e23c1f1dd32b6af48c.doc'},
                {id:'100031',title:'办理文件副本请求书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/5848a2c82f0a227afb1215050b64ac84829bae9b.doc'},
                {id:'100032',title:'专利文档查询复制请求书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/11198c2364c8be51bd19e9b25e0b89f775338bfd.doc'},
                {id:'100034',title:'电子申请用户注册请求书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/9f2e32a90ed5d52fb7c5baf04f76daa18b997fdb.doc'},
                {id:'100035',title:'电子申请用户注册信息变更请求书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/6793ba6474eb7be1cfc63168b006c90894537072.doc'},
                {id:'100036',title:'电子申请用户注册事务意见陈述书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/d916cb9b5c3d6fbcebf1e455de90c83381120a00.doc'},
                {id:'100038',title:'文件备案请求书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/8a6b848b9e9ed77c3cf524b2330627d94e40b39d.doc'},
                {id:'100043',title:'专利申请优先审查请求书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/919eadab5f3a8e6b9539a88afdec215b8bd485d6.doc'},
                {id:'100045',title:'生物材料样品保藏及存活证明中文题录',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/577cf96f0abe720b759059d064d2f3ceac4b7faf.doc'},
                {id:'100047',title:'在先申请文件副本中文题录',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/0e16cc6ee15b366499afb2789f64227c49bd12c8.doc'},
                {id:'100048',title:'优先权转让证明中文题录',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/707ff60855324b3c7e96123104bf158035316638.doc'},
                {id:'100049',title:'专利权评价报告证明',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/db5822a17af15ee6355e953464b58ec8034235fd.doc'},
                {id:'100601',title:'放弃专利权声明',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/ad81fa23c4049eb96c27fd0e8f8914bae81694bc.doc'},
                {id:'100701',title:'专利权评价报告请求书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/b8771d7d68e166dccd33714b44506d3b78048fd0.doc'},
                {id:'100901',title:'复审请求书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/20dd1cf9734a515ee1f321e3ef87af2274347639.doc'},
                {id:'100902',title:'复审无效程序意见陈述书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/fcf1b5fdd0483d90ee2041e2c057040305b9a04b.doc'},
                {id:'100903',title:'复审请求口头审理通知书回执',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/16228cc90296bf5957a7fb16a8e30105846b74c5.doc'},
                {id:'100904',title:'复审无效宣告程序补正书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/4bb1ef24496c7956ded55005c7231de530ae67bc.doc'},
                {id:'100905',title:'复审程序恢复权利请求书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/d226e961f2558831fd7217e6b4f65f646888b1e8.doc'},
                {id:'100906',title:'复审程序延长期限请求书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/2048f181286f139484d79272838f87d91dd279c9.doc'},
                {id:'100907',title:'复审程序授权委托书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/c25f2956cee519e1e93b3c98b76020f026fc7b75.doc'},
                {id:'100908',title:'复审、无效宣告程序优先审查请求书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/c5f4315ab9f0147d9911749ba842094ee6d37f50.doc'},
                {id:'101001',title:'专利权无效宣告请求书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/bf2264ae35965133654573d72b504513e03972b7.doc'},
                {id:'101002',title:'无效宣告请求口头审理通知书回执',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/29963aa7b95eee56cd9f4b93581357cb3c5af895.doc'},
                {id:'101003',title:'专利权无效宣告程序授权委托书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/369b4dc54a06fb5cc0a1a8f5f3dc7fd829291f94.doc'},
                {id:'101101',title:'行政复议申请书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/93a4e90f49db48691092de1698cd64757b6d534f.doc'},
                {id:'110101',title:'发明专利请求书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/9ed3e6c34364140797ef01a66bb49ae6c88f5c11.doc'},
                {id:'110301',title:'发明专利请求提前公布声明',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/e1ff2695c4ac533976f86efc0569691e99a18188.doc'},
                {id:'110401',title:'实质审查请求书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/5e150b2ba27f51e028456842229dab0cf3837751.doc'},
                {id:'110402',title:'参与专利审查高速路（PPH）项目请求表',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/1a5f9acff1dfca5df9999a862ba0e0cdc816ab03.doc'},
                {id:'110403',title:'PPH请求补正书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/c175331952904e6e2484ff23afbf0d474662dd9b.doc'},
                {id:'120101',title:'实用新型专利请求书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/37e744fb88f3e315dee58f7c9a69980b68afc4a1.doc'},
                {id:'120701',title:'实用新型专利检索报告请求书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/d8bcf09812d0f3914a6ab44c690e40d7abc247c6.doc'},
                {id:'130001',title:'外观设计图片或照片',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/515b9f4f2faddd153f8891508055e55d3208e11c.doc'},
                {id:'130002',title:'外观设计简要说明',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/f7748719742aaf59de31b831418f82bd65d5e0f3.doc'},
                {id:'130101',title:'外观设计专利请求书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/208a4dd55d0e958ac7772a34865c1090cdcf193b.doc'},
                {id:'150101',title:'国际申请进入中国国家阶段声明（发明）',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/622f5941dbea97a078360a05eebfdb168799a483.doc'},
                {id:'150102',title:'国际申请进入中国国家阶段声明（实用新型）',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/543ebf65cb2d01a84a11ed4ba991eabe2f7846e5.doc'},
                {id:'150103',title:'补交修改文件的译文或修改文件',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/586b0dfcbcd91ffdf2cb74b7138a7578c730493b.doc'},
                {id:'150104',title:'改正优先权要求请求书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/df27be81cff34367f98d984c3212c2ae69e25938.doc'},
                {id:'150105',title:'改正译文错误请求书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/e4209a9e78dc760c4eaa41d298797ab3bff26573.doc'},
                {id:'150106',title:'关于微生物保藏的说明',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/0014c494419a7ea85dea2b9034fee64a149adc3e.doc'},
                {id:'150111',title:'申请权转让证明中文题录',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/7eeee9d579ce67d3a1690e8afcde468d107595c2.doc'},
                {id:'200106',title:'窗口递交文件回执',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/cfe2c79262ebff8c4803222fe0f5f9ccdd5cead2.doc'},
            ],
            downtable:[
                {
                   title:'序号',
                   dataIndex:'index',
                   key:'index',
                   width:60,
                   render:(text,record,index) =>  `${index + 1}` 
                },
                {
                    title:'标题',
                    dataIndex:'title',
                    key:'title',
                    render:(text,record) => {
                        return <p style={{cursor:'pointer'}} key={record.index} onClick={this.down.bind(this,record.src)}>{record.title}</p>
                    }
                },
            ],
            hg:0
        }
    }

    /**
     * 书式下载
     * @author zxy
     * @param {*} src
     * @memberof Bookdownload
     */
    down(src){
        const w = window;
        w.location.href = `${src}`
    }

    /**
     * 子页面切换
     * @author zxy
     * @param {*} index
     * @memberof Bookdownload
     */
    tablebook(index){
        if(index == 0){
            var list = [
                {id:'100001',title:'权利要求书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/16f23060947d939f602d49bea6e9da917580ef0b.doc'},
                {id:'100002',title:'说明书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/ab20ddfdd922a8986f4ae1b5c3118cc39483b436.doc'},
                {id:'100003',title:'说明书附图',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/d395929d50992550dc1e9d77082896f7c17f9e92.doc'},
                {id:'100004',title:'说明书摘要',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/37c2f6e471f6602c9ea74b74a654d99e6d6ab5bc.doc'},   
                {id:'100005',title:'摘要附图',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/b21a9a4df5e2cd9f04de6dc885f69be65b0f95fc.doc'},
                {id:'100006',title:'补正书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/4d84bfcf1a6a90f1c13b8adc45d5651ad0ebd6ee.doc'},
                {id:'100007',title:'专利代理委托书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/53f5951de897d61c1df382787619fc8fd0f753be.doc'},
                {id:'100008',title:'费用减缴请求书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/addcc80f3957a1edd1b1f73fa309637a48a57a57.doc'},
                {id:'100009',title:'延长期限请求书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/27792e558a8b5b8234b6d580150bcbf9757df067.doc'},
                {id:'100010',title:'恢复权利请求书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/2a2e3db76f22a3aa8be43a039245df637256a279.doc'},
                {id:'100011',title:'意见陈述书（关于费用）',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/aa755047dd1fc16071cb8a2e2a4ce63b2cdbdaa1.doc'},
                {id:'100012',title:'意见陈述书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/218c00c8c659606fdb64d32aca77ca5c3ca51893.doc'},
                {id:'100013',title:'撤回专利申请声明',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/0f7735f92fd752d15a3ab70ca2ecbc347843f294.doc'},
                {id:'100014',title:'附页',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/61054e2ab5dd52158e41c5fe230a879d4419f929.doc'},
                {id:'100015',title:'更正错误请求书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/45b1e80e36b6c105a584c542f54296511fb74b69.doc'},
                {id:'100016',title:'著录项目变更申报书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/cab117c5536d992a15104cf9df5eac8b78bfeb5d.doc'},
                {id:'100017',title:'中止程序请求书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/10e2f849d3643fcda71426a5093c2274b137b5f7.doc'},
                {id:'100018',title:'撤回优先权声明',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/1407d8b8b51b2f09fa783ad5389f58e8d633b7a3.doc'},
                {id:'100019',title:'强制许可请求书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/48074ae4addcbf52a90b4c324d5f2b5884e79d1a.doc'},
                {id:'100020',title:'强制许可使用费数额裁决请求书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/33089e4fad6fe4bfbe1a22d0fddcfe786d08a670.doc'},
                {id:'100021',title:'专利代理委托书（中英文）',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/e27aeb1e98fa59ceb7d4a4bfff19ab22fe016db9.doc'},
                {id:'100022',title:'总委托书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/4cd25bd363c881f2a8f43ccc26509c84c2c9e0fe.doc'},
                {id:'100023',title:'遗传资源来源披露登记表',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/15d5d4d0a4c8590756e512e4cc789d8d0aca9de9.doc'},
                {id:'100026',title:'技术方案说明书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/f578275c23cd66a1389fc2eb3a61210f25ea71d2.doc'},
                {id:'100027',title:'向外国申请专利保密审查请求书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/27b5eada20c1b64eb97c5ce3a499795952ac0f8a.doc'},
                {id:'100030',title:'办理证明文件请求书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/c6c7e68fe9f4c20efe9a49e23c1f1dd32b6af48c.doc'},
                {id:'100031',title:'办理文件副本请求书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/5848a2c82f0a227afb1215050b64ac84829bae9b.doc'},
                {id:'100032',title:'专利文档查询复制请求书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/11198c2364c8be51bd19e9b25e0b89f775338bfd.doc'},
                {id:'100034',title:'电子申请用户注册请求书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/9f2e32a90ed5d52fb7c5baf04f76daa18b997fdb.doc'},
                {id:'100035',title:'电子申请用户注册信息变更请求书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/6793ba6474eb7be1cfc63168b006c90894537072.doc'},
                {id:'100036',title:'电子申请用户注册事务意见陈述书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/d916cb9b5c3d6fbcebf1e455de90c83381120a00.doc'},
                {id:'100038',title:'文件备案请求书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/8a6b848b9e9ed77c3cf524b2330627d94e40b39d.doc'},
                {id:'100043',title:'专利申请优先审查请求书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/919eadab5f3a8e6b9539a88afdec215b8bd485d6.doc'},
                {id:'100045',title:'生物材料样品保藏及存活证明中文题录',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/577cf96f0abe720b759059d064d2f3ceac4b7faf.doc'},
                {id:'100047',title:'在先申请文件副本中文题录',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/0e16cc6ee15b366499afb2789f64227c49bd12c8.doc'},
                {id:'100048',title:'优先权转让证明中文题录',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/707ff60855324b3c7e96123104bf158035316638.doc'},
                {id:'100049',title:'专利权评价报告证明',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/db5822a17af15ee6355e953464b58ec8034235fd.doc'},
                {id:'100601',title:'放弃专利权声明',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/ad81fa23c4049eb96c27fd0e8f8914bae81694bc.doc'},
                {id:'100701',title:'专利权评价报告请求书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/b8771d7d68e166dccd33714b44506d3b78048fd0.doc'},
                {id:'100901',title:'复审请求书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/20dd1cf9734a515ee1f321e3ef87af2274347639.doc'},
                {id:'100902',title:'复审无效程序意见陈述书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/fcf1b5fdd0483d90ee2041e2c057040305b9a04b.doc'},
                {id:'100903',title:'复审请求口头审理通知书回执',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/16228cc90296bf5957a7fb16a8e30105846b74c5.doc'},
                {id:'100904',title:'复审无效宣告程序补正书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/4bb1ef24496c7956ded55005c7231de530ae67bc.doc'},
                {id:'100905',title:'复审程序恢复权利请求书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/d226e961f2558831fd7217e6b4f65f646888b1e8.doc'},
                {id:'100906',title:'复审程序延长期限请求书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/2048f181286f139484d79272838f87d91dd279c9.doc'},
                {id:'100907',title:'复审程序授权委托书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/c25f2956cee519e1e93b3c98b76020f026fc7b75.doc'},
                {id:'100908',title:'复审、无效宣告程序优先审查请求书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/c5f4315ab9f0147d9911749ba842094ee6d37f50.doc'},
                {id:'101001',title:'专利权无效宣告请求书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/bf2264ae35965133654573d72b504513e03972b7.doc'},
                {id:'101002',title:'无效宣告请求口头审理通知书回执',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/29963aa7b95eee56cd9f4b93581357cb3c5af895.doc'},
                {id:'101003',title:'专利权无效宣告程序授权委托书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/369b4dc54a06fb5cc0a1a8f5f3dc7fd829291f94.doc'},
                {id:'101101',title:'行政复议申请书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/93a4e90f49db48691092de1698cd64757b6d534f.doc'},
                {id:'110101',title:'发明专利请求书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/9ed3e6c34364140797ef01a66bb49ae6c88f5c11.doc'},
                {id:'110301',title:'发明专利请求提前公布声明',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/e1ff2695c4ac533976f86efc0569691e99a18188.doc'},
                {id:'110401',title:'实质审查请求书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/5e150b2ba27f51e028456842229dab0cf3837751.doc'},
                {id:'110402',title:'参与专利审查高速路（PPH）项目请求表',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/1a5f9acff1dfca5df9999a862ba0e0cdc816ab03.doc'},
                {id:'110403',title:'PPH请求补正书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/c175331952904e6e2484ff23afbf0d474662dd9b.doc'},
                {id:'120101',title:'实用新型专利请求书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/37e744fb88f3e315dee58f7c9a69980b68afc4a1.doc'},
                {id:'120701',title:'实用新型专利检索报告请求书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/d8bcf09812d0f3914a6ab44c690e40d7abc247c6.doc'},
                {id:'130001',title:'外观设计图片或照片',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/515b9f4f2faddd153f8891508055e55d3208e11c.doc'},
                {id:'130002',title:'外观设计简要说明',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/f7748719742aaf59de31b831418f82bd65d5e0f3.doc'},
                {id:'130101',title:'外观设计专利请求书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/208a4dd55d0e958ac7772a34865c1090cdcf193b.doc'},
                {id:'150101',title:'国际申请进入中国国家阶段声明（发明）',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/622f5941dbea97a078360a05eebfdb168799a483.doc'},
                {id:'150102',title:'国际申请进入中国国家阶段声明（实用新型）',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/543ebf65cb2d01a84a11ed4ba991eabe2f7846e5.doc'},
                {id:'150103',title:'补交修改文件的译文或修改文件',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/586b0dfcbcd91ffdf2cb74b7138a7578c730493b.doc'},
                {id:'150104',title:'改正优先权要求请求书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/df27be81cff34367f98d984c3212c2ae69e25938.doc'},
                {id:'150105',title:'改正译文错误请求书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/e4209a9e78dc760c4eaa41d298797ab3bff26573.doc'},
                {id:'150106',title:'关于微生物保藏的说明',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/0014c494419a7ea85dea2b9034fee64a149adc3e.doc'},
                {id:'150111',title:'申请权转让证明中文题录',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/7eeee9d579ce67d3a1690e8afcde468d107595c2.doc'},
                {id:'200106',title:'窗口递交文件回执',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210910/cfe2c79262ebff8c4803222fe0f5f9ccdd5cead2.doc'},
            ]
            this.setState({
                hg:0,
                datalist:list
            })
        }else if(index == 1){
            var list = [
                {id:'001',title:'专利实施许可合同备案注销申请表',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210911/093d77c529cc288a2883c4ae60426b1f0bb4de00.doc'},
                {id:'002',title:'专利实施许可合同备案申请表',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210911/70fa3c526a1774f43c82f57b8375ef3a507076ac.doc'},
                {id:'003',title:'专利实施许可合同备案变更申请表',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210911/61da6504d5737b4ab3c0fe9d325eb8efea5158e4.doc'},
                {id:'004',title:'专利实施许可合同文本',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210911/b813f04d81999da548e30c5cb2b1069cad55998c.doc'},
                {id:'005',title:'专利申请技术实施许可合同文本',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210911/596bd3d269d28c081852892c27a9626d7e690147.doc'},
                {id:'006',title:'专利权质押登记申请表',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210911/a4f94ef15ddec3961fd5a9bb9907d6cf668c4eac.doc'},
                {id:'007',title:'专利权质押登记变更申请表',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210911/a60013dc9222f4ffe7dd39652aab0e2aaf681008.doc'},
                {id:'008',title:'专利权质押登记注销申请表',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210911/b505117fefb62291e3487ad002940a99c2251346.doc'},
                {id:'009',title:'专利权质押合同文本',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210911/b6d96e83f02936db388093cd12df82d43c9f9b97.doc'}
            ]
            this.setState({
                hg:1,
                datalist:list
            })
        }else if(index == 2){
            // var list = [
            //     {id:'01',title:'商标注册申请书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210911/84a60af7004caef1dd316b0b24299e648d4b23ec.doc'},
            //     {id:'02',title:'商标注册申请书式填写需知',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210911/2a809d21ef45a144cd9fc862bc6f80d6a8ed3300.doc'},
            //     {id:'03',title:'商标注册申请书_台湾',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210911/17c0dc6ca3ef4b24fa3a12de458b19edaa8f455b.doc'},
            //     {id:'04',title:'商标注销申请书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210911/aca09574df72f3694f38111288170b6943d7b749.doc'},
            //     {id:'05',title:'更正商标申请注册事项申请书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210911/ad5e1e019a28cbe9342873eb055dd05ea8ae2156.doc'},

            //     {id:'06',title:'商标续展注册申请书模板',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210911/597c9587f902834abeb15253e50960fe80ea0a69.doc'},
            //     {id:'07',title:'马德里国际商标出具商标注册证明申请书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210911/c208f1407fed004bacc6e424cbc0129b6c171b14.doc'},
            //     {id:'08',title:'撤回商标续展注册申请书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210911/e727bcdfba1ed8c51534174ec9deb6c636fcae03.doc'},
            //     {id:'09',title:'商标代理委托书（示范文本）',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210911/c6044183929c29a1f5f9149eaf31c09c23d6d380.doc'},
            //     {id:'10',title:'变更许可人被许可人名称备案表',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210911/3f36258a6985886ad5722aea3b3e618ed924268d.doc'},

            //     {id:'11',title:'变更商标代理人文件接收人申请书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210911/5f9c812c234865617b07b8cf09a29e70df281a4d.doc'},
            //     {id:'12',title:'商标异议申请书模板',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210911/039530b95ff0f24409209ede34d20eeb65a6e387.doc'},
            //     {id:'13',title:'补发商标注册证申请书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210911/5a17cc3e30e31e9026342b44774c33e7b12d8b02.doc'},
            //     {id:'14',title:'补发变更转让续展证明申请书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210911/44c8d11bfaceccdec296b475bcda194864f06ae4.doc'},
            //     {id:'15',title:'驳回商标注册申请复审申请书（首页）',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210911/76975ee10fe8915ad0929b238166ad3af74c66f8.doc'},

            //     {id:'16',title:'驳回商标注册申请复审申请书（正文样式）',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210911/1ff43705dc27df6a207dd6eeae1fc7179d3278e5.doc'},
            //     {id:'17',title:'商标不予注册复审申请书(首页)',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210911/b0cf72789b0bd51212c67408e1b9d2bdd2a61863.doc'},
            //     {id:'18',title:'商标不予注册复审申请书（正文样式）',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210911/788c7f3ad6ee34650c0727a420ee6c0e0168be1b.doc'},
            //     {id:'19',title:'申请撤销连续三年不使用注册商标',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210911/5eef03547a337b7dd278288b71599c15d378314a.doc'},
            //     {id:'20',title:'撤销连续三年不使用注册商标申请书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210911/e6d4896bc596da919ed42551e82906e9be7d1356.doc'},

            //     {id:'21',title:'商标使用许可备案表',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210911/81ebb9308973c3b35cba939f0117d281a50e54d1.doc'},
            //     {id:'22',title:'撤回商标使用许可备案申请书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210911/ef547282f7fd20b293c521d09bd8914a16cf3303.doc'},
            //     {id:'23',title:'商标使用许可提前终止备案表',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210911/fb14d456f911c11a4ecdf13b086d7762a9bf841d.doc'},
            //     {id:'24',title:'出具优先权证明文件申请书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210911/93c347f9af5ba2c28f11652e85b9ac182f6fdc6a.doc'},
            //     {id:'25',title:'删减商品服务项目申请书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210911/37fb24b1ee5af0492cd846869246e095f7f0ac3c.doc'},

            //     {id:'26',title:'撤回删减商品服务项目申请书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210911/2f4f3bc1410bd9bc692dd514a6b02ca250276855.doc'},
            //     {id:'27',title:'撤回商标注册申请申请书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210911/15195482bb6ab558969a2a378be5f02984430db7.doc'},
            //     {id:'28',title:'撤回商标注销申请书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210911/e5405cf55ae46fa14e9589f080c0524fa9c62daf.doc'},
            //     {id:'29',title:'转让移转申请注册商标申请书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210911/0b316ef3ff8e5d58805fe86cc3c3b86e57eea61a.doc'},
            //     {id:'30',title:'撤回转让移转申请注册商标申请书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210911/18f32d60b66c444fab59d30d95199e57b2a3e794.doc'},

            //     {id:'31',title:'撤销成为商品服务通用名称注册商标申请书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210911/8bf5ad24e7befce1d86ffe25ac535d0b0698b679.doc'},
            //     {id:'32',title:'撤回撤销成为商品服务通用名称注册商标申请书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210911/93773d0985fcd424d541c5971a4592d84375f6f9.doc'},
            //     {id:'33',title:'商标评审代理委托书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210911/7c761c34c9ea597c8f5f7b627f83c0251a2de453.doc'},
            //     {id:'34',title:'商标评审案件证据目录',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210911/aa56779b25615bb888fbc6777ad80e3ee2444071.doc'},
            //     {id:'35',title:'商标评审案件申请材料目录',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210911/a04da8d4c0473e901f7ef37f07ece4f0130f68bb.doc'},

            //     {id:'36',title:'商标评审案件答辩材料目录',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210911/f250f298d16d3238c9bb8ed817614850793293ff.doc'},
            //     {id:'37',title:'注册商标无效宣告申请书（首页）',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210911/704d79f3a5c6bbd0953258c4961bdbd1c413b89a.doc'},
            //     {id:'38',title:'注册商标无效宣告申请书（正文样式）',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210911/2fbfafee4e50cca3a7b052bae87823f1aec1d22e.doc'},
            //     {id:'39',title:'注册商标无效宣告复审申请书（首页）',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210911/d040bf593000082b504370b524fd1535ab53462e.doc'},
            //     {id:'40',title:'注册商标无效宣告复审申请书（正文样式）',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210911/9e9595675c517b93b6d026855ac7d5834d594b1c.doc'},

            //     {id:'41',title:'撤销注册商标复审申请书（首页）',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210911/2a795bf043d17dd3c49db8d7e25482fe683f6259.doc'},
            //     {id:'42',title:'撤销注册商标复审申请书（正文样式）',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210911/ce6b790a5e1a63c0f7ddd414fb7d3342eade7e9c.doc'},
            //     {id:'43',title:'商标专用权质权登记申请书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210911/6556ad50dd6f4123085e2a1df60bd8f89bf038ac.doc'},
            //     {id:'44',title:'商标专用权质权登记注销申请书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210911/63eaeba305987064e1863b6b6a51b3f7d7849544.doc'},
            //     {id:'45',title:'商标专用权质权登记事项变更申请书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210911/15d7b0cc36a285b7b68301d6790cd8987c44daa6.doc'},

            //     {id:'46',title:'商标专用权质权登记证补发申请书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210911/76081b7ca52750973da407cdb1da50afa2623e16.doc'},
            //     {id:'47',title:'商标专用权质权登记期限延期申请书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210911/5e01438c0ff1e169493ae072e129701b036a8772.doc'},
            //     {id:'48',title:'特殊标志登记申请书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210911/30ae74a8f67a6476e3044ca55ea70afe04decb41.doc'},
            //     {id:'49',title:'变更商标申请人注册人名义地址变更集体商标证明商标管理规则集体成员名单申请书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210911/1c744c3572b59460839479dfbb3af0c9284beda0.doc'},
            //     {id:'50',title:'撤回变更商标申请人注册人名义地址变更集体商标证明商标管理规则集体成员名单申请书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210911/e2db8af3128279de8834fb31e10bf2ca0b19d758.doc'},
            // ]
            // this.setState({
            //     hg:2,
            //     datalist:list
            // })
        }else if(index == 3){
            // var list = [
            //     {id:'0001',title:'作品著作权登记申请表',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210911/c3a92accec4f5a75221bddc4cfae25f0409b0745.doc'},
            //     {id:'0002',title:'版权代理委托书模板',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210911/dc879798c86ba00d4bbd42081a329848bdc0facc.doc'},
            //     {id:'0003',title:'计算机软件著作权登记申请表',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210911/4f6caca17bc9c20fd3313cc7f16d3574cfa1306c.doc'},
            //     {id:'0004',title:'计算机软件著作权登记申请表模板',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210911/a0e0c65b81bf864b1a5ff2ac158db74df0ecb308.doc'},   
            //     {id:'0005',title:'作品不予登记通知书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210911/3dab0a921a480dcdcfdab0546a7c571ea6e23e7e.doc'},
            //     {id:'0006',title:'地区编号列表、作品分类号',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210911/7562eb87a47d238d88994a1bf9afa5c4ae841132.doc'},
            //     {id:'0007',title:'著作权专有许可使用合同备案申请表',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210911/88544dbd77f40b504fd99ff831313f489ddfdec7.doc'},
            //     {id:'0008',title:'著作权专有许可使用合同备案证书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210911/a523fefe5986db31861ede9c8d5778b65233f22e.doc'},
            //     {id:'0009',title:'著作权转让合同备案申请表',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210911/f77d85fbc1955f37f9c97530fa5f4e43f888d554.doc'},
            //     {id:'0010',title:'著作权转让合同备案证书',src:'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210911/d35dbe9a8c6a116fe7c11c61e8299bb32574d12a.doc'},
            // ]
            // this.setState({
            //     hg:3,
            //     datalist:list
            // })
        }
    }

    render() {
        return (
            <Fragment >
                <Headernav />
                <div className='bookdownload-wrap'>
                    <ul>
                       {
                           this.state.Bookdownload.map((item,index) => {
                               return <li onClick={this.tablebook.bind(this,index)} className={this.state.hg == index ? 'bookcurrent' : ''} key={index} >{item}</li>
                           })
                       }
                    </ul>
                    <Table rowKey='id' pagination={false} bordered={true} className='table-bookdown' dataSource={this.state.datalist} columns={this.state.downtable} />
                </div>
                <Fixednav />
                <SearchFixed />
            </Fragment>
        )
    }
}

export default Bookdownload