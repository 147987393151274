import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import Headernav from './Headernav'
import '../Component-css/MarketContent.css'
import api from '../api/api'
import { Table, Menu, message, Checkbox } from 'antd'
import Icon from '@ant-design/icons'
import Fixednav from './Fixednav'
import { Pagination } from 'antd'
// import Markettalk from './Markettalk'
import Marketsearch from './Marketsearch'
import SearchFixed from './SearchFixed'

// const pic = () => (
//     <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 1024 1024">
//         <path d="M370.517333 474.581333H90.026667A89.429333 89.429333 0 0 1 0.341333 385.578667V107.264A89.386667 89.386667 0 0 1 90.026667 18.261333h280.448a89.386667 89.386667 0 0 1 89.728 89.002667v278.314667a89.301333 89.301333 0 0 1-89.728 89.002666zM90.026667 85.034667a22.357333 22.357333 0 0 0-22.442667 22.229333v278.314667c0 12.288 10.026667 22.229333 22.442667 22.229333h280.448c12.373333 0 22.442667-9.941333 22.442666-22.229333V107.264a22.357333 22.357333 0 0 0-22.442666-22.229333H90.026667z m280.448 920.533333H90.026667A89.344 89.344 0 0 1 0.341333 916.48V638.293333a89.386667 89.386667 0 0 1 89.728-89.045333h280.448a89.514667 89.514667 0 0 1 89.728 89.045333v278.272a89.301333 89.301333 0 0 1-89.728 89.045334z m-280.448-389.546667a22.357333 22.357333 0 0 0-22.442667 22.186667v278.314667c0 12.288 10.026667 22.272 22.442667 22.272h280.448c12.373333 0 22.442667-9.941333 22.442666-22.272V638.293333a22.186667 22.186667 0 0 0-22.485333-22.272H90.069333z m810.368-141.44h-280.405334a89.429333 89.429333 0 0 1-89.770666-89.002666V107.264A89.386667 89.386667 0 0 1 619.989333 18.261333h280.448a89.386667 89.386667 0 0 1 89.770667 89.002667v278.314667a89.386667 89.386667 0 0 1-89.770667 89.002666z m-280.405334-389.546666a22.357333 22.357333 0 0 0-22.442666 22.229333v278.314667c0 12.288 10.026667 22.229333 22.4 22.229333h280.448c12.373333 0 22.442667-9.941333 22.442666-22.229333V107.264a22.357333 22.357333 0 0 0-22.442666-22.229333h-280.405334z m280.405334 920.533333h-280.405334a89.344 89.344 0 0 1-89.770666-89.045333V638.293333a89.386667 89.386667 0 0 1 89.728-89.045333h280.448a89.514667 89.514667 0 0 1 89.770666 89.045333v278.272a89.386667 89.386667 0 0 1-89.770666 89.045334z m-280.405334-389.546667a22.357333 22.357333 0 0 0-22.442666 22.186667v278.314667c0 12.288 10.026667 22.272 22.4 22.272h280.448c12.373333 0 22.442667-9.941333 22.442666-22.272V638.293333a22.186667 22.186667 0 0 0-22.442666-22.272h-280.405334z" />
//     </svg>
// );

// const list = () => (
//     <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 1024 1024">
//         <path d="M289.391304 44.521739m44.521739 0l623.304348 0q44.521739 0 44.521739 44.521739l0 0q0 44.521739-44.521739 44.521739l-623.304348 0q-44.521739 0-44.521739-44.521739l0 0q0-44.521739 44.521739-44.521739Z" />
//         <path d="M289.391304 445.217391m44.521739 0l623.304348 0q44.521739 0 44.521739 44.521739l0 0q0 44.521739-44.521739 44.52174l-623.304348 0q-44.521739 0-44.521739-44.52174l0 0q0-44.521739 44.521739-44.521739Z" />
//         <path d="M289.391304 890.434783m44.521739 0l623.304348 0q44.521739 0 44.521739 44.521739l0 0q0 44.521739-44.521739 44.521739l-623.304348 0q-44.521739 0-44.521739-44.521739l0 0q0-44.521739 44.521739-44.521739Z" />
//         <path d="M111.304348 489.73913m-89.043478 0a89.043478 89.043478 0 1 0 178.086956 0 89.043478 89.043478 0 1 0-178.086956 0Z" />
//         <path d="M111.304348 934.956522m-89.043478 0a89.043478 89.043478 0 1 0 178.086956 0 89.043478 89.043478 0 1 0-178.086956 0Z" />
//         <path d="M111.304348 89.043478m-89.043478 0a89.043478 89.043478 0 1 0 178.086956 0 89.043478 89.043478 0 1 0-178.086956 0Z" />
//     </svg>
// );

const marketXq = () => (
    <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 1024 1024">
        <path d="M400.00576 913.536H133.25376c-42.688 0-86.208-40.32-86.208-83.008V138.24c0-42.624 43.584-86.144 86.208-86.144h586.56c42.688 0 83.136 43.52 83.136 86.144v159.744c0 16 7.552 26.56 23.552 26.56s23.552-10.624 23.552-26.56V138.176c0-74.624-55.552-133.12-130.24-133.12H133.31776A131.904 131.904 0 0 0 0.00576 138.112v692.352c0 74.56 58.624 129.984 133.312 129.984h266.624c16 0 26.688-7.552 26.688-23.488 0-16-10.688-23.488-26.688-23.488zM585.09376 247.872H227.46176c-15.36 0-24 6.464-24 20.288s8.704 20.352 24 20.352h357.696c15.296 0 24-6.528 24-20.352 0-13.76-8.704-20.288-24-20.288z m419.584 726.4l-97.344-97.152a236.48 236.48 0 0 0 54.72-153.088c0-133.12-103.552-236.544-236.8-236.544-133.376 0-236.928 103.36-236.928 236.544 0 133.184 103.552 236.544 236.864 236.544a237.76 237.76 0 0 0 147.328-49.92l97.92 97.92 2.496 3.072c6.976 8.64 24.32 10.624 34.88 0 10.624-10.688 7.552-26.624-3.2-37.312z m-279.552-60.736c-101.248 0-189.76-88.32-189.76-189.504 0-101.248 88.512-189.568 189.76-189.568 101.376 0 189.76 88.32 189.76 189.568 0.064 101.12-88.384 189.504-189.76 189.504zM585.15776 410.24H227.46176c-15.36 0-24 6.464-24 20.288s8.704 20.352 24 20.352h357.696c15.296 0 24-6.528 24-20.352 0-13.824-8.704-20.288-24-20.288z m19.008 295.68c-12.992 0-25.088 10.496-25.088 23.488 0 12.928 12.096 23.488 25.088 23.488s21.952-10.56 21.952-23.488c0-12.992-8.96-23.552-21.952-23.552z m121.024-104.96c-37.248 0-69.248 10.624-101.248 42.56-10.688 10.688-10.688 21.952 0 32.64 5.312 5.312 16 10.624 21.312 10.624 5.312 0 16-8.32 21.312-13.76 16-21.312 37.312-28.8 58.624-28.8 21.376 0 37.312 2.24 53.376 18.176 10.624 10.624 26.624 13.76 37.312 3.072 10.688-10.624 5.952-26.624-4.672-37.248-21.376-21.312-54.016-27.328-86.016-27.328z" />
    </svg>
);

const lxkf = () => (
    <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 1024 1024">
        <path d="M512 64c249.6 0 448 198.4 448 448s-198.4 448-448 448H102.4l57.6-115.2 19.2-38.4-25.6-32C96 704 64 608 64 512c0-249.6 198.4-448 448-448z m0-64C230.4 0 0 230.4 0 512c0 115.2 38.4 224 102.4 307.2L0 1024h512c281.6 0 512-230.4 512-512s-230.4-512-512-512z" />
        <path d="M192 512a64 64 0 1 0 128 0 64 64 0 0 0-128 0zM448 512a64 64 0 1 0 128 0 64 64 0 0 0-128 0zM704 512a64 64 0 1 0 128 0 64 64 0 0 0-128 0z" />
    </svg>
);

const shoucang = () => (
    <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 1024 1024">
        <path d="M777.728 1023.488c-12.224 0-24.512-3.2-37.824-10.688l-206.592-113.088a39.04 39.04 0 0 0-20.48-5.312 58.432 58.432 0 0 0-20.48 4.288L286.72 1012.8c-11.712 7.232-25.152 10.88-38.848 10.688a85.632 85.632 0 0 1-82.88-101.376l39.936-238.912a49.152 49.152 0 0 0-13.312-40.512L25.856 475.2a87.808 87.808 0 0 1-21.44-89.6c10.24-32 35.84-54.4 67.52-59.776l229.12-35.2a45.696 45.696 0 0 0 33.792-24.512L437.12 48.512a82.56 82.56 0 0 1 74.688-48c31.68 0 60.352 18.112 74.688 48l102.272 217.6a44.8 44.8 0 0 0 33.728 24.512l229.12 35.2c31.808 5.376 57.344 27.776 67.584 58.688a89.536 89.536 0 0 1-20.48 89.6l-164.736 168.576a46.08 46.08 0 0 0-13.312 40.512l38.912 237.888c4.16 25.6-1.984 51.2-18.368 70.4-15.36 20.224-38.912 32-63.488 32z m-13.248-65.088c5.12 2.112 9.152 3.2 12.224 3.2 7.232 0 14.4-3.2 19.52-9.6 5.12-6.4 6.08-14.912 5.12-20.288l-38.976-237.824c-6.144-34.176 5.12-71.488 29.696-94.976l164.736-169.6c6.144-6.4 8.192-16 6.144-26.688a23.36 23.36 0 0 0-20.48-17.024l-229.12-35.2a102.016 102.016 0 0 1-77.76-58.688L533.312 75.2a22.144 22.144 0 0 0-21.504-13.888c-9.216 0-17.408 5.312-22.528 14.912L387.008 291.712a104.384 104.384 0 0 1-77.76 58.688l-229.12 35.2a26.88 26.88 0 0 0-19.456 17.024v1.088a24.064 24.064 0 0 0 6.144 25.6l165.76 169.6c24.512 24.576 34.752 59.776 29.632 94.976l-38.912 237.824a20.864 20.864 0 0 0 5.12 19.2c5.12 6.4 12.288 9.6 19.456 9.6a30.08 30.08 0 0 0 11.264-2.112l206.656-113.088c13.248-8.512 29.632-12.8 47.04-12.8 15.36 0 31.68 4.288 47.04 12.8l204.608 113.088z" />
    </svg>
);

const shoucanged = () => (
    <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 1024 1024">
        <path d="M762.096941 993.400471c-11.504941 0-23.070118-3.011765-35.599059-10.059295l-194.439529-106.435764a36.743529 36.743529 0 0 0-19.275294-4.99953 54.994824 54.994824 0 0 0-19.275294 4.035765L299.971765 983.341176c-11.023059 6.806588-23.672471 10.24-36.562824 10.059295a80.594824 80.594824 0 0 1-78.004706-95.412706l37.586824-224.858353a46.260706 46.260706 0 0 0-12.528941-38.128941L54.452706 477.364706a82.642824 82.642824 0 0 1-20.178824-84.329412c9.637647-30.117647 33.731765-51.2 63.548236-56.259765l215.642353-33.129411a43.008 43.008 0 0 0 31.804235-23.070118l96.256-204.8a77.703529 77.703529 0 0 1 70.294588-45.176471c29.816471 0 56.801882 17.046588 70.294588 45.176471l96.256 204.8a42.164706 42.164706 0 0 0 31.744 23.070118l215.642353 33.129411c29.936941 5.059765 53.970824 26.142118 63.608471 55.235765a84.269176 84.269176 0 0 1-19.275294 84.329412l-155.045647 158.659765a43.369412 43.369412 0 0 0-12.528941 38.128941l36.623058 223.894588c3.915294 24.094118-1.867294 48.188235-17.287529 66.258824-14.456471 19.034353-36.623059 30.117647-59.753412 30.117647z" />
    </svg>
);

// const Pic = props => <Icon component={pic} {...props} />;
// const Listmethod = props => <Icon component={list} {...props} />;
const MarketXq = props => <Icon component={marketXq} {...props} />;
const Lxkf = props => <Icon component={lxkf} {...props} />;
const Shoucang = props => <Icon component={shoucang} {...props} />;
const Shoucanged = props => <Icon component={shoucanged} {...props} />;

class MarketContent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            carousellist:[{ time:'3分钟前',money:'28500',zllx:'发明专利',name:'张*生',zlname:'一种环保型喷涂设备'},{ time:'5分钟前',money:'19500',zllx:'发明专利',name:'王*铭',zlname:'一种铺开使用的玩具'},{time:'8分钟前',money:'26300',zllx:'发明专利',name:'李*福',zlname:'一种电动车地锁'},{time:'9分钟前',money:'21300',zllx:'发明专利',name:'丁*华',zlname:'一种可自适应风力的发电设备'},{time:'9分钟前',money:'25100',zllx:'发明专利',name:'谢*',zlname:'一种可调节自动开罐器'},{time:'10分钟前',money:'2800',zllx:'实用新型专利',name:'黄*才',zlname:'一种中药饮片压制模具'},{time:'13分钟前',money:'3200',zllx:'实用新型专利',name:'左*亮',zlname:'一种木材加工木料靠尺'},{time:'14分钟前',money:'21300',zllx:'发明专利',name:'罗*明',zlname:'一种节能热水壶'},{time:'20分钟前',money:'24900',zllx:'发明专利',name:'蒋*华',zlname:'一种室内空气净化器'},{time:'20分钟前',money:'18530',zllx:'发明专利',name:'李*佳',zlname:'一种智能控温柜系统'},{time:'20分钟前',money:'3500',zllx:'实用新型专利',name:'张*严',zlname:'一种交通监控设备'},{time:'20分钟前',money:'2630',zllx:'实用新型专利',name:'王*伟',zlname:'一种多功能雨伞收纳装置'},{time:'20分钟前',money:'2800',zllx:'实用新型专利',name:'张*',zlname:'一种便于移动的通信存放柜'},{time:'20分钟前',money:'3200',zllx:'实用新型专利',name:'史*勇',zlname:'一种可旋转花盆'},{time:'20分钟前',money:'30440',zllx:'发明专利',name:'汤*',zlname:'一种高效美容仪'},{time:'20分钟前',money:'24540',zllx:'发明专利',name:'李*光',zlname:'一种多动力节能推土机'},{time:'20分钟前',money:'28460',zllx:'发明专利',name:'何*',zlname:'一种快捷的梁设备'}],
            carousellist2:[{ time:'3分钟前',money:'30450',zllx:'发明专利',name:'马*良',zlname:'一种用于计算矩阵乘法的交叉杆陈列'},{ time:'5分钟前',money:'25400',zllx:'发明专利',name:'王*',zlname:'一种新型高效物流装置'},{time:'8分钟前',money:'24800',zllx:'发明专利',name:'胡*善',zlname:'一种运动式信号接收装置'},{time:'9分钟前',money:'35368',zllx:'发明专利',name:'王*',zlname:'一种基于工业机器人的智能制造装置及使用方法'},{time:'9分钟前',money:'26400',zllx:'发明专利',name:'张*远',zlname:'一种无人控制巡逻装置'},{time:'10分钟前',money:'35688',zllx:'发明专利',name:'王*',zlname:'一种汽车启动系统'},{time:'13分钟前',money:'24400',zllx:'发明专利',name:'张*',zlname:'一种初中学生生物标本保存盒'},{time:'14分钟前',money:'45850',zllx:'发明专利',name:'张*',zlname:'基于无人机的复合绝缘子缺陷检测系统'},{time:'20分钟前',money:'55440',zllx:'发明专利',name:'李*',zlname:'一种基于物联网的仓库安全监测系统'},{time:'20分钟前',money:'25400',zllx:'发明专利',name:'李*飞',zlname:'一种具有用于存放供电线的内部空间的家电设备'},{time:'20分钟前',money:'29450',zllx:'发明专利',name:'吕*蓉',zlname:'一种新能源储能热水器'},{time:'20分钟前',money:'35580',zllx:'发明专利',name:'孙*磊',zlname:'一种基于指纹和车牌识别的智能门禁系统'}],
            industry: ['不限', '农林牧渔', '食品饮料', '家居日用', '电子信息', '轻工纺织', '包装印刷', '建筑建材', '环保工程', '电气电力', '','交通运输', '化学化工', '航空航天', '石油工程', '生物医学', '勘探开发', '水电水利', '人工智能', '武器弹药'],
            type: ['不限', '发明专利', '实用新型', '外观专利'],
            price: ['不限', '1万以下', '1万~3万', '3万~5万', '5万~10万', '10万以上', '议价'],
            // paymethod: ['不限', '转让', '许可'],
            zlstuate: ['不限', '授权已下证', '授权未下证'],
            currentIndex1: 0,
            currentIndex2: 0,
            currentIndex3: 0,
            currentIndex4: 0,
            currentIndex5: 0,
            isTime:1,
            isPrice:1,
            ischange: false,
            tableList: [{
                title: '序号',
                width: '3.9%',
                render: (text, record, index) => `${index + 1}`
            }, {
                title: '专利名称',
                key: 'title',
                dataIndex: 'title',
                width: '33.69%'
            },
            {
                title: '专利类型',
                key: 'type',
                dataIndex: 'type',
                width: '9.83%'
            }, {
                title: '状态',
                key: 'auth_status_name',
                width: '9.75%',
                dataIndex: 'auth_status_name'
            }, {
                title: '售价',
                width: '7.54%',
                render: (text, record) =>
                    <div>
                        <div className={record.is_negotiate === 1 ? '' : 'displayNone'}>议价</div>
                        <div className={record.is_negotiate !== 1 ? '' : 'displayNone'}>{record.price}</div>
                    </div>
            }, {
                title: '交易方式',
                key: 'sale_type_name',
                dataIndex: 'sale_type_name',
                width: '7.46%'
            }, {
                title: '所属行业',
                key: 'category_name',
                dataIndex: 'category_name',
                width: '12.04%'
            }, {
                title: '操作',
                width: '15.67%',
                render: (text, record) =>
                    <div className='list-function'>
                        <MarketXq title='详情' className="MarketXq" onClick={(e) => this.marketListXq(record.type_value,record.title,record.application_number,record.auth_status_name,record.sale_type_name,record.type,record.price,record.is_favorite,record.category_name,record.id, e)}>1</MarketXq>
                        <a style={{ width: '16px', height: '16px', marginLeft: '40px',lineHeight:'0' }} rel="noopener noreferrer" target="_blank" href="http://wpa.qq.com/msgrd?v=3&uin=407650541&site=qq&menu=yes"><Lxkf title='客服' className="Lxkf"></Lxkf></a>
                        <Shoucang title='收藏' className={(record.is_favorite === 2 ? ' ' : 'displayNone') + " Shoucang"} onClick={(e) => this.handleListshoucang(record.id, e, record)}>3</Shoucang>
                        <Shoucanged title='收藏' className={(record.is_favorite === 1 ? ' ' : 'displayNone') + " Shoucang"} onClick={(e) => this.handleListquiteshoucang(record.id,e)}>3</Shoucanged>
                    </div>
            }],
            marketList: [],
            isListmethod: 'pic',
            search: '',
            page: 1,
            ps: 20,
            category: '',
            checktype: 0,
            sale_type: 0,
            auth_status: 0,
            price_section: 0,
            timeOrder: 1,
            isHas: false,
            priceOrder: 1,
            total: 0
        }
        this.searchhandel = this.searchhandel.bind(this)
    }

    handleListquiteshoucang = (id,e) => {
        api.post({
            str:'/market/delFavorite'
        },{
            id:id
        })
        .then(res => res.json())
        .then(res => {
            if(res.code === 200) {
                message.success(res.msg)
                this.componentDidMount()
            }else{
                message.error(res.msg)
            }
        })
    }

    handleListshoucang(id, e, record) {
        api.post({
            str: '/market/addFavorite'
        }, {
            id: id
        })
            .then(res => res.json())
            .then(res => {
                if (res.code === 200) {
                    message.success(res.msg)
                    this.componentDidMount()
                } else {
                    message.error(res.msg)
                }
            })
    }

    searchhandel(value) {
        api.post({
            str: '/market/getSaleList'
        }, {
            page: this.state.page,
            ps: 20,
            category: this.state.category,
            type: this.state.type,
            sale_type: this.state.sale_type,
            auth_status: this.state.auth_status,
            price_section: this.state.price_section,
            timeOrder: this.state.timeOrder,
            search_value: value,
            priceOrder: this.state.priceOrder,
            search_type: 'market'
        })
            .then(res => res.json())
            .then(res => {
                if (res.code === 200) {
                    this.setState({
                        isHas: res.data.isHas,
                        total: res.data.total,
                        marketList: res.data.dataList
                    })
                } else {
                    message.error(res.msg)
                }
            })
        this.setState({
            search: value
        })
    }

    marketListXq = (type_value,title,application_number,auth_status_name,sale_type_name,type,price,is_favorite,category_name,id) => {
        var arr = []
        arr.push({
            'type_value':type_value,
            'title':title,
            'application_number':application_number,
            'auth_status_name':auth_status_name,
            'sale_type_name':sale_type_name,
            'type':type,
            'price':price,
            'is_favorite':is_favorite,
            'category_name':category_name,
            'id':id
        })
        var ids = JSON.stringify(arr)
        this.props.history.push(`/MarketContent/${ids}`)
    }

    MarketList() {
        var page = this.state.page
        api.post({
            str: '/market/getSaleList'
        }, {
            page: page,
            ps: 20,
            category: '',
            type: '',
            sale_type: '',
            auth_status: '',
            price_section: '',
            timeOrder:this.state.timeOrder,
            search_value: '',
            priceOrder: this.state.priceOrder,
            search_type: 'market'
        })
            .then(res => res.json())
            .then(res => {
                if (res.code === 200) {
                    this.setState({
                        isHas: res.data.isHas,
                        total: res.data.total,
                        marketList: res.data.dataList
                    })
                } else {
                    message.error(res.msg)
                }
            })
    }

    marketXq(type_value,title,application_number,auth_status_name,sale_type_name,type,price,is_favorite,category_name,id,add_time) {
        var arr = []
        arr.push({
            'type_value':type_value,
            'title':title,
            'application_number':application_number,
            'auth_status_name':auth_status_name,
            'sale_type_name':sale_type_name,
            'type':type,
            'price':price,
            'is_favorite':is_favorite,
            'category_name':category_name,
            'id':id,
            'add_time':add_time
        })
        var ids = JSON.stringify(arr)
        this.props.history.push(`/MarketContent/${ids}`)
    }

    handleshoucang(id) {
        api.post({
            str: '/market/addFavorite'
        }, {
            id: id
        })
            .then(res => res.json())
            .then(res => {
                if (res.code === 200) {
                    message.success(res.msg)
                    this.componentDidMount()
                } else {
                    message.error(res.msg)
                }
            })
    }

    handlequiteshoucang(id) {
        api.post({
            str: '/market/delFavorite'
        }, {
            id: id
        })
            .then(res => res.json())
            .then(res => {
                if (res.code === 200) {
                    message.success(res.msg)
                    this.componentDidMount()
                } else {
                    message.error(res.msg)
                }
            })
    }

    handelIndustry(item, index) {
        var type = this.state.checktype
        var sale_type = this.state.sale_type
        var auth_status = this.state.auth_status
        var price_section = this.state.price_section
        var search_value = this.state.search
        var timeOrder = this.state.timeOrder
        var priceOrder = this.state.priceOrder
        var page = this.state.page
        if (item === '不限') {
            api.post({
                str: '/market/getSaleList'
            }, {
                page: page,
                ps: 20,
                category: '',
                type: type,
                sale_type: sale_type,
                auth_status: auth_status,
                price_section: price_section,
                timeOrder: timeOrder,
                search_value: search_value,
                priceOrder: price_section,
                search_type: 'market'
            })
                .then(res => res.json())
                .then(res => {
                    if (res.code === 200) {
                        this.setState({
                            isHas: res.data.isHas,
                            total: res.data.total,
                            marketList: res.data.dataList
                        })
                    } else {
                        message.error(res.msg)
                    }
                })
        } else {
            api.post({
                str: '/market/getSaleList'
            }, {
                page: page,
                ps: 20,
                category: item,
                type: type,
                sale_type: sale_type,
                auth_status: auth_status,
                price_section: price_section,
                timeOrder: timeOrder,
                search_value: search_value,
                priceOrder: priceOrder,
                search_type: 'market'
            })
                .then(res => res.json())
                .then(res => {
                    if (res.code === 200) {
                        this.setState({
                            isHas: res.data.isHas,
                            total: res.data.total,
                            marketList: res.data.dataList
                        })
                    } else {
                        message.error(res.msg)
                    }
                })
        }
        this.setState({
            currentIndex1: index,
            category: item
        })
    }

    handelType(item, index) {
        var category = this.state.category
        var type = index
        var sale_type = this.state.sale_type
        var auth_status = this.state.auth_status
        var price_section = this.state.price_section
        var search_value = this.state.search
        var timeOrder = this.state.timeOrder
        var priceOrder = this.state.priceOrder
        var page = this.state.page
        this.setState({
            checktype: type,
            currentIndex2: index
        })
        api.post({
            str: '/market/getSaleList'
        }, {
            page: page,
            ps: 20,
            category: category,
            type: type,
            sale_type: sale_type,
            auth_status: auth_status,
            price_section: price_section,
            timeOrder: timeOrder,
            search_value: search_value,
            priceOrder: priceOrder,
            search_type: 'market'
        })
            .then(res => res.json())
            .then(res => {
                if (res.code === 200) {
                    this.setState({
                        isHas: res.data.isHas,
                        total: res.data.total,
                        marketList: res.data.dataList
                    })
                } else {
                    message.error(res.msg)
                }
            })
    }

    handelPrice(item, index) {
        var category = this.state.category
        var type = this.state.checktype
        var sale_type = this.state.sale_type
        var auth_status = this.state.auth_status
        var price_section = index
        var search_value = this.state.search
        var timeOrder = this.state.timeOrder
        var priceOrder = this.state.priceOrder
        var page = this.state.page
        this.setState({
            price_section: price_section,
            currentIndex3: index
        })
        api.post({
            str: '/market/getSaleList'
        }, {
            page: page,
            ps: 20,
            category: category,
            type: type,
            sale_type: sale_type,
            auth_status: auth_status,
            price_section: price_section,
            timeOrder: timeOrder,
            search_value: search_value,
            priceOrder: priceOrder,
            search_type: 'market'
        })
            .then(res => res.json())
            .then(res => {
                if (res.code === 200) {
                    this.setState({
                        isHas: res.data.isHas,
                        total: res.data.total,
                        marketList: res.data.dataList
                    })
                } else {
                    message.error(res.msg)
                }
            })
    }

    handelmethod(item, index) {
        var category = this.state.category
        var type = this.state.checktype
        var sale_type = index
        var auth_status = this.state.auth_status
        var price_section = this.state.price_section
        var search_value = this.state.search
        var timeOrder = this.state.timeOrder
        var priceOrder = this.state.priceOrder
        var page = this.state.page
        this.setState({
            sale_type: sale_type,
            currentIndex4: index
        })
        api.post({
            str: '/market/getSaleList'
        }, {
            page: page,
            ps: 20,
            category: category,
            type: type,
            sale_type: sale_type,
            auth_status: auth_status,
            price_section: price_section,
            timeOrder: timeOrder,
            search_value: search_value,
            priceOrder: priceOrder,
            search_type: 'market'
        })
            .then(res => res.json())
            .then(res => {
                if (res.code === 200) {
                    this.setState({
                        isHas: res.data.isHas,
                        total: res.data.total,
                        marketList: res.data.dataList
                    })
                } else {
                    message.error(res.msg)
                }
            })
    }

    handleChangemethod = e => {
        this.setState({
            isListmethod: e.key
        })
    }

    handelstuate(item, index) {
        var category = this.state.category
        var type = this.state.checktype
        var sale_type = this.state.sale_type
        var auth_status = index
        var price_section = this.state.price_section
        var search_value = this.state.search
        var timeOrder = this.state.timeOrder
        var priceOrder = this.state.priceOrder
        var page = this.state.page
        this.setState({
            auth_status: auth_status,
            currentIndex5: index
        })
        api.post({
            str: '/market/getSaleList'
        }, {
            page: page,
            ps: 20,
            category: category,
            type: type,
            sale_type: sale_type,
            auth_status: auth_status,
            price_section: price_section,
            timeOrder: timeOrder,
            search_value: search_value,
            priceOrder: priceOrder,
            search_type: 'market'
        })
            .then(res => res.json())
            .then(res => {
                if (res.code === 200) {
                    this.setState({
                        isHas: res.data.isHas,
                        total: res.data.total,
                        marketList: res.data.dataList
                    })
                } else {
                    message.error(res.msg)
                }
            })
    }

    muchChange() {
        if (this.state.ischange === false) {
            this.setState({
                ischange: true
            })
        } else {
            this.setState({
                ischange: false
            })
        }
    }

    IndustryCheck(e) {
        var categoryarray = e
        var category = categoryarray.toString()
        var isAll = category.indexOf('不限')
        var type = this.state.checktype
        var sale_type = this.state.sale_type
        var auth_status = this.state.auth_status
        var price_section = this.state.price_section
        var search_value = this.state.search
        var timeOrder = this.state.timeOrder
        var priceOrder = this.state.priceOrder
        var page = this.state.page
        this.setState({
            category: category
        })
        if (isAll === 0) {
            api.post({
                str: '/market/getSaleList'
            }, {
                page: page,
                ps: 20,
                category: '',
                type: type,
                sale_type: sale_type,
                auth_status: auth_status,
                price_section: price_section,
                timeOrder: timeOrder,
                search_value: search_value,
                priceOrder: priceOrder,
                search_type: 'market'
            })
                .then(res => res.json())
                .then(res => {
                    if (res.code === 200) {
                        this.setState({
                            isHas: res.data.isHas,
                            total: res.data.total,
                            marketList: res.data.dataList
                        })
                    } else {
                        message.error(res.msg)
                    }
                })
            message.warn('因为勾选不限，默认搜索不限')
        } else {
            api.post({
                str: '/market/getSaleList'
            }, {
                page: page,
                ps: 20,
                category: category,
                type: type,
                sale_type: sale_type,
                auth_status: auth_status,
                price_section: price_section,
                timeOrder: timeOrder,
                search_value: search_value,
                priceOrder: priceOrder,
                search_type: 'market'
            })
                .then(res => res.json())
                .then(res => {
                    if (res.code === 200) {
                        this.setState({
                            isHas: res.data.isHas,
                            total: res.data.total,
                            marketList: res.data.dataList
                        })
                    } else {
                        message.error(res.msg)
                    }
                })
        }
    }

    onPageChange(pageNumber, pageSize) {
        var category = this.state.category
        var type = this.state.checktype
        var sale_type = this.state.sale_type
        var auth_status = this.state.auth_status
        var price_section = this.state.price_section
        var search_value = this.state.search
        var timeOrder = this.state.timeOrder
        var priceOrder = this.state.priceOrder
        var page = pageNumber
        this.setState({
            page: pageNumber,
        })
        api.post({
            str: '/market/getSaleList'
        }, {
            page: page,
            ps: 20,
            category: category,
            type: type,
            sale_type: sale_type,
            auth_status: auth_status,
            price_section: price_section,
            timeOrder: timeOrder,
            search_value: search_value,
            priceOrder: priceOrder,
            search_type: 'market'
        })
            .then(res => res.json())
            .then(res => {
                if (res.code === 200) {
                    this.setState({
                        isHas: res.data.isHas,
                        total: res.data.total,
                        marketList: res.data.dataList
                    })
                } else {
                    message.error(res.msg)
                }
            })
    }

    timeChangedaoxu() {
        var category = this.state.category
        var type = this.state.checktype
        var sale_type = this.state.sale_type
        var auth_status = this.state.auth_status
        var price_section = this.state.price_section
        var search_value = this.state.search
        var page = this.state.page
        api.post({
            str: '/market/getSaleList'
        }, {
            page: page,
            ps: 20,
            category: category,
            type: type,
            sale_type: sale_type,
            auth_status: auth_status,
            price_section: price_section,
            timeOrder: 1,
            search_value: search_value,
            search_type: 'market'
        })
            .then(res => res.json())
            .then(res => {
                if (res.code === 200) {
                    this.setState({
                        isHas: res.data.isHas,
                        total: res.data.total,
                        marketList: res.data.dataList,
                        isTime:2
                    })
                } else {
                    message.error(res.msg)
                }
            })
    }

    timeChangeshengxu() {
        var category = this.state.category
        var type = this.state.checktype
        var sale_type = this.state.sale_type
        var auth_status = this.state.auth_status
        var price_section = this.state.price_section
        var search_value = this.state.search
        var page = this.state.page
        api.post({
            str: '/market/getSaleList'
        }, {
            page: page,
            ps: 20,
            category: category,
            type: type,
            sale_type: sale_type,
            auth_status: auth_status,
            price_section: price_section,
            timeOrder: 2,
            search_value: search_value,
            search_type: 'market'
        })
            .then(res => res.json())
            .then(res => {
                if (res.code === 200) {
                    this.setState({
                        isHas: res.data.isHas,
                        total: res.data.total,
                        marketList: res.data.dataList,
                        isTime:1
                    })
                } else {
                    message.error(res.msg)
                }
            })
    }

    priceChangedaoxu() {
        var category = this.state.category
        var type = this.state.checktype
        var sale_type = this.state.sale_type
        var auth_status = this.state.auth_status
        var price_section = this.state.price_section
        var search_value = this.state.search
        var page = this.state.page
        api.post({
            str: '/market/getSaleList'
        }, {
            page: page,
            ps: 20,
            category: category,
            type: type,
            sale_type: sale_type,
            auth_status: auth_status,
            price_section: price_section,
            search_value: search_value,
            priceOrder: 2,
            search_type: 'market'
        })
            .then(res => res.json())
            .then(res => {
                if (res.code === 200) {
                    this.setState({
                        isHas: res.data.isHas,
                        total: res.data.total,
                        marketList: res.data.dataList,
                        isPrice:2
                    })
                } else {
                    message.error(res.msg)
                }
            })
    }

    priceChangeshengxu() {
        var category = this.state.category
        var type = this.state.checktype
        var sale_type = this.state.sale_type
        var auth_status = this.state.auth_status
        var price_section = this.state.price_section
        var search_value = this.state.search
        var page = this.state.page
        api.post({
            str: '/market/getSaleList'
        }, {
            page: page,
            ps: 20,
            category: category,
            type: type,
            sale_type: sale_type,
            auth_status: auth_status,
            price_section: price_section,
            search_value: search_value,
            priceOrder: 1,
            search_type: 'market'
        })
            .then(res => res.json())
            .then(res => {
                if (res.code === 200) {
                    this.setState({
                        isHas: res.data.isHas,
                        total: res.data.total,
                        marketList: res.data.dataList,
                        isPrice:1
                    })
                } else {
                    message.error(res.msg)
                }
            })
    }

    movetop(){
        
    }

    componentDidMount() {
        this.MarketList()
    }

    render() {
        // const timeChange = (
        //     <Menu>
        //         <Menu.Item>
                    
        //         </Menu.Item>
        //         <Menu.Item className='timeChange-ul'>
                    
        //         </Menu.Item>
        //     </Menu>
        // )
        // const priceChange = (
        //     <Menu>
        //         <Menu.Item>
                   
        //         </Menu.Item>
        //         <Menu.Item className='timeChange-ul'>
                    
        //         </Menu.Item>
        //     </Menu>
        // )
        return (
            <Fragment>
                <Headernav />
                <div className="marketContent-header">
                    <div className="marketContent-area">
                        <div className="market-industry">
                            <div style={{ fontSize: '1.4rem', height: '57px' }}>
                                <p style={{ fontSize: '1.4rem', width: '100%', height: '26px', lineHeight: '26px', textAlign: 'right',fontWeight:'400',color:'#ccc' }}>行业选择:</p>
                                {/* <div onClick={this.muchChange.bind(this)} className="cousor" style={{ fontSize: '0.75em', width: '100%', height: '26px', lineHeight: '26px', marginBottom: '0', textAlign: 'right', color: '#00C8DF', marginTop: '22px' }}>多选</div> */}
                            </div>
                            <ul className={this.state.ischange === false ? "market-industry-ul" : 'displayNone'}>
                                {
                                    this.state.industry.map((item, index) => {
                                        return <li key={index} onClick={this.handelIndustry.bind(this, item, index)} className={(this.state.currentIndex1 === index ? 'currentCss' : '') + " market-industry-li hoverCss"} >{item}</li>
                                    })
                                }
                            </ul>
                            <ul className={(this.state.ischange === true ? 'market-industry-ul' : 'displayNone')}>
                                <Checkbox.Group options={this.state.industry} onChange={this.IndustryCheck.bind(this)} className="market-checkbox hoverCss" />
                            </ul>
                        </div>
                        <div className="market-type">
                            <div style={{ fontSize: '1.4rem', height: '26px' }}>
                                <p style={{ fontSize: '1.4rem', width: '100%', height: '26px', lineHeight: '26px', textAlign: 'right' ,fontWeight:'400',color:'#ccc'}}>专利类型:</p>
                            </div>
                            <ul className="market-type-ul">
                                {
                                    this.state.type.map((item, index) => {
                                        return <li key={index} onClick={this.handelType.bind(this, item, index)} className={(this.state.currentIndex2 === index ? 'currentCss' : '') + " market-type-li hoverCss"} >{item}</li>
                                    })
                                }
                            </ul>
                        </div>
                        <div className="market-type">
                            <div style={{ fontSize: '1.4rem',  height: '26px' }}>
                                <p style={{ fontSize: '1.4rem', width: '100%', height: '26px', lineHeight: '26px', textAlign: 'right' ,fontWeight:'400',color:'#ccc'}}>价格区间:</p>
                            </div>
                            <ul className="market-type-ul">
                                {
                                    this.state.price.map((item, index) => {
                                        return <li key={index} onClick={this.handelPrice.bind(this, item, index)} className={(this.state.currentIndex3 === index ? 'currentCss' : '') + " market-type-li hoverCss"} >{item}</li>
                                    })
                                }
                            </ul>
                        </div>
                        {/* <div className="market-type">
                            <div style={{ fontSize: '0.75em', width: '6%', height: '26px' }}>
                                <p style={{ fontSize: '0.75em', width: '100%', height: '26px', lineHeight: '26px', textAlign: 'right' }}>交易方式:</p>
                            </div>
                            <ul className="market-type-ul">
                                {
                                    this.state.paymethod.map((item, index) => {
                                        return <li key={index} onClick={this.handelmethod.bind(this, item, index)} className={(this.state.currentIndex4 === index ? 'currentCss' : '') + " market-type-li hoverCss"} >{item}</li>
                                    })
                                }
                            </ul>
                        </div> */}
                        <div className="market-type borderNone">
                            <div style={{ fontSize: '1.4rem',  height: '26px' }}>
                                <p style={{ fontSize: '1.4rem', width: '100%', height: '26px', lineHeight: '26px', textAlign: 'right' ,fontWeight:'400',color:'#ccc'}}>专利状态:</p>
                            </div>
                            <ul className="market-type-ul">
                                {
                                    this.state.zlstuate.map((item, index) => {
                                        return <li key={index} onClick={this.handelstuate.bind(this, item, index)} className={(this.state.currentIndex5 === index ? 'currentCss' : '') + " market-type-li hoverCss"} >{item}</li>
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='carousel'>
                    <div className='carousel-content'>
                        <div className='carousel-title'>
                            <span style={{fontSize:'1.5rem',color:'#00C8DF',fontWeight:'700'}}>最新交易</span>
                            <span style={{fontSize:'1.2rem',color:'#00C8DF'}}>The latest deals</span>
                        </div>
                        <div className='carousel-payed'>
                            {
                                this.state.carousellist.map((item,index) => {
                                    return  <div key={index} className='carousel-payed-list'><span>{item.time}</span><span style={{marginLeft:'30px'}}>{item.name} 以<span style={{color:'#00C8DF'}}>￥{item.money}</span>购买了{item.zllx}“{item.zlname}“</span></div>
                                })
                            }
                        </div>
                        <div className='carousel-payed' >
                        {
                                this.state.carousellist2.map((item,index) => {
                                    return  <div key={index} className='carousel-payed-list2'><span>{item.time}</span><span style={{marginLeft:'30px'}}>{item.name} 以<span style={{color:'#00C8DF'}}>￥{item.money}</span>购买了{item.zllx}“{item.zlname}“</span></div>
                                })
                            }
                        </div>
                    </div>
                </div>
                
                <Marketsearch search={this.searchhandel} />
                <div className='mode-selection'>
                    <div className='mode-selection-content'>
                        <div onClick={this.timeChangedaoxu.bind(this)} className={(this.state.isTime === 1 ? '' : 'displayNone') + " timeChange-li cousor"} >上架时间  ↑</div>
                        <div onClick={this.timeChangeshengxu.bind(this)} className={(this.state.isTime === 2 ? '' : 'displayNone') + " timeChange-li cousor"}>上架时间  ↓</div>
                        <div onClick={this.priceChangedaoxu.bind(this)} className={(this.state.isPrice === 1 ? '' : 'displayNone') + " timeChange-li cousor"}>商品价格  ↑</div>
                        <div onClick={this.priceChangeshengxu.bind(this)} className={(this.state.isPrice === 2 ? '' : 'displayNone') + " timeChange-li cousor"}>商品价格  ↓</div>
                        <Menu defaultSelectedKeys='pic' onClick={this.handleChangemethod.bind(this)} className='mode-list'>
                            <Menu.Item className='mode-list-li' key="pic">图文模式</Menu.Item>
                            <Menu.Item className='mode-list-li' key='list'>列表模式</Menu.Item>
                        </Menu>
                    </div>
                </div>
                <div className={this.state.isListmethod === 'pic' ? 'method-content' : 'displayNone'}>
                    <div className='method-content-wrap'>
                        {
                            this.state.marketList.map((item, index) => {
                                return <div key={index} className='market-content-list' >
                                    <div className='cousor' style={{ width: '100%', height: '140px', position: 'relative' }} onClick={this.marketXq.bind(this, item.type_value,item.title,item.application_number,item.auth_status_name,item.sale_type_name,item.type,item.price,item.is_favorite,item.category_name,item.id,item.add_time)} >
                                        <img className='cousor' alt='list' src={item.type_value === 1 ? 'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210315/8e4cf8f75535b661699cd65923c14d6d74a7fb60.png' : 'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210315/e64daa51d019620281eb99b3e8ecb9e49d6c168b.png' } style={{ width: '100%', height: '140px', objectFit: "cover" }} />
                                        {/* <div className={item.type_value === 1 ? 'fmzl' : ''}>{item.type}</div>
                                        <div className={item.type_value === 3 ? 'syxx' : ''}>{item.type}</div>
                                        <div className={item.type_value === 2 ? 'wgzl' : ''}>{item.type}</div> */}
                                    </div>
                                    <div onClick={this.marketXq.bind(this, item.type_value,item.title,item.application_number,item.auth_status_name,item.sale_type_name,item.type,item.price,item.is_favorite,item.category_name,item.id,item.add_time)} className='market-content-list-title hoverCss cousor'>{item.title}
                                        <div className={item.type_value === 1 ? 'fmzl' : 'displayNone'}>&lt;发明&gt;</div>
                                        <div className={item.type_value === 3 ? 'syxx' : 'displayNone'}>&lt;新型&gt;</div>
                                    </div>
                                    <div className='market-content-list-area1'>
                                        <div style={{fontWeight:'400',fontSize:'1.2rem',color:'#828282'}}>#{item.category_name}</div>
                                    </div>
                                    <div className='market-content-list-area'>
                                        <div style={{fontWeight:'400',fontSize:'1.2rem',color:'#828282'}}>{item.auth_status_name}</div>
                                        <div className={item.is_negotiate === 0 ? '' : 'displayNone'} style={{ marginLeft: 'auto', marginRight: '0', fontSize: '0.875rem', color: '#EA4335' ,fontWeight:'700'}}>{item.price}</div>
                                        <div className={item.is_negotiate === 1 ? '' : 'displayNone'} style={{ marginLeft: 'auto', marginRight: '0', fontSize: '0.875rem', color: '#EA4335',fontWeight:'700' }}>议价</div>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>
                <div className={this.state.isListmethod === 'list' ? 'method-content-contentlist' : 'displayNone'}>
                    <Table rowKey="id" bordered={true} pagination={false} className='market-table' dataSource={this.state.marketList} columns={this.state.tableList}></Table>
                </div>
                <div style={{ width: '100%', height: '100%', display: "flex", justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                    <Pagination showSizeChanger={false} href="#top" style={{ width: '65.1%', borderTop: '0.5px solid rgba(0, 0, 0, 0.1)', height: '60px', marginTop: '24px', paddingLeft: '24px', display: "flex", alignItems: 'center', justifyContent: 'center' }} defaultCurrent={1} total={this.state.total} pageSize={20} onChange={this.onPageChange.bind(this)} />
                </div>
                <Fixednav />
                <SearchFixed />
            </Fragment>
        )
    }
}

export default withRouter(MarketContent)