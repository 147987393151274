import { DatePicker, Popover, message, Pagination, Progress, Select, Table, Menu, Checkbox } from 'antd'
import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import '../Component-css/AnnualfeeContent.css'
import Headernav from './Headernav'
import Icon from '@ant-design/icons'
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import api from '../api/api'
import moment from 'moment'

const { Option } = Select

/**
 * 封装JSON多项删除并导出
 * @author zxy
 * @export
 * @param {object} list json列表
 * @param {array} args 要删除的元素，存入数组
 * @return {object} 
 */
export function Del(list, args) {
    args.forEach((element) => {
        delete list[element]
    })
    return list
}

class AnnualfeeContent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            percentList: [
                { name: '发明专利占比', percent: 0, title: 'Proportion of invention patents', color: '#2F80ED' },
                { name: '实用新型占比', percent: 0, title: 'Proportion of utility model patents', color: '#F2994A' },
                { name: '外观专利占比', percent: 0, title: 'Appearance patent percentage', color: '#BB6BD9' }
            ],
            column: [
                {
                    title: '序号',
                    dataIndex: 'index',
                    render: (text, record, index) => `${index + 1}`
                }, {
                    title: '专利号',
                    dataIndex: 'zlid',
                    align: 'center',
                }, {
                    title: '专利名称',
                    dataIndex: 'zlmc',
                    align: 'center'
                }, {
                    title: '专利类型',
                    dataIndex: 'zllx',
                    align: 'center'
                }, {
                    title: '专利状态',
                    dataIndex: 'patent_status_name',
                    align: 'center',
                }, {
                    title: '缴费时间',
                    dataIndex: 'date_time',
                    align: 'center',
                    render: (text, record) => {
                        return <div>{record.date_time == '' ? '-' : record.date_time}</div>
                    }
                }, {
                    title: '待缴金额',
                    dataIndex: 'money',
                    align: 'center',
                    render: (text, record) => {
                        return <div>{record.money == '' ? '-' : record.money}</div>
                    }
                }, {
                    title: '操作',
                    dataIndex: 'options',
                    align: 'center',
                    render: (text, record) => {
                        const data = record.pay_able
                        const rowSelections = {
                            onChange: (selectedRowKeys, selectedRows) => {
                                this.setState({
                                    selectedRows: selectedRows
                                })
                            },
                        }
                        const columnMenu = [
                            {
                                title: '年限',
                                dataIndex: 'category',
                                width: 100,
                                render: (text, record) => {
                                    let str = record.category
                                    let list = str.split('')
                                    let index = str.indexOf('第')
                                    let string = list.splice(index, list.length - 6)
                                    let category = string.join('')
                                    return <span key={record.date}>{category}</span>
                                }
                            },
                            {
                                title: '费用',
                                dataIndex: 'money',
                                width: 100
                            }
                        ]
                        const menu = (
                            <Menu className='AnnualfeeContent-menu'>
                                <Table bordered={false} rowKey='date' rowSelection={{ type: 'checkbox', ...rowSelections }} columns={columnMenu} dataSource={data} pagination={false} />
                                <div className='AnnualfeeContent-menu-bottom'>
                                    <div onClick={this.handlePay.bind(this)}>前往结账</div>
                                </div>
                            </Menu>
                        )
                        const menu2 = (
                            <Menu className='AnnualfeeContent-process'>
                                <p>专利进度</p>
                                <ul>
                                    {
                                        this.state.trial.length > 0 ? this.state.trial.map((item, index) => {
                                            return <li key={index}>
                                                <div className='AnnualfeeContent-process-header'>
                                                    <div className='AnnualfeeContent-process-header-wrap'>{item.name == '第一次审查意见' || item.name == '第N次审查意见' ? <h3>{item.num}</h3>:<img src={item.img} />}</div>
                                                    <span></span>
                                                    <span></span>
                                                </div>
                                                <b className='AnnualfeeContent-process-title'>{item.name}</b>
                                                <span className='AnnualfeeContent-process-time'>{item.date}</span>
                                            </li>
                                        }) : <div className='AnnualfeeContent-process-null'>
                                            暂无审查信息
                                        </div>
                                    }
                                </ul>
                            </Menu>
                        )
                        return <div>
                            <Popover onClick={this.handlepayData.bind(this, record.zlid, record.zlmc, record.zllx)} placement='bottom' content={menu} style={{ positions: 'relative' }} trigger={'click'}>
                                <div className={record.patent_status == '1' ? '' : 'displaynone'} style={{ color: '#00C8DF', cursor: 'pointer' }}>缴纳</div>
                            </Popover>
                            <Popover onClick={this.handleprocess.bind(this, record.trial_data)} placement='bottom' content={menu2} style={{ positions: 'relative' }} trigger={'click'}>
                                <div className={record.patent_status == '2' ? '' : 'displaynone'} style={{ color: '#00C8DF', cursor: 'pointer' }}>进度</div>
                            </Popover>
                            <div className={record.patent_status == '3' ? '' : 'displaynone'} style={{ color: '#00C8DF', cursor: 'pointer' }}>-</div>
                        </div>
                    }
                }
            ],
            visible: false,
            data: [],
            ids: [],
            page: 1,
            total: 10,
            zllx: 0,
            status: '',
            payList: [],
            monitortotal: 0,
            selectedRows: [],
            monitorlist: [],
            monitorfeelist: [],
            monitorfeetotal: 0,
            annualfeecountStart: '',
            annualfeecountEnd: '',
            monitorfeecountlist: [],
            monitorfeecounttotal: 0,
            annualfeeStart: '',
            annualfeeEnd: '',
            id: 0,
            name: '',
            trial:[],
            zllxValue:''
        }
    }

    componentDidMount() {
        this.monitorcount()
        this.monitorfeecount()
        this.monitorfee()
        this.moncountpercentage()
        this.monitorpagelist(1, 10)
    }

    /**
     * 审查进度
     * @description 审查进度数据处理，添加子属性
     * @author zxy
     * @param {*} trial_data
     * @memberof AnnualfeeContent
     */
    handleprocess(trial_data) {
        trial_data.forEach((element) => {
            if(element.name == '专利受理'){
                element.img = 'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20211116/e7bb8a285cba12c61d1b84e0e7ea39fcaa4e17eb.png'
            }else if(element.name == '初步审查合格'){
                element.img = 'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20211116/67006be21d308d3aa316e9895a76a5206f991721.png'
            }else if(element.name == '进入实质审查'){
                element.img = 'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20211116/67006be21d308d3aa316e9895a76a5206f991721.png'
            }else if(element.name == '第一次审查意见'){
                element.num = '01'
            }else if(element.name == '第N次审查意见') {
                element.num = 'N'
            }else if(element.name == '驳回待复审'){
                element.img = 'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20211116/2bc2bff642e57ef8114fd6e70409620cbca8389d.png'
            }else if(element.name == '授权待登记'){
                element.img = 'http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20211116/090082b26fd5db1ab5662315551872cfdfe66002.png'
            }
        })
        this.setState({
            trial:trial_data
        })
    }

    /**
     * 前往支付(单个专利)
     * @author zxy
     * @memberof AnnualfeeContent
     */
    handlePay() {
        var pay_able = this.state.selectedRows
        var id = this.state.id
        var name = this.state.name
        var sum = 0
        var zllxValue = this.state.zllxValue
        pay_able.forEach((element) => {
            sum += Number(element.money)
            return sum
        })
        var list = [{
            zlid: id,
            zlmc: name,
            pay_able,
            money: sum,
            zllx:zllxValue
        }]
        list[0].pay_able.forEach((item) => {
            var s = item.category.split('')
            var index = item.category.indexOf('第')
            var _s = s.splice(index,s.length - 1)
            var _ss = _s.join('')
            item.category = _ss
        })
        var ls = JSON.stringify(list)
        localStorage.setItem('payList', ls)
        this.props.history.push(`/PayConfirm`)
    }

    /**
     * 专利缴费多选
     * @author zxy
     * @param {int} id
     * @param {string} name
     * @param {string} zllx
     * @memberof AnnualfeeContent
     */
    handlepayData(id, name, zllx) {
        this.setState({
            id,
            name,
            zllxValue:zllx
        })
    }

    /**
     * 分页处理监控列表
     * @author zxy
     * @param {*} pageNumber
     * @param {*} pageSize
     * @memberof AnnualfeeContent
     */
    handlepagechange(pageNumber, pageSize) {
        var zllx = this.state.zllx,
            status = this.state.status
        this.monitorpagelist(pageNumber, 10, zllx, status)
    }

    /**
     * 专利监控总数
     * @author zxy
     * @memberof AnnualfeeContent
     */
    monitorcount() {
        api.post({
            str: '/patentmonitor/getPatentMonitorCount'
        })
            .then(res => res.json())
            .then(res => {
                if (res.code == 200) {
                    var list = [
                        {
                            name: '发明',
                            total: res.data.fm_num
                        }, {
                            name: '新型',
                            total: res.data.xx_num
                        }, {
                            name: '外观',
                            total: res.data.wg_num
                        }
                    ]
                    this.setState({
                        monitortotal: res.data.total,
                        monitorlist: list
                    })
                }
            })
    }

    /**
    * 待缴数量
    * @author zxy
    * @param { String } start
    * @param { String } end
    * @memberof AnnualfeeContent
    */
    monitorfeecount(start, end) {
        api.post({
            str: '/patentmonitor/getPatentMonitorFeeCount'
        }, {
            start_date: start,
            end_date: end
        })
            .then(res => res.json())
            .then(res => {
                if (res.code == 200) {
                    var list = [
                        { name: '发明', total: res.data.fm_num },
                        { name: '新型', total: res.data.xx_num },
                        { name: '外观', total: res.data.wg_num }
                    ]
                    this.setState({
                        monitorfeecountlist: list,
                        monitorfeecounttotal: res.data.total
                    })
                } else {
                    message.error(res.msg)
                }
            })
    }

    /**
     * 待缴数量起始日期
     * @author zxy
     * @param {*} date
     * @param {*} dateString
     * @memberof AnnualfeeContent
     */
    handledateStart(date, dateString) {
        var end = this.state.annualfeecountEnd
        this.setState({
            annualfeecountStart: dateString
        })
        setTimeout(() => {
            this.monitorfeecount(dateString, end)
        }, 100);
    }

    /**
     * 待缴数量终止日期
     * @author zxy
     * @param {*} date
     * @param {*} dateString
     * @memberof AnnualfeeContent
     */
    handledateEnd(date, dateString) {
        var start = this.state.annualfeecountStart
        this.setState({
            annualfeecountEnd: dateString
        })
        setTimeout(() => {
            this.monitorfeecount(start, dateString)
        }, 100);
    }

    /**
     *  待缴费用
     * @author zxy
     * @param {*} start
     * @param {*} end
     * @memberof AnnualfeeContent
     */
    monitorfee(start, end) {
        api.post({
            str: '/patentmonitor/getPatentMonitorFee'
        }, {
            start_date: start,
            end_date: end
        })
            .then(res => res.json())
            .then(res => {
                if (res.code == 200) {
                    var list = [
                        { name: '发明', total: res.data.fm_fee },
                        { name: '新型', total: res.data.xx_fee },
                        { name: '外观', total: res.data.wg_fee }
                    ]
                    this.setState({
                        monitorfeelist: list,
                        monitorfeetotal: res.data.total_fee
                    })
                } else {
                    message.error(res.msg)
                }
            })
    }

    /**
     *  待缴费用起始时间
     * @author zxy
     * @param {*} date
     * @param {*} dateString
     * @memberof AnnualfeeContent
     */
    handlefeedateStart(date, dateString) {
        var end = this.state.annualfeeEnd
        this.setState({
            annualfeeStart: dateString
        })
        setTimeout(() => {
            this.monitorfee(dateString, end)
        }, 100);
    }

    /**
     * 待缴费用结束时间
     * @author zxy
     * @param {*} date
     * @param {*} dateString
     * @memberof AnnualfeeContent
     */
    handlefeedateEnd(date, dateString) {
        var start = this.state.annualfeeStart
        this.setState({
            annualfeeEnd: dateString
        })
        setTimeout(() => {
            this.monitorfee(start, dateString)
        }, 100);
    }

    /**
     * 查询专利监控数量占比
     * @author zxy
     * @memberof AnnualfeeContent
     */
    moncountpercentage() {
        api.post({
            str: '/patentmonitor/getPatMonCountPercentage'
        })
            .then(res => res.json())
            .then(res => {
                if (res.code == 200) {
                    var reg = new RegExp('%')
                    var fm_per = res.data.fm_per.replace(reg, '')
                    var xx_per = res.data.xx_per.replace(reg, '')
                    var wg_per = res.data.wg_per.replace(reg, '')
                    var list = [
                        { name: '发明专利占比', percent: fm_per, title: 'Proportion of invention patents', color: '#2F80ED' },
                        { name: '实用新型占比', percent: xx_per, title: 'Proportion of utility model patents', color: '#F2994A' },
                        { name: '外观专利占比', percent: wg_per, title: 'Appearance patent percentage', color: '#BB6BD9' }
                    ]
                    this.setState({
                        percentList: list
                    })
                } else {
                    message.error(res.msg)
                }
            })
    }

    /**
     *  监控管理分页列表
     * @author zxy
     * @param {*} page
     * @param {*} ps
     * @param {*} zllx
     * @param {*} patent_status
     * @memberof AnnualfeeContent
     */
    monitorpagelist(page, ps, zllx, patent_status) {
        api.post({
            str: '/patentmonitor/patentMonitorPageList'
        }, {
            page,
            ps,
            zllx,
            patent_status
        })
            .then(res => res.json())
            .then(res => {
                if (res.code == 200) {
                    var list = res.data.dataList
                    list.forEach(element => {
                        if (element.pay_able.length > 5) {
                            var l = element.pay_able.splice(0, 5)
                            element.pay_able = l
                        }
                    });
                    this.setState({
                        data: list,
                        page: res.data.page,
                        total: res.data.total
                    })
                } else {
                    message.error(res.msg)
                }
            })
    }

    /**
     * 专利类型
     * @author zxy
     * @param {*} value
     * @memberof AnnualfeeContent
     */
    handletype(value) {
        var status = this.state.status
        this.setState({
            zllx: value
        })
        this.monitorpagelist(1, 10, value, status)
    }

    /**
     * 专利状态
     * @author zxy
     * @memberof AnnualfeeContent
     */
    handlestatus(value) {
        var zllx = this.state.zllx
        this.setState({
            status: value
        })
        this.monitorpagelist(1, 10, zllx, value)
    }

    /**
     * 取消监控
     *
     * @memberof AnnualfeeContent
     */
    handledelMonitor() {
        var page = this.state.page
        var zllx = this.state.zllx
        var status = this.state.status
        var ids = this.state.ids
        if (ids.length == 0) {
            message.error('请选择要取消的专利')
        } else {
            api.post({
                str: '/monitor/delMonitor'
            }, {
                ids
            })
                .then(res => res.json())
                .then(res => {
                    if (res.code == 200) {
                        this.monitorpagelist(page, 10, zllx, status)
                    } else {
                        message.error(res.msg)
                    }
                })
        }
    }

    /**
     * 批量缴费
     * @author zxy
     * @memberof AnnualfeeContent
     */
    handlePaylist() {
        var list = JSON.parse(JSON.stringify(this.state.payList))
        if (list.length > 0) {
            list.forEach((element) => {
                if (element.patent_status == 1) {
                    element.pay_able.splice(1, element.pay_able.length - 1)
                    Del(element, ['add_time', 'category', 'current_time', 'current_trial', 'date_time', 'patent_status', 'patent_status_name', 'trial_data', 'zlid_code', 'uid', 'id'])
                    element.pay_able.forEach((item) => {
                       var s = item.category.split('')
                       var index = s.indexOf('第')
                       var _s = s.splice(index,item.category.length - 1)
                       var _ss = _s.join('')
                       item.category = _ss
                    })
                    var ls = JSON.stringify(list)
                    localStorage.setItem('payList', ls)
                    this.props.history.push(`/PayConfirm`)
                } else {
                    message.error('选项中包含无法缴费专利')
                }
            })
        } else {
            message.error('请选择缴费专利')
        }
    }

    render() {
        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    ids: selectedRowKeys,
                    payList: selectedRows
                })
            },
        }
        const disabledDate = (current) => {
            return current && current > moment().endOf('day');
        }
        return (
            <Fragment>
                <Headernav />
                <div className='Annualfee-wrap'>
                    <ul className='Annualfee-header'>
                        <li className='Annualfee-header-item'>
                            <div className='Annualfee-header-item-left'>
                                <p className='Annualfee-header-item-left-p'>待缴专利件数</p>
                                <div className='Annualfee-header-item-left-date'>
                                    <DatePicker onChange={this.handledateStart.bind(this)} disabledDate={disabledDate} allowClear={false} locale={locale} bordered={false} style={{ width: '100px', color: '#fff', fontSize: '1.2rem', fontWeight: '700', padding: 0 }} />
                                    <span style={{ margin: 'auto 8px', color: '#fff' }}>-</span>
                                    <DatePicker onChange={this.handledateEnd.bind(this)} disabledDate={disabledDate} allowClear={false} locale={locale} bordered={false} style={{ width: '100px', color: '#fff', fontSize: '1.2rem', fontWeight: '700', padding: 0 }} />
                                </div>
                                <ul className='Annualfee-header-item-left-information'>
                                    {
                                        this.state.monitorfeecountlist.map((item, index) => {
                                            return <li key={index}>
                                                <p>{item.name}</p>
                                                <span>{item.total}</span>
                                            </li>
                                        })
                                    }
                                </ul>
                            </div>
                            <p className='Annualfee-header-item-number'>{this.state.monitorfeecounttotal}</p>
                            <span className='Annualfee-header-item-unit'>件</span>
                        </li>
                        <li className='Annualfee-header-item'>
                            <div className='Annualfee-header-item-left'>
                                <p className='Annualfee-header-item-left-p'>待缴年费总额</p>
                                <div className='Annualfee-header-item-left-date'>
                                    <DatePicker onChange={this.handlefeedateStart.bind(this)} disabledDate={disabledDate} allowClear={false} locale={locale} bordered={false} style={{ width: '100px', color: '#fff', fontSize: '1.2rem', fontWeight: '700', padding: 0 }} />
                                    <span style={{ margin: 'auto 8px', color: '#fff' }}>-</span>
                                    <DatePicker onChange={this.handlefeedateEnd.bind(this)} disabledDate={disabledDate} allowClear={false} locale={locale} bordered={false} style={{ width: '100px', color: '#fff', fontSize: '1.2rem', fontWeight: '700', padding: 0 }} />
                                </div>
                                <ul className='Annualfee-header-item-left-information'>
                                    {
                                        this.state.monitorfeelist.map((item, index) => {
                                            return <li key={index}>
                                                <p>{item.name}</p>
                                                <span>{item.total}</span>
                                            </li>
                                        })
                                    }
                                </ul>
                            </div>
                            <p className='Annualfee-header-item-number'>{this.state.monitorfeetotal}</p>
                            <span className='Annualfee-header-item-unit'>元</span>
                        </li>
                        <li className='Annualfee-header-item'>
                            <div className='Annualfee-header-item-left'>
                                <p className='Annualfee-header-item-left-p'>已经为您监控的专利</p>
                                <ul className='Annualfee-header-item-left-information'>
                                    {
                                        this.state.monitorlist.map((item, index) => {
                                            return <li key={index}>
                                                <p>{item.name}</p>
                                                <span>{item.total}</span>
                                            </li>
                                        })
                                    }
                                </ul>
                            </div>
                            <p className='Annualfee-header-item-number'>{this.state.monitortotal}</p>
                            <span className='Annualfee-header-item-unit'>件</span>
                        </li>
                    </ul>

                    <div className='Annualfee-echarts-wrap'>
                        <div className='Annualfee-echarts'></div>
                        <div className='Annualfee-echarts-right'>
                            {
                                this.state.percentList.map((item, index) => {
                                    return <li key={index}>
                                        <Progress strokeWidth={6} trailColor='#4f4f4f' strokeColor={item.color} width={90} type="circle" percent={item.percent} />
                                        <div className='Annualfee-echarts-right-percent'>
                                            <p>{item.name}</p>
                                            <span>{item.title}</span>
                                        </div>
                                    </li>
                                })
                            }
                        </div>
                    </div>

                    <div className='Annualfee-table-title'>
                        <p>监控管理</p>
                        <span>Monitoring management</span>
                        <Select className='Annualfee-table-patenttype' defaultValue='专利类型' onChange={this.handletype.bind(this)}>
                            <Option className='Annualfee-table-patenttype-item' value='发明专利'>发明专利</Option>
                            <Option className='Annualfee-table-patenttype-item' value='实用新型'>实用新型</Option>
                            <Option className='Annualfee-table-patenttype-item' value='外观专利'>外观专利</Option>
                        </Select>

                        <Select className='Annualfee-table-patenttype marginright' defaultValue='专利状态' onChange={this.handlestatus.bind(this)}>
                            <Option className='Annualfee-table-patenttype-item' value='1'>有效专利</Option>
                            <Option className='Annualfee-table-patenttype-item' value='2'>审查专利</Option>
                            <Option className='Annualfee-table-patenttype-item' value='3'>失效专利</Option>
                        </Select>
                    </div>

                    <Table rowKey='id' rowSelection={{ type: 'checkbox', ...rowSelection }} pagination={false} bordered rowClassName='Annualfee-table-row' className={this.state.total > 0 ? 'Annualfee-table' : 'displaynone'} columns={this.state.column} dataSource={this.state.data} />
                    <div className='Annualfee-table-options'>
                        <span onClick={this.handledelMonitor.bind(this)} className='Annualfee-equit'>取消监控</span>
                        <span onClick={this.handlePaylist.bind(this)} className='Annualfee-pay'>批量缴费</span>
                        <p>注：批量缴费仅缴纳当年年费</p>
                        <Pagination className={this.state.total > 0 ? 'Annualfee-table-pagination' : ' displaynone'} current={this.state.page} pageSize={10} defaultCurrent={1} total={this.state.total} onChange={this.handlepagechange.bind(this)} />
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default withRouter(AnnualfeeContent)