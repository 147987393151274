import { message, Pagination } from 'antd'
import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import '../Component-css/ReleaseCollect.css'
import Headernav from './Headernav'
import api from '../api/api'

class ReleaseMine extends Component {
    constructor(props) {
        super(props)

        this.state = {
            collectlist: [],
            page: 1,
            total: 0,
            author: '',
            authorHead: '',
            articleNum: ''
        }
    }

    qs() {
        this.props.history.push('/Qishuo')
    }

    componentDidMount(){
        this.minelist()
    }

    /**
     * 投稿文章列表
     * @author zxy
     * @memberof ReleaseMine
     */
    minelist() {
        var page = this.state.page
        api.post({
            str: '/talk/getUserArticle'
        }, {
            page,
            ps: 10
        })
            .then(res => res.json())
            .then(res => {
                if (res.code == 200) {
                    this.setState({
                        page: page,
                        total: res.data.total,
                        author: res.data.authorName,
                        authorHead: res.data.authorHead,
                        articleNum: res.data.articleNum,
                        collectlist: res.data.dataList
                    })
                } else {
                    message.error(res.msg)
                }
            })
    }

    /**
     * 文章详情
     * @author zxy
     * @param {*} id
     * @memberof ReleaseMine
     */
    handledetail(id){
        this.props.history.push('/QishuoXq/' + id)
    }

    /**
     * 文章分页管理
     * @author zxy
     * @param {*} pageNumber
     * @param {*} pageSize
     * @memberof ReleaseMine
     */
    pagechange(pageNumber,pageSize){
        setTimeout(() => {
            this.setState({
                page:pageNumber
            })
        }, 100);
        this.minelist()
    }

    render() {
        return (
            <Fragment>
                <Headernav />
                <div style={{ marginLeft: 'auto', marginRight: 'auto', width: '100%', display: 'flex', justifyContent: 'center' }}><div className='qs-release-mine' style={{ borderBottom: '0.5px solid #D7D7D7' }}><p>我的项目</p></div></div>
                <div className='qs-relesase-mine-content-wrap' >
                    <div className={this.state.collectlist.length == 0 ? 'displaynone' : 'qs-release-mine-content'} >
                        <ul className='qs-release-mine-content-ul'>
                            {
                                this.state.collectlist.map((item, index) => {
                                    return <li className='qs-release-mine-content-li' onClick={this.handledetail.bind(this,item.id)} key={index} >
                                        <div>
                                            <img src={item.head} />
                                            <span>{item.name}</span>
                                            <p>发布时间：{item.add_time}</p>
                                        </div>
                                        <div>{item.title}</div>
                                        <div>{item.content}</div>
                                    </li>
                                })
                            }
                        </ul>
                        <Pagination style={{marginTop:20}} onChange={this.pagechange.bind(this)} pageSize={10} current={this.state.page} defaultCurrent={1} total={this.state.total} />
                    </div>
                    <div className={this.state.collectlist.length == 0 ? 'qs-release-collect-content-null' : 'displaynone'}>
                        <img src='http://qzhi2019-oss.oss-cn-qingdao.aliyuncs.com/qzhip/image/20210720/5bcb89f9feae4f6f60c7ba5bad74b24c35139d7c.png' />
                        <span>您还没有投稿</span>
                        <div onClick={this.qs.bind(this)}>其说首页</div>
                    </div>
                    <div className='qs-release-mine-content-aside'>
                        <div className='qs-release-mine-content-aside-information'>
                            <img src={this.state.authorHead} />
                            <p>{this.state.author}</p>
                            <span>{this.state.articleNum}篇投稿</span>
                        </div>
                        {/* <div>广告位招商</div> */}
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default withRouter(ReleaseMine)