
import React, { Component, Fragment } from 'react'
import Headernav from '../Component/Headernav.js'
import ZlList from '../Component/ZlList.js'
import ContentPath from '../Component/ContentPath.js'
import Fixednav from '../Component/Fixednav.js'

/**
 *
 *
 * @class Zlxq 专利详情
 * @extends {Component} zxy
 */
class Zlxq extends Component {
    constructor(props) {
        super(props)

        this.state = {
            
        }
    }

    render() {
        return (
            <Fragment>
                <Headernav style={{ zIndex:'1'}} />
                <div style={{width:'100%',display:'flex',flexDirection:'row',backgroundColor:'#fff'}}>
                    <ZlList/>
                    <div style={{width:'85%',display:'flex',flexDirection:'column',marginLeft:'18px'}}>
                        <ContentPath/>
                    </div>
                <Fixednav/>
                </div>
            </Fragment>
        )
    }
}

export default Zlxq